
<template>
  <div class="h5Protection">
    <div class="h5Protection-header">
      <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/assure-icon.svg" alt="">
      <span class="h5Protection-header-buyer">Buyer Guarantee</span>
      <span class="h5Protection-header-purchase">Purchase Protection</span>
      <img src="@/assets/H5/offer/safe-icon.png" alt="" class="safe-icon">
    </div>
    <div class="h5Protection-main">
      <div class="h5Protection-main-content">
        <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/secure-icon.png" alt="" class="h5Protection-img-content">
        <div class="h5Protection-text-content">
          <div class="h5Protection-text-content-title">Secure Payments</div>
          <div class="h5Protection-text-content-desc">Every payment you make on Bestdealer is secured with strict SSL encryption and PCI DSS data protection protocols.</div>
        </div>
      </div>
      <div class="h5Protection-line"></div>
      <div class="h5Protection-main-content h5Protection-second-content">
        <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/standard-icon.png" alt="" class="h5Protection-img-content">
        <div class="h5Protection-text-content">
          <div class="h5Protection-text-content-title">Standard Refund Policy</div>
          <div class="h5Protection-text-content-desc">If your order hasn’t shipped, is missing, or has product issues, Please contact us proactively to request a refund.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import './index.less';
</style>