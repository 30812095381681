<template>
  <H5SearchFoucs v-if="searchFocus" @back="searchFocus = false" />
  <div class="h5-keywords-main" ref="kewordsMainRef" @scroll="layoutOnScroll">
    <KeywordsMiniHeader 
      @focus="searchFocus = true" 
      v-model="inputValue" 
      :isShow="showMiniHeader" />
    <KeywordsHeader 
      @focus="searchFocus = true" 
      v-model="inputValue" 
      :hidden="showMiniHeader"
      :canInput="false"  />

    <div v-show="showGif" class="wait-miniute">
      <div class="wait-miniute-gif">
        <img src="@/assets/H5/home/wait-minute.gif" width="200px" alt="">
      </div>
      <div class="wait-miniute-text">
        <div class="row1">Wait a minute...</div>
        <div class="row2">We are translating search “<span class="row2-keyword">{{ searchKeywords }}</span>”</div>
      </div>
    </div>
    <div v-show="!showGif">
      <div class="h5-search-result">
        <div class="main-text">Results for “<span class="h5-search-result-keywords">{{ searchKeywords }}</span>” </div>
        <div class="sub-text">All items are sold directly by the manufacturer.</div>
      </div>
      <H5TabFilters 
        @search="tabFilterSearch" 
        @categorySearch="tabCategorySearch"
        :fixed="showMiniHeader"
        :categoryId="mostCategoryId" />
      <div v-if="!showEmpty" class="found-items-title">We found <span>{{ totalNums ? new Intl.NumberFormat().format(totalNums) : '**' }}</span> items for you</div>
      <Product />
      <H5Empty v-if="showEmpty" style="height: calc(100vh - 400px)" />
      <template v-else>
        <div v-if="!loadMoreSleleton" class="bottom-line">
          <div class="bottom-line-text">I'm a bottom line</div>
        </div>
        <div v-if="!loadMoreSleleton" class="homeview-footer">
          <div class="homeview-footer-top">
            <div class="homeview-footer-top-1">CS Center</div>
            <div class="homeview-footer-top-2">Support@bestdealer.com</div>
            <div class="homeview-footer-top-3">Monday - Friday | AM 10:00 - PM 18:00</div>
          </div>
          <div class="homeview-footer-line"></div>
          <div class="homeview-footer-bottom">
            <div class="homeview-footer-bottom-1">Coypright 2024 © BestDealer.All Rights Reserved</div>
            <div class="homeview-footer-bottom-2">Visit our corporate website at 1688.besedealer.com</div>
          </div>
        </div>
      </template>
    </div>
  </div>

  <H5Dialog v-model="showLoginOutPopup" class="h5-dialog-content" :height="`calc(100vh - 32px)`">
    <H5LoginIn :handleClickOutside="handleLoginClickOutside" />
  </H5Dialog>
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { fetchData } from '../../../../../../utils/fetch';
import Product from '@/components/H5/Product/index.vue'
import { buriedPointUtil, gaAnalytics, findMostFrequentCategory, redirectWithSearch, checkHasQueryLanguage } from '@/utils/utils';
import H5LoginIn from '@/components/H5/H5LoginIn/index.vue';
import H5Dialog from '@/components/H5/H5Dialog/index.vue';
import KeywordsHeader from '@/components/H5/KeywordsHeader/index.vue'
import KeywordsMiniHeader from '@/components/H5/KeywordsMiniHeader/index.vue'
import H5TabFilters from '@/components/H5/H5TabFilters/index.vue'
import H5Empty from '@/components/H5/H5Empty/index.vue'
import H5SearchFoucs from '@/components/H5/H5SearchFoucs/index.vue'

const store = useStore()
const route = useRoute();
const router = useRouter();

const { lg = "" } = route.query;

const searchInfo = computed(() => store.state.searchInfo);

const productList = computed(() => store.state.productList)
const loadMoreSleleton = computed(() => store.state.loadMoreSleleton)

const language = computed(() => checkHasQueryLanguage(lg) || store.state.h5Module.language)
const categoryId = computed(() => route.query.categoryId)

const innerHeight = ref(0)
const keyWordCn = ref('')
const showGif = ref(false)
const showMiniHeader = ref(false)
const searchKeywords = ref('')
const showEmpty = ref(false);
const showLoginOutPopup = ref(false)
const inputValue = ref('')
const totalNums = ref(0);
const kewordsMainRef = ref(null);
const searchFocus = ref(false);

const mostCategoryId = ref(''); // 出现次数最多的categoryId

onMounted(() => {
  const categorySearch = route.query.categoryId;
  const keywordSearch = route.query.keyword
  if (categorySearch) { // 类目搜索
    searchKeywords.value = route.query.categoryName
    serachKeyWordsList('');
  } else { // 文搜
    searchKeywords.value = keywordSearch
    inputValue.value = keywordSearch
    serachKeyWordsList(keywordSearch);
  }
  innerHeight.value = window.innerHeight
})

const tabFilterSearch = () => {
  store.commit('h5Module/setFiltersSeletonVisible', true);
  store.commit('setProductList', []);
  showMiniHeader.value = false;
  kewordsMainRef.value.scrollTop = 0;
  searchInfo.value.pageNow = 0;
  loadMore();
}

const tabCategorySearch = (value) => {
  store.commit('h5Module/setFiltersSeletonVisible', true);
  store.commit('setProductList', []);
  showMiniHeader.value = false;
  kewordsMainRef.value.scrollTop = 0;
  searchInfo.value.pageNow = 1;
  serachKeyWordsList(value);
}

const isEmptyList = (list) => {
  if (!list.length) {
    if (!productList.value.length) {
      store.commit('setEmptyObj', {
        showEmpty: true,
        msg: 'Sorry, no results found',
      })
      showEmpty.value = true;
    }
    store.commit('setLoadMoreSleleton', false);
    store.commit('h5Module/setFiltersSeletonVisible', false);
    allowScroll.value = false
    showGif.value = false
    return true;
  }
  return false;
}

const delay = (ms) => {
  const start = Date.now();
  while (Date.now() - start < ms ) {};
}

const serachKeyWordsList = async (keyword) => {
  store.commit('setProductList', []);

  totalNums.value = 0;
  showEmpty.value = false;

  if (language.value === 'zh') {
    store.commit('h5Module/setFiltersSeletonVisible', true);
    store.commit('setLoadMoreSleleton', true);
  } else {
    const hasTpage = location.search.includes('tpage');
    if (!hasTpage) {
      showGif.value =  true;
    } else {
      store.commit('setLoadMoreSleleton', true);
    }
  }

  const serachData = {
    ...searchInfo.value,
    pageSize: 20,
    pageNow: searchInfo.value.pageNow,
    imageId: 0,
    region: '',
    async: language.value !== 'zh',
    language: language.value,
    keyword,
  };
  
  if (!serachData.keyword) delete serachData.keyword;

  if (categoryId.value) {
    serachData.categoryId = categoryId.value
  }

  try {
    const { code, data } = await fetchData('/dsp-app/alibaba-common/searchKeyWord?itemNumber=50', {
      method: 'post',
      body: JSON.stringify(serachData),
    });
    buriedPointUtil({ eventId: "MPSC201" });

    if (code === 200) {
      keyWordCn.value = data.keyWordCn
      if (language.value === 'zh') {
        const rows = [...productList.value, ...data.rows || []];

        const isEmpty = isEmptyList(rows);
        if (isEmpty) return;

        const findMostId = findMostFrequentCategory(data.rows || [])
        if(!!findMostId && !mostCategoryId.value) {
          mostCategoryId.value = findMostId
        }

        store.dispatch('setSearchProduct', {
          rows,
          total: data.total,
          searchInfo: serachData,
        });
        totalNums.value = data.total;
        store.commit('h5Module/setFiltersSeletonVisible', false);
        store.commit('setLoadMoreSleleton', false);
        allowScroll.value = true;
      } else {
        searchInfo.value.pageNow = 0;
        if (showGif.value) {
          delay(1500);
          showGif.value = false;
        }
        loadMore();
      }
      gaAnalytics('mp_search_page_view', "mp_search_page_view");
    }

    if (code === 401) {
      buriedPointUtil({ eventId: "MPLG501" });
    }

    if (code === 500) {
      store.commit('h5Module/setSearchFoucs', true)
      router.go(-1);
    }
  } catch (error) {
  }
}

const allowScroll = ref(true);
const layoutOnScroll = (e) => {
  const scrollTop = e.target.scrollTop;
  const scrollHeight = e.target.scrollHeight;
  const clientHeight = window.innerHeight;
  toggleHeader(scrollTop);
 
  if (!allowScroll.value) return;

  // 判断当前位置加 75px 是否大于等于最大滚动位置
  if (scrollTop + 75 >= scrollHeight - clientHeight) {
    loadMore();
  }
}

const toggleHeader = (scrollTop) => {
  if (scrollTop > 120) {
    showMiniHeader.value = true;
  } else {
    showMiniHeader.value = false;
  }
}

const isLoadmore = ref(false);
const loadMore = async () => {
  if(isLoadmore.value) {
    return
  }

  isLoadmore.value = true;

  showEmpty.value = false;
  allowScroll.value = false;
  totalNums.value = 0;

  const serachData = {
    ...searchInfo.value,
    pageNow: searchInfo.value.pageNow + 1,
    pageSize: 20,
    language: 'zh',
    keyword: keyWordCn.value,
    async: false,
  };

  if (categoryId.value) {
    serachData.categoryId = categoryId.value
  }

  if (!serachData.keyword) delete serachData.keyword;
  
  try {
    store.commit('setLoadMoreSleleton', true);
    const { code, data } = await fetchData('/dsp-app/alibaba-common/searchKeyWord?itemNumber=50', {
      method: 'post',
      body: JSON.stringify(serachData),
    });
    if (code === 200) {
      const rows = [...productList.value, ...data.rows || []];
      const isEmpty = isEmptyList(rows);
      if (isEmpty) return;
      const findMostId = findMostFrequentCategory(data?.rows || [])
      if(!!findMostId && !mostCategoryId.value) {
        mostCategoryId.value = findMostId
      }
      store.dispatch('setSearchProduct', {
        rows,
        total: data?.total,
        searchInfo: serachData,
      });
      gaAnalytics('mp_search_page_load_more', "mp_search_page_load_more");
      if (serachData.pageNow > 1) {
        buriedPointUtil({ eventId: "MPSC401", others: { ...serachData } });
      }
      totalNums.value = data?.total;
      allowScroll.value = true;
    }

    if (code === 401) {
      buriedPointUtil({ eventId: "MPLG501" });
    }
    store.commit('setLoadMoreSleleton', false);
    store.commit('h5Module/setFiltersSeletonVisible', false);
    store.commit('search/SET_PRE_SEARCH', false);
    isLoadmore.value = false;
  } catch (error) {
    store.commit('setLoadMoreSleleton', false);
    store.commit('h5Module/setFiltersSeletonVisible', false);
    store.commit('search/SET_PRE_SEARCH', false);
  }
};

const handleLoginClickOutside = () => {
  showLoginOutPopup.value = false;
  store.commit('h5Module/setSearchFoucs', true)
  router.go(-1);
}

const preSearch = computed(() => store.state.search.preSearch);
watch(preSearch, (value) => {
  if (value) {
    loadMore();
  }
})

</script>

<style lang="less">
@import './index.less';
</style>