import { createRouter, createWebHistory } from "vue-router";
import Layout from "../views/Layout/index.vue";
import HomeView from "../views/HomeView/index.vue";
import SearchView from "../views/SearchView/index.vue";
import Offer from "../views/OfferView/index.vue";
import HelpCenterView from "../views/HelpCenterView/index.vue";
import GuideView from "../views/GuideView/index.vue";
import Carts from "../views/Carts/index.vue";
import Orders from "../views/Orders/index.vue";
import Account from "../views/Account/index.vue"
import OrderFilling from "../views/OrderFilling/index.vue";
import { setThemeColor } from '@/utils/utils'

import store from "@/store/index.js";
import { buriedPointUtil, gaAnalytics } from "@/utils/utils";
const isMobile = store.state.isMobile === "MOBILE";

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      component: Layout,
      children: [
        {
          path: "",
          component: HomeView,
        },
        {
          path: "/search",
          component: isMobile ? HomeView :SearchView,
        },
        {
          path: "offer/:id",
          component: Offer,
        },
        {
          path: "/help",
          component: HelpCenterView,
        },
        {
          path: '/guide',
          component: GuideView,
        },
        {
          path: '/carts',
          component: Carts
        },
        {
          path: '/orders',
          component: Orders
        },
        {
          path: '/account',
          component: Account
        },
        {
          path: '/orderFilling',
          component: OrderFilling
        },
        {
          path: '/notSupport',
          component: () => import('../views/NotSupport/index.vue')
        }
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  const isMobile = store.state.isMobile === 'MOBILE'
  if (isMobile) {
    buriedPointUtil({ eventId: "MPWH101" });
    gaAnalytics("mp_page_view", "mp_page_view", {
      bdUserId: localStorage.getItem("dsp.uid"),
    });
    if (to.path === "/search") {
      buriedPointUtil({ eventId: "MPSC101" });
    }
    if (to.path === '/' && store.state.productList.length === 0) {
      store.dispatch('initHomeData', {
        adsWord: to.query.data,
      })
    }

    if (to.path.includes('/orders') || to.path.includes('/carts') || to.path.includes('account') || to.path.includes('/orderFilling')) {
      setThemeColor('#141414')
    } else {
      setThemeColor('#052785')
    }
  }
  next();
});

export default router;
