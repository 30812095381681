<template>
  <div class="breadcrumb">
    <div class="breadcrumb-inner">
      <a class="route can-click" href="/">Home</a>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.09056 6.00171L3.96484 2.87428L4.57142 2.26857L8.30284 6.002L4.57113 9.73143L3.96542 9.12514L7.09056 6.00171Z" fill="#232323" stroke="#232323" stroke-width="0.5"/>
      </svg>

      <a class="route can-click" @click="clickAllCategories">All Categories</a>
      <span v-if="parentParentCategoryName">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.09056 6.00171L3.96484 2.87428L4.57142 2.26857L8.30284 6.002L4.57113 9.73143L3.96542 9.12514L7.09056 6.00171Z" fill="#232323" stroke="#232323" stroke-width="0.5"/>
        </svg>

        <a class="route can-click" @click="clickParentParentCategory">{{
          parentParentCategoryName
        }}</a>
      </span>
      <span v-if="parentCategoryName">
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.09056 6.00171L3.96484 2.87428L4.57142 2.26857L8.30284 6.002L4.57113 9.73143L3.96542 9.12514L7.09056 6.00171Z" fill="#232323" stroke="#232323" stroke-width="0.5"/>
        </svg>

        <a class="route can-click" @click="clickParentCategory">{{
          parentCategoryName
        }}</a>
      </span>

      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.09056 6.00171L3.96484 2.87428L4.57142 2.26857L8.30284 6.002L4.57113 9.73143L3.96542 9.12514L7.09056 6.00171Z" fill="#232323" stroke="#232323" stroke-width="0.5"/>
      </svg>

      <a class="route" v-if="categoryName && categoryName !== 'null'">{{ categoryName }}</a>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { redirectWithSearch } from "@/utils/utils";
const store = useStore();
const route = useRoute();
const props = defineProps({
  categoryName: String,
});

const parentParentCategoryName = computed(() => route.query.ppName);
const parentCategoryName = computed(() => route.query.pName);

// 点击All Categories 展示类目筛选
const clickAllCategories = () => {
  store.commit("common/SET_CATEGORY_VISIBLE", true);
};
// 点击 一级, 展示类目筛选优先选中一级
const clickParentParentCategory = () => {
  store.commit("common/SET_CATEGORY_VISIBLE", true);
};
// 点击二级跳转页面
const clickParentCategory = () => {
  const searchParams = new URLSearchParams(window.location.search);
  const categoryId = searchParams.get("pId");
  const categoryName = searchParams.get("pName");
  const ppName = searchParams.get("ppName");
  const ppId = searchParams.get("ppId");
  const keyword = searchParams.get("keyword") || "";
  window.open(
    `/search${redirectWithSearch(
      `categoryId=${categoryId}&categoryName=${categoryName}&ppId=${ppId}&ppName=${ppName}&keyword=${keyword}`
    )}`
  );
};
</script>
<style scoped lang="less">
@import "./index.less";
</style>
