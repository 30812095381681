const state = {
  translateLoading: false,
  similarSkeleton: false,
  activeSimilarItem: {},
  similarOfferIdList: [],
  stylesSearchInfo: {},
  stylesList: [],
  stylesListTotal: 0,
  preSearch: false,
};

const mutations = {
  SET_TRANSLATE_LOADING: (state, data) => {
    state.translateLoading = data;
  },
  SET_SIMILAR_SKELETON: (state, data) => {
    state.similarSkeleton = data;
  },
  SET_ACTIVE_SIMILAR_ITEM: (state, data) => {
    state.activeSimilarItem = data;
  },
  SET_SIMILAR_OFFER_ID_LIST: (state, data) => {
    state.similarOfferIdList = data;
  },

  SET_STYLES_SEARCH_INFO: (state, data) => {
    state.stylesSearchInfo = data;
  },
  SET_STYLES_LIST: (state, data) => {
    state.stylesList = data;
  },
  SET_STYLES_LIST_TOTAL: (state, data) => {
    state.stylesListTotal = data;
  },
  SET_PRE_SEARCH: (state, data) => {
    state.preSearch = data;
  }
};

const actions = {
  // 设置搜索信息
  setStylesSearchInfo({ commit }, payload) {
    commit("SET_STYLES_SEARCH_INFO", payload);
  },
  // 设置列表数据
  setStylesList({ commit }, payload) {
    const { rows, total, searchInfo } = payload;
    commit(
      "SET_STYLES_LIST",
      rows.map((v) => {
        return {
          ...v,
          priceInfo: { price: v.priceInfo.price, ...payload },
        };
      })
    );
    commit("SET_STYLES_LIST_TOTAL", total);
    commit("SET_STYLES_SEARCH_INFO", searchInfo);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
