<template>
  <div class="order-detail" @click.stop>
    <div class="body" @scroll="handleScroll">
      <div class="suspend-info" :class="{ 'no-background': ![0, 2].includes(orderStatus), 'failed-background': orderStatus === 20 }">
        <div class="text-1">Status for <span>"{{ orderStatusText }}"</span></div>
        <div v-if="orderStatus === 0" class="text-2">Please complete the order before time ends, othrwise the order will automatically canceled.</div>
        <div v-if="orderStatus === 10" class="text-2">Confirming, please wait patiently.</div>
        <div v-if="orderStatus === 20" class="text-2">Payment confirmation failed, Please pay again.</div>
        <div v-if="orderStatusText === 'Fulfillment'" class="text-2">Your order is on the way, please be patient.</div>
        <div v-if="orderStatusText === 'Completed'" class="text-2">
          <template v-if="orderStatus === 90">Your order has been received.</template>
          <template v-else-if="orderStatus === 99">The transaction is closed.</template>
        </div>
        <template v-if="orderStatus === 0 || orderStatus === 20 && data.b2bPaymentOrderVo.method === 'BANK_TRANSFER_B2B'">
          <div class="split-line"></div>
          <div class="text-3">Awaiting Payment</div>
          <div class="price-clock">
            <IntlCurrency :count="data?.b2bOrderVo?.totalAmountUsd" :size="24" currency="us" color="#f54550" />
            <CountDownTimer v-if="orderStatus === 0" :time="data.b2bOrderVo.createTime" />
            <CountDownTimer v-if="orderStatus === 20 && data.b2bPaymentOrderVo.method === 'BANK_TRANSFER_B2B'" :time="data?.b2bPaymentOrderVo.bankTransferVO?.auditTime" />
          </div>
        </template>
      </div>
      <div class="content" :style="{ top: (orderStatus === 0 || orderStatus === 20 && data.b2bPaymentOrderVo.method === 'BANK_TRANSFER_B2B') ? orderStatus === 0 ? '172px' : '152px' : '72px' }">
        <div class="sku-list" :style="{ height: calcSkuListHeight }">
          <div v-for="(sku, index) in ( showMore ? skuList : skuList.length > 4 ? skuList.slice(0, 4) : skuList )" :key="index" class="sku">
            <div @click="toDetailPage(sku)" class="sku-image">
              <img :src="sku.skuImage" width="100%" alt="">
            </div>
            <div class="sku-info" :class="{ 'hidden-border-bottom': !showMore ? index === 3 : index === skuList.length - 1 }">
              <div class="sku-info-left">
                <div class="sku-name">
                  {{ sku.spuName }}
                </div>
                <div v-for="(spec, index) in sku.skuAttributeVos" :key="index" class="sku-spec">
                  <div class="text">{{ `${ spec.name }: ${ spec.value }` }}</div>
                  <img 
                    @click.stop="e => showFullAttrAlert(spec.value, e)"
                    v-if="(spec.name.length + spec.value.length) > 25" 
                    src="@/assets/H5/home/category-right-icon.svg" alt="">
                  
                </div>
              </div>
              <div class="sku-info-right">
                <div class="sku-price">{{ data.b2bOrderVo.currencyCode.slice(0, 2) }} {{ data.b2bOrderVo.currencyUnit }}{{ sku.skuPrice }}</div>
                <div class="sku-count">x{{ sku.skuQuantity }}</div>
              </div>
            </div>
          </div>
          <div v-if="!showMore && skuList.length > 4" @click="showMore = true" class="show-more">Show More</div>
        </div>
        <div class="card">
          <div class="row">
            <div>Paid with</div>
            <div v-if="data.b2bPaymentOrderVo">
              <span v-if="data.b2bPaymentOrderVo.method === 'BANK_TRANSFER_B2B'" class="flex-center">
                <img src="@/assets/H5/carts/bank-icon.png" width="61px" alt="">
                <span class="mgL6">Bank Payment</span>
              </span>
              <span v-else class="flex-center">
                <img src="@/assets/H5/order/paypal-icon.svg" alt="">
                <span class="mgL6">PayPal</span>
              </span>
            </div>
            <span v-else style="color: #63717a; font-size: 12px; font-family: mon-500;">--</span>
          </div>
          <div class="row">
            <div>Total items</div>
            <div>{{ data?.b2bOrderVo?.currencyCode.slice(0, 2) }} {{ data.b2bOrderVo.currencyUnit }}{{ data?.b2bOrderVo?.itemTotalAmount }}</div>
          </div>
          <div class="row">
            <div class="flex-center">
              Shipping fee 
              <img @click="$emit('showShippingDesc')" class="mgL4" src="@/assets/H5/order/warning-grey.svg" alt="">
            </div>
            <div>
              <span v-if="data?.b2bOrderVo?.originShipAmount > data?.b2bOrderVo?.shipAmount" class="line-through">{{ data.b2bOrderVo.currencyUnit }}{{ data?.b2bOrderVo?.originShipAmount }}</span>
              <span class="mgL4">{{ data?.b2bOrderVo?.currencyCode.slice(0, 2) }} {{ data?.b2bOrderVo?.currencyUnit }}{{ data?.b2bOrderVo?.shipAmount }}</span>
            </div>
          </div>
          <div class="row">
            <div>Total</div>
            <div>{{ data?.b2bOrderVo?.currencyCode.slice(0, 2) }} {{ data?.b2bOrderVo?.currencyUnit }}{{ data?.b2bOrderVo?.totalAmount }}</div>
          </div>
          <div class="row">
            <div>Current exchange rate</div>
            <div>{{ data?.b2bOrderVo?.currencyCode.slice(0, 2) }} {{ data?.b2bOrderVo?.currencyUnit }}1 = US ${{ data?.b2bOrderVo?.usdRateFromCurrency }}</div>
          </div>
          <div class="row">
            <div>Total</div>
            <IntlCurrency :count="data?.b2bOrderVo?.totalAmountUsd" :size="14" currency="us" color="#232323" />
          </div>
          <div v-if="orderStatusText === 'Fulfillment' || orderStatusText === 'Completed'" class="row">
            <div>Actual Payment</div>
            <IntlCurrency :count="data?.b2bOrderVo?.actualTotalAmountUsd" :size="14" currency="us" color="#052785" />
          </div>
        </div>
        <div class="card">
          <div class="row">
            <div>Order Number</div>
            <div>{{ data?.b2bOrderVo?.orderNo }}</div>
          </div>
          <div class="row">
            <div>Order Time</div>
            <div>{{ data?.b2bOrderVo?.createTimeStr }}</div>
          </div>
          <div v-if="orderStatusText === 'Fulfillment'" class="row">
            <div>Payment Time</div>
            <div>{{ data?.b2bPaymentOrderVo?.paymentTime }}</div>
          </div>
          <div v-if="orderStatusText === 'Fulfillment' || orderStatus === 90" class="row">
            <div>Shipping Time</div>
            <div>{{ data?.shippingTimeStr ? data?.shippingTimeStr : '--' }}</div>
          </div>
          <div v-if="orderStatus === 90" class="row">
            <div>Delivery Time</div>
            <div>{{ data?.deliveryTimeStr }}</div>
          </div>
          <div v-if="orderStatus === 99" class="row">
            <div>Cancel Time</div>
            <div>{{ data?.b2bOrderVo.cancelTimeStr }}</div>
          </div>
          <div v-if="data.b2bOrderLogisticsVos && data.b2bOrderLogisticsVos.length" class="track-number-wrapper">
            <div v-for="item in data.b2bOrderLogisticsVos" :key="item.logisticsNo" class="item">
              <div class="text">Tracking Number</div>
              <span @click="toThirdPlatform(item.trackUrl)" class="tracking-number">
                {{ item.logisticsNo }}
                <img src="@/assets/H5/order/right-arrow-blue.svg" alt="">
              </span>
            </div>
            <Transition name="alert">
              <div v-if="trackingAlertVisible" @click="trackingAlertVisible = false" class="alert-box-wrapper" @click.stop>
                <img src="@/assets/H5/order/tracking-alert-bg.svg" height="56px" width="100%" alt="">
                <div class="text">Click to jump to the third-party platform to check the order logistics status.</div>
              </div>
            </Transition>
          </div>
        </div>
        <div class="card address">
          <div class="address-icon">
            <img src="@/assets/H5/order/address-flag.svg" alt="">
          </div>
          <div class="address-info">
            <div>{{ data.addressVo.name }}, {{ data.addressVo.phoneCode + data.addressVo.phone }}</div>
            <div>{{ data.addressVo.extAddr }}</div>
            <div>{{ data.addressVo.streetAddr }}</div>
            <div>{{ `${ data.addressVo.city }, ${ data.addressVo.province },` }} {{ data.addressVo.zip }}</div>
            <div>{{  data.addressVo.country }}</div>
          </div>
        </div>
        <BottomLine style="margin-bottom: 12px;" />
        <div style="height: 72px;"></div>
      </div>
      <Transition name="alert">
        <div v-if="fullAttrVisible" @click="fullAttrVisible = false" class="alert-box-wrapper" :style="fullAttrStyle" @click.stop>
          <img src="@/assets/H5/order/tracking-alert-bg.svg" height="56px" width="100%" alt="">
          <div class="text">{{ fullAttrDesc }}</div>
        </div>
      </Transition>
    </div>
    <div v-if="orderStatusText === 'Awaiting Payment'" class="footer">
      <div class="info">
        <div class="text">Awaiting Payment:</div>
        <div class="price">
          <IntlCurrency :count="data?.b2bOrderVo?.totalAmountUsd" :size="14" currency="us" color="#ffffff" />
        </div>
      </div>
      <div v-if="orderStatus === 0" class="button" @click="$emit('payNow')">Pay Now</div>
      <div v-if="orderStatus === 10" class="button" :class="{ disabled: data.b2bPaymentOrderVo?.method === 'PAYPAL_STANDARD_B2B' }" @click="handleConfirming">Confirming</div>
      <div v-if="orderStatus === 20" class="button" @click="handlePayagain">Pay Again</div>
    </div>
    <div v-else class="footer">
      <div @click="handleBuyAgain" class="button" style="width: 100%;">Rebuy</div>
    </div>
    <div v-show="isPageBottom" class="bottom-click-area" @click="trackingAlertVisible = false"></div>
  </div>
</template>
<script setup>
  import { computed, onMounted, ref } from 'vue'
  import { fetchData } from '@/utils/fetch';
  import { useRouter } from 'vue-router';
  import BottomLine from './BottomLine.vue';
  import CountDownTimer from './CountDownTimer.vue';
  import { gaAnalytics, buriedPointUtil } from '@/utils/utils';

  const emits = defineEmits(['close', 'showShippingDesc', 'payNow', 'repay', 'confirming'])

  const props = defineProps({
    data: {
      type: Object,
      default: () => {}
    }
  })

  const router = useRouter();

  const formatTime = (value) => {
    const date = new Date(value);
    const YY = date.getFullYear();
    const MM = date.getMonth() + 1;
    const DD = date.getDate();
    const res = `${DD}/${MM}/${YY} ${date.toLocaleTimeString()}`
    return res;
  }

  const orderStatus = computed(() => {
    return props.data.b2bOrderVo.status
  });

  const orderStatusText = computed(() => {
    if ([0,10,20].includes(orderStatus.value)) { 
      return 'Awaiting Payment'
    } else if ([30,40,50].includes(orderStatus.value)) {
      return 'Fulfillment'
    } else {
      return 'Completed'
    }
  })

  const trackingAlertVisible = ref(true)
  
  const skuList = computed(() => {
    return props.data?.b2bOrderDetails
  })
  const showMore = ref(false);

  const calcSkuListHeight = computed(() => {
    const h = 88;
    const mT = 16;
    const len = showMore.value ? skuList.value.length :  skuList.value.length > 4 ? 4 : skuList.value.length;
    let height = len * h + len * mT
    if (!showMore.value && skuList.value.length > 4) height += 32;

    return height + 'px'
  })

  const handleBuyAgain = async () => {
    gaAnalytics('mp_buy_again_click', 'mp_buy_again_click')
    const { code, data } = await fetchData('/appb/b2b/cart/reBuyOrder', {
      method: 'POST',
      body: JSON.stringify({
        orderNo: props.data.b2bOrderVo.orderNo
      })
    })
    if (code === 200) {
      fetchData("/appb/b2b/cart/cartCount").then(res => {
        store.commit("cart/SET_CART_COUNT", res.data);
      })

      sessionStorage.setItem('carts', JSON.stringify(data))
      router.push('/carts');
    }
  }

  const toThirdPlatform = (url) => {
    trackingAlertVisible.value = false;
    window.open(url, '_blank')
  }

  const toDetailPage = (item) => {
    window.open('/offer/' + item.spuId)
  }

  const handleConfirming = () => {
    if (props.data.b2bPaymentOrderVo?.method === 'PAYPAL_STANDARD_B2B') return;
    gaAnalytics('mp_confirming_click', 'mp_confirming_click')
    emits('confirming')
  }

  const handlePayagain = () => {
    gaAnalytics('mp_pay_again_click', 'mp_pay_again_click')
    if (props.data.b2bOrderVo.totalAmountUsd > 200) {
      emits('repay')
    } else {
      emits('payNow');
    }
  }

  const isPageBottom = ref(false)
  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target

    if (scrollTop + clientHeight >= scrollHeight) {
      isPageBottom.value = true
    } else {
      isPageBottom.value = false
    }
  }

  const fullAttrStyle = {
    top: 0,
  }
  
  const fullAttrVisible = ref(false)
  const fullAttrDesc = ref('')

  const showFullAttrAlert = (spec, e) => {
    const domRect = e.target.getBoundingClientRect()
    fullAttrStyle.top = domRect.top - 56 + 'px';

    fullAttrVisible.value = true
    fullAttrDesc.value = spec
  }

  onMounted(() => {
    gaAnalytics('mp_order_page_view', 'mp_order_page_view')
    buriedPointUtil({ eventId: 'MPOP502' })
  })
</script>
<style lang="less" scoped>
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .mgL4 {
    margin-left: 4px;
  }

  .mgL6 {
    margin-left: 6px;
  }

  .order-detail {
    width: 100%;
    height: 100%;

    .body {
      position: relative;
      background-color: #f5f5f5;
      border-radius: 8px;
      overflow: scroll;
      height: 100%;

      .alert-box-wrapper {
        position: absolute;
        top: 54px;
        right: 0;
        width: 266px;
        height: 56px;
        z-index: 99;
        overflow: hidden;
        .text {
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 48px;
          color: white;
          font-size: 11px;
          font-family: mon-500;
          padding: 0 16px;
          line-height: 16px;
          display: flex;
          align-items: center;
        }
      }

      .suspend-info {
        position: absolute;
        top: 0;
        width: 100%;
        height: 369px;
        padding: 16px 12px;
        background: linear-gradient(to bottom, #faf6e6, #f5f5f5);

        &.no-background {
          background: none;
        }

        &.failed-background {
          background: linear-gradient(to bottom, #fff5f5, #f5f5f5);
        }

        .text-1 {
          color: #232323;
          font-size: 16px;
          font-family: mon-600;
          line-height: 20px;
          span {
            color: #052784;
            font-family: mon-700;
          }
        }

        .text-2 {
          margin-top: 4px;
          color: #232323;
          font-size: 12px;
          font-family: mon-500;
          line-height: 16px;
        }

        .split-line {
          width: 100%;
          height: 0.5px;
          background-color: #e4e6eb;
          margin-top: 16px;
        }
        
        .text-3 {
          margin-top: 16px;
          color: #5c5c5c;
          font-family: mon-500;
          font-size: 12px;
          line-height: 16px;
        }

        .price-clock {
          display: flex;
          align-items: center;
          gap: 12px;
          margin-top: 4px;
        }
      }
      .content {
        position: absolute;
        top: 172px;
        width: 100%;
        padding: 0 12px;
        overflow: hidden;
        .sku-list {
          padding: 0 12px;
          border-radius: 8px;
          background-color: white;
          // overflow: hidden;
          display: flex;
          flex-direction: column;
          transition: height .3s ease-out;
          
          .sku {
            display: flex;
            gap: 8px;
            height: 88px;
            margin-top: 16px;
            width: 100%;
            .sku-image {
              flex-shrink: 0;
              width: 72px;
              height: 72px;
              border-radius: 4px;
              background-color: #f5f5f5;
              overflow: hidden;
            }
            .sku-info {
              flex: 1;
              overflow: hidden;
              position: relative;
              border-bottom: 0.5px solid #e4e6eb;

              &.hidden-border-bottom {
                border-bottom: none;
              }

              .sku-info-left {    
                .sku-name {
                  width: 140px;
                  color: #5c5c5c;
                  font-size: 12px;
                  font-family: mon-500;
                  line-height: 16px;
                  display: -webkit-box;           
                  -webkit-box-orient: vertical;  
                  -webkit-line-clamp: 2;  
                  overflow: hidden;
                  text-overflow: ellipsis;
                  margin-bottom: 6px;
                }
                .sku-spec {
                  position: relative;
                  height: 16px;
                  margin-bottom: 2px;
                  .text {
                    display: inline-block;
                    color: #6e717a;
                    font-size: 11px;
                    font-family: mon-500;
                    line-height: 16px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    width: calc(100% - 24px);
                  }
                  img {
                    position: absolute;
                    right: 0;
                    top: 2px;
                    width: 12px;
                  }

                  &:last-child {
                    margin-bottom: 0;
                  }
                }
              }
              .sku-info-right {
                position: absolute;
                top: 0;
                right: 0;
                text-align: right;
                .sku-price {
                  font-size: 12px;
                  font-family: mon-600;
                  color: #232323;
                  line-height: 16px;
                }
                .sku-count {
                  margin-top: 2px;
                  color: #6e717a;
                  font-size: 12px;
                  font-family: mon-500;
                  line-height: 16px;
                }
              }
            }
          }

          .show-more {
            font-size: 12px;
            font-family: mon-500;
            color: #009de0;
            line-height: 16px;
            margin-bottom: 16px;
          }
        }

        .card {
          padding: 8px 12px;
          margin-top: 8px;
          background-color: white;
          border-radius: 8px;

          .track-number-wrapper {
            position: relative;

            .item {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 32px;

              .text {
                color: #5c5c5c;
                font-size: 12px;
                font-family: mon-500;
              }

              .tracking-number {
                display: flex;
                align-items: center;
                color: #009de0;
                font-size: 12px;
                font-family: mon-500;
                text-decoration: underline;

                img {
                  margin-left: 4px;
                }
              }
            }

            .alert-box-wrapper {
              position: absolute;
              top: 30px;
              right: 0;
              width: 266px;
              height: 56px;
              z-index: 10;
              overflow: hidden;
              .text {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 48px;
                color: white;
                font-size: 11px;
                font-family: mon-500;
                padding: 0 16px;
                line-height: 16px;
                display: flex;
                align-items: center;
              }
            }
          }

          .row {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 32px;
            
            div:first-child {
              color: #5c5c5c;
              font-size: 12px;
              font-family: mon-500;
            }
            div:last-child {
              color: #6e717a;
              font-size: 12px;
              font-family: mon-500;
            }

            .line-through {
              color: #b2b7c2;
              font-size: 11px;
              text-decoration: line-through;
            }
          }

          &.address {
            display: flex;
            align-items: center;
            padding: 12px 16px;
            .address-icon {
              flex-shrink: 0;
              width: 32px;
              height: 32px;
            }
            .address-info {
              flex: 1;
              margin-left: 16px;

              div:first-child {
                color: #232323;
                font-family: mon-600;
              }

              div {
                color: #5c5c5c;
                font-size: 12px;
                font-family: mon-500;
                line-height: 16px;
                margin-bottom: 2px;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
    .footer {
      position: fixed;
      bottom: 12px;
      width: calc(100% - 24px);
      height: 72px;
      padding: 0 16px;
      background-color: #242426;
      border-radius: 0 0 6px 6px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      z-index: 99;

      .info {
        .text {
          color: #b2b7c2;
          font-size: 12px;
          font-family: mon-500;
          line-height: 16px;
        }

        .price {
          margin-top: 4px;
        }
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 175px;
        height: 44px;
        background-color: #4681f9;
        color: white;
        font-size: 14px;
        font-family: mon-600;
        border-radius: 22px;

        &.disabled {
          background-color: #ebebeb;
          color: #b2b7c2;
        }
      }
    }

    .bottom-click-area {
      position: fixed;
      bottom: 84px;
      width: calc(100% - 24px);
      height: 160px;
      background-color: transparent;
    }
  }

  .alert-enter-active {
    transform-origin: 80% 0;
    animation: alert 0.3s;
  }

  .alert-leave-active {
    transform-origin: 80% 0;
    animation: alert 0.3s reverse;
  }

  @keyframes alert {
    0% {
      transform: scale(0, 0);
      opacity: 0;
    }
    100% {
      transform: scale(1, 1);
      opacity: 1;
    }
  }
</style>