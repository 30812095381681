<template>
  <div class="category-dialog">
    <div class="category-dialog-level-wrapper">
      <div v-if="currentLevel === 1" class="category-dialog-level">
        <div>Shop by Categories</div>
        <div class="category-nums">{{ firstCategoryList.length }}+</div>
      </div>
      <template v-else>
        <div @click="handleBack" class="category-dialog-level-back">
          <img src="@/assets/H5/order/icon-arrow-left.svg" alt="">
          <div class="text">All</div>
        </div>
        <div class="category-dialog-level1-name">
          {{ selectedCategoryName }}
        </div>
      </template>
      <div class="close-btn" @click="handleClose">
        <img src="@/assets/close-circle-icon.svg" width="20px" alt="">
      </div>
    </div>

    <div class="category-dialog-content-wrapper">
      <div ref="firstContentRef" class="category-dialog-content" :class="{ toggle: currentLevel === 2, transition: useTransition }">
        <div v-for="(item, index) in firstCategoryList" :key="index" @click="toSecondMenu(item)" class="category-item">
          <div class="category-item-name">
            {{ item.categoryName }}
          </div>
          <div class="category-item-next">
            <img src="@/assets/H5/home/category-right-icon.svg" alt="">
          </div>
        </div>
      </div>
      <!-- 二级菜单 -->
      <div ref="secondContentRef" class="category-dialog-content" :class="{ toggle: currentLevel === 2, transition: useTransition }">
        <div 
          v-for="(item, index) in secondMenuList" 
          :key="index" 
          class="category-item level2"
          :style="{ height: calcHeight(item, index) }">
          <div class="category-item-top" @click="item.isExpand = !item.isExpand">
            <div class="category-item-name">
              {{ item.categoryName }}
            </div>
            <div class="category-item-next">
              <img v-if="item.isExpand" src="@/assets/H5/home/category-minus-icon.svg" alt="">
              <img v-else src="@/assets/H5/home/category-plus-icon.svg" alt="">
            </div>
          </div>
          <div class="category-item-expand">
            <div @click="toSearchPage(item)" class="category-item-expand-flag">
              {{ item.categoryName }}
              <img src="@/assets/H5/home/category-right-icon.svg" alt="">
            </div>
            <div ref="dynamicHeightRef" class="category-item-expand-third-category">
              <div 
                @click="toSearchPage(thirdCategory)" 
                v-for="(thirdCategory, index) in item.childCategoryList" 
                :key="index"
                class="third-category-item">
                {{ thirdCategory.categoryName }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="category-dialog-footer">
      <div class="footer-left">
        <div class="info">
          <img src="@/assets/H5/home/language-icon.svg" alt="">
          <div>English</div>
        </div>
        <div class="change" @click="handleChange('language')">Change</div>
      </div>
      <div class="split-line"></div>
      <div class="footer-right">
        <div class="info">
          <img src="@/assets/H5/home/money-icon.svg" alt="">
          <div>{{ currencyData?.targetCurrency }}</div>
        </div>
        <div class="change" @click="handleChange('money')">Change</div>
      </div>
    </div>

    <div class="pop-content" :class="{ visible: popDialogVisible }">
      <CountrySelect 
        v-if="currenyName === 'language'" 
        :handleClose="() => { popDialogVisible = false }" />
      <CurrencySelect 
        v-else 
        :currencyData="currencyData"
        :handleClose="() => { popDialogVisible = false }" />
    </div>
  </div>
</template>
<script setup>
  import { nextTick, ref, computed, watch, onMounted } from 'vue';
  import { useStore } from 'vuex';
  import { fetchData } from '@/utils/fetch'
  import { useRouter, useRoute } from 'vue-router';
  import CountrySelect from '@/components/H5/H5Currency/components/CountrySelect/index.vue'
  import CurrencySelect from '@/components/H5/H5Currency/components/CurrencySelect/index.vue'
  import { checkHasQueryLanguage } from '../../../utils/utils';

  const emits = defineEmits(['close'])

  const store = useStore()
  const router = useRouter();
  const route = useRoute()

  const popDialogVisible = ref(false);
  const currencyDialog = ref(false);
  const currencyData = JSON.parse(localStorage.getItem('currencyData'))
  const currenyName = ref('');
 
  const selectedCategoryName = ref('')

  const secondMenuList = ref([])

  const currentLevel = ref(1);

  const useTransition = ref(true);

  const firstCategoryList = ref([]);

  const { lg = "" } = route.query;

  const language = computed(() => store.state.h5Module.language);

  const firstContentRef = ref(null);
  const secondContentRef = ref(null);

  onMounted(() => {
    getFirstCategory();
  })

  const getFirstCategory = async () => {
    firstCategoryList.value = [];
    let { code, data } = await fetchData(`/dsp-app/alibaba-common/firstLevelCategory?language=${language.value}`);
    if (code === 200) {
      firstCategoryList.value = data;

      // 判断是否直接打开二级类目
      const pCategory = JSON.parse(localStorage.getItem('parentCategory'))
      if (pCategory) {
        useTransition.value = false;
        toSecondMenu(pCategory);
        localStorage.removeItem('parentCategory')
      }
    }
  }

  const handleBack = () => {
    useTransition.value = true;
    toLevel(1);
  }
  
  const toLevel = (level) => {
    currentLevel.value = level;
    if (level === 1) {
      firstContentRef.value.scrollTop = 0;
    } else {
      secondContentRef.value.scrollTop = 0;
    }
  }

  const handleClose = () => {
    store.commit('h5Module/setCategoryDialogVisible', false)
  }

  const firstCategoryId = ref(''); // 一级类目id
  const toSecondMenu = async (item) =>{
    firstCategoryId.value = item.categoryId;
    selectedCategoryName.value = item.categoryName;
    const { code, data } = await fetchData(`/dsp-app/alibaba-common/subordinateCategory?language=${language.value}&firstCategoryId=${item.categoryId}`);

    if (code === 200) {
      secondMenuList.value = data && data.map(item => {
        item.isExpand = false;
        return item;
      })
    } else {
      console.error("获取子类目失败");
    }

    toLevel(2)
  }

  const dynamicHeightRef = ref(null);

  const calcHeight = (item, index) => {
    if (!item.isExpand) return '56px';
    if (!item.childCategoryList) return '112px';

    const elList = dynamicHeightRef.value;
    if (elList) {
      const el = elList[index];
      return `${ el.clientHeight + 90 }px`
    }
  }

  const handleChange = (value) => {
    currenyName.value = value;
    popDialogVisible.value = true;
  }

  const toSearchPage = (item) => {
    cacheFirstCategoryId(firstCategoryId.value);
    const { href } = router.resolve({
      path: '/search',
      query: {
        categoryName: item.categoryName,
        categoryId: item.categoryId
      }
    })
    window.open(href, '_blank')
  }

  // 缓存前三个点击的一级类目
  const cacheFirstCategoryId = (firstCategoryId) => {
    const H5ClickedCategories = JSON.parse(localStorage.getItem('H5ClickedCategories')) || [];
    const index = H5ClickedCategories.findIndex(id => id === +firstCategoryId);
    if (index > -1) H5ClickedCategories.splice(index, 1);
    H5ClickedCategories.unshift(firstCategoryId);
    const _H5ClickedCategories = H5ClickedCategories.slice(0, 3)
    localStorage.setItem('H5ClickedCategories', JSON.stringify(_H5ClickedCategories));
  }
</script>
<style lang="less" scoped>
  .category-dialog {
    width: 100%;
    height: 100%;
    padding: 12px 16px;
    box-sizing: border-box;
    overflow: scroll;
    display: flex;
    flex-direction: column;

    .category-dialog-level-wrapper {
      height: 48px;
      background-color: white;
      padding: 0 12px;
      display: flex;
      align-items: center;
      border-radius: 8px;
      justify-content: space-between;
      box-sizing: border-box;
      margin-bottom: 12px;

      .category-dialog-level {
        font-size: 14px;
        font-family: mon-600;
        display: flex;
        align-items: center;

        .category-nums {
          width: 24px;
          height: 24px;
          background-color: #009de0;
          text-align: center;
          line-height: 24px;
          font-size: 10px;
          font-family: mon-600;
          margin-left: 6px;
          border-radius: 50%;
          color: white;
        }
      }

      .close-btn {
        width: 20px;
        height: 20px;
      }

      .category-dialog-level-back {
        display: flex;
        align-items: center;
        .text {
          margin-left: 4px;
          font-family: mon-600;
          font-size: 13px;
          color: #232323;
        }
      }

      .category-dialog-level1-name {
        font-family: mon-600;
        font-size: 14px;
        color: #232323;
        width: 245px;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .category-dialog-content-wrapper {
      position: relative;
      flex: 1;
      width: 100%;
      height: 100%;
      white-space: nowrap;
      overflow: hidden;
    }

    .category-dialog-content {
      display: inline-block;
      height: 100%;
      width: 100%;
      padding: 8px 0;
      border-radius: 8px;
      background-color: white;
      box-sizing: border-box;
      overflow: scroll;
      transform: translateX(0);

      &.transition {
        transition: transform 0.3s ease;
      }

      &.toggle {
        transform: translateX(-100%);
      }
      .category-item {
        margin: 0 16px;
        padding: 19px 0;
        height: 56px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        border-bottom: 0.5px solid #e4e6eb;

        &.level2 {
          display: block;
          height: auto;
          padding: 0;
          transition: height .3s;
          height: 56px;
          overflow: hidden;
          box-sizing: border-box;
        }

        .category-item-top {
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .category-item-name {
          // box-sizing: border-box;
          font-size: 14px;
          font-family: mon-600;
          color: #232323;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .category-item-next {
          margin-left: 20px;
          width: 16px;
          height: 16px;
        }

        .category-item-expand {
          .category-item-expand-flag {
            display: flex;
            align-items: center;
            height: 24px;
            font-size: 12px;
            font-family: mon-500;
            color: #232323;
            img {
              margin-left: 4px;
              width: 12px;
            }
          }

          .category-item-expand-third-category {
            margin-top: 12px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 12px 17px;
            padding-bottom: 19px;
            .third-category-item {
              flex: 1 1 calc(50% - 8.5px); 
              height: 24px;
              line-height: 24px;
              color: #5c5c5c;
              font-family: mon-500;
              font-size: 12px;
              // &:hover {
              //   color: #009de0;
              //   text-decoration: underline;
              // }
            }
          }
        }
      }
    }

    .category-dialog-footer {
      position: relative;
      height: 48px;
      border-radius: 8px;
      background-color: white;
      box-sizing: border-box;
      display: flex;
      margin-top: 12px;

      .split-line {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 0.5px solid #e4e6eb;
        height: 20px;
      }
      
      .footer-left, .footer-right {
        padding: 0 12px;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .info {
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #232323;
          font-family: mon-500;
          img {
            margin-right: 2px;
          }
        }
        .change {
          font-size: 12px;
          color: #6e717a;
          font-family: mon-500;
        }
      }
    }
    .pop-content {
      position: fixed;
      bottom: 24px;
      height: 0;
      width: calc(100% - 32px);
      background-color: white;
      transition: height 0.3s ease-out;
      border-radius: 8px;
      overflow: hidden;

      &.visible {
        height: calc(100% - 36px - 60px );
      }
    }
  }
</style>