<template>
  <div class="search-by-image">
    <div class="country-select-top">
      <img src="@/assets/country-select.svg" alt="" />
    </div>
    <div class="country-select-hideline"></div>
    <div class="search-main">
      <div class="search-main-header">Search by image</div>
      <div class="search-main-desc">
        Find what you love with better prices on 1688 by using an image search
      </div>
      <div class="upload-image">
        <div v-if="loading" class="upload-image-loading-wrapper">
          <div class="upload-image-loading">
            <div class="spinner"></div>
            <div class="container-text">Identifying your image</div>
          </div>
        </div>
        <img v-if="loading" class="upload-image-tempUrl" :src="tempImgSrc" alt="">
        <div class="upload-image-no" v-if="!imgSrcBase64">
          <template v-if="!loading">
            <img src="@/assets/upload-image.svg" alt="" />
            <div class="upload-image-drop">
              <!-- Drag an image here <br />or -->
              Click the button to upload the image
            </div>
            <div class="upload-image-btn">
              <input class="upload-auto_input" type="file" id="upload" accept="image/*" @input="uploadImg" />
              Upload a photo
            </div>
          </template>
        </div>
        <div class="upload-image-open" v-if="imgSrcBase64">
          <img class="upload-image-tempUrl" :src="tempImgSrc" alt="">
          <input class="reupload-image" type="file" id="upload" accept="image/*" @input="uploadImg" />
          <!-- <img :src="imgSrcBase64" alt="" /> -->
        </div>
      </div>
      <div class="upload-image-bottom" v-if="!imgSrcBase64">
        <!-- *For a quick search hit CTRL+V to paste an image into the search box -->
      </div>
      <div class="upload-image-bottom-btn" v-if="imgSrcBase64">
        <div class="upload-image-bottom-confirm" @click="confirmUpload('similar')">
          Find Similar
        </div>
        <div class="upload-image-bottom-confirm" @click="confirmUpload('styles')">
          Find Styles
        </div>
      </div>
    </div>
    <canvas id="canvas" style="display: none"></canvas>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { fetchData } from "@/utils/fetch";
import { useStore } from "vuex";

let active = ref(0);
const imgSrcBase64 = ref("");
const drawImage = ref([]);
const store = useStore();

import { useRouter } from "vue-router";
import {
  buriedPointUtil,
  gaAnalytics,
  redirectWithSearch,
  uploadImage,
} from "@/utils/utils";

const router = useRouter();

const props = defineProps({
  handleClickOutside: Function,
  handleClose: Function,
});

const handleClickOutside = props.handleClickOutside;
const handleClose = props.handleClose
const loading = ref(false);
const tempImgSrc = ref('');

const uploadImg = async (e) => {
  const file = e.target.files?.[0];
  if (!file) {
    return;
  }
  loading.value = true;
  imgSrcBase64.value = ''
  tempImgSrc.value = URL.createObjectURL(file);
  active.value = -1;
  // upload(files[0]);
  uploadImage(file).then((res) => {
    imgSrcBase64.value = res.fileUrl;
    loading.value = false
  }).then(v => {
    //以下是预加载逻辑
    fetchData("/dsp-app/alibaba-common/imageUpload", {
      method: "post",
      body: JSON.stringify({
        imageBase64: imgSrcBase64.value,
      }),
    }).then(data => {
      fetchData("/dsp-app/alibaba-common/searchImage", {
        method: "post",
        body: JSON.stringify({
          imageId: data.data,
          keyword: '',
          pageNow: 1,
          pageSize: 50,
          region: ''
        }),
      })
    })

  })
};

const uploadImgBtn = () => {
  buriedPointUtil({ eventId: "PSC202" });
}

const cancelUpload = () => {
  buriedPointUtil({ eventId: "PSC204" });
  imgSrcBase64.value = "";
};

const searchInfo = computed(() => store.getters.searchInfo);

watch(searchInfo, (newData) => {
  if (newData) {
    searchInfo.value = newData;
  }
});

const confirmUpload = async (type) => {
  store.commit("setLoading", true);
  if (type === "similar") {
    buriedPointUtil({ eventId: "POI201" });
  } else if (type === "styles") {
    buriedPointUtil({ eventId: "POI202" });
  }
  try {
    const { code, data } = await fetchData("/dsp-app/alibaba-common/imageUpload", {
      method: "post",
      body: JSON.stringify({
        imageBase64: imgSrcBase64.value,
      }),
    });
    if (code === 200) {
      store.commit("setCurrentMainImage", imgSrcBase64.value);
      sessionStorage.setItem(`currentMainImage_${data}`, imgSrcBase64.value);
      if (type === "similar") {
        buriedPointUtil({ eventId: "POI401" });
      } else if (type === "styles") {
        buriedPointUtil({ eventId: "POI402" });
      }
      window.open(
        `/search${redirectWithSearch(`imageId=${data}&type=${type}`)}`,
        "_blank"
      );
    }
    store.commit("setLoading", false);
    handleClose();
  } catch (error) {
    store.commit("setLoading", false);
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
