<template>
  <div class="allow-dlg">
    <div class="allow-dlg-title">Please find a suitable angle</div>
    <div class="allow-dlg-example-images">
      <div v-for="(img, index) in imgList" :key="index" class="example-image">
        <img v-if="[0, 1].includes(index)" class="flag-icon" src="@/assets/success.svg" width="18px" height="18px" alt="" >
        <img v-if="[2, 3].includes(index)" class="flag-icon" src="@/assets/error-circle.svg" width="18px" height="18px" alt="" >
        <img :src="img" width="60px" height="60px" alt="" />
      </div>
    </div>
    <div></div>
    <div class="allow-dlg-desc">If you wan to search items by taking or uploading a photo, please allow us access to your camera and photo album.</div>
    <div class="allow-dlg-btns">
      <div class="button cancel" @click="handleCancel">Cancel</div>
      <div class="button allow" @click="handleAllow">Allow Access</div>
    </div>
  </div>
  <input ref="inputRef" @change="inputChange" type="file" accept="image/*" style="display: none;">
</template>

<script setup>
  import { computed, ref } from 'vue'
  import image1 from '@/assets/example-img1.png';
  import image2 from '@/assets/example-img2.png';
  import image3 from '@/assets/example-img3.png';
  import image4 from '@/assets/example-img4.png';
  import { uploadImage, buriedPointUtil } from '@/utils/utils'
  import { useStore } from 'vuex';

  const emits = defineEmits(['allow'])

  const store = useStore();

  const inputRef = ref();
  const imgList = [image1, image2, image3, image4];

  const handleCancel = () => {
    buriedPointUtil({ eventId: "MPSC204" });
    store.commit('h5Module/setImageSearchGuidePageVisible', false)
  }

  const handleAllow = () => {
    buriedPointUtil({ eventId: "MPSC203" });
    inputRef.value.click();
  }
  const inputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      e.target.value = null
      emits('uploadSuccess', file)
    }
  }
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>