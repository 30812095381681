<template>
  <CartsH5 v-if="isMobile === 'MOBILE'" />
  <CartsPC v-if="isMobile === 'PC'" />
</template>

<script setup>
import { nextTick, onMounted, ref } from 'vue';
import CartsH5 from './components/CartsH5/index.vue'
import CartsPC from './components/CartsPC/index.vue'

const isMobile = ref(false);
onMounted( async () => {
  const userAgent = navigator.userAgent.toLowerCase();
  isMobile.value = /iphone|ipod|ipad|android|blackberry|windows phone/i.test(userAgent) ? "MOBILE" : "PC";
});


</script>