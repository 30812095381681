<template>
  <div
    ref="swipeCell"
    class="swipe-cell"
    @touchstart="onTouchStart"
    @touchmove="onTouchMove"
    @touchend="onTouchEnd"
    @touchcancel="onTouchEnd"
    @click="onContentClick"
    :style="{ transform: `translateX(${contentOffset}px)` }"
  >
    <!-- 主要内容区域 -->
    <div class="swipe-cell-content">
      <slot></slot>
    </div>

    <!-- 右侧操作区域 -->
    <div  class="swipe-cell-right" @click="handleEdit">
      <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/carts/order-delete.svg" alt="">
      <div>Delete</div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, defineProps, watch, computed } from 'vue';
const emit = defineEmits();

const props = defineProps({
  openRight: {
    type: Boolean,
    require: false
  }
})

// 定义响应式变量
const swipeCell = ref(null); // 绑定滑动容器
const isOpen = ref(false); // 控制右侧操作区域的显示
const startX = ref(0); // 记录触摸开始时的位置
const currentX = ref(0); // 记录当前触摸的位置
const contentOffset = ref(0); // 记录内容区域的偏移量
const threshold = 120; // 触发滑动操作的阈值
const maxSwipe = 103; // 最大滑动距离，右侧操作区域的宽度

// 处理触摸开始
const onTouchStart = (event) => {
  startX.value = event.touches[0].clientX;
};

// 处理触摸移动
const onTouchMove = (event) => {
  currentX.value = event.touches[0].clientX - startX.value;
  // 限制滑动的最大距离
  const newOffset = Math.min(0, Math.max(currentX.value, -maxSwipe));
  contentOffset.value = newOffset; // 更新内容区域的偏移量
};

// 处理触摸结束
const onTouchEnd = () => {
  if (Math.abs(currentX.value) > threshold) {
    isOpen.value = currentX.value < 0; // 向左滑动时开启，向右滑动时关闭
  }
  // 如果滑动回到初始位置
  if (Math.abs(currentX.value) < threshold) {
    contentOffset.value = 0; // 重置为初始位置
  }
};

// 点击内容区域，回到初始位置
const onContentClick = () => {
  contentOffset.value = 0;
};

// 编辑操作
const handleEdit = (e) => {
  e.stopPropagation()
  emit('edit');
  contentOffset.value = 0;
};
</script>

<style scoped>
.swipe-cell {
  display: flex;
  position: relative;
  width: 100%;
  transition: transform 0.3s ease; /* 平滑过渡 */
}

.swipe-cell-content {
  width: 100%;
  box-sizing: border-box;
  transition: transform 0.3s ease; /* 平滑过渡 */
  padding: 0px 0px;
}

.swipe-cell-right {
  position: absolute;
  top: 0;
  right: -103px;
  bottom: 0;
  width: 103px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #F54550;
  transition: right 0.3s ease;
  font-size: 12px;
  color: #FFFFFF;
  font-family: mon-500;
  flex-direction: column;
  height: 99%;
}
</style>
