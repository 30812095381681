
<template>
  <div class="guideViewH5">
    <HeaderTopSwiper />
    <div class="guideViewH5-content">
      <div class="content">
        <div class="content-images-wrapper">
          <div
            v-for="(row, rowIndex) in visibleImages"
            :key="rowIndex"
            class="image-row-wrapper"
          >
            <div class="image-row" ref="dynamicRefs" :style="rowIndex === 1 ? { top: '-60px' } : {top: '-30px'}">
              <img
                v-for="(image, index) in row"
                :key="index"
                :src="image"
                width="120px"
                alt="image"
                class="image-item"
              />
            </div>
          </div>
        </div>
        <div class="content-bg"></div>
        <img src="@/assets/guide/group-logo.png" alt="" class="content-logo">
      </div>
      <div class="guide-title">
        Your Gateway to Unlimited Goods!
      </div>
      <div class="guide-desc">
        Discover billions of products for your needs.
      </div>
      <div class="guide-sign-btn" @click="handleSign">
        Sign in / Sign up
      </div>
    </div>
    <H5Dialog class="h5-dialog-content" v-model="showPopup" :height="`calc(${innerHeight - 32}px)`">
      <H5LoginIn :handleClickOutside="handleClickOutside" />
    </H5Dialog>
  </div>
</template>

<script setup>
import H5Dialog from '@/components/H5/H5Dialog/index.vue';
import H5LoginIn from '@/components/H5/H5LoginIn/index.vue';
import HeaderTopSwiper from "@/views/HomeView/components/HomeViewH5/components/HeaderTopSwiper/index.vue";
import { nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();

const showPopup = ref(false)

const group1 = import.meta.glob("@/assets/guide/group1/*.webp", {
  eager: true,
});
const group2 = import.meta.glob("@/assets/guide/group2/*.webp", {
  eager: true,
});
const group3 = import.meta.glob("@/assets/guide/group3/*.webp", {
  eager: true,
});

const visibleImages = ref([]);
const dynamicRefs = ref([]);
const innerHeight = ref(0);
onMounted(() => {
  innerHeight.value = window.innerHeight;
  visibleImages.value = [
    mapImages(group1),
    mapImages(group2),
    mapImages(group3),
  ];
  setInterval(() => {
    startImageTransition();
  }, 5000);
});

const mapImages = (group) => {
  const images = [];
  for (const path in group) {
    images.push(group[path].default);
  }
  return images;
};

const handleSign = () => {
  showPopup.value = true;
}

const handleClickOutside = () => {
  showPopup.value = false;
  store.commit("setLoginVisible", false);
};

const transformNums = ref(0); // 位移次数
const startImageTransition = () => {
  transformNums.value++;
  if (transformNums.value > 2) {
    transformNums.value = 0;
  }
  const elList = dynamicRefs.value;
  elList.forEach((el, index) => {
    const parent = el.parentElement;
    const parentTop = parseInt(window.getComputedStyle(parent).top);

    setTimeout(() => {
      el.style.opacity = 0;
      el.style.transform = `translateY(${-456 * transformNums.value}px)`;

      setTimeout(() => {
        el.style.opacity = 1;
      }, 300);
    }, 800 * index);
  });
};

const handleSuperUser = () => {
  const query = route.query;
  if (+query.sp === 1) {
    handleSign();
  }
};

onMounted(() => {
  handleSuperUser()
})

</script>

<style>
@import "./index.less";
</style>