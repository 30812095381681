<script setup>
import {
  ref,
  onMounted,
  onBeforeUnmount,
  computed,
  watch,
  defineProps,
} from "vue";
import { useStore } from "vuex";
import Country from "./components/Country/index.vue";
import Language from "./components/Language/index.vue";
import SearchImg from "./components/SearchImg/index.vue";
import Login from "@/components/Login/index.vue";
import HelpCenterAnimate from "./components/HelpCenterAnimate/index.vue";
import { fetchData } from "@/utils/fetch";
import { useRoute, useRouter } from "vue-router";
import {
  buriedPointUtil,
  gaAnalytics,
  redirectWithSearch,
} from "@/utils/utils";
import { checkHasQueryLanguage, debounce, throttle } from "../../utils/utils";


const router = useRouter();
const route = useRoute();
const store = useStore();

const searchInfo = computed(() => store.state.searchInfo);
const language = computed(() => localStorage.getItem("language") || "en");

const props = defineProps({
  guidancePopVisible: {
    type: Boolean,
    required: false,
  },
});

const isCountryVisible = ref(false);
const isSearchVisible = ref(false);
const isLoginVisible = ref(false);
const inputValue = ref("");

const lastQuery = ref('')

const userInfo = computed(() => store.state.common.userInfo || {});
const loginVisible = computed(() => store.state.isLoginVisible);

const showHelpCenterAnimate = computed(() => store.state.common.showHelpCenterAnimate);

const handleSearchInput = debounce(() => {
  const complete = localStorage.getItem("complete") || "";
  if (complete.indexOf(5) === -1) {
    store.dispatch("common/openGuidancePop", { id: 5, helpCenterImage: 'https://cdn-image.bestdealer.com/app-bestdealer/1688/guide-image-3.webp' });
  }
}, 300);

const openGuidancePop = () => {

};

watch(loginVisible, (newData) => {
  isLoginVisible.value = newData;
});

const countryObj = ref({});

const toggleCountry = () => {
  isCountryVisible.value = !isCountryVisible.value;
};

const toggleCountryLeave = () => {
  isCountryVisible.value = false
};


const stopClick = (event) => {
  event.stopPropagation();
};

const handleClose = () => {
  isSearchVisible.value = false;
};

const handleClickOutside = (event) => {
  if (
    !event.target.closest(".country-right") &&
    !event.target.closest(".country")
  ) {
    isCountryVisible.value = false;
  }
};

const handleClickSearchOutside = (event) => {
  // search-input
  if (
    !event.target.closest(".search-input") &&
    !event.target.closest(".search-camera")
  ) {
    isSearchVisible.value = false;
  }
};

const handleLoginClickOutside = (event) => {
  store.commit("setLoginVisible", false);
};

const handleLogin = () => {
  if (userInfo.value?.email) {
    return;
  }
  gaAnalytics("p_sign_in_click", "p_sign_in_click");
  buriedPointUtil({ eventId: "P201" });
  store.commit("setPassiveLogin", 0);
  store.commit("setLoginVisible", true);
};

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
  document.addEventListener("click", handleClickSearchOutside);
  getCountry();
  getCurrencyList();
  getUserInfo();

  const { keyword = "" } = route.query;
  if (!!keyword) {
    inputValue.value = keyword;
    document.title = keyword;
  }
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
  document.addEventListener("click", handleClickSearchOutside);
});

const countryData = computed(() => store.getters.countryInfo);

const loadCacheCountry = () => {
  const _cacheData = localStorage.getItem('cacheCountryList')
  if (!_cacheData) return null;

  const cacheData = JSON.parse(_cacheData);
  if (Date.now() - cacheData.timestamp > 24 * 60 * 60 * 1000) {
    localStorage.removeItem('cacheCountryList');
    return null;
  }
  return cacheData.data
}

const getCountry = async () => {
  const format = (data) => {
    store.commit("setCountryInfo", data);

    const countryObj = !!localStorage.getItem("setCurrentCountry")
      ? JSON.parse(localStorage.getItem("setCurrentCountry"))
      : data[0];
    store.commit("setCurrentCountry", countryObj);
    store.commit(
      "common/SET_LANGUAGE",
      checkHasQueryLanguage(lg) || localStorage.getItem("language") || countryObj.language
    );
  }

  const { lg = "" } = route.query;
  const cacheValue = loadCacheCountry();
  if (cacheValue) {
    format(cacheValue)
    return
  }
  const { code, data } = await fetchData("/dsp-app/alibaba-common/country");
  if (code === 200) {
    localStorage.setItem('cacheCountryList', JSON.stringify({
      data,
      timestamp: Date.now()
    }))
    format(data)
  }
};

const getCurrencyList = async () => {
  const { code, data } = await fetchData("/dsp-app/alibaba-common/currencyList");
  if (code === 200) {
    store.commit("setCurrencyList", data);
    if (!localStorage.getItem("currencyData")) {
      localStorage.setItem("currencyData", JSON.stringify(data[0]));
      store.dispatch("setProduct", data[0]);
      store.commit("setCurrencyData", data[0]);
    } else {
      store.dispatch(
        "setProduct",
        JSON.parse(localStorage.getItem("currencyData"))
      );
      store.commit(
        "setCurrencyData",
        JSON.parse(localStorage.getItem("currencyData"))
      );
    }
  }
};

const getUserInfo = async () => {
  const { code, data } = await fetchData("/dsp-app/alibaba-inter/queryMyInfo", {
    method: "post",
  });
  if (code === 200) {
    localStorage.setItem("dsp.uid", data.uid);
    store.commit("common/SET_USER_INFO", data);
    const completeIds = data?.complete || []
    const complete = localStorage.getItem("complete") || "";

    const strNumbers = complete.split(',').map(num => parseFloat(num)).filter(num => !isNaN(num)) || [];
    // 过滤出不在数组中的数字
    const result = strNumbers.filter(num => !completeIds.includes(num));
    if (result.length) {
      const { code } = await fetchData(`/dsp-app/alibaba-inter/userComplete`, {
        method: "PUT",
        body: JSON.stringify(result)
      });
      if (code === 200) {
        const completeStr = [...result, ...completeIds].join(',')
        localStorage.setItem("complete", completeStr);
      }
    } else {
      localStorage.setItem("complete", completeIds.join(','));
    }
  }
};

watch(countryData, (newData) => {
  if (newData) {
    countryObj.value = newData;
  }
});

const mouseMove = ref(false);

const logoutMove = () => {
  if (userInfo?.value?.email) {
    mouseMove.value = true;
  }
};

const mouseleave = () => {
  mouseMove.value = false;
};

const maskedEmail = computed(() => {
  if (!userInfo?.value?.email) {
    return "";
  }
  const [username, domain] = userInfo?.value?.email?.split("@");
  const maskedUsername =
    username.length > 4 ? username.slice(0, 4) + "***" : username;

  return `${maskedUsername}@${domain}`;
});

const singOut = async () => {
  try {
    const { code, data } = await fetchData("/dsp-app/user/logOut", {
      method: "post",
    });
    if (code === 200) {
      localStorage.removeItem("tk");
      localStorage.removeItem("dsp.uid");
      store.commit("common/SET_USER_INFO", {});
    }
  } catch (error) { }
};

const handleChangeItem = throttle((e) => {
  inputValue.value = e.target.value;
  if(e.target.value.length >= lastQuery.value.length) {
    lastQuery.value = e.target.value
  }
  if(e.target.value.length < lastQuery.value.length) {
    lastQuery.value = e.target.value
    return
  }
  const searchData = {
    ...searchInfo.value,
    keyword: e.target.value,
    language: language.value,
    pageSize: 50,
    pageNow: 1,
    imageId: 0,
    region: "",
    async: true,
  };
  fetchData(`/dsp-app/alibaba-common/searchKeyWord?itemNumber=50`, {
    method: "post",
    body: JSON.stringify(searchData),
  });
}, 400)

const handleEnter = async () => {
  try {
    await searchProductList();
  } catch (error) { }
};

const searchProductList = async () => {
  gaAnalytics("p_search_click", "p_search_click");
  buriedPointUtil({ eventId: "PSC201" });
  if (!inputValue.value) {
    return;
  }

  // const searchParams = window.location.search.substring(1);
  // if (searchParams.includes("categoryId")) {
  //   window.open(
  //     `/search${redirectWithSearch(
  //       `${searchParams}&keyword=${inputValue.value}`
  //     )}`
  //   );
  // } else {
  //   window.open(`/search?keyword=${inputValue.value}`);
  // }
  // const { lg = "" } = route.query;
  const language = localStorage.getItem('language')
  window.open(`/search?keyword=${inputValue.value}&lg=${language}`);
};

const showSearchCamera = () => {
  isSearchVisible.value = true;
};

const goHome = () => {
  location.href = "/";
};

watch(
  mouseMove,
  (val) => {
    if (val) {
      toggleCountryLeave()
    }
  }
)

</script>

<template>
  <div class="search-main" @click="openGuidancePop">
    <div class="search-container">
      <div @click="goHome" style="cursor: pointer">
        <!-- <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/logo.svg" alt="1688" /> -->
        <img src="@/assets/header/pc-home-logo.svg" alt="1688">
      </div>
      <div class="search-input" @click="handleSearchInput">
        <Language @mouseenter="toggleCountryLeave" />
        <input type="text" class="search-input-item"
          placeholder="🔍 Enter the product description, and let’s find your treasure!" @input="handleChangeItem"
          v-model="inputValue" @keyup.enter="handleEnter" />
        <div class="search-camera">
          <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/camra-2.svg" alt=""
            @click="showSearchCamera" />
          <transition name="country-fade">
            <SearchImg v-if="isSearchVisible" @click="stopClick" :handleClickOutside="handleClickSearchOutside"
              :handleClose="handleClose" />
          </transition>
        </div>
        <div class="search-btn" @click="searchProductList">
          <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/search-icon.svg" alt="" />
        </div>
      </div>
      <div class="search-country">
        <div class="country-left">
          <img :src="countryData[0]?.countryUrl" alt="" />
        </div>
        <div class="country-right" @mouseenter="toggleCountry">
          <div class="country-text">{{ countryData[0]?.countryName }}</div>
          <div class="country-unit">
            <span>{{ countryData[0]?.countryCode }}</span>
            <img src="@/assets/select-down.svg" alt="" />
          </div>
        </div>
        <transition name="country-fade">
          <Country v-if="isCountryVisible" @mouseleave="toggleCountryLeave" @click="stopClick"
            :handleClickOutside="handleClickOutside" />
        </transition>
      </div>
      <!-- 个人中心 -->
      <div :class="[
        {
          showPerson: showHelpCenterAnimate,
        },
        'search-person',
      ]" @mouseenter="logoutMove" @mouseleave="mouseleave" @click="handleLogin">
        <div :class="[
          {
            personLeftZoom: showHelpCenterAnimate,
          },
          'person-left',
        ]">
          <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/person.svg" alt="" style="cursor: pointer" />
        </div>
        <div class="person-right">
          <div class="person-text">
            Hello,
            {{
              userInfo?.name
                ? userInfo?.name.length > 4
                  ? userInfo?.name.slice(0, 4) + "***"
                  : userInfo?.name
                : "Sign In"
            }}
          </div>
          <div class="person-account">Account</div>
        </div>
        <!-- 个人中心弹出框 -->
        <transition name="country-fade">
          <div class="search-person-hoverd" v-if="mouseMove">
            <div class="sign-out">
              <div class="country-select-top">
                <img src="@/assets/country-select.svg" alt="" />
              </div>
              <div class="country-select-hideline"></div>
              <div class="sign-out-info">
                <div class="sign-out-img">
                  <img src="@/assets/avater.svg" alt="" />
                </div>
                <div class="sign-out-main">
                  <div class="sign-out-title">Welcome back,</div>
                  <div class="sign-out-email">{{ maskedEmail }}</div>
                  <div class="sign-out-btn" @click="singOut">Sign Out</div>
                </div>
              </div>
              <div class="menu-list">
                <a href="/help" target="_blank" rel="noopener noreferrer">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path
                      d="M12.1903 1.52393C12.4934 1.52393 12.7841 1.64433 12.9984 1.85866C13.2128 2.07299 13.3332 2.36368 13.3332 2.66678V13.3334C13.3332 13.4835 13.3036 13.6321 13.2462 13.7708C13.1887 13.9095 13.1046 14.0354 12.9984 14.1416C12.8923 14.2477 12.7663 14.3319 12.6277 14.3893C12.489 14.4467 12.3404 14.4763 12.1903 14.4763H3.80936C3.65928 14.4763 3.51067 14.4467 3.37201 14.3893C3.23335 14.3319 3.10736 14.2477 3.00124 14.1416C2.89511 14.0354 2.81093 13.9095 2.7535 13.7708C2.69606 13.6321 2.6665 13.4835 2.6665 13.3334V2.66678C2.6665 2.36368 2.78691 2.07299 3.00124 1.85866C3.21557 1.64433 3.50626 1.52393 3.80936 1.52393H12.1903ZM12.1903 12.5715H3.80936V13.3334H12.1903V12.5715ZM4.95222 2.66678H3.80936V11.4287H12.1903V2.66678H8.76174V7.23821L6.85698 6.39783L4.95222 7.23821V2.66678ZM7.61889 2.66678H6.09508V5.48469L6.85698 5.14869L7.61889 5.48469V2.66678Z"
                      fill="#232323" />
                  </svg>
                  Help Center
                </a>
              </div>
            </div>
          </div>
        </transition>
        <HelpCenterAnimate v-if="showHelpCenterAnimate" />
      </div>
    </div>
    <div>
      <transition name="country-fade">
        <Login v-if="isLoginVisible" @click="stopClick" :handleClickOutside="handleLoginClickOutside" />
      </transition>
    </div>
  </div>
</template>

<style scoped lang="less">
.search-main {
  width: 100vw;
  height: 72px;
  padding: 4px 0px;
  background-color: #052784;
}

.search-main .search-container {
  width: 1200px;
  margin: auto;
  display: flex;
  align-items: center;
  height: 72px;
}

.search-input {
  margin-left: 32px;
  position: relative;
}

.search-input .search-camera {
  position: absolute;
  top: 48%;
  transform: translateY(-50%);
  right: 64px;
  width: 26px;
  height: 26px;
  z-index: 9;

  img {
    cursor: pointer;
  }
}

.search-input .search-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  width: 48px;
  height: 32px;
  background-color: #f57231;
  border-radius: 16px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}



.search-input .search-input-item {
  width: 656px;
  height: 40px;
  box-sizing: border-box;
  padding-right: 106px;
  border-radius: 20px;
  border: none;
  font-size: 14px;
  padding-left: 88px;
  outline: none;
  font-family: mon-500;
}

.search-input .search-input-item:hover {
  outline: 1px solid #0069e0;
}

.search-input .search-input-item:active {
  outline: 1px solid #0069e0;
}

input::placeholder {
  font-size: 12px;
  font-family: mon-500;
  line-height: 16px;
  color: #6e717a;
}

.search-country {
  margin-left: 32px;
  display: flex;
  align-items: center;
  min-width: 120px;
  position: relative;
}

.search-country .country-left {
  width: 40px;
  height: 40px;
  overflow: hidden;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    padding: 4px;
    width: 100%;
    object-fit: cover;
    display: flex;
  }
}

.search-country .country-right {
  margin-left: 8px;
  cursor: pointer;
}

.search-country .country-right .country-text {
  font-family: mon-500;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.search-country .country-right .country-unit {
  font-family: mon-600;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff;
}

.search-country .country-right .country-unit img {
  vertical-align: middle;
  margin-left: 4px;
}

.search-person {
  display: flex;
  align-items: center;
  min-width: 132px;
  position: relative;

  .search-person-hoverd {
    z-index: 1001;

    .sign-out {
      box-shadow: -6px 0px 12px rgba(110, 113, 122, 0.05),
        6px 0px 12px rgba(110, 113, 122, 0.05),
        0px -6px 12px rgba(110, 113, 122, 0.05),
        0px 6px 12px rgba(110, 113, 122, 0.05);
      position: absolute;
      width: 180px;
      border: 0.5px solid #b2b7c2;
      background-color: #ffffff;
      left: 50%;
      top: 50px;
      transform: translateX(-50%);
      border-radius: 12px;
      margin-left: 8px;
      z-index: 9;

      .country-select-top {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: -16px;
        z-index: 8;
      }

      .country-select-hideline {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0px;
        height: 2px;
        width: 50px;
        z-index: 9;
        background-color: #ffffff;
      }

      .sign-out-info {
        display: flex;
        padding: 18px 14px 18px 14px;

        .sign-out-main {
          margin-left: 8px;

          .sign-out-title {
            font-family: mon-500;
            color: #232323;
            font-size: 10px;
            line-height: 14px;
            letter-spacing: -2%;
          }

          .sign-out-email {
            font-size: 10px;
            font-family: mon-600;
            line-height: 14px;
            color: #232323;
            margin-top: 2px;
          }

          .sign-out-btn {
            margin-top: 8px;
            font-family: mon-500;
            font-size: 10px;
            line-height: 14px;
            color: #009de0;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }

      .menu-list {
        margin: 0 16px;
        border-top: 0.5px solid #e4e6eb;

        a {
          padding: 8px 4px;
          display: flex;
          align-items: center;
          cursor: pointer;
          color: #232323;
          font-family: Montserrat;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          text-decoration: none;
          margin-top: 12px;
          margin-bottom: 12px;

          &:hover {
            border-radius: 4px;
            background: #f5f5f5;
          }

          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .personLeftZoom {
    animation: zoomInOut 0.8s ease-in-out 0.4s infinite;
  }
}

@keyframes zoomInOut {
  0% {
    transform: scale(1);
    /* 初始大小 */
  }

  50% {
    transform: scale(1.2);
    /* 放大到1.5倍 */
  }

  100% {
    transform: scale(1);
    /* 缩小回原始大小 */
  }
}

.showPerson {
  z-index: 1200;
}

.search-person .person-right {
  margin-left: 8px;
  cursor: pointer;
}

.search-person .person-right .person-text {
  font-size: 12px;
  font-family: mon-500;
  line-height: 18px;
  color: #ffffff;
}

.search-person .person-right .person-account {
  font-size: 12px;
  font-family: mon-600;
  line-height: 18px;
  color: #ffffff;
}

/* 添加动画效果 */
.country-fade-enter-active,
.country-fade-leave-active {
  transition: opacity 0.3s ease;
}

.country-fade-enter,
.country-fade-leave-to {
  opacity: 0;
}

.country-fade-enter-to,
.country-fade-leave {
  opacity: 1;
}
</style>
