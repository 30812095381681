<template>
  <H5Orders v-if="isMobile === 'MOBILE'" />
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { useCommonToast } from '@/hooks/useCommonToast'
import H5Orders from './H5Orders/index.vue'
import { useRoute } from 'vue-router';
import router from '../../router';

const { toast } = useCommonToast();

const route = useRoute()

const isMobile = ref('');
onMounted(() => {
  const userAgent = navigator.userAgent.toLowerCase();
  isMobile.value = /iphone|ipod|ipad|android|blackberry|windows phone/i.test(userAgent) ? "MOBILE" : "PC";

  // pc订单上线后删除
  if (isMobile.value === 'PC' && route.fullPath.includes('fromEmail')) {
    toast(
      'Please open the email on your mobile device, click "Resubmit," and upload a new screenshot of the bank transfer.', 
      { 
        type: 'warning',
        duration: 3000,
      }
    )
    return
  }
  
  if (isMobile.value === 'PC') {
    toast(
      'In the development of web functions, please go to the mobile end and reopen this email for further operations.', 
      { 
        type: 'warning',
        duration: 3000,
      }
    )
    setTimeout(() => {
      router.push('/')
    }, 3000);
  }
});
</script>