<template>
  <div class="carousel-main">
    <div class="carousel">
      <!-- 轮播图容器 -->
      <div class="carousel-container" :style="carouselStyle">
        <div v-for="(image, index) in images" :key="index" class="carousel-item"
          :class="{ active: index === currentIndex }" :style="getItemStyle(index)">
          <img :src="image" :alt="image" />

          <div class="carousel-btn">
            <div class="carousel-tool">
              <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/transtale-tool.svg" alt=""
                class="carousel-traslate" :class="activeTool === 'TRANSLATE' && 'activeTool'">
              <img class="translate-btn" @click="(e) => translateFun(image, e)"
                src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-translate-icon.svg" alt=""
                @mouseenter="toggleDropdown('TRANSLATE')" @mouseleave="handleButtonMouseLeave">
            </div>
            <div class="carousel-tool">
              <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/findsimilar-tool.svg" alt=""
                class="carousel-traslate" :class="activeTool === 'FIND_SIMILAR' && 'activeTool'" style="width: 103px;">
              <img class="translate-btn"
                src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-findsmilar-icon.svg" alt=""
                @mouseenter="toggleDropdown('FIND_SIMILAR')" @mouseleave="handleButtonMouseLeave"
                @click="(e) => findSimilar(image, e)">
            </div>
            <div class="carousel-tool">
              <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/findstyle-tool.svg" alt=""
                class="carousel-traslate" :class="activeTool === 'FIND_STYLE' && 'activeTool'" style="width: 96px;">
              <img class="translate-btn"
                src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-findstyle-icon.svg" alt=""
                @mouseenter="toggleDropdown('FIND_STYLE')" @mouseleave="handleButtonMouseLeave"
                @click="(e) => findStyles(image, e)">
            </div>
          </div>
        </div>
        <div class="hoverd-image" v-show="!!currentHoverdImage">
          <img :src="currentHoverdImage" alt="" />
          <div class="carousel-btn">
            <div class="carousel-tool">
              <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/transtale-tool.svg" alt=""
                class="carousel-traslate" :class="activeTool === 'TRANSLATE' && 'activeTool'">
              <img class="translate-btn" @click="(e) => translateFun(currentHoverdImage, e)"
                src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-translate-icon.svg" alt=""
                @mouseenter="toggleDropdown('TRANSLATE')" @mouseleave="handleButtonMouseLeave">
            </div>
            <div class="carousel-tool">
              <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/findsimilar-tool.svg" alt=""
                class="carousel-traslate" :class="activeTool === 'FIND_SIMILAR' && 'activeTool'" style="width: 103px;">
              <img class="translate-btn"
                src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-findsmilar-icon.svg" alt=""
                @mouseenter="toggleDropdown('FIND_SIMILAR')" @mouseleave="handleButtonMouseLeave"
                @click="(e) => findSimilar(currentHoverdImage, e)">
            </div>
            <div class="carousel-tool">
              <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/findstyle-tool.svg" alt=""
                class="carousel-traslate" :class="activeTool === 'FIND_STYLE' && 'activeTool'" style="width: 96px;">
              <img class="translate-btn"
                src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-findstyle-icon.svg" alt=""
                @mouseenter="toggleDropdown('FIND_STYLE')" @mouseleave="handleButtonMouseLeave"
                @click="(e) => findStyles(currentHoverdImage, e)">
            </div>
          </div>
        </div>
      </div>



      <button class="prev" @click="prevImage">
        <img src="@/assets/offer/left.svg" alt="" />
      </button>
      <button class="next" @click="nextImage">
        <img src="@/assets/offer/right.svg" alt="" />
      </button>
    </div>
    <div class="thumbnails">
      <div class="thumbnails-switch thumbnails-prev" @click="prevImage" v-show="images.length > 6">
        <div>
          <img src="@/assets/offer/left-arrow.svg" alt="" />
        </div>
      </div>
      <!-- 微缩图轮播容器 -->
      <div class="thumbnails-container" ref="scrollThumbnailsContainer">
        <div v-for="(image, index) in images" :key="'thumb-' + index" class="thumbnail"
          :class="{ active: index === currentIndex }" @click="goToImage(index)">
          <img :src="image" :alt="image" />
        </div>
      </div>
      <div class="thumbnails-switch thumbnails-next" @click="nextImage" v-show="images.length > 6">
        <div>
          <img src="@/assets/offer/right-arrow.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed, watch } from "vue";
import { useStore } from "vuex";
import { buriedPointUtil, redirectWithSearch } from "@/utils/utils";

const store = useStore();
const images = ref([]);
const currentIndex = ref(0);
const activeTool = ref('')

const scrollThumbnailsContainer = ref(null);

const offerData = computed(() => store.state.offerDetail);
const firstrAttributesimages = computed(
  () => store.state.firstrAttributesimages
);

const toggleDropdown = (key) => {
  activeTool.value = key
}

const handleButtonMouseLeave = () => {
  activeTool.value = ''
}

// 找相似
const findSimilar = async (imageUrl, e) => {
  e.stopPropagation();
  buriedPointUtil({ eventId: "POP201" });
  sessionStorage.setItem(`currentMainImage_${offerData.value.offerId}`, imageUrl);
  window.open(
    `/search${redirectWithSearch(`offerId=${offerData.value.offerId}&type=similar`)}`
  );
};

// 找样式一致
const findStyles = async (imageUrl, e) => {
  e.stopPropagation();
  buriedPointUtil({ eventId: "POP202" });
  sessionStorage.setItem(`currentMainImage_${offerData.value.offerId}`, imageUrl);
  window.open(
    `/search${redirectWithSearch(`offerId=${offerData.value.offerId}&type=styles`)}`
  );
};

const currentHoverdImage = computed(() => store.state.currentHoverdImage);

const translateFun = async (item, e) => {
  e.stopPropagation();
  buriedPointUtil({ eventId: "PTR201" });
  try {
    // 1. 加载图片并转换为 Bitmap
    const response = await fetch(item);
    const blob = await response.blob();
    const bitmap = await createImageBitmap(blob);

    // 2. 绘制到 Canvas 并导出为 PNG
    const canvas = document.createElement("canvas");
    canvas.width = bitmap.width;
    canvas.height = bitmap.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(bitmap, 0, 0);

    // 3. 将 Canvas 转换为 Blob (PNG)
    const pngBlob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/png")
    );

    // 4. 写入剪贴板
    const clipboardItem = new ClipboardItem({ "image/png": pngBlob });
    await navigator.clipboard.write([clipboardItem]);
    store.commit("common/SET_TOAST_VISIBLE", true);
    store.commit("common/SET_TOAST_INFO", {
      msg: "Copy Successfully",
      type: "success",
    });
    const language = (localStorage.getItem('language') || 'en').replace('zh', 'zh-CN')
    window.open(
      `https://translate.google.com/?sl=auto&tl=${language}&text=${item.subjectTrans}&op=images`
    );
  } catch (error) {
    console.error("复制失败:", error);
    console.error("复制失败:", error.message);

    store.commit("common/SET_TOAST_VISIBLE", true);
    store.commit("common/SET_TOAST_INFO", {
      msg: "Copy Error",
      type: "error",
    });
  }
};

watch([offerData, firstrAttributesimages], (newData) => {
  if (newData[0] && newData[1]) {
    const uniqueArr = [
      ...newData[0]?.productImage?.images,
      ...new Set([...newData[1].filter((item) => item !== null)]),
    ];
    images.value = uniqueArr || [];
  }
});

watch(currentIndex, (newIndex, oldIndex) => {
  scrollToIndex(newIndex);
});

watch(currentHoverdImage, (newData) => {
  if (newData) {
    const _index = images.value.findIndex((v) => v === newData);
    if (_index !== -1) {
      currentIndex.value = _index;
      currentHoverdImage.value = newData;
    }
  }
});

// 自动切换图片
let interval = null;

// 滚动轮播图
const scrollToIndex = (index) => {
  const scrollThumbnailsContainerEle = scrollThumbnailsContainer.value;
  const items = scrollThumbnailsContainerEle.children; // 所有子元素
  const itemWidth = items[0].offsetWidth; // 单个图片的宽度（含间距）
  const containerWidth = scrollThumbnailsContainerEle.clientWidth; // 容器的可见宽度
  const currentItem = items[index]; // 当前索引对应的元素
  const itemLeft = currentItem.offsetLeft; // 当前块的左侧距离容器的左边缘距离
  const itemRight = itemLeft + itemWidth; // 当前块的右边界距离容器的左边缘距离

  // 判断是否需要滚动
  const isOutOfView =
    itemLeft < scrollThumbnailsContainerEle.scrollLeft ||
    itemRight > scrollThumbnailsContainerEle.scrollLeft + containerWidth;

  if (isOutOfView) {
    // 将当前块移动到容器的中间位置
    const targetScroll = itemLeft - (containerWidth - itemWidth) / 2;
    scrollThumbnailsContainerEle.scrollTo({
      left: targetScroll,
      behavior: "smooth", // 平滑滚动
    });
  }
};

const startAutoPlay = () => {
  interval = setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % images.value.length;
  }, 3000); // 每3秒切换
};

const stopAutoPlay = () => {
  if (interval) {
    clearInterval(interval);
  }
};

// 切换到上一个图片
const prevImage = () => {
  store.commit("setCurrentHoverdImage", "");
  currentIndex.value =
    (currentIndex.value - 1 + images.value.length) % images.value.length;
};

// 切换到下一个图片
const nextImage = () => {
  store.commit("setCurrentHoverdImage", "");
  currentIndex.value = (currentIndex.value + 1) % images.value.length;
};

// 跳转到指定图片
const goToImage = (index) => {
  store.commit("setCurrentHoverdImage", "");
  currentIndex.value = index;
};

// 获取当前图片项的样式
const getItemStyle = (index) => {
  return {
    transform: `translateX(${(index - currentIndex.value) * 100}%)`,
    transition: "transform 0.5s ease-in-out",
  };
};

// 动态计算轮播图容器的宽度
const carouselStyle = computed(() => ({
  width: `${images.value.length * 100}%`,
  transition: "transform 0.5s ease-in-out",
}));

onMounted(() => {
  // startAutoPlay(); // 开始自动切换图片
});

onBeforeUnmount(() => {
  stopAutoPlay(); // 清理定时器
});
</script>

<style lang="less">
@import "./index.less";
</style>
