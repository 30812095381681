<template>
  <div class="confrim-order-container" @click.stop>
    <div class="body">
      <div class="success-created-card"></div>
      <div class="text-1">Transfer information is being confirmed</div>
      <div class="text-2">Once approved, we will notify you via email immediately.</div>

      <div class="transfer-container" :class="{ mgB80: !data.b2bPaymentOrderVo.bankTransferVO.remark }">
        <div class="title">Transfer after completion, submit the following for confirmation.</div>
        <div class="sub-title">Transfer screenshot <span>*</span> :</div>
        <div class="screenshot-wrapper">
          <div v-for="item in data?.b2bPaymentOrderVo?.bankTransferVO?.receiptInfos" class="screenshot">
            <img :src="item" alt="">
          </div>
        </div>
      </div>

      <div v-if="data.b2bPaymentOrderVo.bankTransferVO.remark" class="textarea-box">
        <textarea v-model="data.b2bPaymentOrderVo.bankTransferVO.remark" disabled type="text" maxlength="200"></textarea>
        <!-- <p class="input-length">{{ inputValue.length }} / 200</p> -->
      </div>

      <div class="footer">
        <div class="button" @click="handleGot">I Got It</div>
      </div>
    </div>

    <PreviewImage v-model="showPreview" :list="data.b2bPaymentOrderVo.bankTransferVO.receiptInfos"/>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, nextTick } from 'vue'
import PreviewImage from './PreviewImage.vue';
import { gaAnalytics } from '@/utils/utils';
import { buriedPointUtil } from '@/utils/utils';

const props = defineProps({
  data: {
    type: Object,
    default: () => {}
  }
})

const emits = defineEmits(['got'])

const showPreview = ref(false);

const handleGot = () => {
  buriedPointUtil({ eventId: 'MPRP203' })
  emits('got')
}

onMounted(() => {
  gaAnalytics('mp_transfer_review_page_view', 'mp_transfer_review_page_view')
  buriedPointUtil({ eventId: 'MPRP502' })
})

onUnmounted(() => {
  
})
</script>

<style lang="less" scoped>
.confrim-order-container {
  position: relative;
  height: 100%;
  overflow: scroll;

  .body {
    padding: 0 16px;
    background-color: white;
    border-radius: 8px;

    .success-created-card {
      margin-top: 20px;
      height: 120px;
      background-image: url("@/assets/H5/order/confirm-order-card.png");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 8px;
    }

    .text-1 {
      margin-top: 24px;
      color: #232323;
      font-size: 16px;
      font-family: mon-700;
      line-height: 20px;
    }

    .text-2 {
      margin-top: 6px;
      color: #232323;
      font-size: 12px;
      font-family: mon-500;
      line-height: 16px;
    }

    .transfer-container {
      height: 240px;
      margin-top: 24px;
      padding: 16px;
      border-radius: 8px;
      border: 1px solid #ebf1f5;

      &.mgB80 {
        margin-bottom: 80px;
      }

      .title {
        color: #232323;
        font-size: 14px;
        font-family: mon-600;
        line-height: 18px;
      }

      .sub-title {
        margin-top: 16px;
        color: #232323;
        font-size: 12px;
        font-family: mon-600;
        span {
          color: #f53131;
        }
      }

      .screenshot-wrapper {
        display: flex;
        gap: 24px;
      }

      .screenshot {
        position: relative;
        height: 120px;
        width: 120px;
        margin-top: 12px;
        border-radius: 6px;
        overflow: hidden;
        img {
          width: 100%;
        }

        .mask {
          position: absolute;
          inset: 0;
          background-color: rgba(0, 0, 0, .6);
        }

        .scale-icon {
          position: absolute;
          top: 4px;
          right: 4px;
          width: 24px;
          height: 24px;
        }
      }
    }

    .textarea-box {
      position: relative;
      height: 120px;
      margin: 12px 0 72px 0;
      padding: 16px 12px;
      border: 1px solid #ebf1f5;
      border-radius: 8px;

      textarea {
        width: 100%;
        height: 100%;
        outline: none;
        border: none;
        font-size: 12px;
        font-family: mon-500;
        line-height: 16px;
        color: #5c5c5c;
        resize: none;

        &::placeholder {
          color: #b2b7c2;
          font-size: 12px;
          font-family: mon-500;
        }

        &:disabled { 
          background-color: white;
        }
      }

      .input-length {
        position: absolute;
        right: 12px;
        bottom: 12px;
        color: #b2b7c2;
        font-size: 12px;
        font-family: mon-500;
      }
    }

    .footer {
      position: fixed;
      left: 12px;
      right: 12px;
      bottom: 12px;
      height: 72px;
      border-top: 0.5px solid #e4e6eb;
      background-color: white;
      border-radius: 0 0 8px 8px;
      display: flex;
      justify-content: end;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        width: 160px;
        border-radius: 22px;
        background-color: #4681F9;
        color: white;
        font-size: 14px;
        font-family: mon-600;
        margin: 12px 16px 0 0;
      }
    }
  }
}
</style>