<template>
  <div class="footer">
    <div class="footer-main">
      <div class="footer-left">
        Coypright 2025 ©
        <br />
        BestDealer.All Rights Reserved
      </div>
      <div class="footer-right">
        CS Center | Support@bestdealer.com Monday - Friday | AM 10:00 - PM 18:00
      </div>
    </div>
  </div>
</template>

<style scoped lang="less">
.footer {
  position: relative;
  width: 100%;
  bottom: 0px;
  // background-color: #103266;
  background: url("@/assets/footer-bg.png") center no-repeat;
  z-index: 99;
  .footer-main {
    display: flex;
    justify-content: space-between;
    height: 64px;
    width: 1200px;
    margin: auto;
    align-items: end;
    .footer-left {
      font-size: 11px;
      font-family: mon-500;
      color: #DBEFF9;
      line-height: 16px;
      margin-bottom: 14px;
    }

    .footer-right {
      font-size: 11px;
      font-family: mon-500;
      color: #DBEFF9;
      line-height: 16px;
      margin-bottom: 14px;
    }
  }
}
</style>