<template>
  <div class="h5-account-container" :style="{ '--height': innerHeight }">
    <div class="h5-loging-out">
      <div class="logout-header">
        <img @click="hiddenPop" v-if="popVisible" src="@/assets/H5/order/icon-arrow-left.svg" alt="">
        <div class="logout-header-title" :class="{ center: popVisible }">{{ title }}</div>
        <img src="@/assets/H5/offer/sourcing-close.svg" alt="" class="logout-header-close" @click="closeBtn">
      </div>
      <div class="logout-content">
        <img src="@/assets/H5/login/logout-bg.png" alt="" class="h5-logout-bg">
        <div class="logout-account">
          <img src="@/assets/H5/login/account.png" alt="" />
        </div>
        <div class="logout-welcome-title">Welcome back,</div>
        <div class="logout-welcome-email">{{ userInfo.email || "" }}</div>
        <div class="logout-center">
          <div class="logout-menu">
            <div @click="goCarts" class="logout-menu-item">
              <div class="icon">
                <img v-if="cartCount" src="@/assets/H5/order/full-carts.svg" alt="">
                <img v-else src="@/assets/H5/order/carts.svg" alt="">
              </div>
              <div class="text">
                My Carts
                <div v-if="cartCount" class="cartCount">{{ cartCount > 99 ? '99+' : cartCount }}</div>
              </div>
            </div>
            <div @click="goOrders" class="logout-menu-item">
              <div class="icon">
                <img src="@/assets/H5/order/orders.svg" alt="">
              </div>
              <div class="text">Fulfillment Orders</div>
            </div>
            <div @click="showShipAddress" class="logout-menu-item">
              <div class="icon">
                <img src="@/assets/H5/order/address.svg" alt="">
              </div>
              <div class="text">Shipping Address</div>
            </div>
            <div class="logout-menu-item">
              <div class="icon">
                <img src="@/assets/H5/login/help-center.svg" alt="">
              </div>
              <div @click="goHelpCenter" class="text">Help Center</div>
            </div>
          </div>
        </div>
        <div class="h5-logout-bottom">
          <div class="logout-signout">
            <img src="@/assets/H5/login/logout.svg" alt="">
            <span @click="singOut">Sign Out</span>
          </div>
        </div>
      </div>

      <div class="pop-container" :class="{ visible: popVisible }" @click.stop>
        <H5AddressInfo 
          v-if="showAddressInfo"
          :handleClose="handleCloseAddressDialog"
          :addressType="3"
          :currentCountry="currentCountry" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { fetchData } from "@/utils/fetch";
import { useStore } from "vuex";
import { useRouter } from 'vue-router'
import H5AddressInfo from '@/components/H5/H5AddressInfo/index.vue'
import { buriedPointUtil } from "@/utils/utils";
import { useCommonToast } from '@/hooks/useCommonToast'

const ORIGIN_TITLE = 'My Account'

const title = ref(ORIGIN_TITLE)

const router = useRouter();

const store = useStore();

const { toast } = useCommonToast()

const userInfo = computed(() => store.state.common.userInfo);

const currentCountry = computed(() => store.state.currentCountry);

const cartCount = computed(() => store.state.cart.cartCount)

const popVisible = ref(false);

const showAddressInfo = ref(false)

const innerHeight = ref(0)

onMounted(() => {
  innerHeight.value = window.innerHeight
})

const closeBtn = (event) => {
  if (popVisible.value) {
    hiddenPop();
  } else {
    router.push('/')
  }
};

const goCarts = () => {
  buriedPointUtil({ eventId: 'MPPC201' })
  router.push('/carts')
}

const goOrders = () => {
  router.push('/orders')
}

const goHelpCenter = () => {
  router.push('/help')
}

const singOut = async () => {
  try {
    const { code, data } = await fetchData("/dsp-app/user/logOut", {
      method: "post",
    });
    if (code === 200) {
      localStorage.removeItem("tk");
      localStorage.removeItem("dsp.uid");
      store.commit("common/SET_USER_INFO", {});
      router.push('/')
    }
  } catch (error) { }
};

const showShipAddress = () => {
  showAddressInfo.value = true;
  title.value = 'Shipping Address'
  showPop();
}

const showPop = () => {
  popVisible.value = true;
}

const hiddenPop = () => {
  popVisible.value = false;
  title.value = ORIGIN_TITLE;
  showAddressInfo.value = false;
}

const handleCloseAddressDialog = () => {
  hiddenPop();
  toast('Saved successfully')
}
</script>

<style lang="less" scoped>
.h5-account-container {
  padding: 12px;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  height: calc(var(--height) * 1px);
}

.h5-loging-out {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  .logout-header {
    position: relative;
    padding: 0 12px;
    background-color: #FFFFFF;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .logout-header-title {
      font-family: mon-600;
      color: #232323;
      font-size: 14px;
      line-height: 18px;

      &.center {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .logout-content {
    flex: 1;
    margin-top: 12px;
    border-radius: 8px;
    background-color: #FFFFFF;
    position: relative;
    .h5-logout-bg {
      position: absolute;
      width: 100%;
      border-radius: 8px;
    }
    .logout-account {
      display: flex;
      justify-content: center;
      position: relative;
      z-index: 1;
      img {
        width: 96px;
        margin-top: 32px;
      }
    }
  }
  .logout-welcome-title {
    text-align: center;
    color: #232323;
    font-family: mon-500;
    font-size: 12px;
    margin-top: 16px;
    line-height: 16px;
  }
  .logout-welcome-email {
    margin-top: 8px;
    color: #232323;
    font-family: mon-600;
    line-height: 20px;
    font-size: 16px;
    text-align: center;
    padding-bottom: 32px;
  }
  .logout-center {
    padding: 6px 0px;
    background-color: #F5F5F5;

    .logout-menu {
      padding: 4px 0;
      background-color: white;

      .logout-menu-item {
        display: flex;
        height: 56px;
        padding-right: 16px;
        &:last-child {
          .text {
            border-bottom: none;
          }
        }
        .icon {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 56px;
          height: 56px;
        }
        .text {
          position: relative;
          flex: 1;
          height: 100%;
          line-height: 56px;
          color: #232323;
          font-size: 14px;
          font-family: mon-500;
          border-bottom: 0.5px solid #e4e6eb;

          .cartCount {
            position: absolute;
            top: 50%;
            left: 68px;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #009DE0;
            color: white;
            font-size: 10px;
            font-family: mon-600;
          }
        }
      }
    }
  }
  .h5-logout-bottom {
    margin-top: 20px;
    .logout-signout {
      padding: 0px 16px;
      display: flex;
      align-items: center;
      span {
        font-family: mon-500;
        font-size: 14px;
        color: #232323;
        margin-left: 16px;
      }
    }
  }
}

.pop-container {
  position: fixed;
  left: 12px;
  bottom: 12px;
  height: 0;
  width: calc(100% - 24px);
  background-color: white;
  transition: height 0.3s ease-out;
  border-radius: 8px;
  overflow: hidden;
  z-index: 999;

  &.visible {
    height: calc(100% - 48px - 36px );
  }
}
</style>
