<template>
  <div class="toast-container">
    <transition-group name="toast" tag="div">
      <div
        v-for="toast in state.toasts"
        :key="toast.id"
        class="toast"
        :class="toast.type || 'success'"
      >
        <div class="toast-content">
          <img v-if="toast.type === 'error'" src="@/assets/toast-error.svg" alt="">
          <img v-else-if="toast.type === 'warning'" src="@/assets/warning.svg" alt="">
          <img v-else src="@/assets/success.svg" alt="">
          <div v-html="toast.message"></div>
        </div>
      </div>
    </transition-group>
  </div>
</template>

<script>
export default {
  props: {
    state: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="less" scoped>
.toast-container {
  position: fixed;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  width: calc(100% - 32px);
}

.toast {
  background-color: #ebf5ff;
  color: #232323;
  font-family: mon-500;
  font-size: 14px;
  padding: 12px 12px;
  border-radius: 8px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  pointer-events: auto;
  .toast-content {
    display: flex;
    align-items: center;
    gap: 12px;
    line-height: 20px;
  }
}

.toast.success {
  background-color: #ebf5ff;
}

.toast.warning {
  background-color: #FFFBEB;
}

.toast.error {
  background-color: #FFEBEB;
}

/* 动画类 */
.toast-enter-active {
  transform-origin: center;
  animation: alert .3s ease-out;
}

.toast-leave-active {
  transform-origin: center;
  animation: alert .3s ease-out reverse;
}

@keyframes alert {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}
</style>
