
<template>
   <div class="h5-currency-content">
    <div class="currency-content-title">Ship to</div>
    <img src="@/assets/H5/home/close.svg" alt="" class="h5-shipto-close" @click="closeBtn">
    <div class="h5-currency-basic">
      <div v-for="(item, index) in countryList" :key="index" class="currency-item" @click="saveCountry(item)">
        <div class="currency-item-left">
          <div class="currency-item-left-title">
            <img class="country-image" :src="item.countryUrl" alt="">
            <span class="country-name">{{ item?.countryName }}</span>
          </div>
        </div>
        <div v-if="currentCountry.countryCode === item.countryCode" class="selected">
          <img src="@/assets/H5/filters/check.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const props = defineProps({
  handleClose: Function,
  countryList: Object,
  currentCountry: Object
});

const countryList = props.countryList
const currentCountry = ref(props.currentCountry)
const handleClose = props.handleClose

const saveCountry = async (item) => {
  if(item.countryCode === currentCountry.value.countryCode) {
    return
  }
  currentCountry.value = item
  localStorage.setItem('setCurrentCountry', JSON.stringify(item))
  store.commit('setCurrentCountry', item)
}

const closeBtn = () => {
  handleClose(currentCountry.value)
}


</script>

<style lang="less" scoped>
.h5-currency-content {
  padding: 24px 16px 0px;
  position: relative;
  .currency-left {
    position: absolute;
    top: 16px;
    left: 16px;
  }
  .h5-shipto-close {
    position: absolute;
    top: 16px;
    right: 16px;
  }
  .currency-content-title{
    font-size: 16px;
    color: #232323;
    line-height: 20px;
    font-family: mon-600;
    text-align: center;
  }
  .h5-currency-basic {
    margin-top: 28px;
    height: calc(100vh - 80px - 32px);
    overflow-y: scroll;
    .currency-item {
      display: flex;
      justify-content: space-between;
      padding: 16px 0;
      height: 52px;
      border-bottom: 0.5px solid #e4e6eb;
      .country-image {
        width: 20px;
        height: 15px;
        vertical-align: middle;
      }
      .currency-item-left {
        display: flex;
        align-content: center;
        .currency-item-left-title {
          display: flex;
          align-items: center;
          font-family: mon-500;
          font-size: 12px;
          color: #232323;
          margin-left: 6px;

          .country-name {
            margin-left: 8px;
          }
        }
      }

      .selected {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: black;
      }
    }
  }
}
</style>