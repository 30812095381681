<template>
  <div class="h5-image-search">
    <div v-if="showCanvasImage" class="canvas-image-wrapper">
      <div class="image-list-box">
        <div v-if="canvasImgList.length" class="image-list">
          <div class="image-box" v-for="(img, index) in canvasImgList" :key="index">
            <img @click="toggleCanvasImage(index)" :src="img.imgUrl" alt="">
            <div :class="{active: index === activeIndex}"  ></div>
          </div>
        </div>
        <div v-else class="image-list">
          <div class="image-box" v-for="(img, index) in 3" :key="index">
            <div class="mockImg" :class="{ first: index === 0 }"></div>
            <div :class="{active: index === activeIndex}"  ></div>
          </div>
        </div>
      </div>
      <div class="close" @click="closeImageSearch">
        <img src="@/assets/close-circle-icon.svg" alt="">
      </div>
    </div>
    <H5Dialog 
      class="custom-image-search-dialog"
      :style="getDialogStyle"
      v-model="showImageSearchDlg" 
      :scrollable="true"
      :scrollEvent="layoutOnScroll"
      :height="`calc(100% - 60px)`">
      <H5Empty v-if="showEmpty" />
      <template v-else>
        <div class="image-search-title-wrapper">
          <div class="image-search-title" :style="{ paddingBottom: currentBottomTab === 'styles' ? 0 : '16px' }">
            <div class="text1" :class="{ stylesClass: currentBottomTab === 'styles' }">Results for <span>"Similar Recommendations"</span></div>
            <div class="text2">All items are sold directly by the manufacturer.</div>
            <!-- 搜款式列表 -->
            <div v-if="currentBottomTab === 'styles'">
              <!-- 骨架屏 -->
              <div v-if="styleSleleton" class="product-styles-list-skeleton">
                <div class="product-styles-list-skeleton-item" v-for="item in 50" :key="item"></div>
              </div>
              <div 
                v-else 
                class="product-styles-list">
                <div 
                  @click="switchStyles(item, index)"
                  class="product-list-item"
                  :class="{ active: index === activeStyleIndex }"
                  v-for="(item, index) in stylesProductList" 
                  :key="index">
                  <div v-if="index === activeStyleIndex" class="product-list-item-selected">
                    <img src="@/assets/H5/home/h5-styles-selected.svg" alt="">
                  </div>
                  <div class="product-info-wrapper">
                    <div class="product-img">
                      <img :src="item.imageUrl" alt="">
                    </div>
                    <div class="product-info">
                      <div class="item-money">
                        <span>{{ item?.priceInfo?.targetUnit }}</span>
                        <span>{{ productPrice(item) }}</span>
                        <span>{{ item?.priceInfo?.targetCurrency }}</span>
                      </div>
                      <div class="item-price">
                        {{ item?.priceInfo?.fromUnit }}
                        {{ item?.priceInfo?.price }}
                      </div>
                      <div class="min-order">
                        Min. order: <br/>{{ item.minOrderQuantity }} Piece
                      </div>
                    </div>
                  </div>
                  <swiper
                    class="info-swiper"
                    :loop="true"
                    :autoplay="{ delay: 2000, disableOnInteraction: false }"
                    :direction="'vertical'"
                    :modules="[ Autoplay ]">
                    <swiper-slide>
                      <div class="item-sold">
                        <img src="@/assets/H5/home/fire.svg" width="12px" alt="">
                        <span class="item-sold-num">
                            {{ new Intl.NumberFormat().format(item?.monthSold || 0) }}
                        </span>
                        <span class="item-sold-title">sold</span>
                      </div>
                    </swiper-slide>
                    <swiper-slide>
                      <div v-if="repeatPurchasePercent(item)" class="item-repeat">
                        <img src="@/assets/H5/home/repeat.svg" width="12px" alt="">
                        <span class="item-repeat-num">{{ repeatPurchasePercent(item) }}%</span>
                        <span class="item-repeat-title">repeat customer</span>
                      </div>
                    </swiper-slide>
                  </swiper>
                </div>
              </div>
            </div>
          </div>
          <div v-if="loadMoreSleleton" class="total-products-skeleton"></div>
          <div v-else class="total-products" :class="{ stylesClass: currentBottomTab === 'styles' }"> 
            <div v-if="currentBottomTab === 'similar'">We found <span>{{ Intl.NumberFormat().format(totalNums) }}</span> items for you</div>
            <div v-else>Results for <span>"Styles Recommendations"</span></div>
          </div>
        </div>
        <Product />
        <div class="bottom-search-bar">
          <div class="search-bar">
            <div @click="findSimilar" class="find-similar" :class="{ active: currentBottomTab === 'similar'}">Find Similar</div>
            <div @click="findStyles" class="find-styles" :class="{ active: currentBottomTab === 'styles'}">Find Styles</div>
          </div>
        </div>
      </template>
    </H5Dialog>
  </div>
</template>

<script setup>
  import H5Dialog from '@/components/H5/H5Dialog/index.vue';
  import H5LoginIn from '@/components/H5/H5LoginIn/index.vue';
  import Product from '@/components/H5/Product/index.vue'
  import H5Empty from '@/components/H5/H5Empty/index.vue';
  import { onMounted, ref, computed, watch, nextTick, onUnmounted } from 'vue';
  import { useStore } from 'vuex';
  import { fetchData } from '@/utils/fetch'
  import { buriedPointUtil, gaAnalytics } from '@/utils/utils';
  import { useRouter } from 'vue-router'
  import { Swiper, SwiperSlide} from 'swiper/vue'
  import { Autoplay } from 'swiper/modules';
import { bankersRound } from '../../../utils/utils';

  const store = useStore();
  const router = useRouter();

  const loadMoreSleleton = computed(() => store.state.loadMoreSleleton)
  const productList = computed(() => store.state.productList);
  const backToHome = computed(() => store.state.h5Module.backToHome);
  const searchInfo = computed(() => store.state.searchInfo);

  const showEmpty = ref(false)
  const canvasImgList = ref([]); // 裁剪后的图片列表
  const totalNums = ref(0); 
  const showImageSearchDlg = ref(false);
  const showCanvasImage = ref(true);
  const activeIndex = ref(0);
  const innerHeight = ref(0)

  const currencyData = JSON.parse(localStorage.getItem('currencyData'));
  const imageSearchInfo = JSON.parse(localStorage.getItem('imageSearchInfo'));
  const observerIdList = ref(new Set())
  let observer;
  onMounted(async () => {
    innerHeight.value = window.innerHeight;
    initParams();

    currentBottomTab.value = imageSearchInfo.type;
    searchImageData();

    observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const offerId = entry.target.dataset.id;
          observerIdList.value.add(offerId)

          const half = Math.floor(lastPageSize.value / 2);
          const point = productList.value.length - half
          if (observerIdList.value.size >= point) {
            if (currentBottomTab.value === 'styles') {
              searchStylesProductList(true)
            } else {
              loadMore();
            }
          }
        }
      })
    }, { threshold: 0.5 })
  })

  onUnmounted(() => {
    observer.disconnect();
  })

  const observeProduct = () => {
    document.querySelectorAll('.h5product-item').forEach(product => {
      observer.observe(product);
    })
  }

  const lastPageSize = ref(0)
  watch(productList, (value, oldValue) => {
    if (value && value.length) {
      lastPageSize.value = value.length - oldValue.length;
      nextTick(() => {
        observeProduct();
      })
    }
  })
  
  // 初始化状态
  const initParams = () => {
    activeIndex.value = 0;
    canvasImgList.value = [];
    pageNow.value = 1;
  }

  watch(showImageSearchDlg, (value) => {
    if (value) {
      document.querySelector('body').classList.add('no-scroll');
    } else {
      document.querySelector('body').classList.remove('no-scroll');
    }
  })

  const setCurrencyData = (array) => {
    const list = array.map(item => {
      return {
        ...item,
        priceInfo: { price: item.priceInfo.price, ...currencyData },
      }
    })
    return list;
  }

  const currentImageRegion = ref();
  const searchImageData = async (region = '') => {
    // nextTick(() => showImageSearchDlg.value = true);
    showImageSearchDlg.value = true
    store.commit('setProductList', []);
    store.commit('setLoadMoreSleleton', true);
    styleSleleton.value = true;

    currentImageRegion.value = region;
    const params = {
      keyword: '',
      imageId: imageSearchInfo.imageId,
      pageNow: 1,
      pageSize: 20,
      region: region
    }
    if (params.imageId === 0) params.imageAddress = imageSearchInfo.imageUrl;
    const { code, data } = await fetchData('/dsp-app/alibaba-common/searchImage', {
      method: 'post',
      body: JSON.stringify(params),
    })
    if (code === 200) {
      gaAnalytics('mp_search_page_view', "mp_search_page_view");
      const { picRegionInfo, currentRegion, rows, total } = data;

      // 空列表处理
      if (rows && !rows.length) {
        store.commit('setEmptyObj', {
          msg: 'No matching results yet',
          showEmpty: true
        })
        store.commit('setLoadMoreSleleton', false);
        showEmpty.value = true;
        return
      }

      // 处理图片裁剪
      if (!canvasImgList.value.length) {
        currentImageRegion.value = picRegionInfo.currentRegion;
        const coordinates = calculateCropDimensions(picRegionInfo);
        cutImageByCoordinates(imageSearchInfo.imageUrl, coordinates, picRegionInfo).then(list => {
          canvasImgList.value = list
        })
      }

      if (currentBottomTab.value === 'styles') {
        stylesProductList.value = rows.map(item => {
          return {
            ...item,
            priceInfo: { price: item.priceInfo.price, ...currencyData }
          }
        });
        styleSleleton.value = false;
        currentStyleProduct.value = rows[0];
        searchStylesProductList();
      } else {
        const list = setCurrencyData(rows || [])
        store.commit('setProductList', list);
        store.commit('setLoadMoreSleleton', false);
      }
      totalNums.value = total
      allowScroll.value = true;
    }

    if (code === 401) {
      buriedPointUtil({ eventId: "MPLG501" });
    }

    if (code === 500) {
      showEmpty.value = true;
      store.commit('setLoadMoreSleleton', false);
    }
  }

  // 根据坐标裁剪图片
  const cutImageByCoordinates = async (imgUrl, coordinates, picRegionInfo) => {
    const response = await fetch(imgUrl);
    const blob = await response.blob();
    const base64Image = await blobToBase64(blob);

    const image = new Image();
    image.src = base64Image;
    const list = [];

    return new Promise((resolve, reject) => {
      image.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        coordinates.forEach((coord, index) => {
          const width = coord.y0 - coord.x0;
          const height = coord.y1 - coord.x1;

          canvas.width = width;
          canvas.height = height;

          ctx.clearRect(0, 0, canvas.width, canvas.height); // 清空画布
          ctx.drawImage(image, coord.x0, coord.x1, width, height, 0, 0,width, height);
          list.push({
            imgUrl: canvas.toDataURL(),
            region: picRegionInfo?.yoloCropRegion?.split(';')[index],
          });
        });
        resolve(list);
      }
    })  
  }

  const blobToBase64 = (blob) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  };

  const calculateCropDimensions = (picRegionInfo) => {
    const regions = picRegionInfo?.yoloCropRegion?.split(';');
    const result = regions.map((region) => {
      const [x0, y0, x1, y1] = region.split(',').map(Number);
      return { x0, y0, x1, y1 }
    });
    return result;
  };

  const closeImageSearch = () => {
    const productList = JSON.parse(localStorage.getItem('homeProductList'));
    if (productList) {
      store.commit('setProductList', productList);
      localStorage.removeItem('homeProductList');
    }
    store.commit('setSearchInfo', {
      ...searchInfo.value,
      pageNow: 1,
    })
    showImageSearchDlg.value = false;
    showCanvasImage.value = false;
    setTimeout(() => {
      store.commit('h5Module/closeImageSearchDialog')
    }, 300);
    router.go(-1);
  }

  const allowScroll = ref(true);
  const resetScrollPosition = () => {
    document.querySelectorAll('*').forEach((el) => {
      el.scrollTop = 0;
    });
    allowScroll.value = false; // 禁用滚动
  };

  const toggleCanvasImage = (index) => {
    buriedPointUtil({ eventId: "MPSI201" });

    resetScrollPosition();
    activeIndex.value = index;
    const region = canvasImgList.value[index].region;
    store.commit('setEmptyObj', { msg: '', showEmpty: false })

    // 搜款式
    if (currentBottomTab.value === 'styles') {
      styleSleleton.value = true;
      activeStyleIndex.value = 0;
      stylesSearchInfo.value.pageNow = 1;
      stylesKeywords.value = ''
    }
    pageNow.value = 1;
    searchImageData(region);
  }

  const layoutOnScroll = (e) => {
    if (!allowScroll.value) return;
    const el = e.target;
    const scrollTop = el.scrollTop;
    const scrollHeight = el.scrollHeight;
    const clientHeight = window.innerHeight;

    if (scrollTop + clientHeight >= scrollHeight - 75) {
      if (currentBottomTab.value === 'styles') {
        searchStylesProductList(true);
      } else {
        loadMore();
      }
    }
  }

  const pageNow = ref(1);
  const isSearching = ref(false);
  const loadMore = async () => {
    if (isSearching.value) return;
    isSearching.value = true;

    if (canvasImgList.value.length === 0) return;
    allowScroll.value = false;

    const params = {
      keyword: '',
      imageId: imageSearchInfo.imageId,
      pageNow: ++pageNow.value,
      pageSize: 20,
      region: currentImageRegion.value,
    };
    if (params.imageId === 0) params.imageAddress = imageSearchInfo.imageUrl;
    store.commit('setLoadMoreSleleton', true);
    try {
      const { code, data } = await fetchData('/dsp-app/alibaba-common/searchImage', {
        method: 'post',
        body: JSON.stringify(params),
      });
      if (code === 200) {
        buriedPointUtil({ eventId: "MPSC401", others: { ...params } });
        gaAnalytics('mp_search_page_load_more', "mp_search_page_load_more");
        const list = setCurrencyData([...productList.value, ...data.rows])
        store.commit('setProductList', list);
        store.commit('setSearchInfo', {
          ...searchInfo.value,
          pageNow: pageNow.value,
        })
      }

      if (code === 401) {
        buriedPointUtil({ eventId: "MPLG501" });
      }

      if (code === 500) {
        showEmpty.value = true;
      }

      allowScroll.value = true;
      isSearching.value = false;
      store.commit('setLoadMoreSleleton', false);
      store.commit('search/SET_PRE_SEARCH', false);
    } catch (error) {
      isSearching.value = false;
      store.commit('setLoadMoreSleleton', false);
      store.commit('search/SET_PRE_SEARCH', false);
    }
  }

  const getDialogStyle = computed(() => {
    if (!imageSearchInfo) return {};
    return {
      background: `linear-gradient(rgba(0, 0, 0, 0.64), rgba(0, 0, 0, 0.64)),
                url(${imageSearchInfo.imageUrl})`,
      'background-size': 'cover',
      'background-position': 'center',
      'background-repeat': 'no-repeat'
    }
  })

  const currentBottomTab = ref('similar');
  const stylesProductList= ref([]);
  const currentStyleProduct = ref({});
  const stylesSearchInfo = ref({
    pageNow: 1,
    pageSize: 20,
  })
  const findSimilar = async () => {
    if (currentBottomTab.value === 'similar') return;
    buriedPointUtil({ eventId: "MPOI201" });
    observerIdList.value.clear();
    
    currentBottomTab.value = 'similar';
    router.replace(router.currentRoute.value.fullPath.replace('styles', 'similar'));
    resetScrollPosition();

    searchImageData(currentImageRegion.value);
  }

  const styleSleleton = ref(false);
  const findStyles = async () => {
    if (currentBottomTab.value === 'styles') return;
    buriedPointUtil({ eventId: "MPOI202" });
    observerIdList.value.clear();

    currentBottomTab.value = 'styles';
    router.replace(router.currentRoute.value.fullPath.replace('similar', 'styles'));
    resetScrollPosition();

    styleSleleton.value = true;
    searchImageData();
  }

  const activeStyleIndex = ref(0);
  const switchStyles = (item, index) => {
    buriedPointUtil({ eventId: "MPSF204" });
    activeStyleIndex.value = index;
    currentStyleProduct.value = item;
    stylesSearchInfo.value.pageNow = 1;
    stylesKeywords.value = '';
    searchStylesProductList();
  }

  const stylesKeywords = ref('')
  const isStyleProductSearching = ref(false)
  const searchStylesProductList = (loadMore = false) => {
    if (isStyleProductSearching.value) return;
    isStyleProductSearching.value = true;

    allowScroll.value = false;
    store.commit('setLoadMoreSleleton', true);

    if (loadMore) {
      stylesSearchInfo.value.pageNow++;
    } else {
      store.commit('setProductList', []);
    }

    const params = {
      categoryId: currentStyleProduct.value.secondCategoryId,
      offerId: currentStyleProduct.value.offerId,
      similarOfferIds: stylesProductList.value.map(item => item.offerId),
      subject: currentStyleProduct.value.subject,
      ...stylesSearchInfo.value,
      keyword: stylesKeywords.value,
    }

    if (!params.keyword) delete params.keyword;

    fetchData('/dsp-app/alibaba-common/relatedRecommend', {
      method: 'post',
      body: JSON.stringify(params),
    }).then(res => {
      const list = res.data.rows || [];
      if (!list.length) {
        store.commit('setEmptyObj', {
          msg: 'No matching results yet',
          showEmpty: true
        })
        store.commit('setLoadMoreSleleton', false);
        showEmpty.value = true;
        return;
      }
      if (loadMore) {
        const list = setCurrencyData([...productList.value, ...res.data.rows])
        store.commit('setProductList', list);
      } else {
        const list = setCurrencyData(res.data.rows)
        store.commit('setProductList', list);
      }
      stylesKeywords.value = res.data.keyword
      store.commit('setLoadMoreSleleton', false);
    }).finally(() => {
      styleSleleton.value = false;
      allowScroll.value = true;
      isStyleProductSearching.value = false;
    })
  }

  const productPrice = computed(() => (item) => {
    if (item) {
      const price = item?.priceInfo?.price || 0;
      const rate = item?.priceInfo?.rate || 1;
      return bankersRound(price * rate, 2)
    } else {
      return ''
    }
  })

  const repeatPurchasePercent = computed(() => (item) => {
    if (item) {
      const repeatPurchasePercent = item?.sellerDataInfo?.repeatPurchasePercent || 0
      return Number((repeatPurchasePercent * 100).toFixed(0))
    }
    return ''
  })

</script>

<style lang="less" scoped>
@import url('./index.less');

.custom-image-search-dialog {
  ::v-deep(.popup-content) {
    background-color: white;
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(8px);
    z-index: -1;
  }
}

.product-styles-list-skeleton {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 20px;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  .product-styles-list-skeleton-item {
    flex-shrink: 0;
    width: 178px;
    height: 110px;
    background-color: #f5f5f5;
    background-image: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 200% 100%;
    background-position: 0 0;
    animation: gradient 2s linear infinite;
    margin-right: 8px;
    border-radius: 4px;

    @keyframes gradient {
      0% {
        background-position: 0 center;
      }
      100% {
        background-position: 200% center;
      }
    }
  }
}

.product-styles-list {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 20px;
  overflow: scroll;
  padding-bottom: 8px;

  &::-webkit-scrollbar {
    display: none;
  }

  .product-list-item {
    position: relative;
    flex-shrink: 0;
    width: 178px;
    height: 110px;
    padding: 4px;
    border: 1px solid #ebf1f5;
    margin-right: 8px;
    margin-bottom: 8px;
    border-radius: 6px;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      border: none;
    }

    .product-list-item-selected {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      background: url('@/assets/H5/home/h5-styles-selected.svg') no-repeat;
      width: 178px;
      height: 118px;
    }

    .product-info-wrapper {
      display: flex;
      gap: 10px;
      .product-img {
        border-radius: 4px;
        height: 72px;
        width: 72px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: cover;
        }
      }

      .product-info {
        .item-money {
          display: flex;
          align-items: center;
          color: #052784;
          font-family: mon-700;
    
          span:nth-child(1) {
            font-size: 10px;
            position: relative;
            bottom: 2px;
          }
    
          span:nth-child(2) {
            font-size: 14px;
            margin-left: 1px;
          }
    
          span:nth-child(3) {
            font-size: 10px;
            position: relative;
            margin-left: 1px;
            bottom: 2px;
          }
        }
        .item-price {
          margin-top: 2px;
          font-family: mon-500;
          font-size: 12px;
          color: #232323;
        }
        .min-order {
          margin-top: 4px;
          font-family: mon-500;
          font-size: 11px;
          color: #5c5c5c;
        }
      }
    }

    .info-swiper {
      height: 20px;
      margin-top: 8px;
      overflow: hidden;
      // animation: toggle 5s infinite;
      overflow: hidden;

      @keyframes toggle {
        0% {
          transform: translateY(-100%)
        }
        100% {
          transform: translateY(0)
        }
      }

      .item-sold {
        border-radius: 10px;
        height: 20px;
        color: #009DE0;
        background-color: #faf9fa;
        font-size: 12px;
        font-family: mon-500;
        display: flex;
        align-items: center;
        img {
          margin-left: 6px;
        }
        .item-sold-num {
          margin-left: 4px;
        }
        .item-sold-title {
          margin-left: 2px;
        }
      }
      .item-repeat {
        height: 20px;
        border-radius: 10px;
        background-color: #faf9fa;
        font-size: 12px;
        font-family: mon-500;
        color: #1CAD90;
        display: flex;
        align-items: center;
        img {
          margin-left: 6px;
        }
        .item-repeat-num {
          margin-left: 4px;
        }
        .item-repeat-title {
          margin-left: 3px;
        }
      }
    }
  }
}

.bottom-search-bar {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 66px;
  background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 80));
  z-index: 9999;
  .search-bar {
    position: relative;
    width: 240px;
    height: 52px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    background-color: white;
    border-radius: 26px;
    box-shadow: 0 0 8px #00000016;
    &::before {
      position: absolute;
      content: '';
      width: 0.5px;
      height: 24px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #E4E6EB;
    }          
  }

  .find-similar, .find-styles {
    font-size: 14px;
    color: #b2b7c2;
    font-family: mon-600;
    padding: 7px 0;
    height: 33px;
    -webkit-tap-highlight-color: transparent; /* 透明点击高亮 */

    &.active {
      position: relative;
      color: #232323;
      &::after {
        content: '';
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 0;
        height: 2px;
        background-color: #232323;
      }
    }
  }
}
</style>