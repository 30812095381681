<template>
  <div 
    ref="imgPreviewRef"
    v-if="visible" 
    @click="visible = false" 
    class="image-preview">
    <img ref="imgsRef" v-for="url in list" :src="url" alt="">
    <div class="point">{{ currentPage }}/{{ list.length }}</div>
  </div>
</template>

<script setup>
  import { nextTick, onMounted, onUnmounted, ref, watch } from 'vue'

  const props = defineProps({
    list: {
      type: Array,
      default: () => []
    }
  })

  const visible = defineModel({ required: true, default: false })

  const currentPage = ref(0);

  let observe = null;

  const imgPreviewRef = ref(null)
  const imgsRef = ref([])

  watch(visible, (value) => {
    if (value) {
      nextTick(() => {
        observeImages();
      })
    }
  })

  const observeImages = () => {
    const imgs = imgsRef.value;
    if (!imgs.length) return;

    observe = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          currentPage.value = Array.from(imgs).indexOf(entry.target) + 1
        }
      })
    }, { 
      root: imgPreviewRef.value,
      threshold: 0.5
    })

    imgs.forEach(img => observe.observe(img))
  }

  onMounted(() => {
    
  })

  onUnmounted(() => {
    if (observe) observe.disconnect();
  })
</script>

<style lang="less" scoped>
.image-preview {
  position: fixed;
  top: 12px;
  left: 12px;
  right: 12px;
  bottom: 12px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  background-color: #141414;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  z-index: 99;

  &::-webkit-scrollbar {
    display: none;
  }
  
  img {
    flex-shrink: 0;
    width: 100%;
    height: auto;
    // aspect-ratio: 9 / 16;
    object-fit: contain;
    scroll-snap-align: start;
  }

  .point {
    position: fixed;
    left: 50%;
    bottom: 24px;
    transform: translateX(-50%);
    width: 32px;
    height: 24px;
    background-color: white;
    border-radius: 12px;
    color: #232323;
    font-size: 12px;
    font-family: mon-500;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>