<template>
  <div class="country-select">
    <div class="country-select-top">
      <img src="@/assets/country-select.svg" alt="" />
    </div>
    <div class="country-select-hideline"></div>
    <div class="country-container-main">
      <div class="country-language-title">Country</div>
      <div class="custom-select">
        <div class="searchImg">
          <img :src="searchImg" alt="" style="width: 20px;height: auto; padding-top: 1px">
        </div>
        <input v-model="searchText" @focus="openDropdown" @blur="closeDropdown" @input="filterOptions" :placeholder="placeholderSearchText" />
        <div class="custom-select-img">
          <img src="@/assets/country/down.svg" alt="" v-if="!isDropdownOpen">
          <img src="@/assets/country/search.svg" alt="" v-if="isDropdownOpen">
        </div>
        <!-- 使用 Vue 的 transition 组件 -->
        <transition name="dropdown-fade">
          <div v-if="isDropdownOpen" class="dropdown">
            <div v-for="option in filteredCountryList" :key="option.countryName" @click="selectOption(option)"
              class="dropdown-item">
              <img :src="option?.countryUrl" alt="" class="dropdown-item-img">
              {{ option.countryName }}
            </div>
          </div>
        </transition>
      </div>
      <div class="country-confirm" @click="saveCountry">
        Save
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import CountrySelect from '@/components/CountrySelect/index.vue';
import { useStore } from 'vuex';
import { fetchData } from '../../../../../utils/fetch';

const props = defineProps({
  handleClickOutside: Function,
  currentCountry: Object,
  handleClose: Function
});

const handleClickOutside = props.handleClickOutside;
const currentCountry = props.currentCountry;
const handleClose = props.handleClose;

const emit = defineEmits();
const isDropdownOpen = ref(false);
const searchText = ref('');
const placeholderSearchText = ref(currentCountry?.countryName)

const selectedValue = ref('');
const country = ref({})
const searchImg = ref(currentCountry?.countryUrl)
const currencyData = ref({})

const list = []

const store = useStore()

const countryList = computed(() => store.state.countryInfo)
const mergedArray = computed(() => store.state.mergedArray)

const filteredCountryList = ref(countryList.value)

// const countryObj = computed(() => store.state.currentCountry)


// watch(countryList, (newData) => {
//   if (newData) {
//     countryList.value = newData;
//   }
// });

const filterOptions = (e) => {
  filteredCountryList.value = countryList.value.filter(v => 
    v.countryName.toLowerCase().includes(e.target.value.toLowerCase())
  )
};

watch(mergedArray, (newData) => {
  if (newData) {
    mergedArray.value = newData;
  }
});


const openDropdown = () => {
  isDropdownOpen.value = true;
};

const closeDropdown = () => {
  setTimeout(() => {
    isDropdownOpen.value = false;
  }, 200);
};

const selectOption = (option) => {
  searchText.value = option.countryName;
  searchImg.value = option.countryUrl
  // emit('updateModelValue', option);
  country.value = option
  closeDropdown();
};


const emitFn = (e) => {
  const data = countryList.value.filter(v => v === e)[0]
  country.value = data.countryName
}

const saveCountry = async (event) => {
  if(!searchText.value) {
    handleClose()
    return
  }
  localStorage.setItem('setCurrentCountry', JSON.stringify(country.value))
  store.commit('setCurrentCountry', country.value)
  if (!Object.keys(mergedArray.value).length) {
    handleClickOutside(event, country.value)
    return
  }
  try {
    // store.commit('setLoading', true)
    const { code, data } = await fetchData('/dsp-app/alibaba-common/supplierFee', {
      method: 'post',
      body: JSON.stringify({ logisticFeeProductInputs: mergedArray.value, countryCode: country.value.countryCode, userId: localStorage.getItem('dsp.uid') || '' }),
    })
    if (code === 200) {
      store.commit('setSupplierFee', data);
      handleClickOutside(event, country.value)
    }
    store.commit('setLoading', false)
  } catch (error) {
    store.commit('setLoading', false)
  }
}


</script>


<style lang="less">
@import './index.less';
</style>