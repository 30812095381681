<template>
  <div class="paypal-success">
    <div class="header">
      <div @click="$router.push('/')" class="left">Home</div>
      <div class="middle">Order Payment</div>
      <img @click="$emit('close')" src="@/assets/close-circle-icon.svg" alt="">
    </div>
    <div class="content">
      <img src="@/assets/H5/order/paypal-success.svg" alt="paypal-success">
      <div class="text-1">Order has been paid successfully</div>
      <div class="text-2">Your order is on the way, please be patient</div>
      <div @click="handleViewOrder" class="button">View Order</div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { buriedPointUtil } from '@/utils/utils';

const handleViewOrder = () => {
  location.reload();
}

onMounted(() => {
  buriedPointUtil({ eventId: 'MPPP503' })
})
</script>

<style lang='less' scoped>
  .paypal-success {
    position: fixed;
    top: 12px;
    left: 12px;
    right: 12px;
    bottom: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .header {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 12px;
      background-color: #fff;
      height: 48px;
      border-radius: 8px;

      .left {
        color: #232323;
        font-size: 12px;
        font-family: mon-600;
      }

      .middle {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        color: #232323;
        font-size: 14px;
        font-family: mon-700;
      }
    }

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      border-radius: 8px;
      img {
        width: 160px;
        height: 160px;
        margin-top: 80px;
      }

      .text-1 {
        color: #232323;
        font-size: 14px;
        font-family: mon-600;
        line-height: 18px;
      }

      .text-2 {
        color: #232323;
        font-size: 12px;
        font-family: mon-500;
        line-height: 16px;
        margin-top: 4px;
      }

      .button {
        display: flex;
        align-items: center;
        margin-top: 16px;
        height: 32px;
        border-radius: 20px;
        border: 1px solid #b2b7c2;
        padding: 0 20px;
        color: #232323;
        font-size: 12px;
        font-family: mon-600;
      }
    }
  }
</style>