<template>
  <div
    :class="{ 'style-item': isStylesItem, 'product-item': true }"
    @mouseleave="isHovered = null"
  >
    <div
      class="product-inner"
      @mouseenter="isHovered = item.offerId"
      @click="goDetail(item.offerId, item.monthSold, item.ext)"
    >

      <div class="product-item-current">
        <img
          v-if="isImageInView(item.id)"
          class="product-item-current-main"
          :src="item.imageUrl"
          alt=""
        />
        <transition name="fade" v-if="isHovered === item.offerId">
          <div class="translate-hover" @click="(e) => translateFun(item, e)">
            Translate
          </div>
        </transition>
        <transition
          name="fade"
          v-show="!isStylesItem && isHovered === item.offerId"
        >
          <div class="product-item-img-hover">
            <div class="btn" @click="(e) => findSimilar(item, e)">
              Find Similar
            </div>
            <div class="btn" @click="(e) => findStyles(item, e)">
              Find Styles
            </div>
          </div>
        </transition>
      </div>

      <div class="product-main" :style="handleProductMainHeight">
        <div class="product-main-content" v-if="!showMainHover(item)">
          <div class="product-price">
            <span class="product-price-2">{{ productUnit(item) }}</span>
            <span class="product-price-3">{{ productPrice(item) }}</span>
            <span class="product-price-1">{{ productCurrency(item) }}</span>
          </div>
          <div class="product-from">
            Factory Price {{ currencyData?.fromCurrency }}
            {{ currencyData?.fromUnit }}{{ item?.priceInfo?.price }}
          </div>
          <div class="product-title">
            {{ item?.subjectTrans }}
          </div>
          <div class="product-order">
            {{ item?.minOrderQuantity ? `Min. order: ${item?.minOrderQuantity} pieces` : '' }}
          </div>
          <div class="product-hot" v-show="item?.monthSold">
            <img src="@/assets/hot.svg" alt="" />
            <span class="product-hot-num">
              {{ new Intl.NumberFormat().format(item?.monthSold || 0) }}
          </span>
            <span class="product-hot-sold">Sold</span>
          </div>
          <div class="product-favorite" :style="handleProductFavoriteStyle(item?.monthSold)" v-if="!!item?.sellerDataInfo?.repeatPurchasePercent">
            <img src="@/assets/favorite.svg" alt="" />
            <span class="product-favorite-precent"
            >{{ repeatPurchasePercent(item) }}%</span
            >
            <span class="product-favorite-repeat">Repeat Customer Rate</span>
          </div>
          <div v-if="debugMode" style="padding-top: 3px; padding-bottom: 3px;" class="product-debug-info">
            <span v-show="item?.ext">
              <p v-show="item?.ext?.RECALL">
                <span>RECALL:</span><br> {{ abbreviations(item?.ext?.RECALL) }}
              </p>
              <p v-show="item?.ext?.RANK">
                <span>RANK:</span><br> {{ abbreviations(item?.ext?.RANK) }}
              </p>
              <p v-show="item?.ext?.FILTER">
                <span>FILTER:</span><br> {{ abbreviations(item?.ext?.FILTER) }}
              </p>
            </span>
          </div>
        </div>
        <div class="product-main-hoverd" v-if="showMainHover(item)">
          <div class="supplier-title">Supplier Credit Rating</div>
          <div class="product-star">
            <div class="product-star-img">
              <img
                  src="@/assets/star.svg"
                  alt=""
                  v-for="i in new Array(
                Math.floor(item?.sellerDataInfo?.compositeServiceScore || 0)
              ).fill(0)"
              />
              <img
                  src="@/assets/star-no.svg"
                  alt=""
                  v-for="i in new Array(
                Math.ceil(
                  5 - (item?.sellerDataInfo?.compositeServiceScore || 0)
                )
              ).fill(0)"
              />
            </div>
            <div class="product-star-cost">
              ({{ item?.sellerDataInfo?.compositeServiceScore }})
            </div>
          </div>
          <div class="product-trade">
            <div class="product-desc">
              <div class="product-desc-text trade-medal">Trade Medal Level</div>
              <div class="product-desc-cost">
                ({{ item?.sellerDataInfo?.tradeMedalLevel }})
              </div>
            </div>
            <div class="product-desc">
              <div class="product-desc-text quality-experience">
                Quality Experience
              </div>
              <div class="product-desc-cost">
                ({{ item?.sellerDataInfo?.offerExperienceScore }})
              </div>
            </div>
            <div class="product-desc">
              <div class="product-desc-text quality-experience">
                Composite Service
              </div>
              <div class="product-desc-cost">
                ({{ item?.sellerDataInfo?.compositeServiceScore }})
              </div>
            </div>
            <div class="product-desc">
              <div class="product-desc-text quality-experience">
                Dispute Complaint
              </div>
              <div class="product-desc-cost">
                ({{ item?.sellerDataInfo?.disputeComplaintScore }})
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- find styles btn -->
      <transition name="fade" v-if="isStylesItem && isHovered === item.offerId">
        <div class="product-bottom-hover">
          <div class="btn" @click="(e) => findSimilar(item, e)">
            Find Similar
          </div>
          <div class="btn" @click="(e) => findSimilarItemStyles(item, e)">
            Find Styles
          </div>
        </div>
      </transition>
    </div>
    <!-- find styles btn -->
    <template v-if="isStylesItem && isHovered !== item.offerId">
      <div class="product-bottom" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <div
          :class="{ active: isActive, 'styles-btn': true }"
          @click="(e) => findSimilarItemStyles(item, e)"
        >
          Find Styles
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M12.3113 4.6938V12.3128H4.69223V11.17H10.36L3.28613 4.09571L4.09451 3.28809L11.168 10.3616L11.1684 4.6938H12.3113Z"
              :fill="isActive ? '#fff' : '#232323'"
            />
          </svg>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup>
import { ref, computed, defineProps, defineEmits } from "vue";
import { useStore } from "vuex";
import {
  buriedPointUtil,
  gaAnalytics,
  debounce,
  redirectWithSearch,
} from "@/utils/utils";

const store = useStore();

const props = defineProps({
  item: {
    type: Object,
    required: true,
    default: () => ({
      offerId: 0,
      imageUrl: "",
      priceInfo: {
        targetUnit: "",
        price: 0,
        rate: 0,
        targetCurrency: "",
        fromCurrency: "",
        fromUnit: "",
      },
      subjectTrans: "",
      monthSold: 0,
      sellerDataInfo: {
        compositeServiceScore: 0,
        tradeMedalLevel: 0,
        offerExperienceScore: 0,
        disputeComplaintScore: 0,
        repeatPurchasePercent: 0,
      },
    }),
  },
  isImageInView: {
    type: Function,
    required: true,
  },
  isStylesItem: {
    type: Boolean,
    required: false,
    default: false,
  },
  isActive: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits();
const isHovered = ref(0);

const currencyData = computed(() => store.state.currencyData);
const debugMode = computed(() => store.state.debugMode);

const productPrice = computed(() => (item) => {
  if (item) {
    const price = item?.priceInfo?.price || 0;
    const rate = item?.priceInfo?.rate || currencyData?.value?.rate || 1;
    return Math.ceil(price * rate * 100) / 100;
  } else {
    return "";
  }
});

const handleProductMainHeight = computed(() => {
  return debugMode.value ? { height: 'auto' } : {};
})

const handleProductFavoriteStyle = (monthSold) => {
  if (!monthSold) {
    return "margin-top: 8px;"
  }
}

const productUnit = computed(() => (item) => {
  if (item && item?.priceInfo?.targetUnit) {
    return item.priceInfo.targetUnit;
  } else {
    return currencyData?.value?.targetUnit;
  }
});

const productCurrency = computed(() => (item) => {
  if (item && item?.priceInfo?.targetCurrency) {
    return item.priceInfo.targetCurrency;
  } else {
    return currencyData?.value?.targetCurrency;
  }
});

const repeatPurchasePercent = computed(() => (item) => {
  if (item) {
    const repeatPurchasePercent =
      item?.sellerDataInfo?.repeatPurchasePercent || 0;
    return Number((repeatPurchasePercent * 100).toFixed(0));
  }
  return "";
});

// 减端 DeBug 模式中的信息
const abbreviations = (info) => {
  return info?.replace('Recommend', '').replace('Recall', '').replace('Rank', '').replace('Filter', '');
}

// 找相似
const findSimilar = async (item, e) => {
  e.stopPropagation();
  buriedPointUtil({ eventId: "POP201" });
  sessionStorage.setItem(`currentMainImage_${item.offerId}`, item.imageUrl);
  window.open(
    `/search${redirectWithSearch(`offerId=${item.offerId}&type=similar`)}`
  );
};

// 找样式一致
const findStyles = async (item, e) => {
  e.stopPropagation();
  buriedPointUtil({ eventId: "POP202" });
  sessionStorage.setItem(`currentMainImage_${item.offerId}`, item.imageUrl);
  window.open(
    `/search${redirectWithSearch(`offerId=${item.offerId}&type=styles`)}`
  );
};

// 查找similarItem的styles
const findSimilarItemStyles = async (item, e) => {
  e.stopPropagation();
  buriedPointUtil({ eventId: "PSF204" });
  emit("findSimilarItemStyles", item);
};

// 翻译
const translateFun = async (item, e) => {
  e.stopPropagation();
  buriedPointUtil({ eventId: "PTR201" });
  const complete = localStorage.getItem("complete") || "";

  if (complete.indexOf(4) === -1) {
    console.log(item, ';')
    store.dispatch("common/openGuidancePop", { id: 4, helpCenterImage: 'https://cdn-image.bestdealer.com/app-bestdealer/1688/guide-image-2.webp' });
    return
  }

  try {
    // 1. 加载图片并转换为 Bitmap
    const response = await fetch(item.imageUrl);
    const blob = await response.blob();
    const bitmap = await createImageBitmap(blob);

    // 2. 绘制到 Canvas 并导出为 PNG
    const canvas = document.createElement("canvas");
    canvas.width = bitmap.width;
    canvas.height = bitmap.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(bitmap, 0, 0);

    // 3. 将 Canvas 转换为 Blob (PNG)
    const pngBlob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/png")
    );

    // 4. 写入剪贴板
    const clipboardItem = new ClipboardItem({ "image/png": pngBlob });
    await navigator.clipboard.write([clipboardItem]);
    store.commit("common/SET_TOAST_VISIBLE", true);
    store.commit("common/SET_TOAST_INFO", {
      msg: "Copy Successfully",
      type: "success",
    });

    const language = (localStorage.getItem('language') || 'en').replace('zh', 'zh-CN')
    setTimeout(
        () => {
          window.open(`https://translate.google.com/?sl=auto&tl=${language}&text=${item.subjectTrans}&op=images`);
          store.commit("common/SET_TOAST_VISIBLE", false);
        }, 1000
    )
  } catch (error) {
    console.error("复制失败:", error);
    console.error("复制失败:", error.message);

    store.commit("common/SET_TOAST_VISIBLE", true);
    store.commit("common/SET_TOAST_INFO", {
      msg: "Copy Error",
      type: "error",
    });
  }
};

// 跳转详情
const goDetail = (id, monthSold, recExt) => {
  if (location.pathname.includes("/search")) {
    gaAnalytics("p_search_page_click", "p_search_page_click", { spuId: id });
    buriedPointUtil({ eventId: "PSC205", spuId: id });
  } else {
    gaAnalytics("p_home_product_click", "p_home_product_click", { spuId: id });
    buriedPointUtil({ eventId: "PHM203", spuId: id, recExt: recExt });
  }
  // buriedPointUtil({ eventId: "P203", spuId: id });
  window.open(`/offer/${id}${redirectWithSearch(`key=${btoa(monthSold)}`)}`);
};

const showMainHover = (item) => {
  const sellerDataInfo = item.sellerDataInfo || {};
  const tradeMedalLevel = sellerDataInfo.tradeMedalLevel || 0;
  const offerExperienceScore = sellerDataInfo.offerExperienceScore || 0;
  const compositeServiceScore = sellerDataInfo.compositeServiceScore || 0;
  const disputeComplaintScore = sellerDataInfo.disputeComplaintScore || 0;

  // 判断值为0的数量
  const zeroCount = [tradeMedalLevel, offerExperienceScore, compositeServiceScore, disputeComplaintScore].filter(item => Number(item) === 0).length;
  if (zeroCount >= 2) {
    return false;
  }
  return isHovered.value === item.offerId;
}

const handleMouseEnter = (e) => {
  const parentElement = event.target.closest('.product-con'); // 查找指定父元素
  parentElement.style.boxShadow = "none";
}

const handleMouseLeave = (e) => {
  console.log('e: ', e);
  const parentElement = event.target.closest('.product-con'); // 查找指定父元素
  parentElement.style.boxShadow = '';
}
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
