<template>
  <div class="login">
    <div class="login-container">
      <div class="login-container-banner">
        <img src="@/assets/login-banner.png" alt="" />
      </div>
      <div class="login-container-label">Enter email</div>
      <div class="login-main-email">
        <div class="login-email">
          <input
            type="text"
            placeholder="Please enter your email"
            class="login-email-input"
            v-model="form.loginAccount"
            @input="handleInput('loginAccount')"
          />
          <div class="login-email-btn" @click="startCountdown">
            {{ isButtonDisabled ? `${countdown}s` : "Get Code" }}
          </div>
        </div>
        <div class="login-email-error" v-if="verifyCodeError">
          <img src="@/assets/error.svg" alt="" /> {{ verifyCodeError }}
        </div>
      </div>
      <div class="login-main-verifyCode">
        <input
          type="text"
          placeholder="Enter the verification code from your email"
          v-model="form.verifyCode"
          class="login-code"
          @input="handleInput('verifyCode')"
        />
        <div class="login-verifyCode-error" v-if="codeError">
          <img src="@/assets/error.svg" alt="" /> {{ codeError }}
        </div>
      </div>
      <div class="login-btn" @click="loginIn">Continue</div>
      <div class="split-line">or</div>
      <div class="login-google" @click="googleLogin">
        <img src="@/assets/google.svg" alt="" />
        <div>Continue with Google</div>
      </div>
      <div class="login-close" @click="closeBtn">
        <img src="@/assets/pc-login-close-icon.svg" alt="" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from "vue";
import { fetchData } from "../../utils/fetch";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { buriedPointUtil, gaAnalytics } from "../../utils/utils";
import router from "../../router";
import { useCommonToast } from '@/hooks/useCommonToast'

const emit = defineEmits();
const store = useStore();

const route = useRoute();
const countdown = ref(60); // 倒计时 30 秒
const isButtonDisabled = ref(false); // 控制按钮是否禁用
const msg = ref("");
const passiveLogin = computed(() => store.state.passiveLogin);

const { source = "" } = route.query;

watch(passiveLogin, (newData) => {
  if (newData) {
    passiveLogin.value = newData;
  }
});

onMounted(() => {
  buriedPointUtil({ eventId: "PLG501" });
  handleSuperUser()
});

// 表单数据
const form = reactive({
  loginAccount: "",
  verifyCode: "",
});

// 用于跟踪字段是否被触摸过
const touched = reactive({
  loginAccount: false,
  verifyCode: false,
});

// 验证邮箱格式
const validateEmail = () => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailPattern.test(form.loginAccount);
};

const validateCode = () => {
  const emailPattern = /^[0-9]*$/;
  return emailPattern.test(form.verifyCode);
};

// 验证密码（最少6位）
const validatePassword = () => {
  return form.verifyCode.length === 6;
};

// 计算属性：邮箱错误提示
const verifyCodeError = computed(() => {
  if (!form.loginAccount && touched.loginAccount)
    return "The email address is required.";
  if (form.loginAccount.length >= 100) return "Cannot exceed 100 characters.";
  if (!validateEmail() && form.loginAccount && touched.loginAccount)
    return "Please enter a valid email address.";
  return "";
});

const codeError = computed(() => {
  if (!form.verifyCode && touched.verifyCode)
    return "The verification code is required.";
  if (!validateCode() && form.verifyCode && touched.verifyCode)
    return "Please enter a valid verification code.";
  if (form.verifyCode && !validatePassword() && touched.verifyCode)
    return "Please enter a 6-digit verification code.";
  return "";
});

const handleInput = (field) => {
  touched[field] = true;
};

const props = defineProps({
  handleClickOutside: Function,
});
const handleLoginClickOutside = props.handleClickOutside;


const { toast } = useCommonToast();
const startCountdown = async () => {
  if (isButtonDisabled.value) return; // 如果按钮已禁用，忽略点击事件
  if (!!verifyCodeError.value || !form.loginAccount) {
    touched.loginAccount = true;
    return;
  }
  buriedPointUtil({ eventId: "PLG201" });
  try {
    const {
      code,
      data,
      msg: errorMsg,
    } = await fetchData("/dsp-app/alibaba-common/verifyCode", {
      method: "post",
      body: JSON.stringify({ loginAccount: form.loginAccount }),
    });
    if (code === 200) {
      isButtonDisabled.value = true; // 禁用按钮
      countdown.value = 60; // 重置倒计时为 30 秒
      msg.value = "Send Successfully";
      toast(msg.value)

      const timer = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value--; // 每秒减少 1
        } else {
          clearInterval(timer); // 停止倒计时
          isButtonDisabled.value = false; // 启用按钮
        }
      }, 1000);
    } else {
      msg.value = errorMsg;
      toast(msg.value, { type: 'warning' })
    }
  } catch (error) {}
};

const loginIn = async () => {
  if (!!verifyCodeError.value || !form.loginAccount) {
    touched.loginAccount = true;
    return;
  }
  if (!!codeError.value || !form.verifyCode) {
    touched.verifyCode = true;
    return;
  }

  gaAnalytics("p_login_click", "p_login_click", {
    passiveLogin: passiveLogin.value,
    loginType: "account",
  });
  buriedPointUtil({ eventId: "PLG202" });

  const registerChannel = !!localStorage.getItem("source")
      ? localStorage.getItem("source")
      : 2;

  try {
    const {
      code,
      data,
      msg: errorMsg,
    } = await fetchData("/dsp-app/alibaba-common/register", {
      method: "post",
      body: JSON.stringify({
        loginAccount: form.loginAccount,
        verifyCode: form.verifyCode,
        registerTerminal: 1,
        registerChannel,
        saasChannel: 12,
        superuser: !!route.query.sp,
      }),
    });
    if (code === 200) {
      msg.value = "login Successfully";
      toast(msg.value)
      if (!!data?.newUser) {
        gaAnalytics("p_register_success", "account", {
          userId: data.uid,
          newUser: data?.newUser,
          bdUserId: data.uid,
          loginType: "account",
        });
      }
      localStorage.setItem('tk', data.tk)
      localStorage.setItem('dsp.uid', data.uid)
      localStorage.removeItem('searchUrl')

      await getUserInfo(data.tk)
      store.commit("setLoginVisible", false);

      await getSuperUserAddress()
      // 超级用户登录直接跳首页

      if (+route.query.sp === 1) {
        router.push('/')
        return
      } 
      // 引导页面登录成功后返回上一级
      if (route.fullPath.includes('/guide')) {
        const loginRedirectUrl = localStorage.getItem('loginRedirectUrl')
        if (loginRedirectUrl) {
          window.location.href = loginRedirectUrl
        } else {
          router.push('/')
        }
      } else {
        router.push('/')
      }
    } else {
      msg.value = errorMsg;
      toast(msg.value, { type: 'warning' })
    }
  } catch (error) {}
};

const googleLogin = () => {
  buriedPointUtil({ eventId: "PLG203" });
  gaAnalytics("p_login_click", "p_login_click", {
    passiveLogin: passiveLogin.value,
    loginType: "continue_with_google",
  });
  checkRedirectUri()
  auth2GoogleLogin(`${window.location.origin}`);
};

const checkRedirectUri = () => {
  if(window.location.pathname === '/guide') {
    return
  }
  localStorage.setItem('loginRedirectUrl', window.location.href)
}

const auth2GoogleLogin = (redirect_uri) => {
  // Google's OAuth 2.0 endpoint for requesting an access token
  var oauth2Endpoint = "https://accounts.google.com/o/oauth2/v2/auth";

  // Create <form> element to submit parameters to OAuth 2.0 endpoint.
  var form = document.createElement("form");
  form.setAttribute("method", "GET"); // Send as a GET request.
  form.setAttribute("action", oauth2Endpoint);

  // Parameters to pass to OAuth 2.0 endpoint.
  var params = {
    client_id:
      "186402003671-mkb97o4u3hj9s3826skvss430a3lfk4o.apps.googleusercontent.com",
    redirect_uri,
    response_type: "token",
    scope: "https://www.googleapis.com/auth/userinfo.email",
    include_granted_scopes: "true",
    state: "pass-through value",
  };

  // Add form parameters as hidden input values.
  for (var p in params) {
    var input = document.createElement("input");
    input.setAttribute("type", "hidden");
    input.setAttribute("name", p);
    input.setAttribute("value", params[p]);
    form.appendChild(input);
  }

  // Add form to page and submit it to open the OAuth 2.0 endpoint.
  document.body.appendChild(form);
  form.submit();
};

const closeBtn = (event) => {
  handleLoginClickOutside(event);
};

const getUserInfo = async (tk) => {
  const { code, data } = await fetchData("/dsp-app/alibaba-inter/queryMyInfo", {
    method: "post",
    tk,
  });
  if (code === 200) {
    store.commit("common/SET_USER_INFO", data);
  }
};

const handleSuperUser = () => {
  const query = route.query;
  if (+query.sp === 1) {
    form.loginAccount = query.email
    form.verifyCode = query.password
  }
}

const getSuperUserAddress = async () => {
  const query = route.query;
  if (+query.sp === 1) {
    const { code, data } = await fetchData('/appb/b2b/address/get')
    if (code === 200) {
      if (data) {
        const cacheCountryList = JSON.parse(localStorage.getItem('cacheCountryList'))
        let currentCountry
        let phoneCodeCountry
        if (cacheCountryList) {
          currentCountry = cacheCountryList.data.find(item => item.countryName === data.country)
          phoneCodeCountry = cacheCountryList.data.find(item => item.phoneCode === data.phoneCode && item.countryName === data.country)
        } else {
          const { code: code2, data: countryList } = await fetchData('/dsp-app/alibaba-common/country')
          if (code2 === 200) {
            currentCountry = countryList.find(item => item.countryName === data.country)
            phoneCodeCountry = countryList.find(item => item.phoneCode === data.phoneCode && item.countryName === data.country)
          }
        }
        const address = {
          ...data,
          phoneValue: data.phone,
          streetAddress: data.streetAddr,
          aptEtc: data.extAddr,
          postCode: data.zip,
          searchImg: currentCountry?.countryUrl,
          searchCountryText: currentCountry?.countryName,
          placeholderSearchText: currentCountry?.countryName,
          areaCodeImg: phoneCodeCountry ? phoneCodeCountry.countryUrl :currentCountry?.countryUrl,
          placeholderAreaCodeText: currentCountry?.countryName,
        }
        if (address.country === 'Brazil') {
          address.cpfNumber = data.extension
        }
        if (address.country === 'Chile') {
          address.rutNumber = data.extension
        }
        localStorage.setItem('addressForm', JSON.stringify(address))
      } else {
        localStorage.removeItem('addressForm')
      }

      const {code: code3, data: count} = await fetchData("/appb/b2b/cart/cartCount")
      if (code3 === 200) {
        store.commit("cart/SET_CART_COUNT", count);
      }
    }
  }
}
</script>

<style scoped lang="less">
@import "./index.less";
</style>
