<template>
  <div class="homeview-footer">
    <div class="homeview-footer-top">
      <div class="homeview-footer-top-1">CS Center</div>
      <div class="homeview-footer-top-2">Support@bestdealer.com</div>
      <div class="homeview-footer-top-3">Monday - Friday | AM 10:00 - PM 18:00</div>
    </div>
    <div class="homeview-footer-line"></div>
    <div class="homeview-footer-bottom">
      <div class="homeview-footer-bottom-1">Coypright 2025 © BestDealer.All Rights Reserved</div>
      <div class="homeview-footer-bottom-2">Visit our corporate website at products.besedealer.com</div>
    </div>
  </div>
</template>

<script setup>

</script>

<style>
@import './index.less';
</style>