<template>
  <div class="page-container">
    <!-- <div class="header">
      <div class="header-content">
        <img src="@/assets/guide/header.png" height="52px" width="52px" alt="">
        1688.bestdealer.com Protects You From Payment to Delivery with Exclusive Service.
      </div>
    </div> -->
    <div class="content">
      <div class="content-header">Your Gateway to Unlimited Goods!</div>
      <div class="content-header_sub">Sign in or sign up - <span class="highlight">Discover billions of products for your needs.</span></div>
      <div class="content-sign-box" @click="handleSign">Sign in / Sign up</div>
      <div class="content-images-wrapper">
        <div 
          v-for="(row, rowIndex) in visibleImages" 
          :key="rowIndex"
          :style="{ top: calcImageTop(rowIndex) }"
          class="image-row-wrapper">
          <div class="image-row" ref="dynamicRefs">
            <img 
              v-for="(image, index) in row" 
              :key="index" 
              :src="image" 
              width="227px"
              alt="image" 
              class="image-item" 
            />
          </div>
        </div>
      </div>
    </div>
    <div class="linear-footer"></div>
  </div>
</template>
<script setup>
import { nextTick, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();

const group1 = import.meta.glob('@/assets/guide/group1/*.webp', { eager: true });
const group2 = import.meta.glob('@/assets/guide/group2/*.webp', { eager: true });
const group3 = import.meta.glob('@/assets/guide/group3/*.webp', { eager: true });

const imageSet1 = ref([]);
const imageSet2 = ref([])
const imageSet3 = ref([])

const visibleImages = ref([])
const dynamicRefs = ref([])
onMounted(() => {
  imageSet1.value = mapImages(group1);
  imageSet2.value = mapImages(group2);
  imageSet3.value = mapImages(group3);

  const res = imageSet1.value.map((list, index) => {
    return [...list, ...imageSet2.value[index], ...imageSet3.value[index]]
  })
  visibleImages.value = res;

  setInterval(() => {
    startImageTransition();
  }, 5000)

  // 处理超级用户
  handleSuperUser();
})

const handleSuperUser = () => {
  const query = route.query;
  if (+query.sp === 1) {
    handleSign();
  }
}

const mapImages = (group) => {
  const images = [];
  for (const path in group) {
    images.push(group[path].default);
  }
  const result = [];
  for (let i = 0; i< images.length; i += 2) {
    result.push([images[i], images[i + 1]]);
  }
  return result;
}

const transformNums = ref(0); // 位移次数
const startImageTransition = () => {
  transformNums.value ++
  if (transformNums.value > 2) {
    transformNums.value = 0
  }
  const elList = dynamicRefs.value;
  elList.forEach((el, index) => {
    const parent = el.parentElement;
    const parentTop = parseInt(window.getComputedStyle(parent).top);

    setTimeout(() => {
      el.style.opacity = 0;
      el.style.transform = `translateY(${-486 * transformNums.value}px)`;

      setTimeout(() => {
        el.style.opacity = 1; 
      }, 300); 
    }, 500 * index);
  })
}

const handleSign = () => {
  store.commit('setLoginVisible', true)
}


const calcImageTop = (index) => {
  if ([1, 3, 6, 8].includes(index)) {
    return '64px';
  }

  if ([2, 7].includes(index)) {
    return '128px'
  }
}

</script>

<style lang="less" scoped>
.page-container {
  position: relative;

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    width: 100%;
    background-color: #f8f9fa;
    .header-content {
      display: flex;
      align-items: center;
      height: 72px;
      width: 714px;
      border-radius: 8px;
      background-color: #ffffff;
      padding-left: 26px;
      font-size: 13px;
      font-weight: 500;
      font-family: mon-500;
      img {
        margin-right: 14px;
      }
    }
  }
  .content {
    background-color: #ffffff;
    text-align: center;

    .content-header {
      margin-top: 64px;
      color: #052784;
      font-size: 40px;
      font-weight: 800;
      font-family: mon-800;
      line-height: 48px;
      letter-spacing: -0.5px;
    }

    .content-header_sub {
      margin-top: 12px;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      font-family: mon-500;
      color: #232323;
      letter-spacing: -0.5px;
      .highlight {
        color: #009de0;
        font-weight: 600;
        font-family: mon-600;
      }
    }

    .content-sign-box {
      background-color: #232323;
      color: #ffffff;
      width: 162px;
      height: 40px;
      text-align: center;
      line-height: 40px;
      font-size: 14px;
      font-weight: 600;
      font-family: mon-600;
      border-radius: 20px;
      margin: 28px auto 0;
      cursor: pointer;
    }

    .content-images-wrapper {
      display: flex;
      gap: 16px;
      width: 1200px;
      height: 486px;
      margin: 0 auto;
      overflow: hidden;

      .image-row-wrapper {
        position: relative;
        overflow: hidden;
        transition: all .3s ease;
      }

      .image-row {
        display: flex;
        flex-direction: column;
        gap: 16px;
        transition: all 1s ease;
      }
    }
  }

  .linear-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 64px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
  }
}
</style>