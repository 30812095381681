<template>
  <div ref="containerRef" class="bank-payment-container">
    <div class="created-image-card">
      <img src="@/assets/H5/order/confirm-order-card.png" alt="">
    </div>
    <div class="help-text">
      {{ isTransferring ? 'Transfer information is being confirmed' : 'Please use bank transfer to pay the order amount' }}
    </div>
    <div class="help-sub-text">
      {{ isTransferring ? 'Once approved, we will notify you via email immediately.' : 'Please read the transfer information carefully and save the transfer screenshot.' }}
    </div>

    <template v-if="!isTransferring">
      <div class="pending-payment">Awaiting Payment</div>
      <div class="payment-price">
        <IntlCurrency :count="price" :size="32" currency="us" color="#052785" />
      </div>
      <div class="order-number-box">
        <div class="number">Order Number: {{ orderNo }}</div>
        <div class="copy-number" @click="handleCopy(orderNo)">
          <img src="@/assets/H5/order/copy.svg" alt="">
        </div>
      </div>
      <div class="alert-box">
        <img src="@/assets/H5/order/warning-blue.svg" alt="">
        <div class="text">Don't share your bank transfer transaction number with anyone else.</div>
      </div>
      <div class="split-line"></div>

      <div class="bank-info">
        <div class="title">
          Please make the transfer according to the bank information below.
        </div>

        <div class="info-item">
          <img class="icon" src="@/assets/H5/order/account-name.svg" alt="">
          <div class="info">
            <div class="title">Account <span>Name:</span></div>
            <div class="desc">HONG KONG LEYIHUI TECHNOLOGY CO.,LIMITED</div>
          </div>
          <div @click="handleCopy('HONG KONG LEYIHUI TECHNOLOGY CO.,LIMITED')" class="copy">Copy</div>
        </div>
        <div class="info-item">
          <img class="icon" src="@/assets/H5/order/account-number.svg" alt="">
          <div class="info">
            <div class="title">Account <span>Number:</span></div>
            <div class="desc">NRA009-360801-055</div>
          </div>
          <div @click="handleCopy('NRA009-360801-055')" class="copy">Copy</div>
        </div>
        <div class="info-item">
          <img class="icon" src="@/assets/H5/order/bank-name.svg" alt="">
          <div class="info">
            <div class="title">Account <span>Bank:</span></div>
            <div class="desc">HSBC BANK (CHINA) COMPANY LIMITED GZH</div>
          </div>
          <div @click="handleCopy('HSBC BANK (CHINA) COMPANY LIMITED GZH')" class="copy">Copy</div>
        </div>
        <div class="info-item">
          <img class="icon" src="@/assets/H5/order/swit-code.svg" alt="">
          <div class="info">
            <div class="title">SWIFT CODE:</div>
            <div class="desc">HSBCCNSHGZH</div>
          </div>
          <div @click="handleCopy('HSBCCNSHGZH')" class="copy">Copy</div>
        </div>
        <div class="info-item">
          <img class="icon" src="@/assets/H5/order/address.svg" alt="">
          <div class="info">
            <div class="title">Beneficiary Address:</div>
            <div class="desc">FLAT/RM 08 15/F WITTY COMMERCIAL BUILDING 1A-1L TUNG CHOI STREETKL, HONG KONG, 999077</div>
          </div>
          <div @click="handleCopy('FLAT/RM 08 15/F WITTY COMMERCIAL BUILDING 1A-1L TUNG CHOI STREETKL, HONG KONG, 999077')" class="copy">Copy</div>
        </div>
        <div class="info-item">
          <img class="icon" src="@/assets/H5/order/address.svg" alt="">
          <div class="info">
            <div class="title">Bank Address:</div>
            <div class="desc">27F AND 28F TAIKOOHUI TOWER 2 NO 381 TIANHE ROAD TIANHE DISTRICT,GUANGZHOU, CHINA, 510000</div>
          </div>
          <div @click="handleCopy('27F AND 28F TAIKOOHUI TOWER 2 NO 381 TIANHE ROAD TIANHE DISTRICT,GUANGZHOU, CHINA, 510000')" class="copy">Copy</div>
        </div>
      </div>

      <div class="tips-box">
        Tips: Please include your account ID in the note. 
        If entering 'NRA' as part of the recipient information is not possible, please enter '009-360801- 055' as the account number and add 'NRA' in the note. We will confirm your payment details as soon as possible.
      </div>
    </template>

    <div class="upload-box" :class="{ mgB80: !showTextarea }">
      <div class="title">Transfer after completion, submit the following for confirmation.</div>
      <div class="screenshot-text">Transfer screenshot <span class="sign">*</span> : <span class="help-text">(Upload up to 2 images)</span></div>
      <div class="upload-wrapper">
        <div v-if="!isTransferring && uploadImages.length < 2" class="upload-image" :class="{ loading: uploadLoading }" @click="handleUpload">
          <div v-if="uploadLoading" class="loading"></div>
          <template v-else>
            <img src="@/assets/H5/order/upload-img.svg" alt="">
            <div>Upload Image</div>
          </template>
        </div>
        <div v-if="!uploadImages.length" class="example-image">
          <img @click="showPreview = true" class="scale-icon" src="@/assets/H5/order/scale.svg" alt="">
          <img class="emp-img" src="https://cdn-image.bestdealer.com/app-bestdealer/1688/order/bank-payment-example.png" width="100%" alt="">
          <div class="mask"></div>
          <div class="text">Example Image</div>
        </div>
        <div 
          v-else 
          v-for="(url, index) in uploadImages" 
          :key="index" 
          @click.stop="showPreview = true"
          class="example-image">
          <div class="mask"></div>
          <img v-if="isTransferring" @click="showPreview = true" class="scale-icon" src="@/assets/H5/order/scale.svg" alt="">
          <img 
            v-if="!isTransferring"
            @click.stop="deletImage(index)" 
            class="close-icon"
            src="@/assets/H5/order/close-icon.svg" 
            alt="">
          <img class="image" :src="url" alt="" width="100%" height="100%">
        </div>
      </div>
      <input 
        ref="inputRef"
        @change="handleInputChange" 
        type="file" 
        multiple
        accept="image/*" 
        style="display: none;" />
    </div>

    <div v-if="showTextarea" class="notes" :class="{ 'focus-border': isFocused }">
      <textarea 
        @focus="isFocused = true" 
        @blur="isFocused = false" 
        v-model="remark" 
        :placeholder="isTransferring ? '' : 'add few notes to help you later.'" 
        maxlength="200" 
        :disabled="isTransferring">
      </textarea>
      <div class="str-counts">{{ remark.length }} / 200</div>
    </div>

    <div class="footer" :class="{ 'flex-end': isTransferring }">
      <div 
        @click="handleGotIt"
        v-if="isTransferring" 
        class="got-button">I Got It</div>
      <template v-else>
        <div class="cancel-button" @click="handleCancel">Cancel</div>
        <div @click="handlePayment" class="confirm-button" :class="{ disabled: !uploadImages.length }">Confirm</div>
      </template>
    </div>

    <PreviewImage v-model="showPreview" :list="previewImages"/>
  </div>
</template>

<script setup>
  import { onMounted, ref, watch } from 'vue'
  import { copyText, uploadImage } from '@/utils/utils'
  import { fetchData } from '@/utils/fetch'
  import { useCommonToast } from '@/hooks/useCommonToast'
  import PreviewImage from '@/views/Orders/H5Orders/components/PreviewImage.vue';
  import { useRoute, useRouter } from 'vue-router';
  import { buriedPointUtil, gaAnalytics } from '@/utils/utils';

  const emit = defineEmits(['cancel', 'confirm'])
  const props = defineProps({
    price: {
      type: Number,
      default: 0
    },
    orderNo: {
      type: String,
      default: ''
    }
  })

  const route = useRoute()
  const router = useRouter()

  const { toast } = useCommonToast();

  const remark = ref('')

  const inputRef = ref(null)

  const uploadLoading = ref(false)

  const uploadImages = ref([])

  const showPreview = ref(false)

  const previewImages = ref([])

  // 正在转账中
  const isTransferring = ref(false)

  const containerRef = ref(null)

  const showTextarea = ref(true)

  const isFocused = ref(false)

  watch(showPreview, (value) => {
    if (value) {
      previewImages.value = uploadImages.value.length ? uploadImages.value : [ 'https://cdn-image.bestdealer.com/app-bestdealer/1688/order/bank-payment-example.png' ]
    }
  })

  const handleCopy = (value) => {
    copyText(value).then(res => {
      toast('Copy Successfully')
    })
  }

  const handleUpload = () => {
    inputRef.value.click()
  }

  const handleInputChange = async (e) => {
    const maxlength = 2;
    const files = e.target.files

    if (!files.length) return;

    if (files.length > maxlength) {
      e.target.value = ''
      toast('最多上传两个', { type: 'warning' })
      return;
    }

    uploadLoading.value = true;

    let count = 0;
    for (const file of files) {
      const { fileUrl } = await uploadImage(file);
      uploadImages.value.push(fileUrl);
      count++;

      if (count === files.length) {
        uploadLoading.value = false;
      }
    }
  }

  const deletImage = (index) => {
    uploadImages.value.splice(index, 1);
  }

  const handleGotIt = () => {
    buriedPointUtil({ eventId: 'MPRP203' })
    if (route.fullPath === '/orders') {
      location.reload();
    } else {
      router.push('/orders')
    }
  }

  const handlePayment = async () => {
    if (!uploadImages.value.length) return;

    buriedPointUtil({ eventId: 'MPRP201' })
    const params = {
      orderNo: props.orderNo,
      paymentMethodCode: 'BANK_TRANSFER_B2B',
      remark: remark.value,
      transactionNo: '',
      receiptInfos: uploadImages.value // 图片地址
    }
    const { code, data } = await fetchData('/appb/b2b/order/createOrderPay', {
      method: 'POST',
      body: JSON.stringify(params)
    })
    if (code === 200) {
      isTransferring.value = true;
      if (!remark.value) showTextarea.value = false;

      containerRef.value.scrollTo(0, 0);
      gaAnalytics('mp_pay_success', 'mp_pay_success')
      buriedPointUtil({ eventId: 'MPRP502' })

      if (localStorage.getItem('buy-again')) {
        gaAnalytics('mp_buy_again_success', 'mp_buy_again_success')
        localStorage.removeItem('buy-again')
      }

      emit('confirm')
    }
  }

  const handleCancel = () => {
    buriedPointUtil({ eventId: 'MPRP202' })
    emit('cancel')
  }

  onMounted(() => {
    buriedPointUtil({ eventId: 'MPRP501' })
    gaAnalytics('mp_transfer_review_page_view', 'mp_transfer_review_page_view') // 转账审核
  })
</script>

<style lang="less" scoped>
.bank-payment-container {
  position: relative;
  padding: 0 16px;
  height: 100%;
  overflow: auto;

  .created-image-card {
    margin-top: 20px;
    height: 120px;
    width: 100%;
    img {
      height: 100%;
      width: 100%;
    }
  }
  
  .help-text {
    color: #232323;
    font-size: 16px;
    font-family: mon-700;
    line-height: 20px;
    margin-top: 24px;
  }

  .help-sub-text {
    color: #232323;
    font-size: 12px;
    font-family: mon-500;
    line-height: 16px;
    margin-top: 6px;
  }

  .split-line {
    margin-top: 24px;
    height: 0.5px;
    background-color: #e4e6eb;
  }

  .pending-payment {
    margin-top: 24px;
    color: #5c5c5c;
    font-size: 12px;
    font-family: mon-500;
    line-height: 16px;
  }

  .payment-price {
    margin-top: 4px;
  }
  .order-number-box {
    display: flex;
    align-items: center;

    .number {
      color: #232323;
      font-size: 12px;
      font-family: mon-500;
    }

    .copy-number {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
    }
  }

  .alert-box {
    position: relative;
    display: flex;
    height: 64px;
    background-color: #ebf5ff;
    margin-top: 16px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 4px;
      background-color: #1153d6;
    }
    
    img {
      position: absolute;
      top: 16px;
      left: 16px;
    }

    .text {
      position: absolute;
      top: 16px;
      left: 40px;
      width: 265px;
      color: #232323;
      font-size: 12px;
      font-family: mon-500;
      line-height: 16px;
    }
  }

  .bank-info {
    margin-top: 24px;
    .title {
      color: #232323;
      font-size: 14px;
      font-family: mon-600;
    }
  }

  .info-item {
    display: flex;
    align-items: center;
    margin-top: 24px;
    img {
      width: 24px;
      height: 24px;
    }
    .info {
      flex: 1;
      margin-left: 16px;
      .title {
        color: #232323;
        font-size: 12px;
        font-family: mon-600;
        line-height: 16px;
        span {
          color: #1153d6;
        }
      }
      .desc {
        margin-top: 4px;
        color: #5c5c5c;
        font-size: 12px;
        font-family: mon-500;
        line-height: 16px;
      }
    }
    .copy {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 19px;
      width: 52px;
      height: 32px;
      background-color: #f5f5f5;
      border-radius: 20px;
      color: #232323;
      font-size: 12px;
      font-family: mon-600;
    }
  }

  .tips-box {
    margin-top: 24px;
    border: 1px solid #ebf1f5;
    border-radius: 8px;
    padding: 16px;
    color: #b2b7c2;
    font-size: 12px;
    font-family: mon-500;
  }

  .upload-box {
    margin-top: 12px;
    padding: 16px;
    border: 1px solid #ebf1f5;
    border-radius: 8px;

    &.mgB80 {
      margin-bottom: 80px;
    }
    
    .title {
      color: #232323;
      font-size: 14px;
      font-family: mon-600;
    }
    .screenshot-text {
      color: #232323;
      font-size: 12px;
      font-family: mon-600;
      line-height: 16px;
      margin-top: 16px;
      .sign {
        color: #F53131;
      }

      .help-text {
        color: #b2b7c2;
        font-size: 11px;
        font-family: mon-500;
      }
    }

    .upload-wrapper {
      display: flex;
      gap: 24px;
      margin-top: 12px;
      .upload-image {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 4px;
        height: 120px;
        width: 120px;
        border-radius: 6px;
        background-color: #ebf5ff;
        color: #1153d6;
        font-size: 12px;
        font-family: mon-600;
        line-height: 16px;

        &.loading {
          background-color: #EBF5FF;
          border: none;
        }

        .loading {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          border: 4px solid #1153d6;
          border-top: 4px solid transparent;
          animation: rotate 1s linear infinite;

          @keyframes rotate {
            0% {
              transform: rotate(0deg);
            }

            100% {
              transform: rotate(360deg);
            }
          }
        }
      }

      .example-image {
        position: relative;
        height: 120px;
        width: 120px;
        border-radius: 6px;
        overflow: hidden;

        .emp-img {
          position: absolute;
          top: -50%;
        }

        .mask {
          position: absolute;
          inset: 0;
          background-color: rgba(0, 0, 0, .6);
        }

        .image {
          object-fit: contain;
        }

        .scale-icon {
          position: absolute;
          top: 4px;
          right: 4px;
          width: 24px;
          height: 24px;
          z-index: 9;
        }

        .close-icon {
          position: absolute;
          top: 4px;
          right: 4px;
        }
        
        .text {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 12px;
          color: white;
          font-size: 12px;
          font-family: mon-600;
          white-space: nowrap;
        }
      }
    }
  }

  .notes {
    position: relative;
    height: 120px;
    border: 1px solid #ebf1f5;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 104px;
    padding: 16px 12px;

    &.focus-border {
      border: 1px solid #0069E0;
    }

    textarea {
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      resize: none;
      font-size: 12px;
      font-family: mon-500;
      line-height: 16px;
      color: #5c5c5c;
      resize: none;

      &::placeholder {
        color: #B2B7C2;
        font-size: 12px;
        font-family: mon-500;
      }

      &:disabled {
        background-color: white;
      }
    }

    .str-counts {
      position: absolute;
      right: 12px;
      bottom: 12px;
      font-size: 12px;
      font-family: mon-500;
      color: #b2b7c2;
    }
  }

  .footer {
    position: fixed;
    left: 12px;
    right: 12px;
    bottom: 12px;
    width: calc(100% - 24px);
    height: 72px;
    border-radius: 0 0 8px 8px;
    border-top: 0.5px solid #e4e6eb;
    background-color: white;
    padding: 0 16px;
    display: flex;
    justify-content: space-between;
    gap: 17px;

    &.flex-end {
      justify-content: flex-end;
    }

    .cancel-button, .confirm-button, .got-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 160px;
      height: 44px;
      border-radius: 22px;
      font-size: 14px;
      font-family: mon-600;
      margin-top: 12px;
    }

    .cancel-button {
      border: 1px solid #b2b7c2;
    }

    .confirm-button {
      background-color: #4681f9;
      color: white;

      &.disabled {
        opacity: .4;
      }
    }

    .got-button {
      background-color: #4681f9;
      color: white;
    }
  }
}
</style>