<template>
  <div v-if="visible" @click.stop="maskClick" class="h5-full-mask"></div>
</template>
<script setup>
import { useStore } from 'vuex';
import { computed } from 'vue';
const store = useStore();
const visible = computed(() => store.state.h5Module.fullMask);

const emits = defineEmits(['maskClick']);
const maskClick = () => {
  store.commit('h5Module/setFullMask', false);
  emits('maskClick');
}
</script>
<style lang="less">
  .h5-full-mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 999;
  }
</style>