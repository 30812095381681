<template>
  <div class="h5-cart" :style="isLoading ? 'padding: 12px 0px 0px 0px;' : ''">
    <div class="cart-header-container">
      <div class="cart-header">
        <div class="cart-header-title">
          Carts
          <span class="cart-nums" :style="{ opacity: list.length }">{{
            list.length
          }}</span>
          <img
            src="@/assets/H5/carts/batch-delete.svg"
            alt=""
            class="cart-header-batch-delete"
            @click="handleBatchDelete"
            v-if="renderBatchDelete && !isCartsGuide"
          />
        </div>
        <div class="cart-header-btn">
          <img
            src="@/assets/H5/carts/empty-close.svg"
            alt=""
            class="cart-header-close"
            @click="closeBtn"
          />
        </div>
      </div>
    </div>
    <template v-if="!isLoading">
      <div class="cart-empty" v-if="isEmpty">
        <CartEmpty />
        <BottomSafeCards />
      </div>
      <div
        class="cart-main"
        :style="{ overflow: isCartsGuide && 'hidden' }"
        v-if="!isEmpty"
        ref="cartMain"
      >
        <div class="cart-content-main">
          <div class="cart-content">
            <HeaderBuyer />
            <div class="cart-center">
              <div class="cart-item" v-for="(item, index) in list" :key="index">
                <SwipeCell @edit="handleEdit(item)" :style="getStyle(index)">
                  <div class="cart-item-main">
                    <div class="cart-item-left">
                      <div class="cart-item-left-checkbox">
                        <img
                          src="https://cdn-image.bestdealer.com/app-bestdealer/1688/carts/h5-unchecked-icon.svg"
                          alt=""
                          v-if="!item.checked"
                          @click="handleCheck(item)"
                        />
                        <img
                          src="https://cdn-image.bestdealer.com/app-bestdealer/1688/carts/h5-checked-icon.svg"
                          alt=""
                          v-if="item.checked"
                          @click="handleCheck(item)"
                        />
                      </div>
                      <img
                        :src="item.skuImage"
                        alt=""
                        class="cart-center-main-img"
                        @click="handleSub(item, index)"
                      />
                    </div>
                    <div class="cart-item-right">
                      <div class="cart-item-price" @click="handleSub(item, index)">
                        <span
                          >{{ currencyData?.targetCurrency?.slice(0, 2) }}
                          {{ currencyData?.targetUnit
                          }}{{
                            bankersRound(
                              currencyData?.rate * item?.skuPriceRmb,
                              2
                            )
                          }}</span
                        >
                      </div>
                      <div class="cart-item-title" @click="handleSub(item, index)">
                        {{ item.spuName }}
                      </div>
                      <div
                        class="cart-item-sku"
                        v-for="(skuSpec, index) in item.skuSpec?.split(';') ||
                        ''"
                        :key="index"
                        :style="{ marginTop: index === 1 ? '2px' : '6px' }"
                        @click="handleSub(item, index)"
                      >
                        <span class="cart-item-type">{{
                          skuSpec.replace(/:/g, ": ")
                        }}</span>
                      </div>
                      <div class="cart-item-checknum">
                        <div class="secondAttributes-btn">
                          <div
                            class="secondAttributes-btn-sub"
                            @click="attributesSub(item, index)"
                          >
                            <img
                              :id="'secondAttributes-btn-sub-' + index"
                              :src="
                                item.skuQuantity > 1 &&
                                (!item.checked || getSpuQuantity(item.spuId) >
                                  getSpuBatchNum(item.spuId))
                                  ? secondAttributesBtnReduce
                                  : IMAGES.irreducible
                              "
                              alt=""
                            />
                          </div>
                          <input
                            class="secondAttributes-btn-input"
                            :class="{
                              'secondAttributes-btn-input-error':
                                getSpuBatchNum(item.spuId) >
                                getSpuQuantity(item.spuId) && item.checked,
                            }"
                            @change="(e) => changeAttributes(item, index, e)"
                            :value="item.skuQuantity"
                          />
                          <div
                            class="secondAttributes-btn-add"
                            @click="attributesAdd(item, index)"
                          >
                            <img
                              :id="'secondAttributes-btn-add-' + index"
                              :src="
                                item.skuQuantity < item.max
                                  ? secondAttributesBtnAdd
                                  : IMAGES.cannotBeAdded
                              "
                              alt=""
                            />
                          </div>
                        </div>
                        <div class="cart-item-checknum-right">
                          <div class="cart-item-checknum-subTitle">
                            Subtotal:
                          </div>
                          <div class="cart-item-checknum-subTotal">
                            <IntlCurrency
                              :count="
                                (
                                  bankersRound(
                                    currencyData?.rate * item.skuPriceRmb,
                                    2
                                  ) * item.skuQuantity
                                ).toFixed(2)
                              "
                              :size="14"
                              :currency="
                                currencyData?.targetCurrency.slice(0, 2)
                              "
                              color="#052785"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwipeCell>
                <div
                  class="cart-item-remark"
                  v-if="getSpuBatchNum(item.spuId) > getSpuQuantity(item.spuId) && getSpuQuantity(item.spuId) > 0"
                >
                  The minimum order quantity of
                  {{ getSpuBatchNum(item.spuId) }} items has not been met.
                </div>
                <div
                  class="cart-item-line"
                  v-if="index !== list.length - 1"
                ></div>
                <div
                  v-if="isCartsGuide && !index"
                  :class="[
                    {
                      'mask-cart-item-1': step === 2,
                    },
                    'mask-cart-item',
                  ]"
                  :style="{ height: cartItemMainHeight }"
                >
                  <img
                    src="@/assets/H5/carts/mark-checked.svg"
                    alt=""
                    class="mask-cart-item-checked"
                    v-if="step === 1"
                  />
                  <img
                    src="@/assets/H5/carts/mask-handle.svg"
                    alt=""
                    class="mask-cart-item-handle"
                    v-if="step === 2"
                  />
                </div>
              </div>
              <div class="cart-mask-step-1" v-if="step === 1 && isCartsGuide">
                <div class="step-label">
                  Click here to select items you want to add to checkout or
                  delete.
                </div>
                <div class="step-tool">
                  <div @click="skip">Skip</div>
                  <div @click="step = 2">Next (1/2)</div>
                </div>
                <img
                  src="@/assets/H5/carts/tool-tips.svg"
                  alt=""
                  class="step-tool-1"
                />
              </div>
              <div class="cart-mask-step-2" v-if="step === 2 && isCartsGuide">
                <div class="step-label step-label-2">
                  Swipe right to delete items you don't want to it.
                </div>
                <div class="step-tool">
                  <div @click="skip">Skip</div>
                  <div @click="skip">Got it</div>
                </div>
                <img
                  src="@/assets/H5/carts/tool-tips.svg"
                  alt=""
                  class="step-tool-2"
                />
              </div>
            </div>
          </div>
          <div style="margin-top: 12px; margin-bottom: 184px">
            <BottomSafeCards :showPaymentMethods="true" />
          </div>
        </div>
      </div>
      <div class="cart-pay-container" v-if="!isEmpty">
        <div class="h5-cart-pay">
          <div class="cart-pay-subtotal">
            <div class="cart-pay-subtotal-label">Subtotal</div>
            <div class="cart-pay-subtotal-value">
              <IntlCurrency
                :count="renderSubtotal"
                :size="16"
                :currency="currencyData?.targetCurrency.slice(0, 2)"
                color="#ffffff"
              />
            </div>
          </div>
          <div class="h5-cart-subtotal-tips">
            <img src="@/assets/H5/carts/mark-tips.svg" alt="" />
            <span>The current order doesn't include shipping costs.</span>
          </div>
          <div class="checkout-main">
            <div class="checkout-radio" @click="handleCheckAll">
              <img
                src="@/assets/H5/carts/unchecked-all.svg"
                alt=""
                v-if="!isCheckedAll"
              />
              <img
                src="@/assets/H5/carts/all-checked.svg"
                alt=""
                v-if="isCheckedAll"
              />
              <span class="checkout-radio-label">All</span>
            </div>
            <div class="checkout-btn" @click="handleCheckout">
              <template v-if="allowOrderSwitch === 1">
                Checkout
                <span v-if="renderCartsTotal">({{ renderCartsTotal }})</span>
              </template>
              <template v-else>
                <img src="@/assets/H5/carts/whatsapp-icon.svg" alt="">
                Enable Order Function
              </template>
            </div>
          </div>
        </div>
        <div 
          class="whatsApp-skip-error" 
          :style="{ 
            height: whatsAppBottomSheet ? '380px' : '0',
          }">
          <img class="logo" src="@/assets/H5/carts/whatsApp-alert.svg" alt="">
          <div class="text-1">Oops!</div>
          <div class="text-2">Sorry, if WhatsApp redirect is disabled. Please contact your agent directly on WhatsApp at: </div>
          <div class="text-2">{{ whatsAppNumber }}</div>

          <div @click="handleCopyLink" class="copy-button">
            <img src="@/assets/H5/carts/copy-icon.svg" alt="">
            CopyLink
          </div>
          <div @click="whatsAppBottomSheet = false" class="cancel-button">Cancel</div>
        </div>
      </div>
    </template>
    <div class="h5-guide" v-if="isCartsGuide">
      <img
        v-if="isCartsGuide"
        src="https://cdn-image.bestdealer.com/app-bestdealer/1688/carts/h5-delete-icon.svg"
        alt=""
        class="cart-header-guide"
      />
      <div class="mask-checkout" v-if="isCartsGuide && step === 1">
        Checkout
      </div>
    </div>
    <div class="h5-cart-loading" v-if="isLoading">
      <img src="@/assets/H5/empty.gif" alt="" />
    </div>
  </div>
</template>


<script setup>
import { computed, onMounted, ref, onUnmounted, watch, nextTick } from "vue";
import H5Dialog from "@/components/H5/H5Dialog/index.vue";
import CartEmpty from "./components/CartEmpty/index.vue";
import BottomSafeCards from "./components/BottomSafeCards/index.vue";
import SwipeCell from "./components/SwipeCell/index.vue";
import HeaderBuyer from "./components/HeaderBuyer/index.vue";

import { useRouter } from "vue-router";
import { fetchData } from "../../../../utils/fetch";
import {
  bankersRound,
  buriedPointUtil,
  copyText,
  gaAnalytics,
} from "../../../../utils/utils";
import { useStore } from "vuex";
import { useCommonToast } from "@/hooks/useCommonToast";

const { toast } = useCommonToast();

const store = useStore();

const router = useRouter();
const innerHeight = ref(0);
const showPopup = ref(true);
const step = ref(1);
const isLoading = ref(true);
const cartMain = ref(null);

const currencyData = ref(null);
const cartItemMain = ref(null);
const allowOrderSwitch = ref(0);

const list = ref([]);
// const isCartsGuide = computed(() => {
//   return !localStorage.getItem("cartsGuide") && list.value.length !== 0;
// });

const isCartsGuide = ref(false);

const whatsAppBottomSheet = ref(false)
const whatsAppUrl = ref('')
const whatsAppNumber = ref('')

watch(isCartsGuide, (newVal) => {
  if (newVal) {
    document.querySelector("body").classList.add("no-scroll");
  } else {
    document.querySelector("body").classList.remove("no-scroll");
  }
});

const getCurrency = async () => {
  const { code, data } = await fetchData(
    "/dsp-app/alibaba-common/currencyList"
  );
  if (code === 200) {
    const storageCurrencyData = localStorage.getItem("currencyData");
    if (storageCurrencyData) {
      const storageCurrencyDataObj = JSON.parse(storageCurrencyData);
      currencyData.value = data.find(
        (item) => item.targetCurrency === storageCurrencyDataObj.targetCurrency
      );
    } else {
      currencyData.value = data[0];
    }
  }
};

const IMAGES = {
  addDefault:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/add_default.svg",
  add: "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/add.svg",
  reduceDefault:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/reduce_default.svg",
  reduce:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/reduce.svg",
  irreducible:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/irreducible.svg",
  cannotBeAdded:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/cannot_be_added.svg",
};
const secondAttributesBtnAdd = ref(IMAGES.addDefault);
const secondAttributesBtnReduce = ref(IMAGES.reduceDefault);

onMounted(async () => {
  innerHeight.value = window.innerHeight;
  document.querySelector("body").classList.remove("no-scroll");
  gaAnalytics("mp_cart_page_view", "mp_cart_page_view");
  buriedPointUtil({ eventId: "MPC501" });
  await getUserInfo();
  await getCurrency();
  await getCarts();
  document.querySelector(".content").style.backgroundColor = "#000000";
  document.body.style.backgroundColor = "#000000";
});

onUnmounted(() => {
  document.querySelector(".content").style.backgroundColor = "#FFFFFF";
  document.body.style.backgroundColor = "#FFFFFF";
});
const cartItemMainHeight = computed(() => {
  if (step.value === 2) {
    const cartItemMain = document.querySelector(".cart-item-main");
    if (cartItemMain) {
      return cartItemMain.offsetHeight - 1 + "px";
    }
  }
  return "";
});

const getUserInfo = async () => {
  const { code, data } = await fetchData("/dsp-app/alibaba-inter/queryMyInfo", {
    method: "post",
  });
  if (code === 200) {
    allowOrderSwitch.value = data?.allowOrderSwitch;
  }
}


const getCarts = async () => {
  try {
    const { code, data } = await fetchData(
      "/appb/b2b/cart/cartList?page=1&pageSize=200",
      {
        method: "get",
      }
    );
    if (code === 200) {
      list.value = data?.rows?.map((v, index) => {
        return {
          ...v,
          checked: index <= 49,
          max: Infinity,
          skuSubtotal:
            bankersRound(currencyData?.value.rate * v.skuPriceRmb, 2) *
            v.skuQuantity,
        };
      });
      isCartsGuide.value =
        !localStorage.getItem("cartsGuide") && data?.rows.length !== 0;
    }
    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
  }
};

const isEmpty = computed(() => {
  return list.value.length === 0;
});

const isCheckedAll = computed(() => {
  return list.value.length > 50
    ? list.value.filter((item) => item.checked).length >= 50
    : list.value.every((item) => item.checked);
});

const handleCheckAll = () => {
  if (isCheckedAll.value) {
    list.value.forEach((item) => {
      item.checked = false;
    });
  } else {
    list.value.forEach((item, index) => {
      item.checked = index < 50;
    });
  }
};

const getStyle = (index) => {
  if (isCartsGuide.value && step.value === 2 && index === 0) {
    return { transform: "translateX(-103px)" };
  }
  return null;
};

watch(isEmpty, (newVal) => {
  if (newVal) {
    // document.querySelector(".cart-empty").style.height = `calc(${window.innerHeight}px - 12px)`;
  } else {
  }
});

const getSpuBatchNum = (spuId) => {
  const spuItems = list.value.filter((item) => item.spuId === spuId);
  return Math.max(...spuItems.map((item) => item.batchNum));
};

const getSpuQuantity = (spuId) => {
  const spuItems = list.value.filter((item) => item.spuId === spuId && item.checked);
  return spuItems.reduce((sum, item) => sum + Number(item.skuQuantity), 0);
};

const attributesSub = async (item, index) => {
  if(isCartsGuide.value) {
    return
  }
  const spuQuantity = getSpuQuantity(item.spuId);
  const spuBatchNum = getSpuBatchNum(item.spuId);

  if ((!item.checked || spuQuantity > spuBatchNum) && item.skuQuantity > 1) {
    item.skuQuantity--;
    item.skuSubtotal =
      bankersRound(currencyData?.value.rate * item.skuPriceRmb, 2) *
      item.skuQuantity;

    await updateCarts({
      cartId: item.cartId,
      quantity: item.skuQuantity,
    });
  }
};

const attributesAdd = async (item, index) => {
  if(isCartsGuide.value) {
    return
  }
  if (item.skuQuantity < item.max) {
    item.skuQuantity++;
    item.skuSubtotal =
      bankersRound(currencyData?.value.rate * item.skuPriceRmb, 2) *
      item.skuQuantity;

    await updateCarts({
      cartId: item.cartId,
      quantity: item.skuQuantity,
    });
  }
};

const changeAttributes = async (item, index, e) => {
  if(isCartsGuide.value) {
    return
  }
  item.skuQuantity = e.target.value;
  if (item.skuQuantity < item.min) {
    item.skuQuantity = item.min;
  }
  if (item.skuQuantity > item.max) {
    item.skuQuantity = item.max;
  }
  item.skuSubtotal =
    bankersRound(currencyData?.value.rate * item.skuPriceRmb, 2) *
    item.skuQuantity;
  await updateCarts({
    cartId: item.cartId,
    quantity: item.skuQuantity,
  });
};

const updateCarts = async (params) => {
  const { code, data } = await fetchData("/appb/b2b/cart/cartUpdate", {
    method: "post",
    body: JSON.stringify(params),
  });
};

const renderSubtotal = computed(() => {
  let total = 0;
  list.value.forEach((v) => {
    if (v.checked) {
      total += Number(v.skuSubtotal);
    }
  });
  return total.toFixed(2);
});

const handleBatchDelete = async () => {
  const checkedCartsItems = list.value
    .filter((item) => item.checked)
    ?.map((item) => item.cartId);
  try {
    const { code, data } = await fetchData("/appb/b2b/cart/cartDelete", {
      method: "post",
      body: JSON.stringify({
        cartIds: checkedCartsItems,
      }),
    });
    if (code === 200) {
      toast("Deleted successfully.", { type: "success" });
      list.value = list.value.filter(
        (item) => !checkedCartsItems.includes(item.cartId)
      );

      fetchData("/appb/b2b/cart/cartCount").then((res) => {
        if (res.code === 200) {
          store.commit("cart/SET_CART_COUNT", res.data);
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const skip = () => {
  step.value = 1;
  isCartsGuide.value = false;
  localStorage.setItem("cartsGuide", 1);
};

const handleEdit = async (item) => {
  const { code, data } = await fetchData("/appb/b2b/cart/cartDelete", {
    method: "post",
    body: JSON.stringify({
      cartIds: [item.cartId],
    }),
  });
  if (code === 200) {
    toast("Deleted successfully.", { type: "success" });
    list.value = list.value.filter((v) => v.cartId !== item.cartId);

    fetchData("/appb/b2b/cart/cartCount").then((res) => {
      if (res.code === 200) {
        store.commit("cart/SET_CART_COUNT", res.data);
      }
    });
  }
};

const renderCartsTotal = computed(() => {
  return list.value.filter((item) => item.checked).length;
});

const renderBatchDelete = computed(() => {
  return list.value.some((item) => item.checked);
});

const clickCount = ref(0)
const handleCheckout = async () => {
  if(isCartsGuide.value) {
    return
  }
  buriedPointUtil({ eventId: "MPC204" });

  clickCount.value++
  if(allowOrderSwitch.value === 0) {
    const { code, data } = await fetchData("/appb/alibaba-inter/redirectView", {
      method: "post",
      body: JSON.stringify({
        code: 2
      })
    });
    if (code === 200) {
      buriedPointUtil({ eventId: "MPC601" });
      const res = window.open(data, '_blank');

      if (res === null || clickCount.value === 2) {
        clickCount.value = 0
        whatsAppBottomSheet.value = true
        whatsAppUrl.value = data
        const url = new URL(data)
        const number = url.searchParams.get('phone')
        whatsAppNumber.value = number
      }
    }
    return
  }
  const checkedCartsItems = list.value
    .filter((item) => item.checked)
    ?.map((item) => item.cartId);
  if (checkedCartsItems.length === 0) {
    toast("Please select at least one item.", { type: "error" });
    return;
  }

  // 检查每个SPU是否满足最小批量
  const checkedItems = list.value.filter((item) => item.checked);
  const spuIds = [...new Set(checkedItems.map((item) => item.spuId))];

  for (const spuId of spuIds) {
    const batchNum = getSpuBatchNum(spuId);
    const quantity = getSpuQuantity(spuId);
    if (quantity < batchNum) {
      toast(`Please meet the minimum order quantity for all items.`, {
        type: "error",
      });
      return;
    }
  }
  sessionStorage.setItem("carts", JSON.stringify(checkedCartsItems));
  buriedPointUtil({ eventId: "MPC203" });
  router.push("/orderFilling");
};

const handleCopyLink = () => {
  copyText(whatsAppUrl.value).then(() => {
    toast('Copy Successfully')
  })
}

const closeBtn = () => {
  if (!localStorage.getItem("tk")) {
    router.replace("/");
    return;
  }
  router.go(-1);
};
const handleCheck = (item) => {
  if (list.value.filter((item) => item.checked).length >= 50 && !item.checked) {
    toast("You can only select up to 50 items", { type: "error" });
    return;
  }

  item.checked = !item.checked;
};

const handleSub = (item, index) => {
  if(isCartsGuide.value) {
    return
  }
  router.replace(`/offer/${item.spuId}`);
};
</script>

<style>
@import "./index.less";
</style>