<template>
  <div class="intl-currency">
    <div class="currency" v-html="svgContent" :style="{ color: color }"></div>
    <div class="count" :style="{ '--size': size, '--color': color }">{{ count }}</div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref, watch } from 'vue';

const props = defineProps({
  count: {
    type: [Number, String],
    required: true,
  },
  size: {
    type: Number,
    required: true,
    validator: (value) => {
      return [14, 16, 18, 24, 32].includes(value)
    }
  },
  currency: {
    type: String,
    required: true,
    validator: (value) => {
      const _value = value.toLowerCase();
      return ['us', 'eu', 'jp', 'gb', 'au', 'ar'].includes(_value);
    }
  },
  color: {
    type: String,
    default: '',
    validator: (value) => {
      if (!value) return true;
      return /^#[0-9A-Fa-f]{6}$/.test(value) || 
             /^rgb\(\d{1,3},\s*\d{1,3},\s*\d{1,3}\)$/.test(value) ||
             /^[a-zA-Z]+$/.test(value);
    }
  }
})

// 使用 raw 模式导入 SVG 文件
const group14 = import.meta.glob('@/assets/intlCurrency/14/*.svg', { eager: true, as: 'raw' });
const group16 = import.meta.glob('@/assets/intlCurrency/16/*.svg', { eager: true, as: 'raw' });
const group18 = import.meta.glob('@/assets/intlCurrency/18/*.svg', { eager: true, as: 'raw' });
const group24 = import.meta.glob('@/assets/intlCurrency/24/*.svg', { eager: true, as: 'raw' });
const group28 = import.meta.glob('@/assets/intlCurrency/28/*.svg', { eager: true, as: 'raw' });
const group32 = import.meta.glob('@/assets/intlCurrency/32/*.svg', { eager: true, as: 'raw' });

const currencyMap = reactive({})
const svgContent = ref('')

const formatGroup = (group) => {
  for(let key in group) {
    const _currencyKey = key.split('?')[0]
    const currencyKey = _currencyKey.split('/').pop().replace('.svg', '')
    currencyMap[currencyKey] = group[key];
  }
}

const loadSvgContent = (svgText) => {
  try {
    svgContent.value = svgText.replace(/fill="[^"]*"/g, 'fill="currentColor"');
  } catch (error) {
    console.error('Error processing SVG:', error);
  }
}

const getCurrency = computed(() => {
  return props.currency?.toLocaleLowerCase()
})

const currentSvgContent = computed(() => {
  if (currencyMap[getCurrency.value]) {
    return currencyMap[getCurrency.value];
  }
  return '';
})

watch(currentSvgContent, (newContent) => {
  if (newContent) {
    loadSvgContent(newContent);
  }
})

onMounted(() => {
  if (props.size === 14) {
    formatGroup(group14)
  }

  if (props.size === 16) {
    formatGroup(group16)
  }

  if (props.size === 18) {
    formatGroup(group18)
  }

  if (props.size === 24) {
    formatGroup(group24)
  }

  if (props.size === 28) {
    formatGroup(group28)
  }

  if (props.size === 32) {
    formatGroup(group32)
  }

  if (currencyMap[getCurrency.value]) {
    loadSvgContent(getCurrency.value);
  }
})
</script>

<style lang='less' scoped>
.intl-currency {
  display: inline-flex;
  align-items: center;

  .currency {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .count {
    color: var(--color);
    font-size: calc((var(--size) * 1px));
    font-family: mon-700;
  }
}
</style>