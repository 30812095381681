<template>
  <div class="h5-offer">
    <div v-show="!searchFoucs">
      <KeywordsMiniHeader :isShow="showMiniHeader" />
      <div class="main-header" :class="{ hidden: showMiniHeader }">
        <Header />
      </div>
      <div v-if="!isEmpty.showEmpty && sourcingStatusInfo?.status !== 3">
        <div v-show="!showInitSkeleton">
          <div class="h5-offer-header">
            <H5Carousel :showH5Sourcing="showH5Sourcing" />
            <div class="h5-offer-main">
              <div
                class="h5-offer-title"
                ref="textContent"
                :class="{ expanded: isExpanded }"
              >
                {{ offerData?.subjectTrans }}
              </div>
              <div
                class="h5-offer-expaned"
                @click="toggleExpand"
                v-if="shouldShowExpandBtn"
              >
                <div class="h5-offer-ellipsis">
                  <img src="@/assets/H5/offer/ellipsis.svg" alt="" />
                </div>
                <img
                  src="@/assets/H5/offer/down.svg"
                  alt=""
                  class="h5-offer-down"
                  :style="{
                    transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                  }"
                />
              </div>
            </div>
            <div class="h5-offer-sold">
              <span class="h5-offer-sold-1"
                >{{
                  (
                    offerData?.sellerDataInfo?.repeatPurchasePercent * 100
                  ).toFixed(0)
                }}% repeat customer rate</span
              >
              <span class="h5-offer-sold-2"></span>
              <span class="h5-offer-sold-3"> {{ soldOut }} </span>
              <span class="h5-offer-sold-4"> sold </span>
            </div>
            <div class="h5-offer-Piece">
              Min. Order: {{ offerData?.minOrderQuantity }} Piece
            </div>
          </div>
          <div
            class="h5-offer-content"
            :style="{
              padding: !offerData?.productSkuInfos?.[0]?.price
                ? '12px 16px 12px'
                : '1px 16px 12px',
            }"
          >
            <H5PriceInterval
              :offerData="offerData"
              :currencyData="currencyData"
            />
            <div v-if="offerData?.productSkuInfos">
              <div class="h5-sku" v-if="!showArrangement()">
                <div class="h5-sku-left" @click="handleH5SourcingPopup">
                  <div class="h5-sku-left-title">
                    {{
                      offerData?.productSkuInfos?.[0].skuAttributes?.[0]
                        ?.attributeNameTrans?.charAt(0).toUpperCase() + 
                        offerData?.productSkuInfos?.[0].skuAttributes?.[0]
                        ?.attributeNameTrans?.slice(1)
                    }}
                    to select
                  </div>
                  <div style="display: flex">
                    <img src="@/assets/H5/offer/sku-right.svg" alt="" />
                  </div>
                </div>
                <div class="h5-sku-right" @click="handleH5SourcingPopup">
                  <div class="h5-sku-right-title">
                    {{
                      offerData?.productSkuInfos?.[0].skuAttributes?.[1]
                        ? offerData?.productSkuInfos?.[0].skuAttributes?.[1]
                            .attributeNameTrans
                        : "Quantity"
                    }}
                    to select
                  </div>
                  <img src="@/assets/H5/offer/sku-right.svg" alt="" />
                </div>
              </div>
              <div class="h5-sku-length" v-if="showArrangement()">
                <div class="h5-sku-left" @click="handleH5SourcingPopup">
                  <div class="h5-sku-left-title">
                    {{
                      offerData?.productSkuInfos?.[0].skuAttributes?.[0]
                        .attributeNameTrans
                    }}
                    to select
                  </div>
                  <img src="@/assets/H5/offer/sku-right.svg" alt="" />
                </div>
                <div class="h5-sku-right" @click="handleH5SourcingPopup">
                  <div class="h5-sku-right-title">
                    {{
                      offerData?.productSkuInfos?.[0].skuAttributes?.[1]
                        ? offerData?.productSkuInfos?.[0].skuAttributes?.[1]
                            .attributeNameTrans
                        : "Quantity"
                    }}
                    to select
                  </div>
                  <img src="@/assets/H5/offer/sku-right.svg" alt="" />
                </div>
              </div>
            </div>
            <div
              v-if="!offerData?.productSkuInfos"
              class="h5-no-sku-length"
              @click="handleH5SourcingPopup"
            >
              <div class="h5-no-sku-title">Quantity to select</div>
              <img src="@/assets/H5/offer/sku-right.svg" alt="" />
            </div>
            <div class="h5-shipto">
              <div class="h5-shipto-left">
                <img src="@/assets/H5/offer/shipto.svg" alt="" />
                <span class="h5-shipto-left-title">Shipping to:</span>
                <img
                  :src="currentCountry?.countryUrl"
                  alt=""
                  class="ship-country-img"
                />
                <span class="ship-main-title">{{
                  currentCountry?.countryName
                }}</span>
              </div>
              <div
                style="
                  display: flex;
                  align-items: center;
                  width: 60px;
                  justify-content: end;
                "
                @click="handleH5SourcingPopup"
              >
                <img src="@/assets/H5/offer/sku-right.svg" alt="" />
              </div>
            </div>
            <div class="h5-supplier">
              <div class="h5-supplier-left">
                <img
                  src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/supplier-icon.svg"
                  alt=""
                  class="h5-supplier-left-1"
                />
                <span class="h5-supplier-left-2">Fulfillment Supplier:</span>
                <img
                  class="h5-supplier-left-3"
                  src="@/assets/H5/offer/logo.svg"
                  alt=""
                />
              </div>
              <div class="h5-supplier-right">
                Professional international fulfillment services
              </div>
            </div>
            <div class="h5-supplier">
              <div class="h5-supplier-left">
                <img
                  src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/payment-icon.svg"
                  alt=""
                  class="h5-supplier-left-1"
                />
                <span class="h5-supplier-left-2">Payment Methods:</span>
                <!-- <span class="h5-supplier-left-2-only">Only with</span> -->
                <img
                  class="h5-supplier-left-2-img"
                  src="@/assets/offer/pay-method.png"
                  alt=""
                />
              </div>
            </div>
          </div>
          <H5Protection />
          <div class="h5source-factory">
            <div class="h5source-factory-content">
              <img
                src="@/assets/H5/offer/factory-icon.svg"
                alt=""
                class="h5source-factory-icon"
              />
              <div class="h5source-factory-text">
                Source Direct-From-Factory
              </div>
            </div>
            <img
              class="h5source-factory-bg"
              src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/factory-bg.png"
              alt=""
            />
          </div>
          <div class="h5-store">
            <div class="h5-store-header">Shop Store</div>
            <div class="h5-store-content">
              <div class="h5-store-content-left">
                <div class="h5-store-content-left-store">
                  {{ offerData?.sellerDataInfo?.compositeServiceScore }}
                </div>
                <div class="product-star">
                  <div
                    class="product-star-img"
                    v-if="
                      offerData?.sellerDataInfo?.compositeServiceScore ||
                      offerData?.sellerDataInfo?.compositeServiceScore == 0
                    "
                  >
                    <img
                      src="@/assets/H5/offer/star.svg"
                      alt=""
                      v-for="(item, index) in new Array(
                        Math.floor(
                          offerData?.sellerDataInfo?.compositeServiceScore
                        )
                      ).fill(0)"
                      :key="'star-' + index"
                    />
                    <img
                      src="@/assets/H5/offer/star-no.svg"
                      alt=""
                      v-for="(item, index) in new Array(
                        Math.ceil(
                          5 - offerData?.sellerDataInfo?.compositeServiceScore
                        )
                      ).fill(0)"
                      :key="'star-no-' + index"
                    />
                  </div>
                </div>
                <div class="h5-store-content-left-rating">
                  Supplier Credit Rating
                </div>
              </div>
              <div class="h5-store-content-right">
                <div class="h5-store-content-right-content">
                  <span class="h5-store-content-right-title"
                    >Trade Medal Level</span
                  >
                  <span class="h5-store-content-right-score">{{
                    Number(offerData?.sellerDataInfo?.tradeMedalLevel || 0).toFixed(2)
                  }}</span>
                </div>
                <div class="h5-store-content-right-content">
                  <span class="h5-store-content-right-title"
                    >Quality Experence</span
                  >
                  <span class="h5-store-content-right-score">{{
                    Number(offerData?.sellerDataInfo?.offerExperienceScore || 0).toFixed(2)
                  }}</span>
                </div>
                <div class="h5-store-content-right-content">
                  <span class="h5-store-content-right-title"
                    >Composite Service</span
                  >
                  <span class="h5-store-content-right-score">{{
                    Number(offerData?.sellerDataInfo?.compositeServiceScore || 0).toFixed(2)
                  }}</span>
                </div>
                <div class="h5-store-content-right-content">
                  <span class="h5-store-content-right-title"
                    >Dispute Complaint</span
                  >
                  <span class="h5-store-content-right-score">{{
                    Number(offerData?.sellerDataInfo?.disputeComplaintScore || 0).toFixed(2)
                  }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="h5-description">
            <div
              class="h5-description-header"
              @click="handleH5DescriptionPopup"
            >
              <div class="h5-description-header-title">Description</div>
              <img src="@/assets/H5/offer/sku-right.svg" alt="" />
            </div>
            <div class="h5-offer-detail-continer">
              <div
                v-for="(item) in descList"
                :key="item.attributeNameTrans"
                class="h5-offer-detail-item"
              >
                <div class="h5-item-attributeNameTrans">
                  {{ item?.attributeNameTrans }}:
                </div>
                <div class="h5-item-valueTrans">
                  {{ item?.valueTrans }}
                </div>
              </div>
            </div>
            <div class="h5-offer-line"></div>
            <div class="h5-offer-detail">
              <div class="h5-offer-detail-header" ref="offerTitleRef">
                More Details
              </div>
              <div class="h5-offer-image-list" v-if="!init">
                <div
                  v-for="(item, index) in displayedImages" 
                  :key="index"
                  class="offer-image-item"
                >
                  <img :src="item" alt="" />
                  <div class="h5-offer-tool">
                    <img
                      src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-findsmilar-icon.svg"
                      alt=""
                      @click="handleFind('similar', item)"
                    />
                    <img
                      src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-findstyle-icon.svg"
                      alt=""
                      @click="handleFind('styles', item)"
                    />
                  </div>
                </div>
                <div
                  class="h5-offer-image-more"
                  @click="toggleDescExpand"
                  v-if="!isDesExpanded"
                >
                  <div class="h5-offer-image-more-title">View More</div>
                  <img src="@/assets/H5/offer/down.svg" alt="" />
                </div>
              </div>
              <div class="h5-offer-image-init" v-if="init">
                <div class="h5-offer-image-init-1"></div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="h5-ads" @click="handleClickSeo" v-show="!showInitSkeleton">
          <img
            src="https://cdn-image.bestdealer.com/app-bestdealer/1688/SEOAnt.png"
            alt=""
          />
        </div> -->
        <div class="h5-offer-footer-bg" v-show="!showInitSkeleton">
          <img src="@/assets/H5/offer/bottom-line.png" alt="">
        </div>
        <div class="h5-offer-footer" v-show="!showInitSkeleton">
          <div class="homeview-footer">
            <div class="homeview-footer-top">
              <div class="homeview-footer-top-1">CS Center</div>
              <div class="homeview-footer-top-2">Support@bestdealer.com</div>
              <div class="homeview-footer-top-3">
                Monday - Friday | AM 10:00 - PM 18:00
              </div>
            </div>
            <div class="homeview-footer-line"></div>
            <div class="homeview-footer-bottom">
              <div class="homeview-footer-bottom-1">Coypright 2025 ©</div>
              <div class="homeview-footer-bottom-2">
                BestDealer.All Rights Reserved
              </div>
            </div>
          </div>
        </div>
      </div>
      <H5Empty :style="{ height: 'calc(100vh - 104px)' }" />
      <H5SourcingFailed v-if="sourcingStatusInfo?.status == 3" :reason="sourcingStatusInfo?.message" />
      <div
        class="h5-offer-fixed-footer"
        @click="handleH5SourcingPopup"
        v-if="
          (sourcingStatusInfo?.status == 4 || sourcingStatusInfo?.status == 2) && !showInitSkeleton
        "
      >
        <div class="h5-offer-fixed-footer-sourcing">
          {{ sourcingStatusInfo?.status == 2 ? 'Add to Cart' : 'Sourcing Now' }}
        </div>
      </div>
      <div class="sourcing-processing-content" v-if="sourcingStatusInfo?.status == 1">
        <img src="@/assets/H5/offer/exclamation-sm.svg" alt="">
        <span
          >The platform will complete processing within 1 working day. After
          successful processing, you can view your purchase list in the shopping
          cart. Please wait patiently.</span
        >
      </div>
      <div class="h5-offer-fixed-footer" v-if="sourcingStatusInfo?.status == 1">
        <!-- <div class="h5-offer-fixed-footer-sourcing">
          Purchase Request Processing
        </div> -->
        <img src="@/assets/H5/offer/order-amount.gif" alt="" class="order-amount-gif" @click="handleOrderAmount">
        <!-- <div class="sourcing-mask"></div> -->
      </div>
      <div 
        class="whatsApp-skip-error" 
        :style="{ 
          height: whatsAppBottomSheet ? '380px' : '0',
        }">
        <img class="logo" src="@/assets/H5/carts/whatsApp-alert.svg" alt="">
        <div class="text-1">Oops!</div>
        <div class="text-2">Sorry, if WhatsApp redirect is disabled. Please contact your agent directly on WhatsApp at: </div>
        <div class="text-2">{{ whatsAppNumber }}</div>

        <div @click="handleCopyLink" class="copy-button">
          <img src="@/assets/H5/carts/copy-icon.svg" alt="">
          CopyLink
        </div>
        <div @click="whatsAppBottomSheet = false" class="cancel-button">Cancel</div>
      </div>
      <div :style="{ height: sourcingStatusInfo?.status == 1 ? '144px' : '72px' }" v-if="sourcingStatusInfo?.status == 1"></div>
    </div>
    <H5EmptyInitSkeleton v-if="showInitSkeleton" :reason="sourcingStatusInfo?.message" />
    <H5SearchFoucs
      v-if="searchFoucs"
      @back="() => store.commit('h5Module/setSearchFoucs', false)"
    />
    <H5Dialog
      v-model="showH5DescriptionPopup"
      :height="`calc(${innerHeight}px - 40px)`"
      :initStyles="{ background: 'white' }"
    >
      <H5Description
        :descAllList="descAllList"
        :closeH5Description="closeH5Description"
      />
    </H5Dialog>
    <div v-if="initShowH5SourcingDialog">
      <H5SourcingDialog
        v-model:visible="showH5SourcingPopup"
        :height="`calc(${innerHeight}px - 16px)`"
      >
        <H5Sourcing
          :offerData="offerData"
          :closeH5Sourcing="closeH5Sourcing"
          :currentCountry="currentCountry"
          :newParam="newParam"
          :sourcingStatusInfo="sourcingStatusInfo"
        />
      </H5SourcingDialog>
    </div>
  </div>
  <H5SendSuccess v-if="h5SendSuccess" />
</template>

<script setup>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import Header from "@/components/H5/Header/index.vue";
import H5SearchFoucs from "@/components/H5/H5SearchFoucs/index.vue";
import H5Carousel from "./components/H5Carousel/index.vue";
import H5Description from "./components/H5Description/index.vue";
import H5Dialog from "@/components/H5/H5Dialog/index.vue";
import H5Empty from "@/components/H5/H5Empty/index.vue";
import H5EmptyInitSkeleton from "./components/H5EmptyInitSkeleton/index.vue";
import H5SendSuccess from "./components/H5SendSuccess/index.vue";
import H5PriceInterval from "./components/H5PriceInterval/index.vue";
import H5Protection from "./components/H5Protection/index.vue";
import H5SourcingFailed from "./components/H5SourcingFailed/index.vue";

import H5SourcingDialog from "./components/H5SourcingDialog/index.vue";
import H5Sourcing from "./components/H5Sourcing/index.vue";
import KeywordsMiniHeader from "@/components/H5/KeywordsMiniHeader/index.vue";
import {
  computed,
  nextTick,
  onBeforeUnmount,
  onMounted,
  onUnmounted,
  onUpdated,
  reactive,
  ref,
  watch,
} from "vue";
import { fetchData } from "../../../../utils/fetch";
import {
  buriedPointUtil,
  copyText,
  gaAnalytics,
  waitForIntercom,
} from "../../../../utils/utils";
import { useCommonToast } from "@/hooks/useCommonToast";

const { toast } = useCommonToast();

const router = useRouter();
const route = useRoute();

const store = useStore();
const offerId = route.params.id;

const showInitSkeleton = ref(true);
const isExpanded = ref(false);
const shouldShowExpandBtn = ref(false);
const textContent = ref(null);
const soldOut = ref("");
const currentCountry = ref({});
const descList = ref([]);
const descAllList = ref([]);
const imageList = ref([]);
const productImageList = ref(null);
const isDesExpanded = ref(false);
const showH5DescriptionPopup = ref(false);
const showH5SourcingPopup = ref(false);
const initShowH5SourcingDialog = ref(false);

// 1-搜品审核中，2-搜品成功，3-搜品失败，4-可发起搜品
const sourcingStatusInfo = ref({
  status: null,
  message: "",
});

const newParam = reactive({
  path: "",
});
const innerHeight = ref(0);
const offerTitleRef = ref(null);
const init = ref(true);

const searchedEnough = ref(false);

const whatsAppBottomSheet = ref(false)
const whatsAppUrl = ref('')
const whatsAppNumber = ref('')

const offerData = computed(() => store.state.offerDetail);
const searchFoucs = computed(() => store.state.h5Module.searchFoucs);
const currencyData = computed(() => store.state.currencyData);
const countryObj = computed(() => store.state.currentCountry);
const countryList = computed(() => store.state.countryInfo);
const isEmpty = computed(() => store.state.emptyObj);
const h5SendSuccess = computed(() => store.state.h5SendSuccess);

watch([countryList, countryObj], (newData) => {
  if (newData) {
    const country = !!localStorage.getItem("setCurrentCountry")
      ? JSON.parse(localStorage.getItem("setCurrentCountry"))
      : "";
    if (country) {
      currentCountry.value = country;
      return;
    }
    if (!!newData[1]) {
      currentCountry.value = newData[1];
      return;
    }
    currentCountry.value = newData[0][0];
  }
});

watch(isEmpty, (newData) => {
  if (newData) {
    isEmpty.value = newData;
  }
});

watch(searchFoucs, (newData) => {
  if (newData) {
    searchFoucs.value = newData;
  }
});

onMounted( async () => {
  innerHeight.value = window.innerHeight;
  gaAnalytics("mp_product_detail_page_view", "mp_product_detail_page_view", {
    spuId: offerId,
  });
  buriedPointUtil({ eventId: "MPEX101", others: { offerIdList: [offerId] } });
  // buriedPointUtil({ eventId: 'MPWH101' })
  // gaAnalytics('mp_page_view', "mp_page_view", { bdUserId: localStorage.getItem('dsp.uid') });
  await getDetail();
  buriedEmail();
  window.addEventListener("scroll", handleScroll);
  if(document.querySelector(".homeview-footer")) {
    document.querySelector(".homeview-footer").style.paddingBottom = `178px`;
  }
});

onUnmounted(() => {
  if(document.querySelector(".homeview-footer")) {
    document.querySelector(".homeview-footer").style.paddingBottom = `0px`;
  }
})

onMounted(() => {
  const observer = new IntersectionObserver(
    (entries, observer) => {
      if (entries[0].isIntersecting) {
        getDetailImageList(); // 调用 API
        observer.disconnect(); // 只触发一次，避免重复请求
      }
    },
    {
      root: null, // 视口为浏览器窗口
      threshold: 0.5, // 50% 可见时触发
    }
  );

  if (offerTitleRef.value) {
    observer.observe(offerTitleRef.value);
  }
});

const getSourcingStatus = async () => {
  const { code, data } = await fetchData(
    `/appb/alibaba-common/offerDetail/sourcingStatus/${offerId}`
  );
  if (code === 200) {
    sourcingStatusInfo.value = data;
  }
};

onBeforeUnmount(() => {
  window.removeEventListener("scroll", handleScroll);
});

const showMiniHeader = ref(false);
let lastScrollTop = 0;
const handleScroll = () => {
  const scrollTop =
    document.documentElement.scrollTop || document.body.scrollTop;

  if (scrollTop > 314 && scrollTop > lastScrollTop) {
    // 向下滚动，显示小头部
    showMiniHeader.value = true;
  } else if (scrollTop < lastScrollTop) {
    // 向上滚动，显示大头部
    showMiniHeader.value = false;
  }
  lastScrollTop = scrollTop;
};

const buriedEmail = () => {
  const { emailType = "", email_id = "", orderNo = "" } = route.query;
  if (!!emailType) {
    buriedPointUtil({ eventId: "MPPD501", emailId: atob(email_id) });
    !!orderNo && waitForIntercom(orderNo);
  }
};

const getDetailImageList = async () => {
  init.value = false;
  try {
    const { code, data } = await fetchData(
      "/dsp-app/alibaba-common/searchProductImages",
      {
        method: "post",
        body: JSON.stringify({
          offerId,
        }),
      }
    );
    if (code === 200) {
      productImageList.value = data;
    }
    init.value = false;
  } catch (error) {
    init.value = false;
  }
};

const getDetail = async () => {
  await getSourcingStatus()
  try {
    showInitSkeleton.value = true;
    // store.commit('setLoading', true)
    const { code, data } = await fetchData(
      "/dsp-app/alibaba-common/searchProductDetail",
      {
        method: "post",
        body: JSON.stringify({
          offerId,
        }),
      }
    );
    if (code === 200) {
      store.commit("setLoading", false);
      store.commit("setOfferDetail", data);
      shouldShowExpandBtn.value = data?.subjectTrans.length > 70;
    }
    if (code === 500) {
      store.commit("setLoading", false);
      searchedEnough.value = false;
    }
    if (code === 401) {
      showInitSkeleton.value = true;
      return;
    }
    showInitSkeleton.value = false;
  } catch (error) {
    showInitSkeleton.value = false;
    store.commit("setLoading", false);
  }
};

const toggleExpand = () => {
  isExpanded.value = !isExpanded.value;
};

watch(offerData, (newData) => {
  if (newData) {
    const { key } = route.query;

    soldOut.value = !!key ? atob(key) : newData?.soldOut;

    document.title = newData?.subjectTrans;

    descList.value = mergeAttributes(newData?.productAttribute || []).slice(
      0,
      3
    );
    descAllList.value = mergeAttributes(newData?.productAttribute || []);
    // htmlContent.value = newData?.description
    imageList.value = newData?.productImage?.images;
    if (newData?.subjectTrans.length > 170) {
      shouldShowExpandBtn.value = true;
    }
    getFirstrAttributesImages(newData?.productSkuInfos);
  }
});

const getFirstrAttributesImages = (productSkuInfos) => {
  if (!productSkuInfos) {
    return [];
  }
  const colorAttributes = productSkuInfos?.map((sku) => {
    return sku.skuAttributes[0]; // 获取每个 SKU 中的第一个 skuAttribute（颜色）
  });
  const uniqueColors = colorAttributes?.filter((value, index, self) => {
    return index === self.findIndex((t) => t.valueTrans === value.valueTrans);
  });
  if (!uniqueColors) {
    return;
  }
  const imagesList = [];
  uniqueColors.forEach((v) => {
    imagesList.push(v.skuImageUrl);
  });
  store.commit("setFirstrAttributesimages", imagesList);
};

const mergeAttributes = (arr) => {
  const result = {};

  // 遍历输入数组
  arr.forEach((item) => {
    const {
      attributeId,
      attributeName,
      attributeNameTrans,
      value,
      valueTrans,
    } = item;

    if (!result[attributeId]) {
      // 如果 attributeId 不在 result 对象中，初始化
      result[attributeId] = {
        attributeId,
        attributeName,
        attributeNameTrans,
        value,
        valueTrans: valueTrans,
      };
    } else {
      // 如果 attributeId 已存在，拼接 valueTrans
      result[attributeId].valueTrans += `, ${valueTrans}`;
    }
  });

  // 将结果对象转换为数组
  return Object.values(result);
};

const displayedImages = computed(() => {
  if (!productImageList.value) return;
  const list = productImageList.value.length
    ? productImageList.value
    : imageList.value;
  if (isDesExpanded.value) {
    return list;
  }
  return list.slice(0, 2);
});

const toggleDescExpand = () => {
  isDesExpanded.value = !isDesExpanded.value;
};

const handleClickSeo = () => {
  window.open("https://share.seoant.com/app/1166f3dc2789866KFZ");
};

const handleH5DescriptionPopup = () => {
  showH5DescriptionPopup.value = true;
  document.querySelector("body").classList.add("no-scroll");
};

const closeH5Description = () => {
  showH5DescriptionPopup.value = false;
  document.querySelector("body").classList.remove("no-scroll");
};

const handleH5SourcingPopup = () => {
  if (sourcingStatusInfo.value?.status !== 2 && sourcingStatusInfo.value?.status !== 4 && sourcingStatusInfo.value?.status !== 1) {
    return
  }
  if (searchedEnough.value) {
    toast(
      "You've searched enough for today, come back tomorrow and recharge!",
      { type: "warning" }
    );
    return;
  }
  initShowH5SourcingDialog.value = true;
  showH5SourcingPopup.value = true;
  nextTick(() => {
    const intercom = document.querySelector(".intercom-lightweight-app");
    if (intercom) {
      intercom.style.display = "none"; // 隐藏元素
    }
  });
  newParam["path"] = "ship";
  document.querySelector("body").classList.add("no-scroll");
};

const closeH5Sourcing = async (refresh) => {
  showH5SourcingPopup.value = false;
  document.querySelector("body").classList.remove("no-scroll");
  if(refresh) {
    await getSourcingStatus()
  }
};

const showH5Sourcing = () => {
  handleH5SourcingPopup();
  if (sourcingStatusInfo.value?.status === 4 || sourcingStatusInfo.value?.status === 2 || sourcingStatusInfo.value?.status === 1) {
    document.querySelector("body").classList.add("no-scroll");
  }
};

const showArrangement = () => {
  const list = offerData.value?.productSkuInfos?.[0].skuAttributes || [];
  let isShowArrangement = false;
  list.forEach((v) => {
    if (v.attributeNameTrans.length >= 10) {
      isShowArrangement = true;
    }
  });
  return isShowArrangement;
};

const closeMask = (e) => {
  store.commit("h5Module/setPressProductSearchVisible", false);
};

const handleFind = (type, imageUrl) => {
  if (type === "similar") {
    buriedPointUtil({ eventId: "MPOP201" });
  } else {
    buriedPointUtil({ eventId: "MPOP202" });
  }

  closeMask();
  store.commit("h5Module/closeImageSearchDialog");
  nextTick(() => {
    store.commit("h5Module/showImageSearchDialog", {
      type,
      imageUrl,
      imageId: 0,
    });

    const url = `/search?offerId=${offerData.value.offerId}&type=${type}`;
    if (route.fullPath.includes("imageId")) {
      router.replace(url);
    } else {
      router.push(url);
    }
  });
};

const clickCount = ref(0)
const handleOrderAmount = async () => {
  buriedPointUtil({ eventId: "MPPD203" });
  clickCount.value++
  const { code, data } = await fetchData(
    `/appb/alibaba-inter/redirectView`,
    {
      method: "post",
      body: JSON.stringify({
        offerId,
        code: 1
      }),
    }
  );
  if (code === 200) {
    buriedPointUtil({ eventId: "MPPD603" });
    const res = window.open(data, '_blank');
    if (res === null || clickCount.value === 2) {
      clickCount.value = 0
      whatsAppBottomSheet.value = true
      whatsAppUrl.value = data
      const url = new URL(data)
      const number = url.searchParams.get('phone')
      whatsAppNumber.value = number
    }
  }
}

const handleCopyLink = () => {
  copyText(whatsAppUrl.value).then(() => {
    toast('Copy Successfully')
  })
}
</script>

<style>
@import "./index.less";
</style>