<template>
  <div class="h5-carousel">
    <div class="h5-carousel-main">
      <div class="main-swiper">
        <swiper @slideChange="onSlideChange" @swiper="setMainSwiper" effect='slide' grabCursor centeredSlides
          slidesPerView='1' :modules="[Thumbs]" :centerInsufficientSlides="true" :loop="false" :thumbs="{}">
          <swiper-slide v-for="(image, index) in images" :key="index">
            <img :src="image" :alt="'Image ' + index" />
            <div class="h5-offer-tool">
              <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-findsmilar-icon.svg" alt=""
                @click="handleFind('similar', image)">
              <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-findstyle-icon.svg" alt=""
                @click="handleFind('styles', image)">
            </div>
          </swiper-slide>
        </swiper>
        <div class="toggle-bar" :class="{ 'single-images': stylesImages.length === 0 }" @click="handleToggleClick">
          <div class="toggle-bar-transition" :style="{ left: isImages ? '2px' : 'calc(50% - 2px)' }"></div>
          <div class="toggle-bar-left">Images</div>
          <div v-if="stylesImages.length" class="toggle-bar-right">Styles</div>
        </div>
        <div class="page-nums">
          <template v-if="isImages">{{ `${mainActiveIndex + 1}/${productImageLength}` }}</template>
          <template v-else>{{ `${activeIndex + 1}/${stylesImages.length}` }}</template>
        </div>
      </div>

      <div class="h5-thumbnails">
        <!-- 微缩图轮播容器 -->
        <div class="h5-thumbnails-main">
          <!-- 存在缩略图 -->
          <template v-if="stylesImages.length">
            <div class="split-line"></div>
            <div @click="mainImageClick" class="thumbnails-container-mainimage"
              :class="{ 'deactiveStyle': activeIndex !== -1 }">
              <img :src="images[0]" alt="" />
            </div>
            <div class="mySwiperThumbs">
              <swiper :slides-per-view="5" :modules="[Thumbs]" @swiper="setThumbsSwiper">
                <swiper-slide @click="handleThumbsClick(index)" v-for="(image, index) in stylesImages"
                  :key="'thumb-' + index" class="h5-thumbnail" :class="{ 'activeStyle': index === activeIndex }">
                  <img :src="image" :alt="'Thumbnail ' + index" />
                </swiper-slide>
              </swiper>
            </div>
            <div class="thumbnails-right" @click="handleThumbnailsClick">
              <img src="@/assets/H5/offer/right.svg" alt="">
            </div>
          </template>
          <!-- 只有主图 -->
          <template v-else>
            <div @click="mainImageClick(index)" v-for="(img, index) in images" :key="index"
              class="thumbnails-container-mainimage onlyMainImage"
              :class="{ 'activeStyle': mainActiveIndex === index, 'deactiveStyle': mainActiveIndex !== index }">
              <img :src="img" alt="" />
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="h5Carousel-line"></div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed, watch, nextTick } from 'vue';
import { Thumbs } from 'swiper/modules';
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import { buriedPointUtil } from "@/utils/utils";
import { useRoute, useRouter } from 'vue-router';

import { useStore } from 'vuex';
const router = useRouter();
const route = useRoute();

const store = useStore();
const images = ref([]);
const currentIndex = ref(0);
const boxes = ref([]);
const startTouchX = ref(0);  // 用于记录触摸开始时的 X 坐标
const endTouchX = ref(0);
const moveDistance = ref(0);

const scrollThumbnailsContainer = ref(null)

const offerData = computed(() => store.state.offerDetail);
const firstrAttributesimages = computed(() => store.state.firstrAttributesimages);

const currentHoverdImage = computed(() => store.state.currentHoverdImage);

const props = defineProps({
  showH5Sourcing: Function
})

const showH5Sourcing = props.showH5Sourcing

const handleThumbnailsClick = () => {
  showH5Sourcing()
}

const thumbsSwiper = ref(null); // 缩略图swiper实例
const mainSwiper = ref(null); // 主图swiper实例

// 绑定缩略图 Swiper 实例
const setThumbsSwiper = (swiper) => {
  if (swiper) {
    thumbsSwiper.value = swiper;
  }
};

// 绑定主图 Swiper 实例
const setMainSwiper = (swiper) => {
  if (swiper) {
    mainSwiper.value = swiper;
  }
}

const stylesImages = ref([]) // 款式图集合
const productImageLength = ref(0) // 产品图数量

watch([offerData, firstrAttributesimages], (newData) => {
  if (newData[0] && newData[1]) {
    const uniqueArr = [...newData[0]?.productImage?.images, ...new Set([...newData[1].filter(item => item !== null)])];
    // const uniqueArr = newData[0]?.productImage?.images
    images.value = uniqueArr || [];
    stylesImages.value = [...new Set([...newData[1].filter(item => item !== null)])]
    productImageLength.value = newData[0]?.productImage?.images?.length
  }
});

watch(currentIndex, (newIndex, oldIndex) => {
  scrollToIndex(newIndex);
})


watch(currentHoverdImage, (newData) => {
  if (newData) {
    const _index = images.value.findIndex(v => v === newData)
    if (_index !== -1) {
      currentIndex.value = _index
      currentHoverdImage.value = newData
    }
  }
});


const activeIndex = ref(-1) // -1: 默认显示主图，从0开始表示缩略图索引。
const mainActiveIndex = ref(0) // 主图索引

const onSlideChange = (swiper) => {
  const realIndex = swiper.realIndex;
  const onlyMainImages = stylesImages.value.length === 0;
  mainActiveIndex.value = realIndex;
  if (onlyMainImages) {
    mainSwiper.value.slideTo(mainActiveIndex.value);
    return;
  };

  if (!thumbsSwiper.value) return;
  if (realIndex > productImageLength.value - 1) {
    // 微缩轮播图激活的坐标
    const _index = realIndex - productImageLength.value;
    const isNext = _index > activeIndex.value; // 是否向右滑动
    activeIndex.value = _index;
    isImages.value = false
    if (_index > 3) {
      if (isNext) {
        thumbsSwiper.value.slideNext()
      } else {
        thumbsSwiper.value.slidePrev()
      }
    }
    if (_index === 1) { // 保证第一个缩略图完整显示
      thumbsSwiper.value.slidePrev()
    }
  } else {
    isImages.value = true
    activeIndex.value = -1;
    thumbsSwiper.value.slideTo(0, 0); // 子轮播图回到初始位置
  }
}

const isImages = ref(true)
const handleToggleClick = () => {
  isImages.value = !isImages.value

  if (isImages.value) {
    activeIndex.value = -1
    mainSwiper.value.slideTo(0, 0);
  } else {
    activeIndex.value = 0;
    const firstStyleImageIndex = productImageLength.value
    mainSwiper.value.slideTo(firstStyleImageIndex, 0);
  }
}

const mainImageClick = (index = 0) => {
  // 是否只有主图轮播
  const onlyMainImages = stylesImages.value.length === 0;
  if (onlyMainImages) {
    mainActiveIndex.value = index;
    mainSwiper.value.slideTo(index);
  } else {
    isImages.value = true;
    activeIndex.value = -1;
    mainSwiper.value.slideTo(0, 0);
  }
}

const handleThumbsClick = (index) => {
  isImages.value = false;
  const realIndex = productImageLength.value + index; // 在主图中的坐标

  // 从主图切换关闭动画
  if (activeIndex.value === -1) {
    mainSwiper.value.slideTo(realIndex, 0);
  } else {
    mainSwiper.value.slideTo(realIndex);
  }
}

// 自动切换图片
let interval = null;

// 滚动轮播图
const scrollToIndex = (index) => {
  const scrollThumbnailsContainerEle = scrollThumbnailsContainer.value;
  const items = scrollThumbnailsContainerEle.children; // 所有子元素
  const itemWidth = items[0].offsetWidth; // 单个图片的宽度（含间距）
  const containerWidth = scrollThumbnailsContainerEle.clientWidth; // 容器的可见宽度
  const currentItem = items[index]; // 当前索引对应的元素
  const itemLeft = currentItem.offsetLeft; // 当前块的左侧距离容器的左边缘距离
  const itemRight = itemLeft + itemWidth; // 当前块的右边界距离容器的左边缘距离

  // 判断是否需要滚动
  const isOutOfView = itemLeft < scrollThumbnailsContainerEle.scrollLeft ||
    itemRight > scrollThumbnailsContainerEle.scrollLeft + containerWidth;

  if (isOutOfView) {
    // 将当前块移动到容器的中间位置
    const targetScroll = itemLeft - (containerWidth - itemWidth) / 2;
    scrollThumbnailsContainerEle.scrollTo({
      left: targetScroll,
      behavior: "smooth", // 平滑滚动
    });
  }
};

const startAutoPlay = () => {
  interval = setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % images.value.length;
  }, 3000); // 每3秒切换
};

const stopAutoPlay = () => {
  if (interval) {
    clearInterval(interval);
  }
};

// 切换到上一个图片
const prevImage = () => {
  store.commit('setCurrentHoverdImage', '')
  currentIndex.value =
    (currentIndex.value - 1 + images.value.length) % images.value.length;
};

// 切换到下一个图片
const nextImage = () => {
  store.commit('setCurrentHoverdImage', '')
  currentIndex.value = (currentIndex.value + 1) % images.value.length;
};

// 跳转到指定图片
const goToImage = (index) => {
  store.commit('setCurrentHoverdImage', '')
  currentIndex.value = index;
};

// 获取当前图片项的样式
const getItemStyle = (index) => {
  return {
    transform: `translateX(${(index - currentIndex.value) * 100}%)`,
    transition: 'transform 0.5s ease-in-out',
    width: `${document?.body.offsetWidth}px`
  };
};

// 动态计算轮播图容器的宽度
const carouselStyle = computed(() => ({
  width: `${images.value.length * 100}%`,
  transition: 'transform 0.5s ease-in-out',
  height: `${document?.body.offsetWidth}px`
}));

// 处理触摸开始事件
const handleTouchStart = (event) => {
  startTouchX.value = event.touches[0].clientX;  // 获取触摸开始时的 X 坐标
};

const moveTouch = (event) => {
  if (startTouchX.value) {
    moveDistance.value = event.touches[0].clientX - startTouchX.value; // 计算滑动的距离
    // 根据滑动的距离来动态更新图片位置
    boxes.value.forEach((box, idx) => {
      const translateX = (idx - currentIndex.value) * 100 + (moveDistance.value / window.innerWidth) * 100;
      box.style.transform = `translateX(${translateX}%)`;
      box.style.transition = 'none'; // 滑动时移除动画
    });
  }
};

// 处理触摸结束事件
const handleTouchEnd = (event) => {
  endTouchX.value = event.changedTouches[0].clientX;  // 获取触摸结束时的 X 坐标
  if (startTouchX.value - endTouchX.value > 50) {
    nextImage();  // 向左滑动，切换到下一张
  } else if (endTouchX.value - startTouchX.value > 50) {
    prevImage();  // 向右滑动，切换到上一张
  }
};

const closeMask = (e) => {
  store.commit('h5Module/setPressProductSearchVisible', false)
}


const handleFind = (type, imageUrl) => {
  if (type === 'similar') {
    buriedPointUtil({ eventId: "MPOP201" });
  } else {
    buriedPointUtil({ eventId: "MPOP202" });
  }

  closeMask()
  store.commit('h5Module/closeImageSearchDialog')
  nextTick(() => {
    store.commit('h5Module/showImageSearchDialog', {
      type,
      imageUrl,
      imageId: 0,
    })

    const url = `/search?offerId=${offerData.value.offerId}&type=${type}`
    if (route.fullPath.includes('imageId')) {
      router.replace(url)
    } else {
      router.push(url)
    }
  })
}

onMounted(() => {
  // startAutoPlay(); // 开始自动切换图片
});

onBeforeUnmount(() => {
  // stopAutoPlay(); // 清理定时器
});
</script>

<style lang="less">
@import './index.less';
</style>
