<template>
  <div class="pay-now-container" @click.stop>
    <div class="body">
      <div class="payment-price">
        <div class="text">Awaiting Payment</div>
        <div class="price">
          <IntlCurrency :count="data?.b2bOrderVo?.totalAmountUsd" :size="24" currency="us" color="#052785" />
        </div>
      </div>

      <div class="payment-methods">Payment Methods</div>
      <div class="sub-text">All transactions are secure and encrypted.</div>

      <div class="payment-options">
        <div @click="choosePaypal" class="option" :class="{ active: activeIndex === 1, disabled: disabledPaypal }">
          <div class="radio">
            <img v-if="activeIndex === 1" src="@/assets/H5/order/radio-selected.svg" alt="">
            <img v-else src="@/assets/H5/order/radio.svg" alt="">
          </div>
          <div class="image">
            <img src="@/assets/H5/order/paypal.svg" alt="">
          </div>
          <div class="desc">Pay with PayPal</div>
        </div>
        <div @click="activeIndex = 2" class="option" :class="{ active: activeIndex === 2 }">
          <div class="radio">
            <img v-if="activeIndex === 2" src="@/assets/H5/order/radio-selected.svg" alt="">
            <img v-else src="@/assets/H5/order/radio.svg" alt="">
          </div>
          <div class="image">
            <img src="@/assets/H5/order/pay-with-bank.svg" alt="">
          </div>
          <div class="desc">Pay with Bank</div>
        </div>
      </div>

      <div class="secure-payments">
        <div class="content">
          <div class="text">
            <div class="title">Secure Payments</div>
            <div class="desc">Each payment you make on Bestdealer is secured with strict SSL encryption and PCI DSS data protection protocols.</div>
          </div>
          <div class="image">
            <img src="@/assets/H5/order/secure-payment.svg" alt="">
          </div>
        </div>
      </div>
      <div v-if="activeIndex === 1" style="height: 156px;"></div>
      <div v-if="activeIndex === 2" class="split-line"></div>
      <!-- paypal支付 -->
      <div 
        class="paypal-button-container"
        ref="paypalButtonContainer" 
        v-show="activeIndex === 1">
      </div>
      <!-- 银行支付 -->
      <div v-if="activeIndex === 2" @click="handlePayNow" class="bottom-button">Pay Now</div>
    </div>

    <PaypalSuccess 
      v-if="paypalSuccessVisible"
      @close="paypalSuccessVisible = false" />
  </div>
</template>

<script setup>
  import { onMounted, ref } from 'vue';
  import { fetchData } from '@/utils/fetch';
  import PaypalSuccess from './PaypalSuccess.vue';
  import { useRoute, useRouter } from 'vue-router';
  import { gaAnalytics, buriedPointUtil } from '@/utils/utils';
  const emits = defineEmits(['bankPayment'])

  const props = defineProps({
    data: {
      type: Object,
      default: () => {}
    },
    paypalInstance: {
      type: Object,
      default: () => {}
    }
  })

  const router = useRouter();

  const route = useRoute()

  const paypalSuccessVisible = ref(false);

  const activeIndex = ref(1); // 1 paypal  2 bank

  const paypalButtonContainer = ref(null)

  const disabledPaypal = ref(false)

  const handlePayNow = () => {
    buriedPointUtil({ eventId: 'MPPP202' })
    emits('bankPayment')
  }

  const tradeCode = ref('')

  const initPaypal = async () => {
    props.paypalInstance.Buttons({
      style: {
        layout: "vertical", // 设置按钮水平排列
        shape: "pill", // 圆形按钮
        size: "small", // 自适应大小
        tagline: false, // 隐藏标签
      },
      createOrder: async () => { // 创建订单，返回一个订单 ID，供 PayPal 处理
        const { code, data } = await fetchData('/appb/b2b/order/createOrderPay', {
          method: 'POST',
          body: JSON.stringify({
            orderNo: props.data.b2bOrderVo.orderNo,
            paymentMethodCode: 'PAYPAL_STANDARD_B2B',
            transactionNo: ''
          })
        })

        buriedPointUtil({ eventId: "MPPP201", others: { type: 'PayPal' } });

        if (code === 200) {
          tradeCode.value = data.tradeCode;
          return data.thirdTradeCode
        }
      },
      onApprove: async (data) => {
        const { code } = await fetchData('/appb/b2b/order/completePayAction', {
          method: 'POST',
          body: JSON.stringify({
            tradeCode: tradeCode.value,
            orderNo: props.data.b2bOrderVo.orderNo
          })
        })

        if (code === 200) {
          paypalSuccessVisible.value = true;
          gaAnalytics('mp_pay_success', 'mp_pay_success')
        }
          
      },
      onCancel: (data) => {
        // if (route.fullPath === '/orders') {
        //   location.reload();
        // } else {
        //   router.push('/orders')
        // }
        console.log("用户取消支付", data);
      },
      onError: (err) => {
        // if (route.fullPath === '/orders') {
        //   location.reload();
        // } else {
        //   router.push('/orders')
        // }
        console.error("支付失败", err);
      },
    }).render(paypalButtonContainer.value);
  }

  const choosePaypal = () => {
    if (disabledPaypal.value) return;
    activeIndex.value = 1;
  }

  onMounted(() => {
    initPaypal()
    buriedPointUtil({ eventId: 'MPPP502' })

    
    if (route.fullPath.includes('fromEmail') && props.data.b2bOrderVo.totalAmountUsd < 200) {
      activeIndex.value = 2
    }

    if (props.data.b2bOrderVo.totalAmountUsd > 200) {
      activeIndex.value = 2
      disabledPaypal.value = true;
    }
  })
</script>

<style lang="less" scoped>
.pay-now-container {
  position: relative;
  height: 100%;
  overflow: auto;

  .body {
    background-color: white;
    border-radius: 8px;

    .payment-price {
      text-align: center;
      padding: 0 12px;

      .text {
        color: #5c5c5c;
        font-size: 12px;
        font-family: mon-500;
        line-height: 16px;
        margin-top: 24px;
      }

      .price {
        margin-top: 4px;
      }
    }

    .payment-methods {
      margin: 24px 0 0 16px;
      color: #232323;
      font-size: 14px;
      font-family: mon-700;
      line-height: 18px;
    }

    .sub-text {
      margin: 4px 0 0 16px;
      color: #5c5c5c;
      font-size: 12px;
      font-family: mon-500;
      line-height: 16px;
    }

    .payment-options {
      margin-top: 16px;
      padding: 0 16px;

      .option {
        display: flex;
        align-items: center;
        border: 1px solid #ebf1f5;
        border-radius: 8px;
        height: 64px;

        &:last-child {
          margin-top: 12px;
        }

        &.active {
          border: 1px solid #232323;
        }

        &.disabled {
          opacity: .5;
        }

        .radio {
          margin-left: 12px;
          width: 20px;
          height: 20px;
        }

        .image {
          width: 75px;
          height: 32px;
          margin-left: 12px;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .desc {
          color: #232323;
          font-size: 13px;
          font-family: mon-600;
          margin-left: 10px;
        }
      }
    }

    .secure-payments {
      margin-top: 24px;
      padding: 0 16px;

      .content {
        display: flex;
        align-items: center;
        background-color: #f5f5f5;
        border-radius: 6px;
        padding: 16px;
        .text {
          flex: 1;
          .title {
            color: #232323;
            font-size: 14px;
            font-family: mon-600;
            line-height: 18px;
          }
          .desc {
            color: #5c5c5c;
            font-size: 12px;
            font-family: mon-500;
            line-height: 16px;
            margin-top: 8px;
          }
        }
        .image {
          width: 80px;
          height: 80px;
          margin-left: 8px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .split-line {
      position: absolute;
      left: 12px;
      right: 12px;
      bottom: 76px;
      height: 0.5px;
      background-color: #e4e6eb;
    }

    .bottom-button {
      position: absolute;
      left: 16px;
      right: 16px;
      bottom: 16px;
      height: 44px;
      background-color: #4681f9;
      border-radius: 22px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-size: 14px;
      font-family: mon-600;
    }
  }
}

.paypal-button-container {
  position: fixed;
  bottom: 12px;
  left: 28px;
  right: 28px
}
</style>