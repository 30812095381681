
<template>
  <div class="h5-detail-empty">
    <div class="h5-detail-main" :style="{height: `${initHeight}px`}">
      <div class="h5-detail-main-center">
        <div class="h5-detail-main-center-content"></div>
      </div>
      <div class="h5-detail-main-right"></div>
    </div>
    <div class="h5-detail-thmail">
      
    </div>
    <div class="h5-detail-thmail-line"></div>
    <div class="h5-detail-bottom">
      <div class="h5-detail-bottom-1"></div>
      <div class="h5-detail-bottom-2"></div>
      <div class="h5-detail-bottom-3"></div>
    </div>
    <div class="h5-detail-bottom-line"></div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const initHeight = ref(window.innerWidth)

</script>

<style lang="less">
@import './index.less';
</style>