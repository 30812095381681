import { fetchData } from "./fetch";
import OSS from 'ali-oss';

export const parseUrlParams = () => {
  // 获取问号后面的查询参数部分
  const url = window.location.search;
  const queryString = url.split("?")[1];

  // 如果没有查询参数，返回空对象
  if (!queryString) {
    return {};
  }

  // 将查询参数部分拆分为键值对
  const params = queryString.split("&");
  const result = {};

  params.forEach((param) => {
    const [key, value] = param.split("=");
    result[decodeURIComponent(key)] = decodeURIComponent(value || "");
  });

  return result;
};
/**
 * 设置 过期时间 过期返回false 没过期返回true
 * time 基础一天
 *
 */
export function timelocalStorage(value, time) {
  let status = true;
  const newData = new Date().getTime(); // 当前时间
  const local = Number(localStorage.getItem(`${value}`));
  if (local === 0 || (local > 0 && local <= newData)) {
    // 不存在 或者 存在过期了 设置新时间
    localStorage.setItem(`${value}`, (newData + time * 86400000).toFixed(0));
  } else if (local > 0 && local > newData) {
    status = false;
  }
  return status;
}

export function hanleEventName(value) {
  return value
    .replace(/\//g, "")
    .replace(/ {2}/g, " ")
    .replace(/-/g, "_")
    .replace(/'/g, "_")
    .replace(/&/g, "_")
    .replace(/\?/g, "_")
    .replace(/\//g, "_")
    .replace(/:/g, "_")
    .replace(/,/g, "_");
}

const apiUrl = import.meta.env.VITE_API_URL;
/**
 *
 * @param {*} name 事件名称
 * @param {*} category 事件分类
 * @param {*} ext 附加属性 {}
 */
export const gaAnalyticsMore = async (name, category, ext) => {
  try {
    const uid = localStorage.getItem("dsp.uid");
    gtag("event", `${name}`, {
      event_category: `${category}`,
      event_label: `${name}`,
      bdUserId: `${uid}`,
      ...ext,
      ...parseUrlParams(),
      host_name: window.location.host,
      urlsearch: window.location.search,
    }); // eslint-disable-line
  } catch (err) {}
};
/**
 *
 * @param {*} name 事件名称
 * @param {*} category 事件分类
 * @param {*} status 为true时
 */
export const gaAnalytics = async (names, categorys, ext) => {
  const name = hanleEventName(names).split(" ").join("_");
  const category = hanleEventName(categorys).split(" ").join("_");

  gaAnalyticsMore(name, category, ext);
};

const getOstype = () => {
  let os = "Unknown OS";
  let userAgent = window.navigator.userAgent;

  if (userAgent.indexOf("Windows NT") !== -1) {
    os = "Windows";
  } else if (userAgent.indexOf("Macintosh") !== -1) {
    os = "macOS";
  } else if (userAgent.indexOf("Linux") !== -1) {
    os = "Linux";
  } else if (userAgent.indexOf("Android") !== -1) {
    os = "Android";
  } else if (
    userAgent.indexOf("iPhone") !== -1 ||
    userAgent.indexOf("iPad") !== -1
  ) {
    os = "iOS";
  }
  return os;
};

const getBrowser = () => {
  let userAgent = window.navigator.userAgent;
  let browser = "Unknown Browser";

  // 检查不同的浏览器标识符
  if (userAgent.indexOf("Firefox") !== -1) {
    browser = "Mozilla Firefox";
  } else if (userAgent.indexOf("Edg") !== -1) {
    browser = "Microsoft Edge";
  } else if (
    userAgent.indexOf("Chrome") !== -1 &&
    userAgent.indexOf("Edg") === -1 &&
    userAgent.indexOf("OPR") === -1
  ) {
    browser = "Google Chrome";
  } else if (
    userAgent.indexOf("Safari") !== -1 &&
    userAgent.indexOf("Chrome") === -1
  ) {
    browser = "Safari";
  } else if (
    userAgent.indexOf("OPR") !== -1 ||
    userAgent.indexOf("Opera") !== -1
  ) {
    browser = "Opera";
  } else if (
    userAgent.indexOf("MSIE") !== -1 ||
    userAgent.indexOf("Trident") !== -1
  ) {
    browser = "Internet Explorer";
  }
  return browser;
};

export const buriedPointUtil = async (props) => {
  const { uid, eventId, ...others } = props;
  if (localStorage.getItem("deviceId") === null) {
    localStorage.setItem("deviceId", "web" + Math.random().toString(36).substr(2));
  }
  await fetch(`${apiUrl}/buried-point/pushPoint/point`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      eventId,
      os: getOstype(),
      browser: getBrowser(),
      userId: localStorage.getItem("dsp.uid") || uid || "",
      deviceId: localStorage.getItem("deviceId") || "",
      detail: {
        url: window.location.href,
        ...others,
      },
    }),
  });
};

/**
 * 上传图片
 * @param {File} file 文件
 * @param {Number} size 文件大小(kb),超出开启压缩
 * @param {Number} quality 压缩质量 0 ~ 1之间
 */
export const uploadImage = async (file, size = 200, quality = 0.2) => {
  const { data } = await fetchData("/dsp-app/alibaba-common/ossCredentials");
  let BASE_URL = 'https://1688-upload-image.bestdealer.com'
  const ossOptions = {
    bucket: "1688images",
    region: "oss-us-east-1",
    accessKeyId: data.accessKeyId,
    accessKeySecret: data.accessKeySecret,
    stsToken: data.securityToken,
  };

  if (import.meta.env.VITE_APP_ENV === "development") {
    (ossOptions.bucket = "1688images-test"),
      (ossOptions.region = "oss-cn-shenzhen");
      BASE_URL = 'https://1688-upload-image.lerdex.co'
  }

  const client = new OSS(ossOptions);

  // 超出200kb开启压缩
  const isCompress = file.size > size * 1024;

  if (file.type.includes("webp")) {
    file = await resizeImage(file, 800);
    const { file: miniFile } = await compressImg(
      file,
      isCompress ? quality : 0.8
    );
    file = miniFile;
  } else {
    if (isCompress) {
      file = await resizeImage(file, 800);
      const { file: miniFile } = await compressImg(file, quality);
      file = miniFile;
    }
  }


  return new Promise((resolve, reject) => {
    client
      .put(file.name, file, {
        headers: {
          "Content-Type": file.type, // 动态设置文件类型
        },
      })
      .then((res) => {
        resolve({
          fileUrl: res.url?.replace(/https?:\/\/.*?aliyuncs\.com/, BASE_URL),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  });
};

/**
 * 图片压缩
 * @param {string} file 文件
 * @param {Number} quality 压缩质量 0 ~ 1之间
 */
export const compressImg = (file, quality) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const image = new Image();
      image.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        canvas
          .getContext("2d")
          .drawImage(image, 0, 0, image.width, image.height);
        const canvasURL = canvas.toDataURL("image/jpeg", quality); // 转成base64
        const buffer = atob(canvasURL.split(",")[1]); // 解码base64
        let length = buffer.length;
        const bufferArray = new Uint8Array(length);
        while (length--) {
          bufferArray[length] = buffer.charCodeAt(length);
        }
        const miniFile = new File([bufferArray], file.name, {
          type: "image/jpeg",
        });
        resolve({
          file: miniFile,
          origin: file,
          beforeSrc: e.target.result,
          afterSrc: canvasURL,
          beforeKB: Number((file.size / 1024).toFixed(2)),
          afterKB: Number((miniFile.size / 1024).toFixed(2)),
        });
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(file);
  });
};

/**
 * 图片等比缩小
 * @param {File} file 图片文件
 * @param {Number} maxWidth 最大宽度（单位：px）
 * @returns {Promise<File>} 返回调整后的文件
 */
export const resizeImage = (file, maxWidth) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const image = new Image();
      image.onload = () => {
        // 获取原始宽高
        const { width, height } = image;

        // 如果宽度超过最大宽度，进行缩放
        if (width > maxWidth) {
          const scale = maxWidth / width; // 计算缩放比例
          const newWidth = maxWidth;
          const newHeight = height * scale; // 等比缩放高度

          const canvas = document.createElement("canvas");
          canvas.width = newWidth;
          canvas.height = newHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, newWidth, newHeight);

          canvas.toBlob((blob) => {
            const miniFile = new File([blob], file.name, {
              type: "image/jpeg",
            });
            resolve(miniFile);
          }, "image/jpeg");
        } else {
          resolve(file); // 如果宽度不超过最大值，直接返回原文件
        }
      };
      image.src = e.target.result;
    };
    reader.readAsDataURL(file);
  });
};

// 防抖函数
export const debounce = (fn, delay) => {
  let timer;

  return function (...args) {
    // 清除上一次的定时器
    if (timer) {
      clearTimeout(timer);
    }

    // 设置新的定时器
    timer = setTimeout(() => {
      fn(...args); // 执行原函数
    }, delay);
  };
};

/**
 * 重定向路由
 * @param {String} additionalString 附加的查询字符串
 * @returns {String}
 */
export const redirectWithSearch = (additionalString = '') => {
  if(localStorage.getItem('tk')) {
    return `?${additionalString}`
  }
  const currentLocationStorageSearch = localStorage.getItem('searchUrl');

  // 将查询字符串解析为键值对对象
  const parseQueryString = (queryString) => {
    const params = new URLSearchParams(queryString.startsWith('?') ? queryString.slice(1) : queryString);
    return Array.from(params.entries()).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
  };

  // 将键值对对象转回查询字符串
  const stringifyQueryParams = (params) => {
    return new URLSearchParams(params).toString();
  };

  // 解析当前存储的参数
  const currentParams = currentLocationStorageSearch ? parseQueryString(currentLocationStorageSearch) : {};

  // 解析附加参数
  const additionalParams = additionalString ? parseQueryString(additionalString) : {};

  // 需要检查的关键参数
  const keysToCheck = ['offerId', 'keyword', 'imageId', 'email_id', 'orderNo', 'emailType', 'eventId'];

  // 判断 additionalString 是否包含关键参数
  const containsKeyToCheck = keysToCheck.some((key) => additionalParams.hasOwnProperty(key));

  // 如果包含关键参数，删除 currentParams 中的相关参数
  if (containsKeyToCheck) {
    keysToCheck.forEach((key) => delete currentParams[key]);
  }

  // 合并参数，优先 additionalParams
  const mergedParams = { ...currentParams, ...additionalParams };

  // 转换为查询字符串
  const mergedQueryString = stringifyQueryParams(mergedParams);

  // 返回结果
  return mergedQueryString ? `?${mergedQueryString}` : '';
};


export const waitForIntercom = (orderNo) => {
  const interval = setInterval(() => {
    if (window?.Intercom) {
      // 调用 Intercom 方法
      window.Intercom("showNewMessage", `Order Number: ${orderNo}, pay the order amount.`);
      clearInterval(interval); // 清除定时器
    }
  }, 100); // 每 100ms 检查一次
}


/**
 * 取出现次数的id
 * @param {Array}
 * @returns {number}
 */
export const findMostFrequentCategory = (rows) => {
  const categoryCount = { third: {}, second: {}, top: {} };

  // 统计每个 category 的出现次数
  rows.forEach(({ thirdCategoryId, secondCategoryId, topCategoryId }) => {
    if (thirdCategoryId !== null) {
      categoryCount.third[thirdCategoryId] = (categoryCount.third[thirdCategoryId] || 0) + 1;
    }
    if (secondCategoryId !== null) {
      categoryCount.second[secondCategoryId] = (categoryCount.second[secondCategoryId] || 0) + 1;
    }
    if (topCategoryId !== null) {
      categoryCount.top[topCategoryId] = (categoryCount.top[topCategoryId] || 0) + 1;
    }
  });

  // 获取每个 category 中出现最多的 id
  const getMostFrequentId = (categoryMap) => {
    return Object.entries(categoryMap).reduce((mostFrequentId, [id, count]) => {
      return count > (categoryMap[mostFrequentId] || 0) ? id : mostFrequentId;
    }, null);
  };

  // 按优先级顺序返回出现最多的 ID
  let mostFrequentId = getMostFrequentId(categoryCount.third);
  if (!mostFrequentId) mostFrequentId = getMostFrequentId(categoryCount.second);
  if (!mostFrequentId) mostFrequentId = getMostFrequentId(categoryCount.top);

  return mostFrequentId;
}


// 节流函数
export const throttle = (fn, delay) => {
  let timer;

  return function (...args) {
    if (timer) {
      clearTimeout(timer); // 清除之前的定时器
    }

    timer = setTimeout(() => {
      fn(...args); // 执行请求
    }, delay);
  };
}


//判断当前url上的lg是否匹配接口的语言list
export const checkHasQueryLanguage = (lg) => {
  const languageList = !!localStorage.getItem('languageList') ? JSON.parse(localStorage.getItem('languageList')) : [];
  const _index = languageList.findIndex(v => (v.language).toLowerCase() === lg.toLowerCase())
  if(_index !== -1) {
    return (lg)?.toLowerCase()
  }
  return false
}

export const copyText = (text) => {
  return new Promise((resolve, reject) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        resolve(text)
      }).catch(err => {
        reject(err)
      });
    } else {
      const input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('value', text);
      document.body.appendChild(input);
      input.setSelectionRange(0, 9999);
      if (document.execCommand('copy')) {
        document.execCommand('copy');
      }
      document.body.removeChild(input);
      resolve(text)
    }
  })
}

//银行家算法
export const bankersRound = (num, decimals) => {

  // 处理特殊值
  if (isNaN(num) || !isFinite(num)) {
    return num;
  }

  const factor = Math.pow(10, decimals);
  const value = num * factor;

  // 获取小数部分
  const decimal = value - Math.floor(value);

  // 如果小数部分恰好是0.5
  if (Math.abs(decimal - 0.5) < Number.EPSILON) {
    // 向最近的偶数舍入
    const floor = Math.floor(value);
    return ((floor % 2 === 0 ? floor : floor + 1) / factor).toFixed(decimals);
  }

  // 其他情况使用四舍五入
  return (Math.round(value) / factor).toFixed(decimals);
}

export const setThemeColor = (color) => {
  let metaTag = document.querySelector('meta[name="theme-color"]');
  if (!metaTag) {
    metaTag = document.createElement('meta');
    metaTag.setAttribute('name', 'theme-color');
    document.head.appendChild(metaTag);
  }
  metaTag.setAttribute('content', color);
}

