<template>
  <div class="offerDetailInfo">
    <div class="line"></div>
    <div class="offer-detail">
      <div class="offer-detail-title">Specifications</div>
      <div class="offer-detail-continer">
        <div v-for="(item, index) in descList" key="index" class="offer-detail-item">
          <div class="item-attributeNameTrans">
            <span class="item-attributeNameTrans-text">{{
              item?.attributeNameTrans
            }}</span>
          </div>
          <div class="item-valueTrans" @mouseenter="mouseValueTransMove(index)"
            @mouseleave="mouseValueTransleave(index)">
            <span class="item-attributeNameTrans-text">{{
              item?.valueTrans
            }}</span>
            <span class="item-valueTrans-hoverd" v-if="valueTransIndex === index && item?.valueTrans?.length >= 87">
              {{ item?.valueTrans }}
            </span>
          </div>
        </div>
      </div>
      <div class="offer-description">
        <div class="offer-description-title" ref="offerTitleRef">Description</div>
        <div class="offer-image-list" v-if="!init">
          <div v-for="(item, index) in !!productImageList.length ? productImageList : imageList" key="index"
            class="offer-image-item">
            <img :src="item" alt="" />
            <div class="carousel-btn">
              <div class="carousel-tool">
                <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/transtale-tool.svg" alt=""
                  class="carousel-traslate" :class="activeTool === 'TRANSLATE' && 'activeTool'">
                <img class="translate-btn" @click="(e) => translateFun(item, e)"
                  src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-translate-icon.svg" alt=""
                  @mouseenter="toggleDropdown('TRANSLATE')" @mouseleave="handleButtonMouseLeave">
              </div>
              <div class="carousel-tool">
                <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/findsimilar-tool.svg" alt=""
                  class="carousel-traslate" :class="activeTool === 'FIND_SIMILAR' && 'activeTool'"
                  style="width: 103px;">
                <img class="translate-btn"
                  src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-findsmilar-icon.svg" alt=""
                  @mouseenter="toggleDropdown('FIND_SIMILAR')" @mouseleave="handleButtonMouseLeave"
                  @click="(e) => findSimilar(item, e)">
              </div>
              <div class="carousel-tool">
                <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/findstyle-tool.svg" alt=""
                  class="carousel-traslate" :class="activeTool === 'FIND_STYLE' && 'activeTool'" style="width: 96px;">
                <img class="translate-btn"
                  src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/offer-findstyle-icon.svg" alt=""
                  @mouseenter="toggleDropdown('FIND_STYLE')" @mouseleave="handleButtonMouseLeave"
                  @click="(e) => findStyles(item, e)">
              </div>
            </div>
          </div>
        </div>
        <div v-if="init">
          <div class="empty-desc-1"></div>
          <div class="empty-desc-2"></div>
          <div class="empty-desc-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import { buriedPointUtil, redirectWithSearch } from "@/utils/utils";
import { fetchData } from "../../../utils/fetch";
const store = useStore();
const descList = ref([]);
const imageList = ref([]);
const productImageList = ref([])
const offerData = computed(() => store.state.offerDetail);

const htmlContent = ref("");
const activeTool = ref('');

const route = useRoute();
const offerId = route.params.id;
const offerTitleRef = ref(null)

const valueTransIndex = ref(null);

const init = ref(true)

onMounted(() => {
  const observer = new IntersectionObserver((entries, observer) => {
    if (entries[0].isIntersecting) {
      getDetailImageList() // 调用 API
      observer.disconnect() // 只触发一次，避免重复请求
    }
  }, {
    root: null, // 视口为浏览器窗口
    threshold: 0.5 // 50% 可见时触发
  })

  if (offerTitleRef.value) {
    observer.observe(offerTitleRef.value)
  }
})

const getDetailImageList = async () => {
  init.value = false
  try {
    const { code, data } = await fetchData('/dsp-app/alibaba-common/searchProductImages', {
      method: 'post',
      body: JSON.stringify({
        offerId
      }),
    })
    if (code === 200) {
      productImageList.value = data
    }
    init.value = false
  } catch (error) {
    init.value = false
  }
}

const mouseValueTransMove = (index) => {
  valueTransIndex.value = index;
};

const mouseValueTransleave = (index) => {
  valueTransIndex.value = null;
};

const translateFun = async (item, e) => {
  e.stopPropagation();
  buriedPointUtil({ eventId: "PTR201" });
  try {
    // 1. 加载图片并转换为 Bitmap
    const response = await fetch(item);
    const blob = await response.blob();
    const bitmap = await createImageBitmap(blob);

    // 2. 绘制到 Canvas 并导出为 PNG
    const canvas = document.createElement("canvas");
    canvas.width = bitmap.width;
    canvas.height = bitmap.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(bitmap, 0, 0);

    // 3. 将 Canvas 转换为 Blob (PNG)
    const pngBlob = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/png")
    );

    // 4. 写入剪贴板
    const clipboardItem = new ClipboardItem({ "image/png": pngBlob });
    await navigator.clipboard.write([clipboardItem]);
    store.commit("common/SET_TOAST_VISIBLE", true);
    store.commit("common/SET_TOAST_INFO", {
      msg: "Copy Successfully",
      type: "success",
    });

    setTimeout(() => {
      const language = (localStorage.getItem('language') || 'en').replace('zh', 'zh-CN')
      window.open(
        `https://translate.google.com/?sl=auto&tl=${language}&text=${item.subjectTrans}&op=images`
      );
    }, 800)
  } catch (error) {
    console.error("复制失败:", error);
    console.error("复制失败:", error.message);

    store.commit("common/SET_TOAST_VISIBLE", true);
    store.commit("common/SET_TOAST_INFO", {
      msg: "Copy Error",
      type: "error",
    });
  }
};

const toggleDropdown = (key) => {
  activeTool.value = key
}

const handleButtonMouseLeave = () => {
  activeTool.value = ''
}


// 找相似
const findSimilar = async (item, e) => {
  e.stopPropagation();
  buriedPointUtil({ eventId: "POP201" });
  sessionStorage.setItem(`currentMainImage_${offerData.value.offerId}`, item);
  window.open(
    `/search${redirectWithSearch(`offerId=${offerData.value.offerId}&type=similar`)}`
  );
};

// 找样式一致
const findStyles = async (item, e) => {
  e.stopPropagation();
  buriedPointUtil({ eventId: "POP202" });
  sessionStorage.setItem(`currentMainImage_${offerData.value.offerId}`, item);
  window.open(
    `/search${redirectWithSearch(`offerId=${offerData.value.offerId}&type=styles`)}`
  );
};

watch(offerData, (newData) => {
  if (newData) {
    offerData.value = newData;
    descList.value = mergeAttributes(newData?.productAttribute || []);
    imageList.value = newData?.productImage?.images;
    htmlContent.value = newData?.description;
  }
});

const mergeAttributes = (arr) => {
  const result = {};

  // 遍历输入数组
  arr.forEach((item) => {
    const {
      attributeId,
      attributeName,
      attributeNameTrans,
      value,
      valueTrans,
    } = item;

    if (!result[attributeId]) {
      // 如果 attributeId 不在 result 对象中，初始化
      result[attributeId] = {
        attributeId,
        attributeName,
        attributeNameTrans,
        value,
        valueTrans: valueTrans,
      };
    } else {
      // 如果 attributeId 已存在，拼接 valueTrans
      result[attributeId].valueTrans += `, ${valueTrans}`;
    }
  });

  // 将结果对象转换为数组
  return Object.values(result);
};
</script>

<style lang="less">
@import "./index.less";
</style>
