<template>
  <div class="h5countrySelect">
    <div class="h5countrySelect-header">
      <img src="@/assets/H5/order/icon-arrow-left.svg" alt="" @click="goback">
      <div class="h5countrySelect-header-title">Country / Region</div>
    </div>
    <div class="h5countrySelect-main" :style="{ height: `calc(${ innerHeight }px - 92px)` }">
      <div v-for="(item, index) in countryList" :key="index" class="currency-item" @click="saveCountry(item)">
        <div class="currency-item-left">
          <div class="currency-item-left-title">
            <img :src="item.countryUrl" alt="" class="currency-item-left-img">
            <div class="country-name">{{ item?.countryName }}</div>
          </div>
        </div>
        <div v-if="currentCountry.countryCode === item.countryCode" class="selected">
          <img src="@/assets/H5/filters/check.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>


<script setup>
import { computed, onMounted, watch, ref } from 'vue';
import { useStore } from 'vuex';
const store = useStore()

const props = defineProps({
  currentCountry: Object,
  onClose: Function
});

const countryList = computed(() => store.state.countryInfo);
const currentCountry = props.currentCountry;
const onClose = props.onClose

const innerHeight = ref(0)

const goback = () => {
  onClose()
}

const saveCountry = (item) => {
  onClose(item)
}

onMounted(() => {
  innerHeight.value = window.innerHeight
})

</script>

<style>
@import './index.less';
</style>