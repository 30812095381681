<template>
  <GuideViewPC v-if="isMobile === 'PC'" />
  <GuideViewH5 v-if="isMobile === 'MOBILE'" />
</template>

<script setup>
import { nextTick, onMounted, ref } from 'vue';
import GuideViewPC from './components/GuideViewPC/index.vue'
import GuideViewH5 from './components/GuideViewH5/index.vue'

const isMobile = ref(false);
onMounted( async () => {
  const userAgent = navigator.userAgent.toLowerCase();
  isMobile.value = /iphone|ipod|ipad|android|blackberry|windows phone/i.test(userAgent) ? "MOBILE" : "PC";
});


</script>