<template>
  <div class="rating-hovered">
    <div class="country-select-top">
      <img src="@/assets/country-select.svg" alt="" />
    </div>
    <div class="country-select-hideline"></div>
    <div class="supplier-main">
      <div class="supplier-main-title">Supplier Credit Rating</div>
      <div class="product-star">
        <div class="product-star-img" v-if="offerData?.sellerDataInfo?.compositeServiceScore">
          <img
            src="@/assets/star.svg"
            alt=""
            v-for="i in new Array(Math.floor(offerData?.sellerDataInfo?.compositeServiceScore)).fill(0)"
          />
          <img
            src="@/assets/star-no.svg"
            alt=""
            v-for="i in new Array(Math.ceil(5 - offerData?.sellerDataInfo?.compositeServiceScore)).fill(0)"
          />
        </div>
        <div class="product-star-cost">({{ offerData?.sellerDataInfo?.compositeServiceScore }})</div>
      </div>
      <div class="supplier-product">
        <div class="supplier-pd-item">
          <div class="supplier-pd-label">Trade Medal Level</div>
          <div class="supplier-pd-value">
            <div class="supplier-pd-line">
              <div :style="getItemStyle(offerData?.sellerDataInfo?.tradeMedalLevel)" class="supplier-pd-line-percent"></div>
            </div>
            <div class="supplier-pd-score">({{ offerData?.sellerDataInfo?.tradeMedalLevel }})</div>
          </div>
        </div>
        <div class="supplier-pd-item">
          <div class="supplier-pd-label">Quality Experience</div>
          <div class="supplier-pd-value">
            <div class="supplier-pd-line">
              <div :style="getItemStyle(offerData?.sellerDataInfo?.offerExperienceScore)" class="supplier-pd-line-percent"></div>
            </div>
            <div class="supplier-pd-score">({{ offerData?.sellerDataInfo?.offerExperienceScore }})</div>
          </div>
        </div>
        <div class="supplier-pd-item">
          <div class="supplier-pd-label">Composite Service</div>
          <div class="supplier-pd-value">
            <div class="supplier-pd-line">
              <div :style="getItemStyle(offerData?.sellerDataInfo?.compositeServiceScore)" class="supplier-pd-line-percent"></div>
            </div>
            <div class="supplier-pd-score">({{ offerData?.sellerDataInfo?.compositeServiceScore }})</div>
          </div>
        </div>
        <div class="supplier-pd-item">
          <div class="supplier-pd-label">Dispute Complaint</div>
          <div class="supplier-pd-value">
            <div class="supplier-pd-line">
              <div :style="getItemStyle(offerData?.sellerDataInfo?.disputeComplaintScore)" class="supplier-pd-line-percent"></div>
            </div>
            <div class="supplier-pd-score">({{ offerData?.sellerDataInfo?.disputeComplaintScore }})</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const sellerDataInfo = ref({})

const props = defineProps({
  offerData: Object,
});

const offerData = props.offerData;

const getItemStyle = (item) => {
  return {
    width: (item / 5) * 120 + 'px'
  };
};

</script>

<style lang="less">
@import './index.less';
</style>
