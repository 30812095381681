

<template>
  <div class="empty-offer">
    <div class="empty-offer-item">
      <div class="empty-item-left">
        <div class="empty-item-left-top"></div>
        <div class="empty-item-left-bottom">
          <div v-for="item in new Array(6).fill(0)" class="empty-item-selecton" key="item"></div>
        </div>
      </div>
      <div class="empty-item-center">
        <div class="empty-item-center-1"></div>
        <div class="empty-item-center-2"></div>
        <div class="empty-item-center-3"></div>
        <div class="empty-item-center-4"></div>
        <div class="empty-item-center-5"></div>
        <div class="empty-item-center-6"></div>
        <div class="empty-item-center-7">
          <div v-for="item in new Array(5).fill(0)" key="item" class="empty-center-7-item">
            <div class="empty-center-7-item-child"></div>
          </div>
        </div>
        <div class="empty-item-center-8"></div>
        <div class="empty-item-center-9"></div>
        <div class="empty-item-center-10"></div>
        <div class="empty-item-center-11"></div>
      </div>
      <div class="empty-right">
        <div class="empty-item-right">
          <div class="empty-item-right-1"></div>
          <div class="empty-item-right-2"></div>
          <div class="empty-item-right-7"></div>
          <div class="empty-item-right-3"></div>
          <div class="empty-item-right-4"></div>
          <div class="empty-item-right-5"></div>
          <div class="empty-item-right-6"></div>
        </div>
      </div>
    </div>
    <div class="empty-line"></div>
    <div class="empty-desc-1"></div>
    <div class="empty-desc-2"></div>
    <div class="empty-desc-3"></div>
  </div>
</template>

<script setup>
</script>

<style lang="less">

@import './index.less';

</style>