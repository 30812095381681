<template>
  <div class="cancel-order-container" @click.stop>
    <div class="body">
      <img class="image" src="@/assets/H5/order/cancel-order.png" width="160px" alt="">
      <div class="text-1">Are you sure you want to cancel this order?</div>
      <div class="button-1" @click.stop="handleCancel">Confirm</div>
      <div class="button-2" @click.stop="handleClose">I’ll think again</div>
    </div>
  </div>
</template>

<script setup>
  const emits = defineEmits(['close', 'cancel'])

  const handleCancel = () => {
    emits('cancel')
  }

  const handleClose = () => {
    emits('close')
  }
</script>

<style lang="less" scoped>
.cancel-order-container {
  height: 100%;
  .body {
    background-color: white;
    border-radius: 8px;
    text-align: center;

    .image {
      margin-top: 80px;
    }

    .text-1 {
      color: #232323;
      font-size: 12px;
      font-family: mon-500;
      line-height: 16px;
    }

    .button-1, .button-2 {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      height: 36px;
      width: 124px;
      border-radius: 20px;
      font-size: 12px;
      font-family: mon-600;
    }

    .button-1 {
      margin-top: 16px;
      border: 1px solid #b2b7c2;
      color: #232323;
    }

    .button-2 {
      margin-top: 12px;
      background-color: #052785;
      color: white;
    }
  }
}
</style>