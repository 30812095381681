<template>
  <div @click="closeMask" class="search-by-item-contrainer">
    <div class="search-by-item-img" :style="{ backgroundImage: `url(${pressProductItem.imageUrl})` }" @click.stop>
    </div>
    <div class="search-by-item-options-wrapper" @click.stop>
      <div class="search-by-item-options">
        <div class="search-by-item-option-title">
          You can use the image translation function when you visiting our PC website.
        </div>
        <div class="search-by-item-option-similar" @click="handleFind('similar')">
          <div>Find Similar</div>
          <div><img src="@/assets/H5/home/image-search-icon.svg" alt=""></div>
        </div>
        <div class="search-by-item-option-styles" @click="handleFind('styles')">
          <div>Find Styles</div>
          <div><img src="@/assets/H5/home/style-search-icon.svg" alt=""></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
  import { computed, nextTick, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import { useStore } from 'vuex';
  import { buriedPointUtil } from '@/utils/utils'

  
  const store = useStore();
  const router = useRouter();
  const route = useRoute();

  const pressProductItem = computed(() => store.state.h5Module.pressProductItem)
  const pressProductSearchVisible = computed(() => store.state.h5Module.pressProductSearchVisible)

  watch(pressProductSearchVisible, value => {
    if (value) {
      document.querySelector('body').classList.add('no-scroll');
    } else {
      document.querySelector('body').classList.remove('no-scroll');
    }
  })

  const closeMask = (e) => {
    store.commit('h5Module/setPressProductSearchVisible', false)
  }

  const handleFind = (type) => {
    if (type === 'similar') {
      buriedPointUtil({ eventId: "MPOP201" });
    } else {
      buriedPointUtil({ eventId: "MPOP202" });
    }

    closeMask();
    store.commit('h5Module/closeImageSearchDialog')
    nextTick(() => {
      store.commit('h5Module/showImageSearchDialog', {
        type,
        imageUrl: pressProductItem.value.imageUrl,
        imageId: 0,
      })

      const url = `/search?offerId=${pressProductItem.value.offerId}&type=${type}`
      if (route.fullPath.includes('imageId')) {
        router.replace(url)
      } else {
        router.push(url)
      }
    })
  }
</script>
<style lang="less" scoped>
  .search-by-item-contrainer {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 1100;
    padding: 0 16px;
    box-sizing: border-box;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, .8);
      backdrop-filter: blur(8px);
      z-index: -1;
    }
    
    .search-by-item-img {
      margin-top: 48px;
      width: 100%;
      height: 361px;
      border-radius: 12px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px;
        aspect-ratio: 1 / 1;
      }
    }

    .search-by-item-options-wrapper {
      width: fit-content;
    }

    .search-by-item-options {
      margin-top: 16px;
      background-color: rgba(255, 255, 255, .8);
      backdrop-filter: blur(24px);
      width: 240px;
      height: 162px;
      padding: 12px;
      border-radius: 8px;
      box-sizing: border-box;

      .search-by-item-option-title {
        padding-bottom: 12px;
        color: #5c5c5c;
        font-weight: 500;
        font-size: 11px;
        line-height: 15px;
        font-family: mon-500;
        word-break: break-word;
      }

      .search-by-item-option-similar, .search-by-item-option-styles {
        border-top: 0.5px solid #ebebeb;
        height: 48px;
        padding: 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #232323;
        font-size: 14px;
        font-family: mon-600;
        box-sizing: border-box;
      }
    }
  }
</style>