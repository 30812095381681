<template>
  <div class="carousel">
    <button @click="prev" class="nav-btn left-btn" :disabled="isPreDisabled">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.7473 3.33565C17.1142 3.74843 17.077 4.3805 16.6642 4.74742L9.34589 11.2526C8.89838 11.6504 8.89838 12.3496 9.34589 12.7474L16.6642 19.2526C17.077 19.6195 17.1142 20.2516 16.7473 20.6644C16.3803 21.0772 15.7483 21.1143 15.3355 20.7474L8.01716 14.2422C6.67462 13.0489 6.67462 10.9512 8.01716 9.75778L15.3355 3.2526C15.7483 2.88568 16.3803 2.92286 16.7473 3.33565Z"
          :fill="isPreDisabled ? '#B2B7C2' : '#141414'"
        />
      </svg>
    </button>
    <div class="carousel-wrapper">
      <div class="init-skeleton" v-if="similarSkeleton">
        <div v-for="item in new Array(5).fill(0)" class="init-skeleton-item">
          <div class="skeleton-item-main"></div>
          <div class="skeleton-item-line-1"></div>
          <div class="skeleton-item-line-2"></div>
          <div class="skeleton-item-line-3"></div>
          <div class="skeleton-item-line-4"></div>
        </div>
      </div>
      <div v-else class="carousel-track" :style="{ transform: `translateX(${currentIndex * -100}%)`}">
        <div
          v-for="product in productList"
          :key="product.id"
          class="product-con"
        >
          <ProductItem
            :item="product"
            :isImageInView="isImageInView"
            :isStylesItem="true"
            :isActive="activeOfferId === product.offerId"
            @findSimilarItemStyles="findSimilarItemStyles(product)"
          ></ProductItem>
        </div>
      </div>
    </div>
    <button @click="next" class="nav-btn right-btn" :disabled="isNextDisabled">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.25274 3.33565C6.88582 3.74843 6.923 4.3805 7.33578 4.74742L14.6541 11.2526C15.1016 11.6504 15.1016 12.3496 14.6541 12.7474L7.33578 19.2526C6.923 19.6195 6.88582 20.2516 7.25274 20.6644C7.61965 21.0772 8.25173 21.1143 8.66451 20.7474L15.9828 14.2422C17.3254 13.0489 17.3254 10.9512 15.9828 9.75778L8.66451 3.2526C8.25173 2.88568 7.61965 2.92286 7.25274 3.33565Z"
          :fill="isNextDisabled ? '#B2B7C2' : '#141414'"
        />
      </svg>
    </button>
  </div>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  nextTick,
  defineProps,
  defineEmits,
  watch,
} from "vue";
import { useStore } from "vuex";
import ProductItem from "@/components/ProductItem/index.vue";

const store = useStore();
const emit = defineEmits();

const props = defineProps({
  productList: {
    type: Array,
    required: true,
  },
  total: {
    type: Number,
    required: true,
  },
});

const activeOfferId = ref();
const isPreDisabled = ref(true);
const isNextDisabled = ref(false);
const currentIndex = ref(0);
const pageSize = ref(20);
const inViewImages = ref(new Set());

const similarSkeleton = computed(() => store.state.search.similarSkeleton);

const next = () => {
  if (!isNextDisabled.value) {
    currentIndex.value++;
  }
};

const prev = () => {
  if (!isPreDisabled.value) {
    currentIndex.value--;
  }
};

const findSimilarItemStyles = (product) => {
  activeOfferId.value = product.offerId;
  sessionStorage.setItem(
    `currentMainImage_${product.offerId}`,
    product.imageUrl
  );
  store.commit("search/SET_ACTIVE_SIMILAR_ITEM", product);
};

// 判断图片是否在视口内
const isImageInView = (id) => {
  return inViewImages.value.has(id);
};
// 当图片进入视口时标记该图片
const handleImageIntersection = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const imageId = entry.target.dataset.id;
      inViewImages.value.add(imageId); // 标记图片已加载
      observer.unobserve(entry.target); // 只触发一次，停止观察
    }
  });
};

watch(
  () => store.state.search.activeSimilarItem,
  (newVal) => {
    activeOfferId.value = newVal?.offerId;
  },
  { immediate: true, deep: true }
);

watch(
  () => [currentIndex.value, props.total],
  ([indexVal, totalVal]) => {
    isPreDisabled.value = indexVal <= 0;
    isNextDisabled.value = indexVal >= 9;
  }
);

onMounted(() => {
  const observer = new IntersectionObserver(handleImageIntersection, {
    rootMargin: "0px 0px 200px 0px", // 图片提前 200px 开始加载
    threshold: 0.1, // 只有当图片至少 10% 显示时才触发
  });

  // 使用 nextTick 确保组件渲染完成后再进行图片懒加载观察
  nextTick(() => {
    const images = document.querySelectorAll("img"); // 获取所有图片
    images.forEach((image) => observer.observe(image)); // 开始观察每个图片
  });
});

defineExpose({
  currentIndex,
})
</script>

<style scoped lang="less">
@import "./index.less";
</style>
