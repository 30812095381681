<template>
  <main class="main-container">
    <!-- 商品总数 -->
    <div class="product-result">
      <span v-if="total === -1">Searching...</span>
      <span v-else>Treasures Just For You!</span>
    </div>
    <Product :productList="productList" />
    <div class="help-center-bottom" v-if="renderBottom">
      <div></div>
      <div @click="goTopSearch">Come on! Try to search for the treasure you want.</div>
      <div></div>
    </div>
  </main>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, computed, watch } from "vue";
import { useStore } from "vuex";
import Product from "@/components/Product/index.vue";
import { gaAnalytics } from "@/utils/utils";
import { fetchData } from "@/utils/fetch";
import { productInitList } from "@/common/productInitData";
import { useRoute } from "vue-router";

const store = useStore();
const route = useRoute();

const productList = ref([]);
const total = ref(0);

const { data: adsWord } = route.query;

const searchProductList = async () => {
  store.commit("setInitSkeleton", true);
  console.log(adsWord, 'adsWord')
  const queryData = adsWord
    ? `/dsp-app/alibaba-common/recommend/productListV2?itemNumber=60&adsWord=${encodeURIComponent(adsWord).replace(/%20/g, "+")}`
    : "/dsp-app/alibaba-common/recommend/productListV2?itemNumber=50";
  const { data, code } = await fetchData(queryData, { method: "get" });
  if (code === 200 && data.length !== 0) {
    productList.value = data.map((v) => {
      return {
        ...v,
        priceInfo: { price: v.priceInfo.price },
      };
    });
    total.value = data.length;
  } else {
    productList.value = productInitList;
    total.value = productInitList.length;
  }
  store.commit("setInitSkeleton", false);
};

onMounted(() => {
  gaAnalytics("p_home_page_view", "p_home_page_view", {
    bdUserId: localStorage.getItem("dsp.uid"),
  });
  searchProductList();
  window.addEventListener("scroll", layoutOnScroll);
});

onBeforeUnmount(() => {
  window.removeEventListener("scroll", layoutOnScroll);
});

const layoutOnScroll = () => {
  const pageScrollY = window.scrollY;
  const scrollHeight = document.documentElement.scrollHeight;
  const clientHeight = window.innerHeight;

  // 判断当前位置加 75px 是否大于等于最大滚动位置
  if (route.path === "/") {
    if (pageScrollY + 75 >= scrollHeight - clientHeight) {
      loadMore();
    }
  }
};

const goTopSearch = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
  const searchInputItem = document.querySelector(".search-input-item");
  searchInputItem.focus();
};

const loadMore = async () => {
  const { data: adsWord } = route.query;
  if (!!adsWord) {
    return;
  }
  store.commit("setLoadMoreSleleton", true);
  const { data, code } = await fetchData("/dsp-app/alibaba-common/recommend/productListV2?itemNumber=50");
  if (code === 200 && data.length !== 0) {
    const newData = data.map((v) => {
      return {
        ...v,
        priceInfo: { price: v.priceInfo.price },
      };
    });
    productList.value = [...productList.value, ...newData];
    total.value = data.length;
  } else {
    productList.value = productInitList;
    total.value = productInitList.length;
  }
  store.commit("setLoadMoreSleleton", false);
};

const preSearch = computed(() => store.state.search.preSearch);
watch(preSearch, (value) => {
  if (value) {
    loadMore();
  }
});

const renderBottom = computed(() => {
  return !!adsWord;
});

</script>

<style scoped>
.main-container {
  margin: auto;
  h2 {
    span {
      font-family: mon-700;
    }
  }
}

.collection-protects {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #002b5c;
  background-color: #e7f2fb;
  text-align: center;
  line-height: 40px;
  height: 40px;
  font-family: mon-500;
  font-size: 12px;
}

.product-result {
  font-size: 12px;
  font-family: mon-500;
  line-height: 16px;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 16px;
  color: #009de0;
}

.help-center-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 388px;
  margin: 20px auto 0px;
  div:nth-child(1) {
    width: 32px;
    height: 1px;
    background-color: #ebebeb;
  }
  div:nth-child(2) {
    color: #009de0;
    font-size: 12px;
    line-height: 16px;
    font-family: mon-500;
    cursor: pointer;
  }
  div:nth-child(3) {
    width: 32px;
    height: 1px;
    background-color: #ebebeb;
  }
}
</style>
