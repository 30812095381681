
<template>
  <div class="h5-description-dialog">
    <div class="h5-description-top-line"></div>
    <img src="@/assets/H5/home/close.svg" alt="" class="h5-description-close" @click="closeH5Description">
    <div class="h5-description-main">
      <div class="h5-description-text">Description</div>
      <div class="h5-offer-detail-continer">
          <div v-for="(item, index) in descList" key="index" class="h5-offer-detail-item">
            <div class="h5-item-attributeNameTrans">
              {{ item?.attributeNameTrans }}
            </div>
            <div class="h5-item-valueTrans">
              {{ item?.valueTrans }}
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  descAllList: Object,
  closeH5Description: Function
});

const descList = props.descAllList
const closeH5Description = props.closeH5Description

const showPopup = ref(false)
const store = useStore()


const handleClickOutside = () => {
  showPopup.value = false
}

const closeBtn = () => {

}
</script>

<style lang="less">
@import './index.less';
</style>