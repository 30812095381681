<template>
  <div>
    <canvas id="canvas" style="display: none"></canvas>
    <!-- 初始化骨架屏 -->
    <div v-if="initSkeleton">
      <InitSkeleton />
    </div>
    <!-- 商品列表 -->
    <div v-else-if="productList.length" class="product">
      <div class="productList">
        <div
          v-for="(item, index) in productList"
          :key="index"
          class="product-item-block"
          :data-id="item.offerId"
        >
          <ProductItem :item="item" :isImageInView="isImageInView" />
        </div>
        <!-- 加载更多骨架屏 -->
        <LoadMoreSkeleton />
      </div>
    </div>
    <!-- 搜索结果为空 -->
    <Empty v-else-if="productList && !isStyles && initSkeleton === false" :msg="emptyObj?.msg || 'Sorry, no results found'" />
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, ref, defineProps, watch } from "vue";
import { useStore } from "vuex";
import { buriedPointUtil, gaAnalytics, throttle } from "@/utils/utils";
import ProductItem from "@/components/ProductItem/index.vue";
import Empty from "@/components/Empty/index.vue";
import InitSkeleton from "./components/InitSkeleton/index.vue";
import LoadMoreSkeleton from "./components/LoadMoreSkeleton/index.vue";

const props = defineProps({
  productList: {
    type: Array,
    required: true,
  },
  isStyles: {
    type: Boolean
  }
});


const store = useStore();

const inViewImages = ref(new Set());

const initSkeleton = computed(() => store.state.initSkeleton);
const emptyObj = computed(() => store.state.emptyObj);
const searchInfo = computed(() => store.state.searchInfo);

const lastPageSize = ref(0);
watch(() => props.productList, (value, oldValue) => {
  if (value.length) {
    lastPageSize.value = value.length - oldValue.length;
    nextTick(() => {
      setTimeout(() => {
        observerProduct()
      }, 100);
    })
  }
})

let observer2;
const observerIdList = ref(new Set()); // 正在观察的 offerId 列表
const reportedOfferIdList = ref([]); // 已报告的 offerId 列表

const observerProduct = () => {
  if (observer2) {
    observer2.disconnect(); // 先断开观察，防止重复监听
  }
  observer2 = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const offerId = entry.target.dataset.id;
        observerIdList.value.add(offerId);
        reportExposure();
        preSearch();
      }
    })
  }, { threshold: 0.51 });

  document.querySelectorAll('.product-item-block').forEach(product => {
    observer2.observe(product);
  })
}

// 埋点上报
const reportExposure = throttle(() => {
  let offerIdList = [];
  observerIdList.value.forEach(id => {
    if (reportedOfferIdList.value.indexOf(id) === -1) {
      offerIdList.push(id);
      reportedOfferIdList.value.push(id); // 添加到已报告的列表中
    }
  })
  // console.log(`total:${props.productList.length}`)
  // console.log('正在观察的: ', observerIdList.value);
  // console.log('已经上报的: ', reportedOfferIdList.value);


  if (offerIdList.length > 0) {
    buriedPointUtil({ eventId: "MPEX101", others: { offerIdList } })
  }
}, 500)

// 预加载
const preSearch = () => {
  const reportedCount = observerIdList.value.size; // 已曝光商品总数

  const half = Math.floor(lastPageSize.value / 2)
  const point = props.productList.length - half
  if (reportedCount >=  point) {
    store.commit('search/SET_PRE_SEARCH', true);
  }
}

// 判断图片是否在视口内
const isImageInView = (id) => {
  return inViewImages.value.has(id);
};

// 当图片进入视口时标记该图片
const handleImageIntersection = (entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      const imageId = entry.target.dataset.id;
      inViewImages.value.add(imageId); // 标记图片已加载
      observer.unobserve(entry.target); // 只触发一次，停止观察
    }
  });
};

onMounted(() => {
  if (location.pathname.includes("/search")) {
    gaAnalytics("p_search_page_view", "p_search_page_view");
    buriedPointUtil({ eventId: "PSC101" });
  }

  const observer = new IntersectionObserver(handleImageIntersection, {
    rootMargin: "0px 0px 200px 0px", // 图片提前 200px 开始加载
    threshold: 0.1, // 只有当图片至少 10% 显示时才触发
  });

  // 使用 nextTick 确保组件渲染完成后再进行图片懒加载观察
  nextTick(() => {
    const images = document.querySelectorAll("img"); // 获取所有图片
    images.forEach((image) => observer.observe(image)); // 开始观察每个图片
  });

  observer2 = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const offerId = entry.target.dataset.id;
        observerIdList.value.add(offerId);
        reportExposure();
        preSearch();
      }
    })
  }, { threshold: 0.51 })
});
</script>

<style lang="less">
@import "./index.less";
</style>
