import { createApp, reactive } from 'vue'
import Toast from './index.vue'

export default {
  install(app) {
    const state = reactive({
      toasts: [],
    });

    const showToast = (message, options = {}) => {
      const id = Date.now();
      state.toasts.push({ id, message, ...options });

      // 自动移除 toast
      setTimeout(() => {
        state.toasts = state.toasts.filter((toast) => toast.id !== id);
      }, options.duration || 2000);
    };

    // 提供全局方法
    app.config.globalProperties.$h5Toast = showToast;

    // 挂载 Toast 组件
    const toastApp = createApp(Toast, { state });
    const container = document.createElement('div');
    document.body.appendChild(container);
    toastApp.mount(container);
  }
}