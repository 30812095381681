<template>
  <div class="mini-header-mask" v-if="isShow"></div>
  <div class="mini-header-wrapper" :class="{ show: isShow }">
    <div class="mini-header-left">
      <div class="h5-header-menu" @click="showCategory">
        <img src="@/assets/H5/home/h5-menu.svg" alt="">
      </div>
      <div class="h5-header-home" @click="goHome">
        <img src="@/assets/H5/home/logo-icon.svg" alt="">
      </div>
    </div>
    <div class="h5-header-search" @click="showSearchFoucs">
      <div class="search-container-text">
        <input 
          @focus="handleFocus"
          @keyup.enter="searchText" 
          v-model="searchTextValue" 
          placeholder="🔍 Search anything you want" 
          type="text">
      </div>
      <div class="search-container-btn">
        <div @click.stop="photoClick" class="container-btn-photo">
          <img src="@/assets/H5/home/photo.svg" alt="">
        </div>
        <div class="container-btn-search" @click="searchText">
          <img src="@/assets/H5/home/search.svg" alt="">
        </div>
      </div>
    </div>
    <div class="h5-header-sign" @click="signIn">
      <img src="@/assets/H5/home/person.svg" alt="">
    </div>
    <div class="h5-header-cart" @click="toCartPage">
      <template v-if="userInfo.uid">
        <div v-if="cartCount" class="cart-count">{{ cartCount > 99 ? '99+' : cartCount }}</div>
        <img v-if="cartCount === 0" src="@/assets/H5/carts/empty-cart.svg" alt="">
        <img v-else src="@/assets/H5/carts/cart-icon.svg" alt="">
      </template>
      <img v-else src="@/assets/H5/carts/empty-cart.svg" alt="">
    </div>
    <div class="h5-header-country" @click="showCurrency">
      <img :src="countryInfo[0]?.countryUrl" alt="" class="h5-country-logo">
    </div>
  </div>

  <H5Dialog v-model="showPopup" :height="`calc(${innerHeight}px - 32px)`" style="padding: 16px;">
    <H5LoginIn :handleClickOutside="handleClickOutside" />
  </H5Dialog>

  <H5Dialog v-model="showH5Currency" :height="`calc(${innerHeight}px - 80px)`" :initStyles="{ background: 'white' }">
    <H5Currency :handleClickOutside="handleCurrencyClickOutside"  />
  </H5Dialog>
</template>
<script setup>
import { computed, onMounted, ref } from 'vue'
import { useStore } from 'vuex';
import H5Dialog from '@/components/H5/H5Dialog/index.vue';
import H5LoginIn from '@/components/H5/H5LoginIn/index.vue';
import H5LoginOut from '@/components/H5/H5LoginOut/index.vue';
import H5Currency from '@/components/H5/H5Currency/index.vue';
import { buriedPointUtil, gaAnalytics  } from '@/utils/utils'
import { useRouter } from 'vue-router';

const emits = defineEmits(['search', 'focus'])

const props = defineProps({
  isShow: {
    type: Boolean,
    defalut: false,
  }
})

const store = useStore();

const router = useRouter();

const searchTextValue = defineModel({ default: '' })

const userInfo = computed(() => store.state.common.userInfo);
const countryInfo = computed(() => store.state.countryInfo);

const cartCount = computed(() => store.state.cart.cartCount);

const showPopup = ref(false);
const showH5Currency = ref(false)
const innerHeight = ref(0);

onMounted(() => {
  innerHeight.value = window.innerHeight;
})

const signIn = () => {
  if(userInfo.value.uid) {
    router.push('/account')
  }else {
    gaAnalytics('mp_sign_in_click', "mp_sign_in_click");
    buriedPointUtil({ eventId: "MPWH201" });
    buriedPointUtil({ eventId: "MPLG501" });
    store.commit('setPassiveLogin', 0)
    showPopup.value = true
  }
}

const goHome = () => {
  window.location.href = '/'
}

const showCurrency = () => {
  showH5Currency.value = true
}

const photoClick = () => {
  buriedPointUtil({ eventId: "MPSC202" });
  store.commit('h5Module/setImageSearchGuidePageVisible', true)
}

const handleFocus = () => {
  emits('focus')
}

const searchText = (e) => {
  if (!searchTextValue.value) return;
  gaAnalytics('mp_search_click', "mp_search_click");
  emits('search', searchTextValue.value)
}

const showSearchFoucs = () => {
  store.commit('h5Module/setSearchFoucs', true)
  store.commit('setEmptyObj', {})
}

const handleClickOutside = () => {
  showPopup.value = false
  store.commit('setProductList', productListCopy.value)
  store.commit('setLoginVisible', false)
}

const handleCurrencyClickOutside = () => {
  showH5Currency.value = false
}

const showCategory = () => {
  store.commit('h5Module/setCategoryDialogVisible', true)
  buriedPointUtil({ eventId: "MPWH401" });
}

const toCartPage = () => {
  buriedPointUtil({ eventId: 'MPC201' })
  router.push('/carts')
}

</script>
<style lang="less" scoped>
  .mini-header-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    z-index: 99;
    background-color: #052784;
  }
  .mini-header-wrapper {
    position: fixed;
    top: 0;
    height: 60px;
    width: 100%;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background-color: #052784;
    overflow: hidden;
    transform: translateY(-100%);
    transition: transform 0.3s ease;
    z-index: 999;

    &.show {
      transform: translateY(0);
    }

    .mini-header-left {
      display: flex;
    }

    .h5-header-menu {
      width: 24px;
      height: 24px;
    }

    .h5-header-sign {
      width: 24px;
      height: 24px;
      margin-left: 12px;
    }

    .h5-header-cart {
      display: flex;
      align-items: center;
      position: relative;
      width: 24px;
      height: 100%;
      margin-left: 12px;
      .cart-count {
        position: absolute;
        top: 6px;
        left: 8px;
        display: flex;
        align-items: center;
        background-color: #009de0;
        border: 1px solid #FFFFFF;
        border-radius: 8px;
        padding: 0 6px;
        height: 13px;
        font-size: 10px;
        font-family: mon-500;
        color: #FFFFFF;
      }
    }

    .h5-header-home {
      width: 24px;
      height: 24px;
      margin-left: 12px;
    }
    .h5-header-search {
      flex: 1;
      min-width: 0;
      display: flex;
      justify-content: space-between;
      background-color: #fff;
      height: 36px;
      display: flex;
      align-items: center;
      border-radius: 20px;
      margin-left: 16px;
      padding-left: 10px;
      padding-right: 4px;
      .search-container-text {
        display: flex;
        flex: 1;
        min-width: 0;
        input {
          width: 100%;
          font-size: 12px;
          font-family: mon-500;
          font-weight: 500;
          color: #6E717A;
          line-height: 16px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border: none;
          outline: none;
        }
      }
      .search-container-btn {
        width: 78px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .container-btn-photo {
          display: flex;
          align-items: center;
          margin-right: 8px;
        }
        .container-btn-search {
          width: 44px;
          height: 28px;
          border-radius: 16px;
          background-color: #F57231;
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
        }
      }
    }
    .h5-header-country {
      display: flex;
      align-items: center;
      margin-left: 12px;
      width: 24px;
      height: 100%;
      .h5-country-logo {
        width: 100%;
      }
    }
  }
</style>