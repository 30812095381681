
<template>
  <div class="h5SendSuccess">
    <div class="h5SendSuccess-main">
      <div class="h5SendSuccess-content">
        <div class="h5SendSuccess-header">
        <div class="h5SendSuccess-header-title">Request Sended</div>
        <img src="@/assets/H5/offer/address-close.svg" alt="" @click="handleClose">
      </div>
      <img src="@/assets/H5/offer/send-success.png" alt="" class="send-success-img">
      <div class="h5SendSuccess-header-desc1">Sourcing Request Successful</div>
      <div class="h5SendSuccess-header-desc2">We will notify you of the procurement results via email, so please pay attention to check it at that time.</div>
      </div>
      <div class="h5SendSuccess-footer">
        <div class="h5SendSuccess-btn" @click="handleClose">Confirm</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex';
const store = useStore()

const handleClose = () => {
  store.commit('setH5SendSuccess', false)
}
</script>

<style>
@import './index.less';
</style>