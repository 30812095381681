
<template>
  <div class="init-skeleton">
    <div v-for="(item, index) in new Array(12).fill(0)" class="init-skeleton-item">
      <div class="skeleton-item-main"></div>
      <div class="skeleton-item-line-1"></div>
      <div class="skeleton-item-line-2"></div>
      <div class="skeleton-item-line-3"></div>
      <div class="skeleton-item-line-4"></div>
      <div class="skeleton-item-line-5"></div>
    </div>
  </div>
</template>

<script setup>

</script> 

<style lang="less">
  @import './index.less';
</style>