<template>
  <div class="cart-empty-main">
    <div class="h5Protection-header-container">
      <div class="h5Protection-header" ref="h5ProtectionHeader">
      <div class="h5Protection-header-item">
      <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/assure-icon.svg" alt="" style="width: 24px;">
        <span class="h5Protection-header-buyer">BUYER</span>
        <span class="h5Protection-header-guarantee">GUARANTEE</span>
        <span class="h5Protection-header-purchase">Secure Payments</span>
      </div>
      <div class="h5Protection-header-item h5Protection-header-item2">
        SSL encryption and PCI DSS data protection.
      </div>
    </div>
    </div>
    <div class="carts-empty-bg">
      <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/carts/h5-empty-bg.png" alt="" >
    </div>
    <div class="carts-empty-sub-1">Your shopping carts is empty.</div>
    <div class="carts-empty-sub-2">Add your purchase items in it</div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";

const h5ProtectionHeader = ref(null);
let intervalId;

onMounted(() => {
  startScrolling();
});

onUnmounted(() => {
  clearInterval(intervalId);
});

const startScrolling = () => {
  const container = h5ProtectionHeader.value;
  const contentHeight = 40; // 每个滚动项的高度
  let scrollPosition = 0;

  // 复制第一个元素，实现无缝滚动
  const firstItem = container.children[0].cloneNode(true);
  container.appendChild(firstItem);

  intervalId = setInterval(() => {
    scrollPosition += contentHeight;
    container.style.transition = "transform 1s ease-in-out";
    container.style.transform = `translateY(-${scrollPosition}px)`;

    if (scrollPosition >= contentHeight * (container.children.length - 1)) {
      setTimeout(() => {
        container.style.transition = "none"; // 取消动画，瞬间回到初始位置
        container.style.transform = `translateY(0)`;
        scrollPosition = 0;
      }, 1000);
    }
  }, 5000);
};
</script>

<style lang="less" scoped>
.cart-empty-main {
  padding: 16px 12px;
  background-color: #FFFFFF;
  border-radius: 8px;
  margin-bottom: 12px;
  .h5Protection-header-container {
    height: 40px;
    background: #EBF5FF;
    overflow: hidden;
    border-radius: 4px;
  }
  .h5Protection-header-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
  }
  .h5Protection-header {
    height: 40px;
    border-radius: 4px;
    .safe-icon {
      height: 72px;
    }

    .h5Protection-header-buyer {
      margin-left: 8px;
      font-size: 12px;
      font-family: mon-700;
      color: #052785;
    }

    .h5Protection-header-guarantee {
      margin-left: 4px;
      font-size: 12px;
      font-family: mon-700;
      color: #F57232;
    }

    .h5Protection-header-purchase {
      font-family: mon-500;
      font-size: 12px;
      color: #232323;
      margin-left: 6px;
    }
  }
  .h5Protection-header-item2 {
    font-family: mon-500;
    font-size: 12px;
    color: #232323;
  }
  .carts-empty-bg {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    img {
      width: 160px;
    }
  }
  .carts-empty-sub-1 {
    font-family: mon-600;
    font-size: 14px;
    color: #232323;
    text-align: center;
  }
  .carts-empty-sub-2 {
    font-family: mon-500;
    font-size: 12px;
    color: #232323;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 16px;
  }
}
</style>
