<template>
  <div class="h5-currency">
    <div v-if="!showCountryModel && !showCurrency">
      <img src="@/assets/H5/home/close.svg" alt="" class="currency-close" @click="closeBtn">
      <div class="h5-currency-title">Tell me more about yourself!</div>
      <div class="h5-currency-desc">We’ll provide you with comfortable browsing</div>
      <div class="h5-currency-main">
        <div class="h5-currency-language" @click="showCountry">
          <div class="language-title">Language</div>
          <div class="language-content">
            <div class="language-content-title">English</div>
            <img src="@/assets/H5/home/right.svg" alt="">
          </div>
        </div>
        <div class="h5-currency-language h5-main-currency">
          <div class="language-title">Currency</div>
          <div class="language-content" @click="hanldeShowCurrencyModel">
            <div class="language-content-title">{{ currencyData?.targetCurrency.slice(0, 2) }} ({{ currencyData?.targetUnit }})
            </div>
            <img src="@/assets/H5/home/right.svg" alt="">
          </div>
        </div>
        <div class="currency-detail">
          <div class="currency-detail-title">
            <div class="exchange-rate">
              {{ currencyData?.fromCurrency.slice(0, 2) }} {{ currencyData?.fromUnit }}1 ≈ {{ currencyData?.targetCurrency.slice(0, 2) }} <span class="exchange-rate-current"><span>{{ currencyData?.targetUnit }}</span>{{ currencyData?.rate }}</span> 
            </div>
          </div>
          <div class="currency-detail-desc">Data is for reference only.</div>
        </div>
      </div>
    </div>

    <CountrySelect v-if="showCountryModel" :handleClose="handleClose" />
    <CurrencySelect v-show="showCurrency" :handleClose="handleCloseCurrency" :currencyData="currencyData" />

  </div>
</template>

<script setup>
import CountrySelect from './components/CountrySelect/index.vue'
import CurrencySelect from './components/CurrencySelect/index.vue'
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  handleClickOutside: Function,
});

const showCountryModel = ref(false)
const showCurrency = ref(false)
const store = useStore()

const currencyData = ref({})
const currency = computed(() => store.getters.getCurrencyList).value;

const crrencyDataUpdated = computed(() => store.state.currencyData);

if (!!localStorage.getItem('currencyData')) {
  const data = JSON.parse(localStorage.getItem('currencyData'))
  currencyData.value = data
} else {
  currencyData.value = currency[0]
}

watch(crrencyDataUpdated, (newData) => {
  if (newData) {
    currencyData.value = newData;
  }
});

const showCountry = () => {
  showCountryModel.value = true
}

const handleClose = () => {
  showCountryModel.value = false
}

const handleCloseCurrency = () => {
  showCurrency.value = false
}

const hanldeShowCurrencyModel = () => {
  showCurrency.value = true
}

const closeBtn = () => {
  props.handleClickOutside()
}

</script>

<style lang="less">
@import './index.less';
</style>