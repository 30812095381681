<template>
  <HomeViewPC v-if="isMobile === 'PC'" />
  <HomeViewH5 v-if="isMobile === 'MOBILE'" />
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import HomeViewPC from './components/HomeViewPC/index.vue'
import HomeViewH5 from './components/HomeViewH5/index.vue'
import { useStore } from 'vuex';

const store = useStore();
const isMobile = computed(() => store.state.isMobile);
</script>