<template>
  <H5SearchFoucs v-if="searchFocus" @back="searchFocus = false" />
  <div class="help-center">
    <KeywordsMiniHeader v-model="inputValue" :isShow="showMiniHeader" @search="handleSearch" />
    <KeywordsHeader 
      v-model="inputValue" 
      :hidden="showMiniHeader" 
      :canInput="false"
      @focus="searchFocus = true"
      @search="handleSearch"  />
    <div class="help-center-header">
      <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/news-cycle.png" alt="" class="help-center-cycle">
      <div class="help-center-body">
        <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/helpcenter.png" alt="">
        <div class="help-center-customer">
          <div class="help-center-customer-title">Customer Help Center</div>
          <ul>
            <li><span>How to use </span><span> keywords search?</span></li>
            <li><span>How to use</span><span> image translation?</span></li>
            <li><span>How to use </span><span> Sourcing Now?</span></li>
          </ul>
        </div>
      </div>
    </div>
    <div class="help-center-guidelines">
      <span>About New User’s Useing </span>
      <span> Guidelines</span>
    </div>
    <div class="help-center-buniess">
      <div class="help-center-buniess-header">HOW TO FIND THE PRODUCTS FOR</div>
      <div class="help-center-buniess-svg">
        <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/your-business.svg" alt="">
      </div>
      <div class="help-center-videos">
        <div class="help-center-videos-item">
          <VideoPlayer title="How to use the search function?"
            class="help-center-videos-item-video"
            src="https://www.youtube.com/embed/ymZmuIMHJHY?si=WhxX9lT8MWqmm-iI"></VideoPlayer>
          <div class="help-center-videos-item-title">
            How to use keywords search?
          </div>
        </div>
        <div class="help-center-videos-item">
          <VideoPlayer title="How to use the search function?"
            class="help-center-videos-item-video"
            src="https://www.youtube.com/embed/4XlzbzKpjdM?si=21mTp1sS15XFaX-F"></VideoPlayer>
          <div class="help-center-videos-item-title">
            How to use image translation?
          </div>
        </div>
        <div class="help-center-videos-item">
          <VideoPlayer title="How to use the search function?"
            class="help-center-videos-item-video"
            src="https://www.youtube.com/embed/PdTC4gF0cpw?si=oLxhSEfoFTXmfT6P"></VideoPlayer>
          <div class="help-center-videos-item-content">
            <div class="item-content-protect">
              Protects You From Payment to Delivery
            </div>
            <div class="item-content-desc">How to use sourcing now?</div>
          </div>
        </div>
      </div>
      <div class="help-center-bottom">
        <div></div>
        <div>I'm a bottom line</div>
        <div></div>
      </div>
      <H5Footer />
    </div>
  </div>
</template>

<script setup>
import KeywordsHeader from '@/components/H5/KeywordsHeader/index.vue'
import KeywordsMiniHeader from '@/components/H5/KeywordsMiniHeader/index.vue'
import VideoPlayer from "@/components/VideoPlayer/index.vue";
import H5Footer from '@/components/H5/H5Footer/index.vue'
import H5SearchFoucs from '@/components/H5/H5SearchFoucs/index.vue'
import { onMounted, ref, computed, nextTick, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const inputValue = ref('')

const showMiniHeader = ref(false)
const searchFocus = ref(false);

onMounted(() => {
  document.querySelector('body').classList.remove('no-scroll');

  window.addEventListener('scroll', handleScroll);
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
})

const handleSearch = () => {
  const { href } = router.resolve({
    path: '/search',
    query: {
      keyword: inputValue.value
    }
  })
  window.open(href, '_blank')
}

const handleScroll = (e) => {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  if (scrollTop > 120) {
    showMiniHeader.value = true;
  } else {
    showMiniHeader.value = false;
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>