<template>
  <div class="filter-container">
    <div class="filter-container-header">
      <h2>Results for<span class="highlight">"{{ keyword || activeCategory?.categoryName }}"</span></h2>
      <div v-if="routeCategoryId" class="category-search-wrapper">
        <div class="category-search-left">{{ keyword || activeCategory?.categoryName }}</div>
        <div class="category-search-right">
          <input @input="categorySearchInput" v-model="inputValueWithCategory" type="text" placeholder="Search within category">
          <div @click="() => emit('search', inputValueWithCategory)" class="search-icon">
            <img src="@/assets/H5/home/search-black.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="filter-section" v-if="filters && filters.length">
      <div
        class="filter-row"
        v-for="(item, index1) in filters"
        :key="item.id + index1"
      >
        <h3>
          {{ item.ptrans }}
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M9.45473 8.00194L5.28711 3.83203L6.09587 3.02441L11.0711 8.00232L6.09549 12.9749L5.28787 12.1665L9.45473 8.00194Z"
                fill="#232323"
              />
            </svg>
          </span>
        </h3>
        <div class="filter-options">
          <button
            v-for="(option, index2) in visibleOptions(item.values, item.id)"
            :key="option.vtrans + index2"
            :class="{ active: selectedFilters.includes(option) }"
            @click="toggleFilter(option)"
          >
            {{ option.vtrans }}
          </button>

          <svg
            v-if="item.values.length > 10"
            class="toggle-btn"
            @click="toggleExpand(item.id)"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.3322 3.62661C10.1258 3.44315 9.80975 3.46174 9.62629 3.66813L6.3737 7.3273C6.1748 7.55106 5.82519 7.55106 5.62629 7.3273L2.3737 3.66813C2.19024 3.46174 1.8742 3.44315 1.66781 3.62661C1.46142 3.81007 1.44283 4.12611 1.62629 4.3325L4.87888 7.99166C5.47557 8.66294 6.52442 8.66294 7.12111 7.99166L10.3737 4.3325C10.5572 4.12611 10.5386 3.81007 10.3322 3.62661Z"
              fill="#232323"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
  <div class="filter-controls">
    <div class="filter-controls-left">
      <label :class="{ active: currentActive === 'relevance' }" @click="sortByRelevance">
        Relevance
      </label>
      <label :class="{ active: currentActive === 'onSale' }" @click="sortBySales"> Sales </label>
      <div class="price-sort" >
        <label @click="sortByPrice" :class="{ active: currentActive === 'price' }"  > Price </label>
        <svg
            @click="sortByPrice"
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
        >
          <path
              d="M4.65956 2.63889C4.84758 2.4537 5.15242 2.4537 5.34044 2.63889L8.60802 5.85716C8.91132 6.15589 8.69651 6.66667 8.26758 6.66667L1.73243 6.66667C1.30349 6.66667 1.08868 6.15589 1.39198 5.85716L4.65956 2.63889Z"
              :fill="currentActive !== 'price' ? '#B2B7C2' :  sortOrder === 'asc' ? '#FFFFFF' : '#B2B7C2'"
          />
        </svg>
        <svg
            @click="sortByPrice"
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
        >
          <path
              d="M5.34044 7.36111C5.15242 7.5463 4.84758 7.5463 4.65956 7.36111L1.39198 4.14284C1.08868 3.84411 1.30349 3.33333 1.73243 3.33333H8.26757C8.69651 3.33333 8.91132 3.84411 8.60802 4.14284L5.34044 7.36111Z"
              :fill="currentActive !== 'price' ? '#B2B7C2' : sortOrder === 'desc' ? '#FFFFFF' : '#B2B7C2'"
          />
        </svg>
      </div>
      <div class="price-filter">
        <input
            v-model.number="price.min"
            :placeholder="`Low (${currencyData?.targetCurrency} ${currencyData?.targetUnit})`"
            type="number"
        />
        <span>to</span>
        <input
            v-model.number="price.max"
            :placeholder="`Hign (${currencyData?.targetCurrency} ${currencyData?.targetUnit})`"
            type="number"
        />
        <button v-if="isValid" @click="sortByPriceRange">Done</button>
      </div>
    </div>

    <div class="filter-controls-right">
      <div class="total-info-wrapper">
        <div class="total-info">
          We found <span>{{ total }}</span> items for you
        </div>
      </div>
    </div>
  </div>
  <div class="selected-filters">
    <div
        v-for="(value, selectedIndex) in selectedFilters"
        :key="selectedIndex + 'selectedIndex'"
        class="tag-con"
    >
      <span class="tag">
        {{ value.vtrans }}
        <span @click="removeFilter(selectedIndex)">
          <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
          >
            <circle cx="7.00033" cy="7.00033" r="5.83333" fill="#B2B7C2" />
            <path
                d="M9.61239 8.78743C9.84019 9.01523 9.84019 9.38458 9.61239 9.61239C9.38458 9.84019 9.01523 9.84019 8.78743 9.61239L7.00002 7.82498L5.21261 9.61239C4.9848 9.84019 4.61546 9.84019 4.38765 9.61239C4.15985 9.38458 4.15985 9.01523 4.38765 8.78743L6.17506 7.00002L4.38765 5.21261C4.15985 4.9848 4.15985 4.61546 4.38765 4.38765C4.61546 4.15985 4.9848 4.15985 5.21261 4.38765L7.00002 6.17506L8.78743 4.38765C9.01523 4.15985 9.38458 4.15985 9.61239 4.38765C9.84019 4.61546 9.84019 4.9848 9.61239 5.21261L7.82498 7.00002L9.61239 8.78743Z"
                fill="white"
            />
          </svg>
        </span>
      </span>
    </div>
    <button v-if="hasFilters" class="clear-btn" @click="clearFilters">
      Clean
      <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
      >
        <path
            d="M6.2858 5.90485L6.29151 11.5925H7.43437L7.42904 5.90485H8.3757L8.38142 11.5925H9.52427L9.51856 5.90485H11.9368L11.4367 13.0119C11.4163 13.3003 11.2874 13.5703 11.0759 13.7675C10.8644 13.9647 10.586 14.0743 10.2968 14.0744H5.44275C5.15354 14.0743 4.8751 13.9646 4.66358 13.7674C4.45206 13.5701 4.32319 13.3 4.30294 13.0115L3.80199 5.90523L6.2858 5.90485ZM2.0957 4.00046H13.5243V5.14332H2.0957V4.00046ZM10.1936 2.0957V3.23856H5.62218V2.0957H10.1936Z"
            fill="#B2B7C2"
        />
      </svg>
    </button>
  </div>
</template>

<script setup>
import {
  reactive,
  computed,
  ref,
  defineProps,
  defineEmits,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import { fetchData } from "@/utils/fetch";
import { buriedPointUtil } from "@/utils/utils";
import { watch } from "vue";
import { useRoute } from 'vue-router';
import { throttle, checkHasQueryLanguage } from "@/utils/utils";

const props = defineProps({
  activeCategory: {
    type: Object,
    required: true,
    default: () => ({}),
  }
});

const emit = defineEmits();
const store = useStore();
const route = useRoute();

const routeCategoryId = ref(route.query.categoryId);

let filters = reactive([]);
const selectedFilters = reactive([]);
const price = reactive({ min: null, max: null });
const onSale = ref(false);
const relevance = ref(true);
const sortOrder = ref("");
const expanded = reactive({});

const currentActive = ref('relevance')

const currencyData = computed(() => store.state.currencyData);

let newSearchData = ref({});

const inputValueWithCategory = ref('');

const { keyword = '' } = route.query;

const isValid = computed(() => {
  return price.min || price.max;
});

const hasFilters = computed(() => {
  return selectedFilters.length > 0;
});

const total = computed(() => store.state.total);
const searchInfo = computed(() => store.state.searchInfo);

function toggleFilter(option) {
  const index = selectedFilters.indexOf(option);
  if (index === -1) {
    // 如果该选项没有被选中，就添加到数组
    selectedFilters.push(option);
    newSearchData.value = {
      ...searchInfo.value,
      attrValues: selectedFilters.map((item) => item.vname),
    };
    const obj = setPrice(newSearchData.value);
    store.commit("setSearchInfo", {...obj});
    buriedPointUtil({
      eventId: "PSF201",
      categoryId: props.activeCategory.categoryId,
      filterKeyword: option.vtrans,
    });
    
    emit("search", inputValueWithCategory.value);
  } else {
    // 如果该选项已经选中，就移除
    selectedFilters.splice(index, 1);
    newSearchData.value = {
      ...searchInfo.value,
      attrValues: selectedFilters.map((item) => item.vname),
    };
    const obj = setPrice(newSearchData.value);
    store.commit("setSearchInfo", {...obj});
    emit("search", inputValueWithCategory.value);
  }
}

function visibleOptions(options, key) {
  if (!expanded[key]) {
    return options.slice(0, 10);
  }
  return options;
}

function toggleExpand(key) {
  expanded[key] = !expanded[key];
}

// 移除过滤条件
function removeFilter(selectedIndex) {
  selectedFilters.splice(selectedIndex, 1);
  newSearchData.value = {
    ...searchInfo.value,
    attrValues: selectedFilters.map((item) => item.vname),
  };
  const obj = setPrice(newSearchData.value);
  store.commit("setSearchInfo", { ...obj });
  emit("search", inputValueWithCategory.value);
}

function clearFilters() {
  selectedFilters.length = 0;
  newSearchData.value = {
    ...searchInfo.value,
    attrValues: [],
  };
  const obj = setPrice(newSearchData.value);
  store.commit("setSearchInfo", { ...obj });
  emit("search", inputValueWithCategory.value);
}

const getFilters = async (id) => {
  console.log(id, 'id')
  if(!id) {
    return
  }
  const { code, data } = await fetchData(
    `/dsp-app/alibaba-common/categoryHotWords?categoryId=${id}`,
    {
      method: "get",
      headers: { "Content-Type": "application/json" },
    }
  );
  if (code === 200) {
    filters.splice(0, filters.length, ...data); // 直接修改数组内容
  }
};

const setPrice = (obj) => {
  const currencyData = JSON.parse(localStorage.getItem("currencyData"));
  const { min, max } = price
  if (min) {
    obj.priceStart = min / currencyData.rate
  } else {
    delete obj.priceStart
  }

  if (max) {
    obj.priceEnd = max / currencyData.rate
  } else {
    delete obj.priceEnd
  }

  return obj;
}

// 根据销量排序
const sortBySales = () => {
  if(currentActive.value === 'onSale') {
    return
  }
  currentActive.value = 'onSale'
  sortOrder.value = ''
  newSearchData.value = {
    ...searchInfo.value,
    sortMap: {
      monthSold: 'desc',
    },
  };
  const obj = setPrice(newSearchData.value)
  store.commit("setSearchInfo", { ...obj });
  buriedPointUtil({
    eventId: "PSF202",
    sortBy: "sales",
    isDesc: sortOrder.value === "desc" ? 1 : 0,
  });
  
  emit("search", inputValueWithCategory.value);
};
// 根据相关性排序
const sortByRelevance = () => {
  if(currentActive.value === 'relevance') {
    return
  }
  currentActive.value = 'relevance'
  sortOrder.value = ''
  newSearchData.value = {
    ...searchInfo.value,
    sortMap: null,
  };
  const obj = setPrice(newSearchData.value);
  store.commit("setSearchInfo", { ...obj });
  buriedPointUtil({
    eventId: "PSF202",
    sortBy: "relevance",
    isDesc: sortOrder.value === "desc" ? 1 : 0,
  });
  
  emit("search", inputValueWithCategory.value);
};
// 根据价格排序
const sortByPrice = () => {
  currentActive.value = 'price'
  if (sortOrder.value === "") {
    sortOrder.value = "asc";
  } else if (sortOrder.value === "asc") {
    sortOrder.value = "desc";
  } else if (sortOrder.value === "desc") {
    sortOrder.value = "asc";
  }
  if (sortOrder.value !== "") {
    newSearchData.value = {
      ...searchInfo.value,
      sortMap: {
        price: sortOrder.value,
      },
    };
  } else {
    newSearchData.value = {
      ...searchInfo.value,
      sortMap: null,
    };
  }
  const obj = setPrice(newSearchData.value);
  store.commit("setSearchInfo", { ...obj });
  
  emit("search", inputValueWithCategory.value);

  buriedPointUtil({
    eventId: "PSF202",
    sortBy: "price",
    isDesc: sortOrder.value === "desc" ? 1 : 0,
  });
};

// 根据价格区间排序
const sortByPriceRange = () => {
  const currencyData = JSON.parse(localStorage.getItem("currencyData"));
  const obj = setPrice(newSearchData.value)
  store.commit("setSearchInfo", { ...obj });
  buriedPointUtil({
    eventId: "PSF203",
    lowPrice: price.min / currencyData.rate,
    highPrice: price.max / currencyData.rate,
  });
  
  emit("search", inputValueWithCategory.value);
};

watch(
  () => props.activeCategory,
  (val) => {
    //TODO
    // clearFilters();
    getFilters(val.categoryId);
  },
  {
    immediate: true,
    deep: true,
  }
);

const { lg = "" } = route.query;
const categoryId = ref(route.query.categoryId);
const lastQuery = ref('')
const language = computed(() => checkHasQueryLanguage(lg) || localStorage.getItem("language") || "en");
const categorySearchInput = throttle((e) => {
  if(e.target.value.length >= lastQuery.value.length) {
    lastQuery.value = e.target.value
  }
  if(e.target.value.length < lastQuery.value.length) {
    lastQuery.value = e.target.value
    return
  }
  const searchData = {
    ...searchInfo.value,
    keyword: inputValueWithCategory.value,
    language: language.value,
    categoryId: categoryId.value,
    pageSize: 50,
    pageNow: 1,
    imageId: 0,
    region: "",
    async: true,
  };
  fetchData("/dsp-app/alibaba-common/searchKeyWord?itemNumber=50", {
    method: "post",
    body: JSON.stringify(searchData),
  });
}, 400)
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
