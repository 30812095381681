<template>
  <LayoutPC v-if="isMobile === 'PC'" />
  <LayoutH5 v-if="isMobile === 'MOBILE'" />
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue';
import LayoutPC from './components/LayoutPC/index.vue'
import LayoutH5 from './components/LayoutH5/index.vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const { source = '' } = route.query

const isMobile = ref(false);
onMounted(() => {
  saveLocationSearchUrl()
  const userAgent = navigator.userAgent.toLowerCase();
  isMobile.value = /iphone|ipod|ipad|android|blackberry|windows phone/i.test(userAgent) ? "MOBILE" : "PC";

  window.addEventListener('storage', syncUserInfo);
});

onBeforeUnmount(() => {
  window.removeEventListener('storage', syncUserInfo);
});

const syncUserInfo = (event) => {
  if (event.key === 'userInfo') { 
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    if (userInfo) {
      store.commit('common/SET_USER_INFO', userInfo);
    }
  }
}

const saveLocationSearchUrl = () => {
  const uid = localStorage.getItem('tk')
  if(!!uid) {
    localStorage.removeItem('searchUrl')
    return
  }
  const { search = '' } = window.location;
  if (search && search.includes('source=')) {
    const _search = search.replace('&tpage=not', '')
    localStorage.setItem('searchUrl', _search)
    if (!!source && source !== 'null') {
      localStorage.setItem('source', source)
    }
  }
}


</script>