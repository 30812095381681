<template>
  <div class="h5-addressInfo">
    <div class="addressInfoDialog-content" :style="{'--innerHeight': innerHeight}">
      <div class="addressInfoDialog-main">
        <div v-if="!hasAddressSaved" class="no-saved-address">
          <img src="@/assets/H5/carts/no-address.svg" alt="">
          No shipping address saved
        </div>
        <div v-if="isValidAddress" class="erro-deliver">
          <img src="@/assets/H5/error/red-warning.svg" width="12px" alt="">
          The logistics cannot be delivered at the moment. Please contact customer service for more information!
        </div>
        <div class="identify-address">
          <img v-if="identifyAddress" @click="identifyAddress = ''" class="clear-btn" src="@/assets/H5/offer/sourcing-close.svg" alt="">
          <textarea ref="textareaRef" v-model="identifyAddress" @input="adjustHeight" placeholder="{Identify clipboard address} Intelligent splitting of names, phone numbers, and addresses."></textarea>
          <div v-if="!identifyAddress" @click="handlePasteAddress" class="identify-address-btn">Identify Clipboard</div>
          <div v-else class="identify-address-btn" :class="{ identifying: identifying }" @click="handleIdentify">
            <div v-if="identifying" class="loading"></div>
            {{ identifying ? 'Identifying your address' : 'Identify' }}
          </div>
        </div>
        <div class="addressInfoDialog-contact">Contact Information</div>
        <div class="h5-form">
          <div class="form-item">
            <input type="text" class="form-name-input" v-model="formParams.name" @input="(e) => handleInput(e, 'name')"
              placeholder="Name*" />
          </div>
          <div class="address-error" v-if="verifyNameError">
            <img src="@/assets/error.svg" alt="" /> {{ verifyNameError }}
          </div>
          <div class="form-item form-item-country">
            <div class="address-country-img" @click="showAreaCode">
              <img :src="formParams.areaCodeImg" alt="" style="width: 20px; height: 15px;" class="country-img">
              <div class="code-wrapper">
                <span class="country-searchAreaCodeText">({{ formParams.phoneCode }})</span>
                <img src="@/assets/H5/offer/address-right.svg" alt="" class="country-img-right">
              </div>
            </div>
            <div class="form-phone">
              <input type="text" class="areaCode-input" @input="(e) => handleInput(e, 'phoneValue')" v-model="formParams.phoneValue" placeholder="Phone Number" />
            </div>
          </div>
          <div class="address-line"></div>
          <div class="addressInfoDialog-contact">Country / Region*</div>
          <div class="addressInfoDialog-region" @click="showCountrySelect">
            <div class="addressInfoDialog-region-left">
              <img :src="formParams.searchImg" alt="" style="width: 20px; height: 15px;" class="country-img">
              <span class="addressInfoDialog-region-searchCountryText">{{ formParams.searchCountryText }}</span>
            </div>
            <div class="addressInfoDialog-region-right">
              <img src="@/assets/H5/offer/address-right.svg" alt="" class="country-img-right">
            </div>
          </div>
          <div class="address-line"></div>
          <div class="addressInfoDialog-contact">Address</div>
          <div class="h5-form">
            <template v-if="formParams.searchCountryText === 'Brazil'">
              <div class="cpf-number">
                <div class="left">CPF</div>
                <div class="right">
                  <div class="form-item">
                    <input type="text" class="form-name-input mgT0" v-model="formParams.cpfNumber"
                      @input="(e) => handleInput(e, 'cpfNumber')" placeholder="CPF Number*" />
                  </div>
                </div>
              </div>
              <div class="address-error" v-if="verifyCpfNumberError">
                <img src="@/assets/error.svg" alt="" /> {{ verifyCpfNumberError }}
              </div>
            </template>
            <template v-if="formParams.searchCountryText === 'Chile'">
              <div class="cpf-number">
                <div class="left">RUT</div>
                <div class="right">
                  <div class="form-item">
                    <input type="text" class="form-name-input mgT0" v-model="formParams.rutNumber"
                      @input="(e) => handleInput(e, 'rutNumber')" placeholder="RUT Number*" />
                  </div>
                </div>
              </div>
              <div class="address-error" v-if="verifyRutNumberError">
                <img src="@/assets/error.svg" alt="" /> {{ verifyRutNumberError }}
              </div>
            </template>
            <div class="form-item">
              <input type="text" class="form-name-input" v-model="formParams.streetAddress"
                @input="(e) => handleInput(e, 'streetAddress')" placeholder="Street Address*" />
            </div>
            <div class="address-error" v-if="verifyStreetAddressError">
              <img src="@/assets/error.svg" alt="" /> {{ verifyStreetAddressError }}
            </div>
            <div class="form-item">
              <input type="text" class="form-name-input form-item-input" v-model="formParams.aptEtc"
                @input="(e) => handleInput(e, 'aptEtc')" placeholder="Apt, suit, unit, etc (optional)*" />
            </div>
            <div class="address-error" v-if="verifyAptEtcError">
              <img src="@/assets/error.svg" alt="" /> {{ verifyAptEtcError }}
            </div>
            <div class="form-item">
              <input type="text" class="form-name-input form-item-input" v-model="formParams.province"
                @input="(e) => handleInput(e, 'province')" placeholder="State / Province*" />
            </div>
            <div class="address-error" v-if="verifyAptProvinceError">
              <img src="@/assets/error.svg" alt="" /> {{ verifyAptProvinceError }}
            </div>
            <div class="form-item">
              <input type="text" class="form-name-input form-item-input" v-model="formParams.city"
                @input="(e) => handleInput(e, 'city')" placeholder="City / Suburb*" />
            </div>
            <div class="address-error" v-if="verifyAptCityError">
              <img src="@/assets/error.svg" alt="" /> {{ verifyAptCityError }}
            </div>
            <div class="form-item">
              <input type="text" class="form-name-input form-item-input" v-model="formParams.postCode"
                @input="(e) => handleInput(e, 'postCode')" placeholder="Post Code*" />
            </div>
            <div class="address-error" v-if="verifyAptPostCodeError">
              <img src="@/assets/error.svg" alt="" /> {{ verifyAptPostCodeError }}
            </div>
            <div class="form-item">
              <input type="text" class="form-name-input form-item-input" v-model="formParams.email"
                @input="(e) => handleInput(e, 'email')" placeholder="Email" />
            </div>
            <div class="address-error" v-if="verifyEmailError">
                <img src="@/assets/error.svg" alt="" /> {{ verifyEmailError }}
              </div>
          </div>
          <div class="address-line"></div>

        </div>
      </div>
      <div class="address-footer">
        <div class="address-footer-cancel" @click="goback">Cancel</div>
        <div class="address-footer-save" @click="saveAddress">Save</div>
      </div>
    </div>
    <H5Dialog v-model="showH5CountrySelect" :height="innerHeight + 'px'" :initStyles="initStyles()">
      <H5CountrySelect :currentCountry="currentCountry" :onClose="onClose" />
    </H5Dialog>
    <H5Dialog v-model="showAreaCodeSelect" :height="innerHeight + 'px'" :initStyles="initStyles()">
      <H5AreaCodeSelect :currentCountry="currentCountry" :onClose="closeH5AreaCodeSelect" />
    </H5Dialog>
  </div>
</template>

<script setup>
import { computed, nextTick, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import H5Dialog from '@/components/H5/H5Dialog/index.vue';
import H5CountrySelect from './components/H5CountrySelect/index.vue'
import H5AreaCodeSelect from './components/H5AreaCodeSelect/index.vue'
import { fetchData } from '@/utils/fetch';
import { buriedPointUtil, gaAnalytics } from '@/utils/utils';
import { useCommonToast } from '@/hooks/useCommonToast'

const emit = defineEmits(['updateAddress'])

const store = useStore()

const props = defineProps({
  currentCountry: Object,
  handleClose: Function,
  countryList: Object,
  addressType: Number,
});

const initStyles = () => {
  return {
    'border-radius': 0,
    'background': 'rgba(0, 0, 0, 1)'
  }
}

const showH5CountrySelect = ref(false);
const showAreaCodeSelect = ref(false)

const isValidAddress = ref(false);

const userInfo = computed(() => store.state.common.userInfo)

const getCountryList = computed(() => store.state.countryInfo);

const currentCountry = props.currentCountry || {
  countryUrl: JSON.parse(localStorage.getItem('addressForm'))?.searchImg,
  countryName: JSON.parse(localStorage.getItem('addressForm'))?.searchCountryText,
  countryCode: JSON.parse(localStorage.getItem('addressForm'))?.countryCode,
  phoneCode: JSON.parse(localStorage.getItem('addressForm'))?.phoneCode,
}
const handleClose = props.handleClose

const address = localStorage.getItem('addressForm') ? JSON.parse(localStorage.getItem('addressForm')) : {
  name: '',
  searchImg: currentCountry?.countryUrl,
  searchCountryText: currentCountry?.countryName,
  placeholderSearchText: currentCountry?.countryName,
  streetAddress: '',
  aptEtc: '',
  province: '',
  city: '',
  postCode: '',
  email: userInfo.value?.email || '',
  areaCodeImg: currentCountry?.countryUrl,
  placeholderAreaCodeText: currentCountry?.countryName,
  countryCode: currentCountry?.countryCode,
  phoneValue: '',
  phoneCode: currentCountry?.phoneCode,
  cpfNumber: '',
  rutNumber: ''
}

const hasAddressSaved = localStorage.getItem('addressForm') ? true : false
const innerHeight = ref(0)
onMounted(() => {
  gaAnalytics('mp_address_edit_page_view', 'mp_address_edit_page_view')
  buriedPointUtil({ eventId: 'MPAE501', others: { addressType: props.addressType } })
  innerHeight.value = window.innerHeight
})

const touched = reactive({
  name: false,
  streetAddress: false,
  aptEtc: false,
  province: false,
  city: false,
  postCode: false,
  email:false,
  cpfNumber: false,
  rutNumber: false
});

const verifyNameError = computed(() => {
  if (!formParams.name && touched.name) return 'The Name is required.';
  if (formParams.name.length > 100) return 'Cannot exceed 100 characters.';
  return '';
});

const verifyStreetAddressError = computed(() => {
  if (!formParams.streetAddress && touched.streetAddress) return 'The Street Address is required.';
  if (formParams.streetAddress.length > 200) return 'Cannot exceed 200 characters.';
  return '';
});

const verifyCpfNumberError = computed(() => {
  if (!formParams.cpfNumber && touched.cpfNumber) return 'The CPF Number is required.';
  if (formParams?.cpfNumber?.length > 32) return 'Cannot exceed 32 characters.';
  return '';
});

const verifyRutNumberError = computed(() => {
  if (!formParams.rutNumber && touched.rutNumber) return 'The RUT Number is required.';
  if (formParams?.rutNumber?.length > 32) return 'Cannot exceed 32 characters.';
  return '';
});

const verifyAptEtcError = computed(() => {
  if (!formParams.aptEtc && touched.aptEtc) return 'The Apt, suite, etc. (optional) is required.';
  if (formParams.aptEtc.length > 200) return 'Cannot exceed 200 characters.';
  return '';
});

const verifyAptProvinceError = computed(() => {
  if (!formParams.province && touched.province) return 'The State / Province is required.';
  return '';
});

const verifyAptCityError = computed(() => {
  if (!formParams.city && touched.city) return 'The City / Suburb is required.';
  return '';
});

const verifyAptPostCodeError = computed(() => {
  if (!formParams.postCode && touched.postCode) return 'The Post Code is required.';
  if (formParams.postCode.length > 20) return 'Cannot exceed 20 characters.';
  return '';
});

const verifyEmailError = computed(() => {
  if (formParams.email?.length > 100) return 'Cannot exceed 100 characters.';
  return '';
});

const formParams = reactive(address)
const showEditModel = ref(!!localStorage.getItem('addressForm'))

const showEdit = () => {
  buriedPointUtil({ eventId: "MPAC201" });
  showEditModel.value = false
}


const showCountrySelect = () => {
  showH5CountrySelect.value = true
}

const showAreaCode = () => {
  showAreaCodeSelect.value = true
}

const onClose = (item) => {
  showH5CountrySelect.value = false
  if (item) {
    formParams.searchImg = item.countryUrl
    formParams.searchCountryText = item.countryName
    formParams.countryCode = item.countryCode
  }

}

const closeH5AreaCodeSelect = (item) => {
  showAreaCodeSelect.value = false
  if (item) {
    formParams.areaCodeImg = item.countryUrl
    formParams.phoneCode = item.phoneCode
  }
}

const goback = () => {
  gaAnalytics('mp_cancel_click', 'mp_cancel_click')
  buriedPointUtil({ eventId: 'MPAE202', others: { addressType: props.addressType } })
  handleClose()
}

const handleInput = (e, file) => {
  formParams[file] = e.target.value;
  touched[file] = true
}

const requiredPrompt = () => {
  toast('The address information is incorrect, please check again.', {type: 'error'})
}

const saveAddress = async () => {
  if (!!verifyNameError.value || !formParams.name) {
    touched.name = true;
    requiredPrompt()
    return;
  }

  let extension;
  if (formParams.searchCountryText === 'Brazil') {
    if (!!verifyCpfNumberError.value || !formParams.cpfNumber) {
      touched.cpfNumber = true;
      requiredPrompt()
      return;
    }
    extension = formParams.cpfNumber
  }

  if (formParams.searchCountryText === 'Chile') {
    if (!!verifyRutNumberError.value || !formParams.rutNumber) {
      touched.rutNumber = true;
      requiredPrompt()
      return;
    }
    extension = formParams.rutNumber
  }
  
  if (!!verifyStreetAddressError.value || !formParams.streetAddress) {
    touched.streetAddress = true;
    requiredPrompt()
    return;
  }
  if (!!verifyAptEtcError.value || !formParams.aptEtc) {
    touched.aptEtc = true;
    requiredPrompt()
    return;
  }
  if (!!verifyAptProvinceError.value || !formParams.province) {
    touched.province = true;
    requiredPrompt()
    return;
  }
  if (!!verifyAptCityError.value || !formParams.city) {
    touched.city = true;
    requiredPrompt()
    return;
  }
  if (!!verifyAptPostCodeError.value || !formParams.postCode) {
    touched.postCode = true;
    requiredPrompt()
    return;
  }
  if (!!verifyEmailError.value) {
    touched.postCode = true;
    requiredPrompt()
    return;
  }

  gaAnalytics('mp_save_click', 'mp_save_click')

  const { code, data, msg } = await fetchData('/appb/b2b/Logistics/checkSupportDeliveryByAddress', {
    method: 'POST',
    body: JSON.stringify({...address, extension, streetAddr: address?.streetAddress, zip: address?.postCode, phone: address?.phoneValue,country: address?.searchCountryText, extAddr: address?.aptEtc})
  })

  if (code !== 200) {
    isValidAddress.value = true
    toast(msg, { type: 'error' })
    buriedPointUtil({eventId: 'MPAE401', others: { addressType: props.addressType } })
    return;
  }
  buriedPointUtil({ eventId: "MPAE201", others: { addressType: props.addressType } });
  gaAnalytics('mp_address_save_success', 'mp_address_save_success', {
    bdUserId: localStorage.getItem("dsp.uid"),
  })
  localStorage.setItem('addressForm', JSON.stringify({...formParams, streetAddr: formParams?.streetAddress, zip: formParams?.postCode, phone: formParams?.phoneValue,country: formParams?.searchCountryText}))
  handleClose(true)
  emit('updateAddress', {...formParams, streetAddr: formParams?.streetAddress, zip: formParams?.postCode, phone: formParams?.phoneValue,country: formParams?.searchCountryText})
}

// 识别地址相关逻辑
const { toast } = useCommonToast();

const identifyAddress = ref('')
const identifying = ref(false)
const textareaRef = ref(null)
const adjustHeight = () => {
  textareaRef.value.style.height = 'auto' 
  const height = textareaRef.value.scrollHeight;
  textareaRef.value.style.height = height + 'px';
}

const handlePasteAddress = async () => {
  const text = await navigator.clipboard.readText();
  if (!text.trim()) {
    toast('There is no available address. Please copy it and try again.')
    return;
  }
  identifyAddress.value = text;
  nextTick(() => {
    adjustHeight();
  })
}

const handleIdentify = async () => {
  buriedPointUtil({ eventId: 'MPAE203', others: { addressType: props.addressType } })
  identifying.value = true;

  const params = {
    address: identifyAddress.value,
  }

  try {
    const { code, data, msg } = await fetchData('/appb/alibaba-inter/addressResolution', {
      method: 'post',
      body: JSON.stringify(params)
    })
    if (code === 200) {
      formParams.name = data.name
      formParams.phoneCode = data.phoneCode
      formParams.phoneValue = data.phone
      formParams.streetAddress = data.streetAddr
      formParams.aptEtc = data.extAddr
      formParams.province = data.province
      formParams.city = data.city
      formParams.postCode = data.zip
      formParams.email = data.email
      identifying.value = false;

      const item = getCountryList.value.find(item => item.countryName === data.country)
      if (item) {
        formParams.searchCountryText = item.countryName
        formParams.searchImg = item.countryUrl
        formParams.countryCode = item.countryCode
      }
    }
    identifying.value = false;
    if (msg === 'SUCCESS') {
      toast('Success')
    } else {
      toast(msg)
    }
  } catch (error) {
    identifying.value = false;
  }
}
</script>

<style lang="less" scoped>

.h5-addressInfo {
  .addressInfoDialog-header {
    height: 48px;
    background-color: #FFFFFF;
    width: 100%;
    color: #232323;
    font-family: mon-700;
    line-height: 49px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    img {
      margin-right: 16px;
    }
    .addressInfoDialog-header-title {
      margin-left: 16px;
    }
  }
  .addressInfoDialog-content {
    border-radius: 8px;
    background-color: #FFFFFF;
    width: 100%;
    height: calc(var(--innerHeight) * 1px - 72px);
     overflow-y: scroll;
    .addressInfoDialog-main {
      display: flex;
      flex-direction: column;
      width: calc(100% - 32px);
      overflow: scroll;
      padding-top: 16px;
      margin: auto;

      &::-webkit-scrollbar {
        display: none;
      }
      .no-saved-address {
        position: relative;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        height: 48px;
        background-color: #ffebeb;
        color: #000000;
        font-size: 12px;
        font-family: mon-600;
        margin-bottom: 16px;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          width: 4px;
          height: 48px;
          background-color: #f53131;
        }
        img {
          margin-right: 8px;
          margin-left: 16px;
        }
      }
      .erro-deliver {
        display: flex;
        height: 72px;
        padding: 14px 12px;
        background-color: #ffebeb;
        color: #232323;
        font-size: 11px;
        font-family: mon-500;
        line-height: 16px;
        margin-bottom: 16px;
        img {
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }
      }
      .identify-address {
        position: relative;
        padding: 16px 12px;
        border: 0.5px solid #b2b7c2;
        border-radius: 4px;
        margin-bottom: 24px;
        text-align: right;

        .clear-btn {
          position: absolute;
          top: 16px;
          right: 12px;
          width: 18px;
        }

        textarea {
          color: #5c5c5c;
          font-size: 12px;
          font-family: mon-500;
          outline: none;
          border: none;
          resize: none;
          width: 100%;
          height: 100%;
          padding-right: 24px;
          &::placeholder {
            color: #b2b7c2;
            font-size: 12px;
            font-family: mon-500;
          }

          &::-webkit-scrollbar {
            display: none;
          }
        }

        .identify-address-btn {
          margin-top: 36px;
          height: 24px;
          padding: 0 12px;
          border-radius: 22px;
          background-color: #052784;
          color: #FFFFFF;
          font-size: 12px;
          font-family: mon-500;
          display: inline-flex;
          align-items: center;
          justify-content: center;

          &.identifying {
            border: none;
            background-color: #1C2026CC;
            color: white;
          }

          .loading {
            width: 12px;
            height: 12px;
            border-radius: 50%;
            margin-right: 6px;
            animation: loading 1s linear infinite;
            border: 1px solid white;
            border-top: 1px solid transparent;

            @keyframes loading {
              0% {
                transform: rotate(0deg);
              }

              100% {
                transform: rotate(360deg);
              }
            }
          }
        }
      }
      .addressInfoDialog-contact {
        font-size: 14px;
        color: #232323;
        font-family: mon-600;
        line-height: 18px;
      }
      .h5-form {
        .cpf-number {
          display: flex;
          align-items: center;
          gap: 8px;
          margin-top: 12px;

          .mgT0 {
            margin-top: 0 !important;
          }
          .left {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 57px;
            height: 40px;
            border: 0.5px solid #b2b7c2;
            border-radius: 4px;
            background-color: #ebebeb;
            color: #232323;
            font-size: 12px;
            font-family: mon-600;
          }
          .right {
            flex: 1;
          }
        }
        .form-item {
          width: 100%;
          display: flex;
          .form-name-input {
            height: 40px;
            border-radius: 4px;
            border: 0.5px solid #B2B7C2;
            color: #232323;
            font-size: 12px;
            font-family: mon-500;
            width: 100%;
            margin-top: 12px;
            padding-left: 12px;
            outline: none;

            &:focus {
              border: 1px solid #0069E0;
            }

            &::placeholder {
              color: #b2b7c2;
              font-size: 12px;
              font-family: mon-500;
            }
          }
          .form-item-input {
            margin-top: 8px;
          }
          .address-country-img {
            display: flex;
            flex-shrink: 0;
            min-width: 88px;
            height: 40px;
            border-radius: 4px;
            border: 0.5px solid #B2B7C2;
            display: flex;
            align-items: center;
            position: relative;

            .code-wrapper {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              margin-left: 6px;
              width: 100%;
              padding-right: 4px;
            }
            .country-img {
              margin-left: 10px;
            }
            .country-searchAreaCodeText {
              font-size: 12px;
              color: #232323;
              font-family: mon-500;
              
            }
          }
          .form-phone {
            display: flex;
            width: 100%;
            .areaCode-input {
              border-radius: 4px;
              height: 40px;
              border: 0.5px solid #B2B7C2;
              padding-left: 12px; 
              margin-left: 8px;
              padding-left: 12px;
              width: 100%;
              font-size: 12px;
              font-family: mon-500;
              color: #232323;
              outline: none;

              &:focus {
                border: 1px solid #0069E0;
              }
            }
          }
        }
        .address-error {
          color: #F53131;
          font-size: 10px;
          font-family: mon-500;
          margin-top: 4px;
          img {
            vertical-align: middle;
          }
        }
        .form-item-country {
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
        }
        .address-line {
          height: 0.5px;
          background-color: #E4E6EB;
          width: 100%;
          margin-top: 24px;
          margin-bottom: 16px;

          &:last-child {
            background-color: transparent;
          }
        }
        .addressInfoDialog-region {
          height: 39px;
          border-radius: 4px;
          width: 100%;
          border: 0.5px solid #B2B7C2;
          margin-top: 12px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .addressInfoDialog-region-left {
            padding-left: 10px;
            display: flex;
            align-items: center;
            .addressInfoDialog-region-searchCountryText {
              font-family: mon-500;
              color: #232323;
              font-size: 12px;
              margin-left: 6px;
            }
          }
          .addressInfoDialog-region-right {
            padding-right: 8px;
          }
        }
      }
    }
    .address-footer {
      position: relative;
      top: -12px;
      height: 64px;
      width: 100%;
      z-index: 1000;
      background-color: #FFFFFF;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      justify-content: space-between;
      gap: 8px;
      .address-footer-cancel, .address-footer-save {
        height: 40px;
        line-height: 40px;
        text-align: center;
        font-size: 14px;
        font-family: mon-600;
        width: 160px;
        border-radius: 20px;
        margin-top: 8px;
        position: relative;
        z-index: 1000;
      }
      .address-footer-save {
        background-color: #052785;
        color: #FFFFFF;
        margin-right: 12px;
      }
      .address-footer-cancel {
        background-color: #FFFFFF;
        color: #232323;
        border: 0.5px solid #B2B7C2;
        margin-left: 12px;
      }
    }

    .edit-address-main {
      padding: 24px 16px;
      .edit-address-title {
        font-size: 14px;
        color: #232323;
        font-family: mon-600;
        line-height: 18px;
      }
      .edit-address-content {
        border: 1px solid #052785;
        border-radius: 4px;
        padding: 12px;
        display: flex;
        margin-top: 12px;
        .address-detail-info {
          margin-left: 12px;
          .address-detail-name {
            line-height: 16px;
            color: #232323;
            font-size: 12px;
            font-family: mon-600;
          }
          .address-detail-streetAddress, .address-detail-aptEtc, .address-detail-province {
            color: #5C5C5C;
            font-family: mon-500;
            font-size: 12px;
            line-height: 16px;
            margin-top: 2px;
          }
          .address-detail-btn {
            margin-top: 8px;
            font-size: 12px;
            color: #009DE0;
            line-height: 16px;
            font-family: mon-600;
          }
        }
      }
    }
  }
}
</style>