
<template>
  <div class="helpCenter-box">
    <img :src="helpCenterImage" alt="" />
    </div>
</template>

<script setup>
import { computed, watch } from "vue";
import { useStore } from "vuex";

const store = useStore();

const helpCenterImage = computed(() => store.state.common.helpCenterImage)

</script>

<style>
@import './index.less';
</style>