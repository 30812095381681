
<template>
  <div class="headerTopSource">
    <div class="headerTopSource-content">
      <img src="@/assets/header/header-source.svg" alt="" class="HeaderTopSource-img">
    <div class="headerTopSource-main">
      <div class="headerTopSource-title">products.bestdealer.com</div>
      <div class="headerTopSource-desc">Directly purchase from the source manufacturer.</div>
    </div>
    </div>
  </div>
</template>

<script setup></script>

<style>
@import './index.less';
</style>