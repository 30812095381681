<template>
  <div class="h5Protection-header">
    <div class="h5Protection-header-content" ref="scrollContainer">
      <div class="h5Protection-header-item">
        <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/assure-icon.svg" alt="" style="width: 24px;">
        <span class="h5Protection-header-buyer">Buyer</span>
        <span class="h5Protection-header-guarantee">Guarantee</span>
        <span class="h5Protection-header-purchase">Purchase Protection</span>
      </div>
      <div class="h5Protection-header-item h5Protection-header-item2">
        SSL encryption and PCI DSS data protection.
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from "vue";

const scrollContainer = ref(null);
let intervalId;

onMounted(() => {
  startScrolling();
});

onUnmounted(() => {
  clearInterval(intervalId);
});

const startScrolling = () => {
  const container = scrollContainer.value;
  const contentHeight = 40; // 每个滚动项的高度
  let scrollPosition = 0;

  // 复制第一个元素，实现无缝滚动
  const firstItem = container.children[0].cloneNode(true);
  container.appendChild(firstItem);

  intervalId = setInterval(() => {
    scrollPosition += contentHeight;
    container.style.transition = "transform 1s ease-in-out";
    container.style.transform = `translateY(-${scrollPosition}px)`;

    if (scrollPosition >= contentHeight * (container.children.length - 1)) {
      setTimeout(() => {
        container.style.transition = "none"; // 取消动画，瞬间回到初始位置
        container.style.transform = `translateY(0)`;
        scrollPosition = 0;
      }, 1000);
    }
  }, 5000);
};
</script>

<style scoped>
.h5Protection-header {
  height: 40px;
  background: #ebf5ff;
  border-radius: 4px;
  width: calc(100% - 24px);
  margin: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.h5Protection-header-content {
  height: 40px;
  line-height: 40px;
  display: flex;
  flex-direction: column;
}

.h5Protection-header-item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.h5Protection-header-item2 {
  display: flex;
  justify-content: center;
  font-size: 12px;
  color: #232323;
  font-family: mon-500;
  letter-spacing: -0.4px;
}

.h5Protection-header-buyer {
  margin-left: 8px;
  font-size: 13px;
  font-family: mon-700;
  color: #232323;
}

.h5Protection-header-guarantee {
  color: #f57232;
  font-size: 13px;
  font-family: mon-700;
  margin-left: 4px;
}

.h5Protection-header-purchase {
  font-family: mon-600;
  font-size: 13px;
  color: #232323;
  margin-left: 6px;
  letter-spacing: -4%;
}
</style>
