import { createStore } from "vuex";
import common from "./common/index.js";
import search from "./search/index.js";
import h5Module from "./modules/H5/index.js";
import cart from "./cart/index.js";
import { productInitList } from '../common/productInitData';
import { fetchData } from "../utils/fetch.js";

const store = createStore({
  modules: {
    common,
    search,
    h5Module,
    cart
  },
  state() {
    return {
      debugMode: false,
      count: 0,
      productList: [],
      productListCopy: productInitList, // 首页推荐商品列表兜底数据，防止接口异常
      recommendationList: [],
      countryInfo: [],
      currencyList: [],
      currencyData: {},
      isLoginVisible: false,
      offerDetail: {},
      supplierFee: {
        suppliersUrl: "",
        suppliersName: "",
        supplierFee: 0,
      },
      canvasImgSelectList: [],
      searchInfo: {
        pageNow: 1,
        pageSize: 20,
        keyword: "",
        imageId: 0,
        region: "",
      },
      total: 0,
      loading: false,
      mergedArray: [],
      currentCountry: {},
      firstrAttributesimages: [],
      currentHoverdImage: "",
      emptyObj: {},
      currentMainImage: "",
      initSkeleton: undefined,
      loadMoreSleleton: false,
      passiveLogin: 0,
      isMobile: /iphone|ipod|ipad|android|blackberry|windows phone/i.test(navigator.userAgent.toLowerCase()) ? "MOBILE" : "PC",
      h5SendSuccess: false,
    };
  },
  getters: {
    doubleCount(state) {
      return state.count * 2;
    },
    countryInfo: (state) => state.countryInfo,
    getCurrencyList: (state) => state.currencyList,
    getLoginVisible: (state) => state.loginVisible,
    getCurrencyData: (state) => state.currencyData,
  },
  mutations: {
    setCountryInfo(state, payload) {
      state.countryInfo = payload;
    },
    setCurrencyList(state, payload) {
      state.currencyList = payload;
    },
    setCurrencyData(state, payload) {
      state.currencyData = payload;
    },
    setProductList(state, payload) {
      state.productList = payload;
    },
    setRecommendationList(state, payload) {
      state.recommendationList = payload;
    },
    setLoginVisible(state, payload) {
      state.isLoginVisible = payload;
    },
    setOfferDetail(state, payload) {
      state.offerDetail = payload;
    },
    setSupplierFee(state, payload) {
      state.supplierFee = payload;
    },
    setCanvasImgSelectList(state, payload) {
      state.canvasImgSelectList = payload;
    },
    setTotal(state, payload) {
      state.total = payload;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setSearchInfo(state, payload) {
      state.searchInfo = payload;
    },
    setMergedArray(state, payload) {
      state.mergedArray = payload;
    },
    setCurrentCountry(state, payload) {
      state.currentCountry = payload;
    },
    setFirstrAttributesimages(state, payload) {
      state.firstrAttributesimages = payload;
    },
    setCurrentHoverdImage(state, payload) {
      state.currentHoverdImage = payload;
    },
    setEmptyObj(state, payload) {
      state.emptyObj = payload;
    },
    setCurrentMainImage(state, payload) {
      state.currentMainImage = payload;
    },
    setInitSkeleton(state, payload) {
      state.initSkeleton = payload;
    },
    setLoadMoreSleleton(state, payload) {
      state.loadMoreSleleton = payload;
    },
    setPassiveLogin(state, payload) {
      state.passiveLogin = payload;
    },
    setDebugMode(state, payload) {
      state.debugMode = payload;
    },
    increment(state) {
      state.count++;
    },
    decrement(state) {
      state.count--;
    },
    setProductListCopy(state, payload) {
      state.productListCopy = payload;
    },
    setH5SendSuccess(state, payload) {
      state.h5SendSuccess = payload;
    },
  },
  actions: {
    incrementAsync({ commit }) {
      setTimeout(() => {
        commit("increment");
      }, 1000);
    },
    setProduct(ctx, payload) {
      const { state, commit } = ctx;
      const list = state.productList.map((v) => {
        return {
          ...v,
          priceInfo: { price: v.priceInfo.price, ...payload },
        };
      });
      commit("setProductList", list);
    },
    setSearchProduct(ctx, payload) {
      const { state, commit } = ctx;
      const { rows = [], total, searchInfo } = payload;
      const currencyData = JSON.parse(localStorage.getItem("currencyData"));
      const list = rows.map((v) => {
        return {
          ...v,
          priceInfo: { price: v.priceInfo.price, ...currencyData },
        };
      });
      commit("setSearchInfo", { ...state.searchInfo, ...searchInfo });
      commit("setProductList", list);
      commit("setCanvasImgSelectList", []);
      commit("setTotal", total);
    },
    setImgSearch(ctx, payload) {
      const { state, commit } = ctx;
      const {
        canvasImgList = [],
        rows,
        total,
        searchInfo,
        currentMainImage = "",
      } = payload;

      const currencyData = JSON.parse(localStorage.getItem("currencyData"));
      const list = rows.map((v) => {
        return {
          ...v,
          priceInfo: { price: v.priceInfo.price, ...currencyData },
        };
      });
      if (canvasImgList?.length) {
        commit("setCanvasImgSelectList", canvasImgList);
      }
      commit("setSearchInfo", { ...state.searchInfo, ...searchInfo });
      commit("setProductList", list);
      commit("setTotal", total);
      if (!!currentMainImage) {
        commit("setCurrentMainImage", currentMainImage);
      }
    },
    async initHomeData(ctx, payload) {
      const { state, commit, dispatch } = ctx;
      commit('setLoadMoreSleleton', true);
      const { adsWord } = payload;
      const queryData = adsWord ? `/dsp-app/alibaba-common/recommend/productListV2?itemNumber=60&adsWord=${encodeURIComponent(adsWord).replace(/%20/g, "+")}` : "/dsp-app/alibaba-common/recommend/productListV2?itemNumber=20"
      const { data, code } = await fetchData(queryData)
      if (code === 200 && data && data.length) {
        commit('setProductList', data)
        commit('setProductListCopy', data); // 备份
        dispatch('setProduct', JSON.parse(localStorage.getItem('currencyData')) || {})
      } else {
        const currencyData = JSON.parse(localStorage.getItem('currencyData')) || {}
        const list = state.productListCopy.map(v => {
          return {
            ...v,
            priceInfo: { price: v.priceInfo.price, ...currencyData},
          }
        })
        commit('setProductList', list)
      }
      commit('setLoadMoreSleleton', false);
    },
    async loadMoreHomeData(ctx, payload) {
      const { state, commit, dispatch } = ctx;
      commit('setLoadMoreSleleton', true);
      const { data, code } = await fetchData('/dsp-app/alibaba-common/recommend/productListV2?itemNumber=20')
      if (code === 200 && data && data.length) {
        commit('setProductList', [...state.productList, ...data])
        commit('setProductListCopy', [...state.productList, ...data]); // 备份
        dispatch('setProduct', JSON.parse(localStorage.getItem('currencyData')) || {})
      } else {
        const currencyData = JSON.parse(localStorage.getItem('currencyData')) || {}
        const list = state.productListCopy.map(v => {
          return {
            ...v,
            priceInfo: { price: v.priceInfo.price, ...currencyData},
          }
        })
        commit('setProductList', list)
      }
      commit('setLoadMoreSleleton', false);
      commit('search/SET_PRE_SEARCH', false);
    }
  },
});

export default store;
