<template>

  <div class="empty-main" v-if="emptyObj?.showEmpty" :styles="styles">
    <div class="empty-main-content">
      <img src="@/assets/empty.png" alt="" />
      <div class="text-box">
        <div class="empty-main-header">Sorry...</div>
        <div class="empty-main-desc">
          {{ emptyObj?.msg }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore(); 

const emptyObj = computed(() => store.state.emptyObj)

const props = defineProps({
  styles: {
    type: String,
    required: false,
  },
})

const styles = props.styles

watch(emptyObj, (newData) => {
  if (newData) {
    emptyObj.value = newData;
  }
});

</script>

<style scoped lang="less">
.empty-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 48px);
  .empty-main-content {
    text-align: center;
  }
  img {
    height: 160px;
    width: 160px;
  }
  .text-box {
    width: 219px;
    height: 68px;
    text-align: center;
  }

  .empty-main-header {
    font-family: mon-600;
    font-size: 14px;
    line-height: 18px;
    color: #232323;
    text-align: center;
    margin: auto;
  }

  .empty-main-desc {
    font-family: mon-500;
    font-size: 12px;
    line-height: 20px;
    margin-top: 16px;
    text-align: center;
    margin: 4px auto 0px;
  }

  .empty-main-btn {
    width: 154px;
    height: 36px;
    text-align: center;
    line-height: 36px;
    color: #ffffff;
    border-radius: 20px;
    background-color: #052784;
    cursor: pointer;
    margin: 32px auto 0px;
    transition: all 0.3s;
  }

  .empty-main-btn:hover {
    background-color: rgba(5, 29, 132, 0.9);
  }

  .empty-main-btn:active {
    background-color: rgba(5, 29, 132, 0.8);
    transform: scale(0.9);
  }
}
</style>