<template>
  <div style="width: 100%; height: 500px;">

  </div>
</template>

<script setup>
import { useCommonToast } from "@/hooks/useCommonToast";
import { onMounted } from "vue";

const { toast } = useCommonToast();

onMounted(() => {
  toast('In the development of web functions, please go to the mobile end and reopen this email for further operations.', { type: 'error' })
  setTimeout(() => {
    window.location.href = '/'
  }, 2000)
})
</script>

<style lang="less" scoped>

</style>