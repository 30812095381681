<template>
  <canvas ref="canvas" class="animation-canvas"></canvas>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';

// 定义props
const props = defineProps({
  startElement: {
    type: Object,
    default: null
  },
  endElement: {
    type: Object,
    default: null
  },
  color: {
    type: String,
    default: '#4B7BF5'
  },
  size: {
    type: Number,
    default: 20
  },
  duration: {
    type: Number,
    default: 500
  },
  parabolicHeight: {
    type: Number,
    default: 200
  },
  autoPlay: {
    type: Boolean,
    default: true
  }
});

// 定义事件
const emit = defineEmits(['animationStart', 'animationEnd']);

// 创建响应式引用
const canvas = ref(null);
const ctx = ref(null);
const animationId = ref(null);
const isAnimating = ref(false);

// 组件挂载后初始化canvas
onMounted(() => {
  // 初始化canvas上下文
  ctx.value = canvas.value.getContext('2d');
  
  // 设置canvas大小为视口大小
  setCanvasSize();
  
  // 监听窗口大小变化
  window.addEventListener('resize', setCanvasSize);
  
  // 如果设置了自动播放，则立即启动动画
  if(props.autoPlay && props.startElement && props.endElement) {
    play();
  }
});

// 组件卸载前清理
onBeforeUnmount(() => {
  // 移除事件监听
  window.removeEventListener('resize', setCanvasSize);
  
  // 取消可能正在进行的动画
  if (animationId.value) {
    cancelAnimationFrame(animationId.value);
  }
});

// 监听属性变化
watch(() => props.startElement, (newVal) => {
  if (props.autoPlay && newVal && props.endElement) {
    play();
  }
});

watch(() => props.endElement, (newVal) => {
  if (props.autoPlay && newVal && props.startElement) {
    play();
  }
});

// 设置canvas大小
function setCanvasSize() {
  if (canvas.value) {
    canvas.value.width = window.innerWidth;
    canvas.value.height = window.innerHeight;
  }
}

// 清除canvas
function clearCanvas() {
  if (ctx.value) {
    ctx.value.clearRect(0, 0, canvas.value.width, canvas.value.height);
  }
}

// 公开的播放方法
function play() {
  if (!props.startElement || !props.endElement || isAnimating.value) return;
  
  // 清除canvas
  clearCanvas();
  
  // 获取起点和终点元素的位置
  const startRect = props.startElement.getBoundingClientRect();
  const endRect = props.endElement.getBoundingClientRect();
  
  // 计算起点（按钮中心）
  const startX = startRect.left + startRect.width / 2;
  const startY = startRect.top + startRect.height / 2;
  
  // 计算终点（购物车中心）
  const endX = endRect.left + endRect.width / 2;
  const endY = endRect.top + endRect.height / 2;
  
  // 开始动画
  isAnimating.value = true;
  emit('animationStart');
  createFlyingElement(startX, startY, endX, endY);
}

// 创建飞行元素并执行抛物线动画
function createFlyingElement(startX, startY, endX, endY) {
  // 飞行元素的大小
  const elementSize = props.size;
  
  // 使用圆形作为飞行元素
  const element = {
    x: startX,
    y: startY,
    size: elementSize,
    draw: function() {
      ctx.value.beginPath();
      ctx.value.arc(this.x, this.y, this.size / 2, 0, Math.PI * 2);
      ctx.value.fillStyle = props.color;
      ctx.value.fill();
      ctx.value.closePath();
    }
  };
  
  // 计算抛物线控制点（顶点）
  const controlX = (startX + endX) / 2;
  const controlY = Math.min(startY, endY) - props.parabolicHeight;
  
  // 动画参数
  let t = 0; // 时间参数，范围从0到1
  const duration = props.duration; // 动画持续时间（毫秒）
  const startTime = performance.now();
  
  // 动画函数
  function animate(currentTime) {
    // 计算动画进度
    const elapsed = currentTime - startTime;
    t = Math.min(elapsed / duration, 1);
    
    // 使用贝塞尔曲线计算当前位置（平滑的抛物线）
    // 二次贝塞尔曲线公式：B(t) = (1-t)²P₀ + 2(1-t)tP₁ + t²P₂
    const tSquared = t * t;
    const oneMinusT = 1 - t;
    const oneMinusTSquared = oneMinusT * oneMinusT;
    
    element.x = oneMinusTSquared * startX + 2 * oneMinusT * t * controlX + tSquared * endX;
    element.y = oneMinusTSquared * startY + 2 * oneMinusT * t * controlY + tSquared * endY;
    
    // 元素大小随动画进度缩小
    element.size = elementSize * (1 - 0.5 * t);
    
    // 清除canvas并绘制元素
    clearCanvas();
    element.draw();
    
    // 如果动画未结束，继续执行
    if (t < 1) {
      animationId.value = requestAnimationFrame(animate);
    } else {
      // 动画结束，清除canvas
      clearCanvas();
      isAnimating.value = false;
      emit('animationEnd');
    }
  }
  
  // 开始动画
  animationId.value = requestAnimationFrame(animate);
}

// 向外部导出方法
defineExpose({
  play,
  clearCanvas
});
</script>

<style scoped>
.animation-canvas {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2000;
}
</style> 