<template>
  <div class="country-select">
    <div class="country-select-top">
      <img src="@/assets/country-select.svg" alt="" />
    </div>
    <div class="country-select-hideline"></div>
    <div class="country-container">
      <div class="country-language-title">Language</div>
      <div>
        <CustomSelect
          v-model="language"
          :options="languageList"
          @updateModelValue="handleLanguageChange"
        />
      </div>
      <div class="country-language-title country-Currency-title">Currency</div>
      <div>
        <CustomSelect
          v-model="selectedValue"
          :options="currencyList"
          @updateModelValue="emitFn"
        />
      </div>
      <div class="exchange-rate">
        <!-- $ -->
        <span class="exchange-rate-unit"
          >1 {{ currencyData?.fromCurrency }} ≈
          {{ currencyData?.targetUnit }}</span
        >
        <span class="exchange-rate-current">{{ currencyData?.rate }}</span>
        {{ currencyData?.targetCurrency }}
      </div>
      <div class="exchange-desc">Data is for reference only</div>
      <div class="country-confirm" @click="saveCurrency">Save</div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from "vue";
import CustomSelect from "@/components/CustomSelect/index.vue";
import { useStore } from "vuex";

const props = defineProps({
  handleClickOutside: Function,
});

const handleClickOutside = props.handleClickOutside;

const selectedValue = ref("");
const language = ref("English");
const currencyList = [];
const languageList = ref(["English", "others"]);
const currencyData = ref({});

const store = useStore();

const currency = computed(() => store.getters.getCurrencyList).value;
currency.forEach((v) => {
  currencyList.push(v.targetCurrency);
});

if (!!localStorage.getItem("currencyData")) {
  const data = JSON.parse(localStorage.getItem("currencyData"));
  selectedValue.value = data?.targetCurrency;
  currencyData.value = data;
} else {
  selectedValue.value = currency[0]?.targetCurrency;
  currencyData.value = currency[0];
}

// 切换语种
const handleLanguageChange = (val) => {
  if (val === "others") {
    window.open(
      "https://chromewebstore.google.com/detail/google-translate/aapbdbdomjkkjkaonfhkkikfgjllcleb",
      "_blank"
    );
  }
};

const emitFn = (e) => {
  const data = currency.filter((v) => v.targetCurrency === e)[0];
  currencyData.value = data;
};

const saveCurrency = (event) => {
  localStorage.setItem("currencyData", JSON.stringify(currencyData.value));
  store.commit("setCurrencyData", currencyData.value);
  store.dispatch("setProduct", currencyData.value);
  handleClickOutside(event);
};
</script>

<style scoped lang="less">
.country-select {
  position: absolute;
  border: 0.5px solid #b2b7c2;
  border-radius: 12px;
  width: 180px;
  top: 50px;
  background-color: #ffffff;
  transform: translateX(-50%);
  left: 50%;
  z-index: 99;
  .country-select-top {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -16px;
    z-index: 8;
  }
  .country-select-hideline {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0px;
    height: 2px;
    width: 50px;
    z-index: 9;
    background-color: #ffffff;
  }
  .country-container {
    padding: 20px 12px;
    box-shadow: -6px 0px 12px rgba(110, 113, 122, 0.05),
                6px 0px 12px rgba(110, 113, 122, 0.05),
                0px -6px 12px rgba(110, 113, 122, 0.05),
                0px 6px 12px rgba(110, 113, 122, 0.05);
    .country-language-title {
      font-family: mon-600;
      font-size: 12px;
      color: #232323;
      line-height: 16px;
      letter-spacing: -2%;
    }
    .country-Currency-title {
      margin-top: 16px;
    }
  }
  .country-item {
    width: 120px;
    background-color: #ffffff;
  }
  .exchange-rate {
    font-size: 11px;
    line-height: 14px;
    letter-spacing: -2%;
    font-family: mon-600;
    color: #232323;
    margin-top: 12px;
    .exchange-rate-unit {
      display: inline-block;
    }
    .exchange-rate-current {
      color: #052784;
    }
  }
  .exchange-desc {
    font-size: 9px;
    font-family: mon-400;
    line-height: 14px;
    letter-spacing: -2%;
    color: #6e717a;
  }
  .country-confirm {
    width: 100%;
    height: 32px;
    margin-top: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: mon-600;
    font-size: 12px;
    letter-spacing: -2%;
    text-align: center;
    line-height: 24px;
    color: #ffffff;
    background-color: rgba(5, 29, 132, 1);
    border-radius: 16px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .country-confirm:hover {
    background-color: rgba(5, 29, 132, 0.9);
  }
  .country-confirm:active {
    background-color: rgba(5, 29, 132, 0.8);
    transform: scale(0.9);
  }
}
</style>
