<template>
  <div class="h5-sourcing">
    <div class="h5-sourcing-top-line"></div>
    <img
      src="@/assets/H5/offer/sourcing-close.svg"
      alt=""
      class="h5-sourcing-close"
      @click="closeSourcing"
    />

    <!-- 存在sku的情况 -->
    <div v-if="!!offerData?.productSkuInfos" class="h5-sourcing-content">
      <div class="h5-sourcing-header">
        <img
          :src="item?.skuImageUrl || defaultImage"
          alt=""
          v-for="(item, index) in firstrAttributes"
          :key="index"
          class="h5-sourcing-header-item"
          :style="getItemStyle(index)"
        />
      </div>
      <div class="h5-sourcing-main">
        <div class="h5-sourcing-main-header">{{ offerData?.subjectTrans }}</div>
        <div class="h5-sourcing-main-line"></div>
        <H5PriceInterval :offerData="offerData" :currencyData="currencyData" />

        <div class="attributes">
          <div class="firstAttributes">
            <div class="firstAttributes-title">
              {{
                (firstrAttributes?.[0]?.attributeNameTrans || "")
                  .charAt(0)
                  .toUpperCase() +
                (firstrAttributes?.[0]?.attributeNameTrans || "").slice(1)
              }}:
              <span class="firstAttributes-title-sku">{{
                currentFirstSku
              }}</span>
            </div>
            <div class="firstAttributes-item">
              <div
                v-for="(item, index) in firstrAttributes"
                :key="index"
                @click="clickFirstAttributes(item, index)"
                :class="{
                  'firstAttributes-container': true,
                }"
              >
                <div
                  :class="{
                    activedAttributes: firstActivedAttributesIndex === index,
                  }"
                >
                  <img :src="item?.skuImageUrl || defaultImage" alt="" />
                </div>
                <div class="firstAttributes-item-title">
                  <span>
                    {{
                      item?.valueTrans?.charAt(0).toUpperCase() +
                      item?.valueTrans?.slice(1)
                    }}
                  </span>
                </div>
                <div class="firstAttributes-item-total" v-if="item?.total">
                  ×{{ item?.total }}
                </div>
              </div>
            </div>
          </div>

          <div class="secondAttributes">
            <div class="secondAttributes-title">
              <div
                class="secondAttributes-title-left"
                :style="{
                  color: !isAddCarts
                    ? '#232323'
                    : offerData?.minOrderQuantity > estimatedQuntity
                    ? '#F53131'
                    : '#232323',
                }"
              >
                {{
                  secondAttributesQuantityList[0]?.skuAttributes?.[1]
                    ? `${
                        offerData?.productSkuInfos?.[0].skuAttributes?.[1]
                          ?.attributeNameTrans
                      }: ${
                        firstrAttributes[firstActivedAttributesIndex]?.total ||
                        0
                      }`
                    : `Quantity: ${
                        firstrAttributes[firstActivedAttributesIndex]?.total ||
                        0
                      }`
                }}
              </div>
              <div
                class="secondAttributes-title-right"
                :style="{
                  color: !isAddCarts
                    ? '#232323'
                    : offerData?.minOrderQuantity > estimatedQuntity
                    ? '#F53131'
                    : '#232323',
                }"
              >
                Min. Order: {{ offerData?.minOrderQuantity }} PCS
              </div>
            </div>
            <div class="secondAttributes-item">
              <div
                v-for="(item, index) in secondAttributesQuantityList"
                :key="index"
                class="secondAttributes-container"
              >
                <div class="secondAttributes-valueTrans">
                  {{ item?.skuAttributes?.[1]?.valueTrans }}
                </div>
                <div class="secondAttributes-container-main">
                  <div class="secondAttributes-price">
                    <span
                      >{{ currencyData?.targetUnit
                      }}{{
                        bankersRound(
                          renderPrice(item?.price, item) * currencyData?.rate,
                          2
                        )
                      }}
                    </span>
                    /
                    <span
                      >{{ currencyData?.fromUnit
                      }}{{ renderPrice(item?.price) }}</span
                    >
                  </div>
                  <div class="secondAttributes-inStock">
                    {{ item?.amountOnSale }} in stock
                  </div>

                  <div class="secondAttributes-btn">
                    <div
                      class="secondAttributes-btn-sub"
                      @click="attributesSub(item, index)"
                    >
                      <img
                        :id="'secondAttributes-btn-sub-' + index"
                        :src="secondAttributesBtnReduce"
                        alt=""
                      />
                    </div>
                    <input
                      class="secondAttributes-btn-input"
                      v-model="item.quantity"
                      @change="(e) => changeAttributes(item, index, e)"
                    />
                    <div
                      class="secondAttributes-btn-add"
                      :class="{ active: item.quantity > 0 }"
                      @click="attributesAdd(item, index)"
                    >
                      <img
                        v-if="item.quantity > 0"
                        src="@/assets/H5/offer/plus-icon-white.svg"
                        alt=""
                      />
                      <img
                        v-else
                        :id="'secondAttributes-btn-add-' + index"
                        :src="secondAttributesBtnAdd"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="h5-sourcing-footer-line"></div>

        <div class="h5-delivery">
          <div class="h5-delivery-header" ref="deliveryRef">Delivery</div>
          <div class="h5-shipto">
            <div class="h5-shipto-left" @click="showSelectCountry">
              <img src="@/assets/H5/offer/shipto.svg" alt="" />
              <span class="h5-shipto-left-title">Shipping to:</span>
              <img
                :src="currentCountry?.countryUrl"
                alt=""
                class="ship-country-img"
              />
              <span class="ship-main-title">{{
                currentCountry?.countryName
              }}</span>
              <img
                src="@/assets/H5/offer/shipto-right.svg"
                alt=""
                @click="handleH5SourcingPopup"
              />
            </div>
          </div>
          <div class="h5-supplier">
            <div class="h5-supplier-left">
              <img
                src="@/assets/H5/offer/supplier.svg"
                alt=""
                class="h5-supplier-left-1"
              />
              <span class="h5-supplier-left-2">Fulfillment Supplier:</span>
              <img
                class="h5-supplier-left-3"
                src="@/assets/H5/offer/logo.svg"
                alt=""
              />
            </div>
            <div class="h5-supplier-right h5-supplier-right-2">
              <!-- <span class="h5-supplier-right-1">1688.com</span> -->
              <!-- <span class="h5-supplier-right-2"
                >Professional international fulfillment services</span
              > -->
              Professional international fulfillment services
            </div>
          </div>
          <div class="h5-supplier">
            <div class="h5-supplier-left">
              <img
                src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/payment-icon.svg"
                alt=""
                class="h5-supplier-left-1"
              />
              <span class="h5-supplier-left-2">Payment Methods:</span>
              <img
                class="h5-supplier-left-2-img"
                src="@/assets/offer/pay-method.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 无sku的情况 -->
    <div v-if="!offerData?.productSkuInfos" class="h5-nosku-sourcing">
      <div class="h5-nosku-main">
        <div class="h5-sourcing-main-header">{{ offerData?.subjectTrans }}</div>
        <div class="h5-sourcing-main-line"></div>
        <div class="h5-interval" v-if="!offerData?.productSkuInfos?.[0]?.price">
          <div
            v-for="(item, index) in offerData?.productSaleInfo?.priceRangeList"
            class="h5-interval-item"
            :key="index"
          >
            <div class="interval-item-pcs">
              {{ item?.startQuantityStr }} PCS
            </div>
            <div class="interval-item-price">
              <span>{{ currencyData?.targetCurrency }}</span>
              <span>{{ currencyData?.targetUnit }}</span>
              <span>{{
                bankersRound(currencyData?.rate * item?.price, 2)
              }}</span>
            </div>
          </div>
        </div>
        <div class="nosku-attributes">
          <div class="noAttributes-header">
            <div
              class="noAttributes-header-left"
              :style="{
                color: !isAddCarts
                  ? '#232323'
                  : offerData?.minOrderQuantity > estimatedQuntity
                  ? '#F53131'
                  : '#232323',
              }"
            >
              Quantity: {{ noSkuNum }}
            </div>
            <div
              class="noAttributes-header-right"
              :style="{
                color: !isAddCarts
                  ? '#232323'
                  : offerData?.minOrderQuantity > estimatedQuntity
                  ? '#F53131'
                  : '#232323',
              }"
            >
              Min. Order: {{ offerData?.minOrderQuantity }} Piece
            </div>
          </div>
          <div class="noAttributes">
            <div class="secondAttributes-price">
              <span
                >{{ currencyData?.targetUnit }}
                {{ bankersRound(renderNoSkuPrice() * currencyData?.rate, 2) }}
              </span>
              /
              <span>{{ currencyData?.fromUnit }}{{ renderNoSkuPrice() }}</span>
            </div>
            <div class="secondAttributes-inStock">
              {{ offerData?.productSaleInfo?.amountOnSale }} InStock
            </div>
            <div class="secondAttributes-btn">
              <div class="secondAttributes-btn-sub" @click="noAttributesSub()">
                <img src="@/assets/offer/sub.svg" alt="" />
              </div>
              <input
                class="secondAttributes-btn-input"
                @change="(e) => changeNoAttributes(e)"
                v-model="noSkuNum"
              />
              <div class="secondAttributes-btn-add" @click="noAttributesAdd">
                <img src="@/assets/offer/add.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="h5-delivery">
        <div class="h5-delivery-header" ref="deliveryRef">Delivery</div>
        <div class="h5-shipto">
          <div class="h5-shipto-left" @click="showSelectCountry">
            <img src="@/assets/H5/offer/shipto.svg" alt="" />
            <span class="h5-shipto-left-title">Shipping to:</span>
            <img
              :src="currentCountry?.countryUrl"
              alt=""
              class="ship-country-img"
            />
            <span class="ship-main-title">{{
              currentCountry?.countryName
            }}</span>
            <img
              src="@/assets/H5/offer/shipto-right.svg"
              alt=""
              @click="handleH5SourcingPopup"
            />
          </div>
        </div>
        <div class="h5-supplier">
          <div class="h5-supplier-left">
            <img
              src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/supplier-icon.svg"
              alt=""
              class="h5-supplier-left-1"
            />
            <span class="h5-supplier-left-2">Fulfillment Supplier:</span>
            <img
              class="h5-supplier-left-3"
              src="@/assets/H5/offer/logo.svg"
              alt=""
            />
          </div>
          <div class="h5-supplier-right">
            <span class="h5-supplier-right-1">1688.com</span>
            <span class="h5-supplier-right-2"
              >shipping protection available with 1688 official partner</span
            >
          </div>
        </div>
        <div class="h5-supplier">
          <div class="h5-supplier-left">
            <img
              src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/payment-icon.svg"
              alt=""
              class="h5-supplier-left-1"
            />
            <span class="h5-supplier-left-2">Payment Methods:</span>
            <img
              class="h5-supplier-left-2-img"
              src="@/assets/offer/pay-method.png"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>

    <div class="h5-sourcing-footer">
      <div class="h5-sourcing-footer-price">
        <div class="h5-sourcing-footer-price-top">
          <div class="h5-sourcing-footer-price-total">
            Total <span>{{ estimatedQuntity }}</span> items
          </div>
          <div class="h5-sourcing-footer-price-num">
            <span>Subtotal:</span>
            <!-- <span>{{ currencyData?.targetCurrency }}</span>
            <span>{{ currencyData?.targetUnit }}</span>
            <span>{{ totalPrice }} </span> -->
            <IntlCurrency
              :count="totalPrice"
              :size="18"
              :currency="currencyData?.targetCurrency.slice(0, 2)"
              color="#052784"
            />
          </div>
        </div>
        <div class="h5-sourcing-footer-price-desc">
          <img
            src="https://cdn-image.bestdealer.com/app-bestdealer/1688/offer/cycle-info-1.svg"
            alt=""
          />
          <span>The current order doesn’t include shipping costs.</span>
        </div>
      </div>
      <div class="h5-sourcing-add-carts">
        <div class="h5-sourcing-carts" @click="handleAddCarts" ref="cartIcon">
          <div class="carts-nums">
            {{
              cartCount > 99 ? "+99" : cartCount === 0 ? "0" : "+" + cartCount
            }}
          </div>
          <img src="@/assets/H5/offer/carts.svg" alt="" class="carts-icon" />
        </div>
        <div class="h5-sourcing-footer-btn" @click="sourcingNow" ref="addToCartBtn">
          <div v-if="sourcingStatusInfo?.status === 4">Sourcing Now</div>
          <div
            v-else-if="sourcingStatusInfo?.status === 1"
            style="position: relative"
          >
            Purchase Request Processing
            <div class="sourcingStatusInfo-mask"></div>
          </div>
          <div v-else>Add to Cart</div>
          <div
            v-if="sourcingStatusInfo?.status !== 1"
            :class="[
              {
                parabolaActive: isParabolaActive,
              },
              'footer-btn-parabola',
            ]"
          ></div>
        </div>
      </div>
    </div>
    <!-- 引入抛物线动画组件 -->
    <AddToCartAnimation 
      ref="animation"
      :startElement="startElement" 
      :endElement="endElement"
      :color="animationColor"
      :duration="animationDuration"
      :parabolicHeight="parabolicHeight"
      :size="animationSize"
      @animationEnd="onAnimationEnd"
    />
    <H5Dialog
      v-model="showH5ShipTo"
      height="98%"
      :initStyles="{ background: 'white' }"
    >
      <H5ShipTo
        :countryList="countryList"
        :currentCountry="currentCountry"
        :handleClose="closeH5ShipTo"
      />
    </H5Dialog>
    <div
      class="pop-container-wrapper"
      v-if="showAddressInfo"
      :class="{ visible: showAddressInfo }"
    >
      <div class="pop-container-header">
        <img
          src="@/assets/H5/orderFilling/h5-back.svg"
          alt=""
          class="pop-container-header-back"
          @click="handleCloseAddressInfo(false)"
        />
        <div class="pop-container-header-title">Shipping Address</div>
        <img
          src="@/assets/H5/offer/sourcing-close.svg"
          alt=""
          class="pop-container-header-close"
          @click="handleCloseAddressInfo(false)"
        />
      </div>
      <div
        class="pop-container"
        :class="{ visible: showAddressInfo }"
        @click.stop
      >
        <H5AddressInfo
          v-if="showAddressInfo"
          :handleClose="handleCloseAddressInfo"
          :currentCountry="currentCountry"
          :addressType="addressType"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  nextTick,
  onMounted,
  reactive,
  ref,
  toRaw,
  watch,
} from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { fetchData } from "../../../../../../utils/fetch";
import { bankersRound } from "../../../../../../utils/utils";
import H5Dialog from "@/components/H5/H5Dialog/index.vue";
import H5ShipTo from "@/components/H5/H5ShipTo/index.vue";
import H5PriceInterval from "../H5PriceInterval/index.vue";
import { buriedPointUtil, gaAnalytics } from "../../../../../../utils/utils";
import H5LoginIn from "@/components/H5/H5LoginIn/index.vue";
import defaultImage from "@/assets/empty-image.svg";
import { useCommonToast } from "@/hooks/useCommonToast";
import { useRouter } from "vue-router";
import H5AddressInfo from "@/components/H5/H5AddressInfo/index.vue";
import AddToCartAnimation from "../AddToCartAnimation/index.vue";
const store = useStore();
const route = useRoute();
const router = useRouter();

const props = defineProps({
  offerData: Object,
  closeH5Sourcing: Function,
  currentCountry: Object,
  newParam: Object,
  sourcingStatusInfo: {
    type: Object,
    default: () => ({}),
  },
});
const offerData = props.offerData;
const closeH5Sourcing = props.closeH5Sourcing;
const newParam = props.newParam;
const sourcingStatusInfo = computed(() => props.sourcingStatusInfo);
const firstrAttributes = ref([]);
const secondAttributesQuantityList = ref([]);
const secondAttributesQuantityObj = reactive({});
const countryList = computed(() => store.state.countryInfo);

const firstActivedAttributesIndex = ref(0);
const estimatedQuntity = ref(0);
const totalPrice = ref(0);
const appName = ref(import.meta.env.VITE_APP_NAME);
const startTouchX = ref(0);
const boxes = ref(null);
const endTouchX = ref(0);
const isEndTouch = ref(false);

const currencyData = computed(() => store.state.currencyData);
const supplierFee = computed(() => store.state.supplierFee);
const mergedArray = computed(() => store.state.mergedArray);
const currentFirstSku = ref("");
const showH5ShipTo = ref(false);
const deliveryRef = ref(null);
const showH5Address = ref(false);
const showAddressInfoDialog = ref(true);
const showAddressInfoDialogData = ref({});
const isParabolaActive = ref(false);
const showAddressInfo = ref(false);
const isAddCarts = ref(false);

// 动画参数
const startElement = ref(null);
const endElement = ref(null);
const animationColor = ref('#052784');
const animationDuration = ref(500);
const parabolicHeight = ref(260);
const animationSize = ref(18);

const cartIcon = ref(null);
const addToCartBtn = ref(null);
const animation = ref(null);

const addressType = ref(sourcingStatusInfo?.value?.status === 4 ? 1 : 2);

const { toast } = useCommonToast();
//无sku处理
const noSkuNum = ref(0);

const currentCountry = computed(() => store.state.currentCountry);
const cartCount = computed(() => store.state.cart.cartCount);

const initStyles = () => {
  return {
    "border-radius": 0,
    background: "rgba(0, 0, 0, 0.8)",
  };
};

const closeSourcing = () => {
  const intercom = document.querySelector(".intercom-lightweight-app");
  if (intercom) {
    intercom.style.display = "block"; // 隐藏元素
  }
  closeH5Sourcing(true);
};

const stopClick = (event) => {
  event.stopPropagation();
};

watch(currencyData, (newData) => {
  if (newData) {
    currencyData.value = newData;
  }
});

watch(supplierFee, (newData) => {
  if (newData) {
    supplierFee.value = newData;
  }
});

watch(
  mergedArray,
  (newData) => {
    if (newData) {
      let quantity = 0;
      let price = 0;
      if (sourcingStatusInfo.value?.status === 4) {
        newData?.[0]?.skuShipInfos?.forEach((v) => {
          quantity += v.quantity;
          price += v.targetPrice * v.quantity;
        });
        mergedArray.value = newData;
        estimatedQuntity.value = quantity;
        totalPrice.value = price.toFixed(2);
        return;
      }

      newData?.forEach((v) => {
        quantity += v.quantity;
        price += v.targetPrice * v.quantity;
      });
      mergedArray.value = newData;
      estimatedQuntity.value = quantity;
      totalPrice.value = price.toFixed(2);
    }
  },
  {
    immediate: true,
    deep: true,
  }
);

const IMAGES = {
  addDefault:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/add_default.svg",
  add: "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/add.svg",
  reduceDefault:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/reduce_default.svg",
  reduce:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/reduce.svg",
  irreducible:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/irreducible.svg",
  cannotBeAdded:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/cannot_be_added.svg",
};
const secondAttributesBtnAdd = ref(IMAGES.addDefault);
const secondAttributesBtnReduce = ref(IMAGES.irreducible);

onMounted(() => {
  firstSku(offerData?.productSkuInfos);
});

const firstSku = (productSkuInfos) => {
  const colorAttributes = productSkuInfos?.map((sku) => {
    return sku.skuAttributes[0]; // 获取每个 SKU 中的第一个 skuAttribute（颜色）
  });
  const uniqueColors = colorAttributes?.filter((value, index, self) => {
    return index === self.findIndex((t) => t.valueTrans === value.valueTrans);
  });
  firstrAttributes.value = uniqueColors;

  currentFirstSku.value = uniqueColors?.[0]?.valueTrans;
  secondSku(productSkuInfos, uniqueColors?.[0], 0);
};

const secondSku = (
  productSkuInfos,
  currentFirstSelectedObj,
  uniqueColorsIndex
) => {
  const sizeList = productSkuInfos?.filter((v) => {
    return (
      v.skuAttributes[0].valueTrans === currentFirstSelectedObj?.valueTrans
    );
  });
  const list = sizeList?.map((v) => {
    return { ...v, quantity: 0 };
  });
  if (secondAttributesQuantityObj.hasOwnProperty(uniqueColorsIndex)) {
    secondAttributesQuantityList.value =
      secondAttributesQuantityObj[uniqueColorsIndex];
  } else {
    secondAttributesQuantityList.value = list;
    secondAttributesQuantityObj[uniqueColorsIndex] = list;
  }
};

//点第一个sku
const clickFirstAttributes = (item, index) => {
  firstActivedAttributesIndex.value = index;
  currentFirstSku.value = item.valueTrans;

  endTouchX.value = 0;
  // store.commit('setCurrentHoverdImage', item.skuImageUrl)
  secondSku(offerData.productSkuInfos, item, index);
};

const attributesSub = (item, index) => {
  if (secondAttributesQuantityList.value[index].quantity <= 0) {
    return;
  }
  secondAttributesQuantityList.value[index].quantity -= 1;
  firstSkuTotal();
  postLtogisticFee();
};

const attributesAdd = (item, index) => {
  if (secondAttributesQuantityList.value[index].quantity >= item.amountOnSale) {
    return;
  }
  secondAttributesQuantityList.value[index].quantity += 1;
  firstSkuTotal();
  postLtogisticFee();
};

const firstSkuTotal = () => {
  const currentValueTrans =
    secondAttributesQuantityList.value[0].skuAttributes?.[0].valueTrans || "";
  const newfirstList = toRaw(firstrAttributes.value);
  const _index = newfirstList?.findIndex(
    (v) => v.valueTrans === currentValueTrans
  );

  if (_index !== -1) {
    let total = 0;
    secondAttributesQuantityList.value?.forEach((v) => {
      let quantity = v.quantity ? v.quantity : 0;
      total += Number(quantity);
    });

    firstrAttributes.value[_index]["total"] = total;
  }
};

const postLtogisticFee = async () => {
  const skuSales = [];
  let totalQuantity = 0;
  Object.entries(secondAttributesQuantityObj).forEach(([key, value]) => {
    value.forEach((v) => {
      if (v?.quantity > 0) {
        skuSales.push(toRaw(v));
      }
    });
  });
  skuSales.forEach((v) => {
    totalQuantity += Number(v.quantity);
  });
  // 筛选满足条件的价格区间
  const matchedPrice =
    offerData?.productSaleInfo?.priceRangeList
      .filter((range) => totalQuantity >= range.startQuantity)
      .sort((a, b) => b.startQuantity - a.startQuantity)[0]?.price ||
    offerData?.productSaleInfo?.priceRangeList[0]?.price;

  const mergedArray = skuSales?.map((detail) => {
    const shipInfo = !!offerData?.productSkuInfos
      ? offerData?.productSkuInfos
      : offerData?.productShippingInfo?.skuShippingInfoList;
    const matchingSale = shipInfo?.find((sale) => sale.skuId === detail.skuId);
    if (matchingSale && sourcingStatusInfo?.value?.status !== 4) {
      return {
        spuId: offerData.offerId,
        skuId: detail.skuId,
        quantity: detail.quantity,
        spuName: offerData.subjectTrans,
        skuImage: matchingSale.skuAttributes?.[0]?.skuImageUrl,
        skuPriceCny: matchingSale?.price ? matchingSale?.price : matchedPrice,
        batchNum: offerData.minOrderQuantity,
        skuAttributes: matchingSale.skuAttributes
          ? matchingSale.skuAttributes.map((v) => {
              return {
                name: v.attributeNameTrans,
                value: v.valueTrans,
              };
            })
          : [],
        targetPrice: bankersRound(
          (!!matchingSale?.price ? matchingSale?.price : matchedPrice) *
            currencyData.value.rate,
          2
        ),
      };
    } else if (matchingSale && sourcingStatusInfo?.value?.status === 4) {
      return {
        quantity: detail.quantity,
        skuId: detail.skuId,
        weight: matchingSale?.skuShippingInfo?.weight,
        width: matchingSale?.skuShippingInfo?.width,
        length: matchingSale?.skuShippingInfo?.length,
        height: matchingSale?.skuShippingInfo?.height,
        price: !!matchingSale?.price ? matchingSale?.price : matchedPrice,
        skuImageUrl: matchingSale.skuAttributes?.[0]?.skuImageUrl,
        skuName: `${matchingSale.skuAttributes?.[0]?.valueTrans} ${
          matchingSale.skuAttributes?.[1]?.valueTrans || ""
        }`,
        targetPrice: bankersRound(
          (!!matchingSale?.price ? matchingSale?.price : matchedPrice) *
            currencyData.value.rate,
          2
        ),
      };
    } else {
      return detail;
    }
  });
  if (!!mergedArray && !mergedArray.length) {
    store.commit("setSupplierFee", {
      supplierFee: 0,
      suppliersName: "",
      suppliersUrl: "",
    });
    store.commit("setMergedArray", []);
    return;
  }

  if (sourcingStatusInfo?.value?.status === 4) {
    const logisticFeeProductInputs = [
      {
        offerId: offerData.offerId,
        titleCn: offerData.subject,
        categoryId: offerData.categoryId,
        productUrl: window.location.href,
        skuShipInfos: mergedArray,
      },
    ];

    // if (totalQuantity >= offerData?.minOrderQuantity) {
    //   const countryCodeItem = !!localStorage.getItem("setCurrentCountry")
    //     ? JSON.parse(localStorage.getItem("setCurrentCountry"))?.countryCode
    //     : countryList.value[0]?.countryCode;
    //   const { code, data } = await fetchData(
    //     "/dsp-app/alibaba-common/supplierFee",
    //     {
    //       method: "post",
    //       body: JSON.stringify({
    //         logisticFeeProductInputs,
    //         countryCode: countryCodeItem,
    //         userId: localStorage.getItem("dsp.uid") || "",
    //       }),
    //     }
    //   );
    //   if (code === 200) {
    //     store.commit("setSupplierFee", data);
    //     store.commit("setMergedArray", logisticFeeProductInputs);
    //   }
    // }
    store.commit("setMergedArray", logisticFeeProductInputs);
    return;
  }

  store.commit("setMergedArray", mergedArray);
};

const renderPrice = (price, item) => {
  if (!price) {
    const skuSales = [];
    let totalQuantity = 0;
    Object.entries(secondAttributesQuantityObj).forEach(([key, value]) => {
      value.forEach((v) => {
        if (v?.quantity > 0) {
          skuSales.push(toRaw(v));
        }
      });
    });
    skuSales.forEach((v) => {
      totalQuantity += v.quantity;
    });
    const matchedPrice = offerData?.productSaleInfo?.priceRangeList
      .filter((range) => totalQuantity >= range.startQuantity)
      .sort((a, b) => b.startQuantity - a.startQuantity)[0]?.price;
    if (!matchedPrice) {
      return offerData?.productSaleInfo?.priceRangeList?.[0]?.price;
    }
    if (!!matchedPrice) {
      return matchedPrice;
    }
  } else {
    return price;
  }
};

const changeAttributes = (item, index, e) => {
  let value = Number(e.target.value.replace(/[^0-9]/g, ""));
  if (value >= item.amountOnSale) {
    value = item.amountOnSale;
  }
  if (value <= 0) {
    value = 0;
  }
  secondAttributesQuantityList.value[index].quantity = value;
  firstSkuTotal();
  postLtogisticFee();
};

const updateButtonImage = (element, src) => {
  if (element) {
    element.src = src;
  }
};

// watch 更新按钮图标
watch(
  secondAttributesQuantityList,
  (_1, _2) => {
    secondAttributesQuantityList.value?.forEach((item, i) => {
      const imgElementSub = document.getElementById(
        "secondAttributes-btn-sub-" + i
      );
      const imgElementAdd = document.getElementById(
        "secondAttributes-btn-add-" + i
      );

      if (item.quantity === 0) {
        updateButtonImage(imgElementSub, IMAGES.irreducible);
        updateButtonImage(imgElementAdd, IMAGES.addDefault);
      } else if (item.quantity >= item.amountOnSale) {
        updateButtonImage(imgElementSub, IMAGES.reduceDefault);
        updateButtonImage(imgElementAdd, IMAGES.cannotBeAdded);
      } else {
        if (imgElementSub.src !== IMAGES.reduce) {
          updateButtonImage(imgElementSub, IMAGES.reduceDefault);
        }

        if (imgElementAdd?.src !== IMAGES.add) {
          updateButtonImage(imgElementAdd, IMAGES.addDefault);
        }
      }
    });
  },
  { deep: true }
);

const getItemStyle = (moveLength) => {
  let translateX = (0 - firstActivedAttributesIndex.value) * 180;
  return {
    transform: `translateX(${translateX}px)`,
    transition: "transform 0.5s ease-in-out",
  };
};

const showSelectCountry = () => {
  showH5ShipTo.value = true;
};

watch([countryList, currentCountry], (newData) => {
  if (newData) {
    const country = !!localStorage.getItem("setCurrentCountry")
      ? JSON.parse(localStorage.getItem("setCurrentCountry"))
      : "";
    if (country) {
      currentCountry.value = country;
      return;
    }
    if (!!newData[1]) {
      currentCountry.value = newData[1];
      return;
    }
    currentCountry.value = newData[0][0];
  }
});

const closeH5ShipTo = (data) => {
  if (!!offerData?.productSkuInfos) {
    postLtogisticFee();
  } else {
    postNoskuPrice();
  }
  setTimeout(() => {
    document.querySelector("body").classList.add("no-scroll");
  }, 300)
  showH5ShipTo.value = false;
};

const renderNoSkuPrice = () => {
  const matchedPrice = offerData?.productSaleInfo?.priceRangeList
    .filter((range) => noSkuNum.value >= range.startQuantity)
    .sort((a, b) => b.startQuantity - a.startQuantity)[0]?.price;

  if (!matchedPrice) {
    return offerData?.productSaleInfo?.priceRangeList[0]?.price;
  }
  return matchedPrice;
};

const noAttributesSub = () => {
  if (noSkuNum.value <= 0) {
    noSkuNum.value = 0;
    return;
  }
  noSkuNum.value = Number(noSkuNum.value) - 1;
  postNoskuPrice();
};

const noAttributesAdd = () => {
  if (noSkuNum.value >= offerData?.productSaleInfo?.amountOnSale) {
    noSkuNum.value = Number(offerData?.productSaleInfo?.amountOnSale);
    return;
  }
  noSkuNum.value = Number(noSkuNum.value) + 1;
  postNoskuPrice();
};

const changeNoAttributes = (e) => {
  let value = Number(e.target.value.replace(/[^0-9]/g, ""));
  if (value >= offerData?.productSaleInfo?.amountOnSale) {
    value = offerData?.productSaleInfo?.amountOnSale;
  }
  if (value <= 0) {
    value = 0;
  }
  noSkuNum.value = Number(value);
  postNoskuPrice();
};

const postNoskuPrice = async () => {
  const matchedPrice =
    offerData?.productSaleInfo?.priceRangeList
      .filter((range) => noSkuNum.value >= range.startQuantity)
      .sort((a, b) => b.startQuantity - a.startQuantity)[0]?.price ||
    offerData?.productSaleInfo?.priceRangeList[0]?.price;

  //sourcing now逻辑
  if (sourcingStatusInfo?.value?.status === 4) {
    const obj = {
      ...offerData.productShippingInfo?.defaultShippingInfo,
      price: matchedPrice,
      quantity: noSkuNum.value,
      targetPrice: bankersRound(matchedPrice * currencyData.value.rate, 2),
    };
    const logisticFeeProductInputs = [
      {
        offerId: offerData.offerId,
        titleCn: offerData.subject,
        categoryId: offerData.categoryId,
        productUrl: window.location.href,
        skuShipInfos: [obj],
      },
    ];

    if (!matchedPrice) {
      store.commit("setMergedArray", logisticFeeProductInputs);
      return;
    }

    // let countryCode = !!localStorage.getItem("setCurrentCountry")
    //   ? JSON.parse(localStorage.getItem("setCurrentCountry"))?.countryCode
    //   : countryList.value[0]?.countryCode;
    // const { code, data } = await fetchData(
    //   "/dsp-app/alibaba-common/supplierFee",
    //   {
    //     method: "post",
    //     body: JSON.stringify({
    //       logisticFeeProductInputs,
    //       countryCode,
    //       userId: localStorage.getItem("dsp.uid") || "",
    //     }),
    //   }
    // );
    // if (code === 200) {
    //   store.commit("setSupplierFee", data);
    //   store.commit("setMergedArray", [obj]);
    // }
    store.commit("setMergedArray", [obj]);
    return;
  }
  const obj = {
    spuId: offerData.offerId,
    quantity: noSkuNum.value,
    spuName: offerData.subjectTrans,
    batchNum: offerData.minOrderQuantity,
    targetPrice: bankersRound(matchedPrice * currencyData.value.rate, 2),
  };

  store.commit("setMergedArray", [obj]);
};
const onAnimationEnd = () => {
  console.log("动画结束");
  const cartsNums = document.querySelector(".carts-nums");
  console.log(cartsNums ,'cartsNums');
  if (cartsNums) {
    // 添加抖动动画
    cartsNums.classList.add("shake-animation");
    cartsNums.addEventListener("animationend", () => {
      cartsNums.classList.remove("shake-animation");
    });
  }
  const cartsIcon = document.querySelector(".carts-icon");
  if (cartsIcon) {
    // 添加缩放动画
    cartsIcon.classList.add("scale-animation");
    cartsIcon.addEventListener("animationend", () => {
      cartsIcon.classList.remove("scale-animation");
    });
  }
};

const sourcingNow = async () => {

  if (sourcingStatusInfo?.value?.status === 1) {
    return;
  }

  const tk = localStorage.getItem("tk");
  if (!tk) {
    store.commit("setLoginVisible", true);
    closeH5Sourcing();
    return;
  }

  const addressForm = localStorage.getItem("addressForm")
    ? JSON.parse(localStorage.getItem("addressForm"))
    : null;
  if (!addressForm) {
    showAddressInfo.value = true;
    return;
  }

  const {
    categoryId,
    subjectTrans,
    productImage,
    productSaleInfo,
    offerId,
    minOrderQuantity,
  } = offerData;
  if (!estimatedQuntity.value) {
    toast(
      "Please select the items you want before proceeding.",
      { type: "error" }
    );
    isAddCarts.value = true;
    return;
  }
  if (minOrderQuantity > estimatedQuntity.value) {
    toast("The minimum order quantity for the product has not been reached. Please continue to add products.", {
      type: "error",
    });
    isAddCarts.value = true;
    return;
  }

  if (!categoryId) {
    return;
  }

  // //展示去掉动画效果
  // isParabolaActive.value = true;
  // setTimeout(() => {
  //   isParabolaActive.value = false;
  // }, 1500);
  startElement.value = addToCartBtn.value;
  endElement.value = cartIcon.value;
  
  // 播放动画
  animation.value.play();

  // showH5Address.value = true;
  // const price = mergedArray.value?.skuShipInfos ? mergedArray.value?.skuShipInfos?.[0].price : productSaleInfo?.priceRangeList?.[0]?.price
  if (sourcingStatusInfo?.value?.status === 2) {
    buriedPointUtil({ eventId: "MPPD202" });
    await addCarts();
    return;
  }

  gaAnalytics("mp_sourcing_now_click", "mp_sourcing_now_click", {
    spuId: offerId,
  });

  buriedPointUtil({ eventId: "MPPD201" });

  const skus = mergedArray.value?.flatMap((item) =>
    item.skuShipInfos?.map((sku) => ({
      ...sku,
      priceCny: sku.price,
      price: (sku.price * currencyData.value.rate).toFixed(2),
      skuPrice: (sku.price * currencyData.value.rate).toFixed(2),
    }))
  );
  const obj = {
    categoryId,
    sourceUrl: window.location.href,
    // sourceUrl: `https://1688-test.lerdex.co/offer/${offerId}`,
    productName: subjectTrans,
    categoryName: "",
    imageUrl: productImage?.images[0],
    country: currentCountry.value?.countryCode,
    purchasingType: 1,
    estimatedQuntity: estimatedQuntity.value || 0,
    offerId,
    currency: currencyData.value?.targetCurrency,
    currencyUnit: currencyData?.value?.targetUnit,
    mergedArray: mergedArray.value,
    supplierFee:
      supplierFee?.value.supplierFee === "unknown"
        ? "Need a quote"
        : bankersRound(
            supplierFee.value.supplierFee * currencyData.value.rate,
            2
          ),
    skus,
    price: currencyData.value.rate.toFixed(2),
    titleCn: mergedArray[0]?.titleCn,
    customerInfo: {
      ...addressForm,
      streetAddr: addressForm?.streetAddress,
      zip: addressForm?.postCode,
      phone: addressForm?.phoneValue,
      country: addressForm?.searchCountryText,
    },
  };

  try {
    store.commit("setLoading", true);
    const { code, data, msg } = await fetchData(
      "/appb/alibaba-inter/source-purchase",
      {
        method: "post",
        body: JSON.stringify({
          ...obj,
          customerInfo: {
            ...addressForm,
            streetAddr: addressForm?.streetAddress,
            zip: addressForm?.postCode,
            phone: addressForm?.phoneValue,
            country: addressForm?.searchCountryText,
            extAddr: addressForm?.aptEtc,
            extension: addressForm?.countryCode === 'BR' ? addressForm?.cpfNumber || '' : addressForm?.countryCode === 'CL' ? addressForm?.rutNumber || '' : '',
          },
        }),
      }
    );
    if (code === 200) {
      setTimeout(() => {
        toast("Request sent successfully", { type: "success" });
      }, 300)
      gaAnalytics("mp_sourcing_now_success", "mp_sourcing_now_success");

      await getCartCount();
      buriedPointUtil({ eventId: "MPPD602" });
      setTimeout(() => {
        closeH5Sourcing(true);
      }, 500);
    }
    if (code === 10000) {
      toast(msg, { type: "error" });
    }
    store.commit("setLoading", false);
  } catch (error) {
    store.commit("setLoading", false);
  }
};

const addCarts = async () => {
  const { code } = await fetchData("/appb/b2b/cart/cartAdd", {
    method: "post",
    body: JSON.stringify({
      details: mergedArray.value,
    }),
  });
  if (code === 200) {
    setTimeout(() => {
      toast("Added successfully", { type: "success" });
    }, 700)
    gaAnalytics("mp_add_to_cart_success", "mp_add_to_cart_success");
    buriedPointUtil({ eventId: "MPPD601" });
    await getCartCount();
    secondAttributesQuantityList?.value.forEach((item) => {
      item.quantity = 0;
    });
    firstrAttributes.value?.forEach((item) => {
      item.total = 0;
    });
    estimatedQuntity.value = 0;
    noSkuNum.value = 0;
    store.commit("setMergedArray", []);
  } else {
    toast("Add to cart failed", { type: "error" });
  }
};

const getCartCount = async () => {
  const { code, data } = await fetchData("/appb/b2b/cart/cartCount");
  if (code === 200) {
    store.commit("cart/SET_CART_COUNT", data);
  }
};

const handleCloseAddressInfo = (saved) => {
  showAddressInfo.value = false;
  console.log(saved, "saved");
  if (saved) {
    toast("Added successfully", { type: "success" });
  }
};

const handleAddCarts = () => {
  buriedPointUtil({ eventId: "MPC202" });
  closeH5Sourcing();
  router.push("/carts");
};
</script>

<style lang="less">
@import "./index.less";
</style>
