<template>
  <div class="bottom-line_order">
    <div class="bottom-line-text">I'm a bottom line</div>
  </div>
</template>
<script setup>

</script>
<style lang="less" scoped>
  .bottom-line_order {
    padding-top: 16px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    .bottom-line-text {
      position: relative;
      height: 16px;
      width: 188px;
      line-height: 16px;
      color: #b2b7c2;
      text-align: center;
      font-size: 12px;
      font-family: mon-500;
      
      &::before, &::after {
        position: absolute;
        content: '';
        top: 8px;
        width: 32px;
        height: 1px;
        background-color: #ebebeb;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }
    }
  }
</style>