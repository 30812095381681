<template>
  <div class="empty-order">
    <img src="@/assets/H5/order/empty.png" width="160px" alt="">
    <div class="text">
      <span>Your fulfillment orders is empty</span> <br/>
      <span>Add your purchase items in it.</span>
    </div>
  </div>
</template>

<script setup>
</script>

<style lang="less" scoped>
.empty-order {
  width: 100%;
  height: 100%;
  background-color: white;
  border-radius: 8px;
  text-align: center;
  img {
    margin-top: 80px;
  }

  .text {
    color: #232323;
    span:first-child {
      font-size: 14px;
      font-family: mon-600;
      line-height: 18px;
    }
    span:last-child {
      font-size: 12px;
      font-family: mon-500;
      line-height: 16px;
    }
  }
}
</style>