<template>
  <div class="offerInfo">
    <div class="content">
      <div class="content-title">
        {{ offerData?.subjectTrans }}
      </div>
      <div class="content-mini-order-piece">
        <span> Min. Order: {{ offerData?.minOrderQuantity }} Piece </span>
      </div>
      <div class="content-sold">
        <span class="product-favorite-precent"
          >{{
            (offerData?.sellerDataInfo?.repeatPurchasePercent * 100).toFixed(0)
          }}%</span
        >
        <span class="product-favorite-precent">Repeat Customer Rate</span>
        <div class="product-favorite-separator"></div>
        <span class="product-hot-num">{{ soldOut }}</span>
        <span class="content-sold-title">Sold</span>
      </div>
      <div class="line"></div>

      <!-- sku不存在的情况下 -->
      <div class="noAttribute-main" v-if="!offerData?.productSkuInfos">
        <div class="interval">
          <div
            v-for="(item, index) in offerData?.productSaleInfo?.priceRangeList"
            class="interval-item"
            :key="index"
          >
            <div class="interval-item-pcs">
              {{ item?.startQuantityStr }} PCS
            </div>
            <div class="interval-item-price">
              {{ currencyData?.targetCurrency }}
              <span>{{ currencyData?.targetUnit }}</span
              >{{ Math.ceil(currencyData?.rate * item?.price * 100) / 100 }}
            </div>
          </div>
        </div>
        <div class="noAttributes">
          <div class="secondAttributes-price">
            <span
              >{{ currencyData?.targetUnit }}
              <!-- {{ (item?.price * currencyData?.rate).toFixed(2) }} -->
              <!-- {{ Math.ceil(renderPrice(item?.price) * currencyData?.rate * 100) / 100 }} -->
              {{
                Math.ceil(renderNoSkuPrice() * currencyData?.rate * 100) / 100
              }}
            </span>
            /
            <span>{{ currencyData?.fromUnit }}{{ renderNoSkuPrice() }}</span>
          </div>
          <div class="secondAttributes-inStock">
            {{ offerData?.productSaleInfo?.amountOnSale }} InStock
          </div>

          <div class="secondAttributes-btn">
            <div class="secondAttributes-btn-sub" @click="noAttributesSub()">
              <img src="@/assets/offer/sub.svg" alt="" />
            </div>
            <input
              class="secondAttributes-btn-input"
              @change="(e) => changeNoAttributes(e)"
              v-model="noSkuNum"
            />
            <div class="secondAttributes-btn-add" @click="noAttributesAdd">
              <img src="@/assets/offer/add.svg" alt="" />
            </div>
          </div>
        </div>
      </div>

      <!-- sku存在的情况 -->
      <div v-if="!!offerData?.productSkuInfos">
        <div class="interval" v-if="!offerData?.productSkuInfos?.[0]?.price">
          <div
            v-for="(item, index) in offerData?.productSaleInfo?.priceRangeList"
            class="interval-item"
            :key="index"
          >
            <div class="interval-item-pcs">
              {{ item?.startQuantityStr }} PCS
            </div>
            <div class="interval-item-price">
              <span>{{ currencyData?.targetUnit }}</span
              >{{ Math.ceil(currencyData?.rate * item?.price * 100) / 100
              }}<span>{{ currencyData?.targetCurrency }}</span>
            </div>
          </div>
        </div>
        <div class="attributes">
          <div class="firstAttributes">
            <div class="firstAttributes-title">
              {{ firstrAttributes?.[0]?.attributeNameTrans }}:
              <span class="firstAttributes-title-sku">{{
                currentFirstSku
              }}</span>
            </div>
            <div class="firstAttributes-item">
              <div
                v-for="(item, index) in firstrAttributes"
                :key="index"
                @click="clickFirstAttributes(item, index)"
                :class="{
                  'firstAttributes-container': true,
                  activedAttributes: firstActivedAttributesIndex === index,
                }"
              >
                <img :src="item?.skuImageUrl || defaultImage" alt="" />
                <div class="firstAttributes-item-title">
                  <span>
                    {{ item?.valueTrans }}
                  </span>
                </div>
                <div class="firstAttributes-item-total" v-if="item?.total">
                  ×{{ item?.total }}
                </div>
              </div>
            </div>
          </div>
          <div class="secondAttributes">
            <div class="secondAttributes-title">
              {{
                secondAttributesQuantityList[0]?.skuAttributes[1]
                  ?.attributeNameTrans
              }}
            </div>
            <div class="secondAttributes-item">
              <div
                v-for="(item, index) in secondAttributesQuantityList"
                :key="index"
                class="secondAttributes-container"
              >
                <div class="secondAttributes-valueTrans">
                  {{ item?.skuAttributes?.[1]?.valueTrans }}
                </div>

                <div class="secondAttributes-price">
                  <span
                    >{{ currencyData?.targetUnit }}
                    <!-- {{ (item?.price * currencyData?.rate).toFixed(2) }} -->
                    {{
                      Math.ceil(
                        renderPrice(item?.price) * currencyData?.rate * 100
                      ) / 100
                    }}
                  </span>
                  /
                  <span
                    >{{ currencyData?.fromUnit
                    }}{{ renderPrice(item?.price) }}</span
                  >
                </div>
                <div class="secondAttributes-inStock">
                  {{ item?.amountOnSale }} in stock
                </div>

                <div class="secondAttributes-btn">
                  <div
                    class="secondAttributes-btn-sub"
                    @click="attributesSub(item, index)"
                  >
                    <img
                      :id="'secondAttributes-btn-sub-' + index"
                      :src="secondAttributesBtnReduce"
                      @mouseover="
                        (e) =>
                          secondAttributesBtnReduceHoverIn(e, item.quantity)
                      "
                      @mouseleave="
                        (e) =>
                          secondAttributesBtnReduceHoverOut(e, item.quantity)
                      "
                      alt=""
                    />
                  </div>
                  <input
                    class="secondAttributes-btn-input"
                    v-model="item.quantity"
                    @change="(e) => changeAttributes(item, index, e)"
                  />
                  <div
                    class="secondAttributes-btn-add"
                    @click="attributesAdd(item, index)"
                  >
                    <img
                      :id="'secondAttributes-btn-add-' + index"
                      :src="secondAttributesBtnAdd"
                      @mouseover="
                        (e) =>
                          secondAttributesBtnAddHoverIn(
                            e,
                            item?.amountOnSale,
                            item.quantity
                          )
                      "
                      @mouseleave="
                        (e) =>
                          secondAttributesBtnAddHoverOut(
                            e,
                            item?.amountOnSale,
                            item.quantity
                          )
                      "
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref, toRaw, watch } from "vue";
import { useStore } from "vuex";
import { fetchData } from "@/utils/fetch";
import defaultImage from "@/assets/empty-image.svg";

const store = useStore();
const offerData = computed(() => store.state.offerDetail);
const currencyData = computed(() => store.state.currencyData);

const firstrAttributes = ref([]);
const firstActivedAttributesIndex = ref(0);

const secondAttributesQuantityList = ref([]);

const currentFirstSku = ref("");

const soldOut = ref("");

const secondAttributesQuantityObj = reactive({});

const countryList = computed(() => store.state.countryInfo);

import { useRoute } from "vue-router";

const route = useRoute();

const rangePrice = reactive({
  minPrice: "",
  maxPrice: "",
});

const IMAGES = {
  addDefault:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/add_default.svg",
  add: "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/add.svg",
  reduceDefault:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/reduce_default.svg",
  reduce:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/reduce.svg",
  irreducible:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/irreducible.svg",
  cannotBeAdded:
    "https://cdn-image.bestdealer.com/app-bestdealer/1688/icon/cannot_be_added.svg",
};

const updateButtonImage = (element, src) => {
  if (element) {
    element.src = src;
  }
};

// 添加按钮操作
const secondAttributesBtnAdd = ref(IMAGES.addDefault);
const secondAttributesBtnAddHoverIn = (e, amountOnSale, quantity) => {
  const imgElement = e.target;
  if (imgElement) {
    const src = quantity >= amountOnSale ? IMAGES.cannotBeAdded : IMAGES.add;
    updateButtonImage(imgElement, src);
  }
};

const secondAttributesBtnAddHoverOut = (e, amountOnSale, quantity) => {
  const imgElement = e.target;
  if (imgElement) {
    const src =
      quantity >= amountOnSale ? IMAGES.cannotBeAdded : IMAGES.addDefault;
    updateButtonImage(imgElement, src);
  }
};

// 减少按钮操作
const secondAttributesBtnReduce = ref(IMAGES.irreducible);
const secondAttributesBtnReduceHoverIn = (e, quantity) => {
  const imgElement = e.target;
  if (imgElement) {
    const src = quantity === 0 ? IMAGES.irreducible : IMAGES.reduce;
    updateButtonImage(imgElement, src);
  }
};

const secondAttributesBtnReduceHoverOut = (e, quantity) => {
  const imgElement = e.target;
  if (imgElement) {
    const src = quantity === 0 ? IMAGES.irreducible : IMAGES.reduceDefault;
    updateButtonImage(imgElement, src);
  }
};

// watch 更新按钮图标
watch(
    secondAttributesQuantityList,
    (_1, _2) => {
      secondAttributesQuantityList.value?.forEach((item, i) => {
        const imgElementSub = document.getElementById('secondAttributes-btn-sub-' + i);
        const imgElementAdd = document.getElementById('secondAttributes-btn-add-' + i);

      if (item.quantity === 0) {
        updateButtonImage(imgElementSub, IMAGES.irreducible);
        updateButtonImage(imgElementAdd, IMAGES.addDefault);
      } else if (item.quantity >= item.amountOnSale) {
        updateButtonImage(imgElementSub, IMAGES.reduceDefault);
        updateButtonImage(imgElementAdd, IMAGES.cannotBeAdded);
      } else {
        if (imgElementSub.src !== IMAGES.reduce) {
          updateButtonImage(imgElementSub, IMAGES.reduceDefault);
        }

        if (imgElementAdd.src !== IMAGES.add) {
          updateButtonImage(imgElementAdd, IMAGES.addDefault);
        }
      }
    });
  },
  { deep: true }
);

//无sku情况处理
const noSkuNum = ref(0);

const changeNoAttributes = (e) => {
  let value = Number(e.target.value.replace(/[^0-9]/g, ""));
  if (value >= offerData.value?.productSaleInfo?.amountOnSale) {
    value = offerData.value?.productSaleInfo?.amountOnSale;
  }
  if (value <= 0) {
    value = 0;
  }
  noSkuNum.value = Number(value);
  postNoskuPrice(value)
}


const noAttributesSub = () => {
  if (noSkuNum.value <= 0) {
    noSkuNum.value = 0;
    return;
  }
  noSkuNum.value = Number(noSkuNum.value) - 1
  postNoskuPrice(Number(noSkuNum.value) - 1)
}

const noAttributesAdd = () => {
  if (noSkuNum.value >= offerData.value?.productSaleInfo?.amountOnSale) {
    noSkuNum.value = Number(offerData.value?.productSaleInfo?.amountOnSale);
    return;
  }
  noSkuNum.value = Number(noSkuNum.value) + 1
  postNoskuPrice(Number(noSkuNum.value) + 1)
}

const renderNoSkuPrice = () => {
  const matchedPrice = offerData.value?.productSaleInfo?.priceRangeList
    .filter((range) => noSkuNum.value >= range.startQuantity)
    .sort((a, b) => b.startQuantity - a.startQuantity)[0]?.price;

  if (!matchedPrice) {
    return offerData.value?.productSaleInfo?.priceRangeList[0]?.price;
  }
  return matchedPrice;
};

const postNoskuPrice = async (num) => {

  const matchedPrice = offerData.value?.productSaleInfo?.priceRangeList
    .filter((range) => noSkuNum.value >= range.startQuantity)
    .sort((a, b) => b.startQuantity - a.startQuantity)[0]?.price;

    const obj = {
   ...offerData.value.productShippingInfo?.defaultShippingInfo,
   price: matchedPrice,
   quantity: num,
  }

  const logisticFeeProductInputs = [
    {
      offerId: offerData.value.offerId,
      titleCn: offerData.value.subject,
      categoryId: offerData.value.categoryId,
      productUrl: window.location.href,
      skuShipInfos: [obj]
    }
  ]

  if(!matchedPrice) {
    store.commit('setMergedArray', logisticFeeProductInputs);
    return
  }


  let countryCode = !!localStorage.getItem('setCurrentCountry') ? JSON.parse(localStorage.getItem('setCurrentCountry'))?.countryCode : countryList.value[0]?.countryCode
  const { code, data } = await fetchData('/dsp-app/alibaba-common/supplierFee', {
      method: 'post',
      body: JSON.stringify({ logisticFeeProductInputs, countryCode, userId: localStorage.getItem('dsp.uid') || '' }),
    });
    if (code === 200) {
      store.commit('setSupplierFee', data);
      store.commit('setMergedArray', logisticFeeProductInputs);
    }
}


const moveFirstAttributes = (imageUrl) => {
  store.commit("setCurrentHoverdImage", imageUrl);
};

const leaveFirstAttributes = () => {
  store.commit("setCurrentHoverdImage", "");
};

const renderPrice = (price) => {
  if (!price) {
    const skuSales = [];
    let totalQuantity = 0;
    Object.entries(secondAttributesQuantityObj).forEach(([key, value]) => {
      value.forEach((v) => {
        if (v?.quantity > 0) {
          skuSales.push(toRaw(v));
        }
      });
    });
    skuSales.forEach((v) => {
      totalQuantity += v.quantity;
    });

    const matchedPrice = offerData.value?.productSaleInfo?.priceRangeList
      .filter((range) => totalQuantity >= range.startQuantity)
      .sort((a, b) => b.startQuantity - a.startQuantity)[0]?.price;

    if (!matchedPrice) {
      return offerData.value?.productSaleInfo?.priceRangeList?.[0]?.price;
    }

    if (!!matchedPrice) {
      return matchedPrice;
    }
  } else {
    return price;
  }
};

watch(offerData, (newData) => {
  if (newData) {
    offerData.value = newData;
    firstSku(newData?.productSkuInfos);
    const priceList = newData.productSaleInfo?.priceRangeList.map((v) => {
      return v.price;
    });
    rangePrice.minPrice = Math.min(priceList);
    rangePrice.maxPrice = Math.max(priceList);

    const { key } = route.query;

    const decodedKey = key ? atob(key) : null;
    soldOut.value = decodedKey ? Math.max(decodedKey, newData?.soldOut || 0) : newData?.soldOut;

    document.title = newData?.subjectTrans;
  }
});

watch(currencyData, (newData) => {
  if (newData) {
    currencyData.value = newData;
  }
});

watch(countryList, (newData) => {
  if (newData) {
    countryList.value = newData;
  }
});

const firstSku = (productSkuInfos) => {
  const colorAttributes = productSkuInfos?.map((sku) => {
    return sku.skuAttributes[0]; // 获取每个 SKU 中的第一个 skuAttribute（颜色）
  });
  const uniqueColors = colorAttributes?.filter((value, index, self) => {
    return index === self.findIndex((t) => t.valueTrans === value.valueTrans);
  });
  firstrAttributes.value = uniqueColors;

  currentFirstSku.value = uniqueColors?.[0]?.valueTrans;

  secondSku(productSkuInfos, uniqueColors?.[0], 0);
  getFirstrAttributesImages(uniqueColors);
};

const getFirstrAttributesImages = (uniqueColors) => {
  if (!uniqueColors) {
    return;
  }
  const imagesList = [];
  uniqueColors.forEach((v) => {
    imagesList.push(v.skuImageUrl);
  });
  store.commit("setFirstrAttributesimages", imagesList);
};

const secondSku = (
  productSkuInfos,
  currentFirstSelectedObj,
  uniqueColorsIndex
) => {
  const sizeList = productSkuInfos?.filter((v) => {
    return (
      v.skuAttributes[0].valueTrans === currentFirstSelectedObj?.valueTrans
    );
  });
  const list = sizeList?.map((v) => {
    return { ...v, quantity: 0 };
  });
  if (secondAttributesQuantityObj.hasOwnProperty(uniqueColorsIndex)) {
    secondAttributesQuantityList.value =
      secondAttributesQuantityObj[uniqueColorsIndex];
  } else {
    secondAttributesQuantityList.value = list;
    secondAttributesQuantityObj[uniqueColorsIndex] = list;
  }
};

//点第一个sku
const clickFirstAttributes = (item, index) => {
  firstActivedAttributesIndex.value = index;
  currentFirstSku.value = item.valueTrans;
  store.commit("setCurrentHoverdImage", item.skuImageUrl);
  secondSku(offerData.value.productSkuInfos, item, index);
};

const attributesSub = (item, index) => {
  if (secondAttributesQuantityList.value[index].quantity <= 0) {
    return;
  }
  secondAttributesQuantityList.value[index].quantity -= 1;
  firstSkuTotal();
  postLtogisticFee();
};

const attributesAdd = (item, index) => {
  if (secondAttributesQuantityList.value[index].quantity >= item.amountOnSale) {
    return;
  }
  secondAttributesQuantityList.value[index].quantity += 1;
  // console.log(firstrAttributes.value, secondAttributesQuantityList.value,'firstrAttributes')
  firstSkuTotal();
  postLtogisticFee();
};

const firstSkuTotal = () => {
  const currentValueTrans =
    secondAttributesQuantityList.value[0].skuAttributes?.[0].valueTrans || "";
  const newfirstList = toRaw(firstrAttributes.value);
  const _index = newfirstList?.findIndex(
    (v) => v.valueTrans === currentValueTrans
  );

  if (_index !== -1) {
    let total = 0;
    secondAttributesQuantityList.value.forEach((v) => {
      let quantity = v.quantity ? v.quantity : 0;
      total += Number(quantity);
    });

    firstrAttributes.value[_index]["total"] = total;
  }
};

const changeAttributes = (item, index, e) => {
  let value = Number(e.target.value.replace(/[^0-9]/g, ""));
  if (value >= item.amountOnSale) {
    value = item.amountOnSale;
  }
  if (value <= 0) {
    value = 0;
  }
  secondAttributesQuantityList.value[index].quantity = value;
  firstSkuTotal();
  postLtogisticFee();
};

const postLtogisticFee = async () => {
  const skuSales = [];
  let totalQuantity = 0;
  Object.entries(secondAttributesQuantityObj).forEach(([key, value]) => {
    value.forEach((v) => {
      if (v?.quantity > 0) {
        skuSales.push(toRaw(v));
      }
    });
  });

  skuSales.forEach((v) => {
    totalQuantity += Number(v.quantity);
  });

  // 筛选满足条件的价格区间
  const matchedPrice = offerData.value?.productSaleInfo?.priceRangeList
    .filter((range) => totalQuantity >= range.startQuantity)
    .sort((a, b) => b.startQuantity - a.startQuantity)[0]?.price;

  const mergedArray = skuSales?.map((detail) => {
    const shipInfo = !!offerData.value?.productSkuInfos
      ? offerData.value?.productSkuInfos
      : offerData.value?.productShippingInfo?.skuShippingInfoList;
    const matchingSale = shipInfo?.find((sale) => sale.skuId === detail.skuId);
    if (matchingSale) {
      return {
        quantity: detail.quantity,
        skuId: detail.skuId,
        weight: matchingSale?.skuShippingInfo?.weight,
        width: matchingSale?.skuShippingInfo?.width,
        length: matchingSale?.skuShippingInfo?.length,
        height: matchingSale?.skuShippingInfo?.height,
        price: !!matchingSale?.price ? matchingSale?.price : matchedPrice,
        skuImageUrl: matchingSale.skuAttributes?.[0]?.skuImageUrl,
        skuName: `${matchingSale.skuAttributes?.[0]?.valueTrans} ${matchingSale.skuAttributes?.[1]?.valueTrans || ''}`
      };
    }
    return detail;
  });

  if (!!mergedArray && !mergedArray.length) {
    store.commit('setSupplierFee', {
      supplierFee: 0,
      suppliersName: '',
      suppliersUrl: ''
    });
    store.commit('setMergedArray', []);
    return
  }

  const logisticFeeProductInputs = [
    {
      offerId: offerData.value.offerId,
      titleCn: offerData.value.subject,
      categoryId: offerData.value.categoryId,
      productUrl: window.location.href,
      skuShipInfos: mergedArray
    }
  ]

  if (totalQuantity >= offerData?.value?.minOrderQuantity) {
    const countryCodeItem = !!localStorage.getItem('setCurrentCountry') ? JSON.parse(localStorage.getItem('setCurrentCountry'))?.countryCode : countryList.value[0]?.countryCode
    const { code, data } = await fetchData('/dsp-app/alibaba-common/supplierFee', {
      method: 'post',
      body: JSON.stringify({ logisticFeeProductInputs, countryCode: countryCodeItem, userId: localStorage.getItem('dsp.uid') || ''}),
    });
    if (code === 200) {
      store.commit('setSupplierFee', data);
      store.commit('setMergedArray', logisticFeeProductInputs);
    }
  }

};
</script>

<style lang="less">
@import "./index.less";
</style>
