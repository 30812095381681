<template>
  <div class="mask" v-if="maskVisible"></div>
  <div class="newbie-guidance" v-if="videoVisible">
    <div class="close-btn" @click="close()">x</div>
    <div class="guidance-pop">
      <iframe :title="title" :src="videoSrc"   frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, defineProps, defineEmits } from "vue";
import { useStore } from "vuex";

const store = useStore();
const emit = defineEmits();

const props = defineProps({
  popVisible: {
    type: Boolean,
    required: false,
  },
  title: {
    type: String,

    required: true,
  },
  videoSrc: {
    type: String,
    required: true,
  },
});

const videoVisible = ref(false);
const maskVisible = ref(false)

const close = () => {
  videoVisible.value = false
  store.commit("common/SET_ANIMATE_HELPCENTER", true);
  store.commit("common/SET_TOAST_VISIBLE", false);
  setTimeout(() => {
    maskVisible.value = false
    store.commit("common/SET_ANIMATE_HELPCENTER", false);
    store.commit("common/SET_TOAST_INFO", {
      msg: "Saved to “Help Center”",
      type: "success",
    });
    emit("close");
  }, 1000)
};

watch(
  () => props.popVisible,
  (visible) => {
    videoVisible.value = visible;
    maskVisible.value = visible;
    if (visible) {
      document.body.classList.add("mask-active");
    } else {
      document.body.classList.remove("mask-active");
      store.commit("common/SET_TOAST_VISIBLE", true);
    }
  }
);
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
