<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.74001 6.0017L7.86572 2.87455L7.26001 2.26855L3.52858 6.00198L7.26001 9.7317L7.86572 9.12541L4.74001 6.0017Z"
      :fill="color"
      :fill-opacity="opacity"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: String,
      default: "12",
    },
    height: {
      type: String,
      default: "12",
    },
    viewBox: {
      type: String,
      default: "0 0 12 12",
    },
    color: {
      type: String,
      default: "#fff",
    },
    opacity: {
      type: String,
      default: "1",
    },
  },
};
</script>
