<template>
  <div>
    <div class="sourcing" :class="{ scrollFixed: isScrollTop }">
      <div class="sourcing-main">
        <div class="ship">
          <div class="ship-title">Ship to</div>
          <div class="ship-main" @click="showShipTo">
            <img :src="currentCountry?.countryUrl" alt="" class="ship-country-img" />
            <span class="ship-main-title">{{
              currentCountry?.countryName
              }}</span>
            <img src="@/assets/offer/down.svg" alt="" />
            <ShipTo v-if="isSearchVisible" @click="stopClick" :handleClickOutside="handleClickOutside"
              :currentCountry="currentCountry" :handleClose="handleClose" />
          </div>
        </div>
        <div class="line"></div>
        <div class="fulfillment">Fulfillment Supplier</div>
        <div class="bd">
          <img src="@/assets/offer/bd.png" alt="" />
          <div class="bd-partner">1688 Official Partner</div>
        </div>
        <div class="estimated">Estimated Fulfillment Costs</div>
        <div class="minOrderQuantity">
          MOQ: {{ offerData?.minOrderQuantity }}PCS
        </div>
        <div class="price" v-if="supplierFee?.supplierFee === 'unknown'">
          <span style="margin-left: 0px">Need a quote</span>
        </div>
        <div class="price" v-if="supplierFee?.supplierFee !== 'unknown'">
          <span>{{ currencyData?.targetUnit }}</span>{{
            (
              Math.ceil(currencyData?.rate * supplierFee?.supplierFee * 100) /
              100
            ).toFixed(2)
          }}<span>{{ currencyData?.targetCurrency }}</span>
        </div>
        <div class="additional">
          Total Cost: Items amount + Additional shipping costs (estimated)
        </div>
        <div class="purchased" @mouseenter="mousePurchasedMove" @mouseleave="mousePurchasedleave">
          <img src="@/assets/offer/cricle.svg" alt="" style="cursor: pointer" />
          <span style="cursor: pointer">Price when purchased online now</span>

          <transition name="country-fade">
            <div class="purchased-hoverd" v-if="false">
              <div class="country-select-top">
                <img src="@/assets/country-select.svg" alt="" />
              </div>
              <div class="country-select-hideline"></div>
              <div>
                The specific price will change with exchange rate fluctuations
              </div>
            </div>
          </transition>
        </div>
        <div class="sourcing-now" @click="sourcingNow">Sourcing Now</div>
        <div class="line"></div>
        <div class="know">Did you Know?</div>
        <div class="buyer">
          <div class="buyer-img">
            <img src="@/assets/offer/buyer.png" alt="" />
          </div>
          <div class="buyer-mian">
            <div>Buyer Protection</div>
            <div>
              Shop confidently on this website knowing if something goes wrong
              with an order, we've got your back for all eligible purchases.
            </div>
          </div>
        </div>
        <div class="line"></div>
        <div class="rating">
          <img src="@/assets/offer/gift.svg" alt="" class="rating-gift" />
          <span>Supplier Credit Rating</span>
          <div style="position: relative" @mouseenter="mouseRatingMove" @mouseleave="mouseRatingLeave">
            <img src="@/assets/offer/rating.svg" alt="" class="rating-cricle" />
            <transition name="country-fade">
              <Rating v-if="isRatingVisible" :offerData="offerData" />
            </transition>
          </div>
        </div>
        <div class="product-star">
          <div class="product-star-img" v-if="
            offerData?.sellerDataInfo?.compositeServiceScore ||
            offerData?.sellerDataInfo?.compositeServiceScore == 0
          ">
            <img src="@/assets/star.svg" alt="" v-for="i in new Array(
              Math.floor(offerData?.sellerDataInfo?.compositeServiceScore)
            ).fill(0)" />
            <img src="@/assets/star-no.svg" alt="" v-for="i in new Array(
              Math.ceil(5 - offerData?.sellerDataInfo?.compositeServiceScore)
            ).fill(0)" />
          </div>
          <div class="product-star-cost">
            {{ offerData?.sellerDataInfo?.compositeServiceScore }}
          </div>
        </div>
      </div>
      <!-- <div class="ads" @click="handleClick" style="cursor: pointer">
        <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/SEOAnt.png" alt="" />
      </div> -->
    </div>
    <AddressInfoDialog v-if="showAddressInfoDialog" :currentCountry="currentCountry" :handleClose="handleCloseAddressInfoDialog" @click="stopClick" :showAddressInfoDialogData="showAddressInfoDialogData" />
  </div>
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, onMounted, onUnmounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import ShipTo from './components/ShipTo/index.vue';
import Rating from './components/Rating/index.vue';
import AddressInfoDialog from './components/AddressInfoDialog/index.vue'
import { fetchData } from '../../../utils/fetch';
import { buriedPointUtil, gaAnalytics } from '../../../utils/utils';
import { useRoute } from 'vue-router';
import { useCommonToast } from '@/hooks/useCommonToast'

const { toast } = useCommonToast();

const store = useStore();
const route = useRoute();
const offerData = computed(() => store.state.offerDetail);
const supplierFee = computed(() => store.state.supplierFee);
const currencyData = computed(() => store.state.currencyData);

const userInfo = computed(() => store.state.common.userInfo);

const appName = ref(import.meta.env.VITE_APP_NAME);

const isPurchasedVisible = ref(false);
const isRatingVisible = ref(false);

const currentCountry = ref({});
const isSearchVisible = ref(false);
const showAddressInfoDialog = ref(false)
const showAddressInfoDialogData = ref({})

const estimatedQuntity = ref(0);
const isScrollTop = ref(false);
const headerHeight = ref(221)

const mergedArray = computed(() => store.state.mergedArray);

const countryList = computed(() => store.state.countryInfo);

const countryObj = computed(() => store.state.currentCountry);

const handleClick = () => {
  window.open("https://share.seoant.com/app/1166f3dc2789866KFZ");
};

const handleScroll = (event) => {
  if (window.scrollY > headerHeight.value) {
    isScrollTop.value = true;
  } else {
    isScrollTop.value = false;
  }
};

onMounted(() => {
  window.addEventListener("scroll", handleScroll);

  nextTick(() => {
    const headerPc = document.querySelector('.header-pc');
    const height = headerPc ? headerPc.offsetHeight : 221;
    headerHeight.value = height
  })

});

onUnmounted(() => {
  window.removeEventListener("scroll", handleScroll);
});

watch([countryList, countryObj], (newData) => {
  if (newData) {
    const country = !!localStorage.getItem("setCurrentCountry")
      ? JSON.parse(localStorage.getItem("setCurrentCountry"))
      : "";
    if (country) {
      currentCountry.value = country;
      return;
    }
    if (!!newData[1]) {
      currentCountry.value = newData[1];
      return;
    }
    currentCountry.value = newData[0][0];
  }
}, { immediate: true });

// watch(countryObj, (newData) => {
//   if (newData) {
//     currentCountry.value = newData
//   }
// });

watch(mergedArray, (newData) => {
  if (newData) {
    let quantity = 0;
    newData?.[0]?.skuShipInfos?.forEach((v) => {
      quantity += v.quantity;
    });
    mergedArray.value = newData;
    estimatedQuntity.value = quantity;
  }
});

const sourcingNow = async () => {
  const { categoryId, subjectTrans, productImage, productSaleInfo, offerId, minOrderQuantity } = offerData.value;
  buriedPointUtil({ eventId: "PPD201" });
  const tk = localStorage.getItem('tk')
  if(!tk) {
    store.commit('setLoginVisible', true);
    return
  }

  const complete = localStorage.getItem("complete") || "";
  if (complete.indexOf(3) === -1) {
    store.dispatch("common/openGuidancePop", { id: 3, helpCenterImage: 'https://cdn-image.bestdealer.com/app-bestdealer/1688/guide-image-1.webp' });
    return
  }

  if(minOrderQuantity > estimatedQuntity.value) {
    toast('The minimum order quantity for the product has not been reached. Please continue to add products.', { type: 'error' })
    return
  }
  showAddressInfoDialog.value = true
  const price = mergedArray.value?.skuShipInfos ? mergedArray.value?.skuShipInfos?.[0].price : productSaleInfo?.priceRangeList?.[0]?.price
  const obj = {
    categoryId,
    sourceUrl: window.location.href,
    productName: subjectTrans,
    categoryName: "",
    imageUrl: productImage?.images[0],
    country: countryObj.value?.countryCode,
    purchasingType: 1,
    estimatedQuntity: estimatedQuntity.value || 0,
    price,
    supplierFee: supplierFee.value.supplierFee,
    offerId,
    currency: currencyData.value?.targetCurrency,
    currencyUnit: currencyData?.value?.targetUnit,
    mergedArray: mergedArray.value
  };
  showAddressInfoDialogData.value = obj
  return
};

const mouseMove = () => {
  isSearchVisible.value = true;
};

const mouseleave = () => {
  isSearchVisible.value = false;
};

const mousePurchasedMove = () => {
  isPurchasedVisible.value = true;
};

const mousePurchasedleave = () => {
  isPurchasedVisible.value = false;
};

const mouseRatingMove = () => {
  isRatingVisible.value = true;
};

const mouseRatingLeave = () => {
  isRatingVisible.value = false;
};

const handleClickOutside = (e, data) => {
  if (!e.target.closest(".ship-main") || !!data) {
    isSearchVisible.value = false;

    if (!!data) {
      currentCountry.value = data;
    }
  }
};

const handleClose = () => {
  isSearchVisible.value = false;
};

watch(offerData, (newData) => {
  if (newData) {
    offerData.value = newData;
  }
});

watch(currencyData, (newData) => {
  if (newData) {
    currencyData.value = newData;
  }
});

watch(supplierFee, (newData) => {
  if (newData) {
    supplierFee.value = newData;
  }
});

const showShipTo = () => {
  isSearchVisible.value = true;
};

const stopClick = (event) => {
  event.stopPropagation();
};

const handleCloseAddressInfoDialog = () => {
  showAddressInfoDialog.value = false
}

onMounted(() => {
  document.addEventListener("click", handleClickOutside);
});

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside);
});


</script>

<style lang="less">
@import "./index.less";
</style>
