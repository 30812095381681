<template>
  <div class="h5-header">
    <div class="h5-header-banner" :style="renderStyles()">
      <div class="h5-header-top" v-if="isHome">
        <div class="h5-header-top-title">Your Gateway to Unlimited Goods!</div>
        <div class="h5-header-top-desc">
          Discover millions of products for your needs.
        </div>
      </div>
      <div class="h5-header-main">
        <div class="h5-header-main-left">
          <div class="h5-header-menu" @click="showCategory">
            <img src="@/assets/H5/home/h5-menu.svg" alt="">
          </div>
          
          <div class="h5-header-main-logo" @click="goHome">
            <img src="@/assets/H5/home/logo.svg" alt="" />
          </div>
        </div>
        <div class="h5-header-main-right">
          <div class="h5-header-sign" @click="signIn">
            <img src="@/assets/H5/home/person.svg" alt="">
          </div>
          <div class="h5-header-cart" @click="toCartPage">
            <template v-if="userInfo.uid">
              <div v-if="cartCount" class="cart-count">{{ cartCount > 99 ? '99+' : cartCount }}</div>
              <img v-if="cartCount === 0" src="@/assets/H5/carts/empty-cart.svg" alt="">
              <img v-else src="@/assets/H5/carts/cart-icon.svg" alt="">
            </template>
            <img v-else src="@/assets/H5/carts/empty-cart.svg" alt="">
          </div>
          <div class="h5-header-country" @click="showCurrency">
            <img
              :src="countryInfo[0]?.countryUrl"
              alt=""
              class="h5-country-logo"
            />
            <div>
              {{
                countryInfo[0]?.countryName.length > 7
                  ? countryInfo[0]?.countryCode
                  : countryInfo[0]?.countryName
              }}
            </div>
            <img src="@/assets/H5/home/down.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="h5-home-header-search">
      <div class="language-selectbox" v-if="showLangunageSelectBox">
        <div class="language-selectbox-selected">
          <div class="selected">
            <!-- English / EN -->
             {{ String(selectedLanguage).toLocaleUpperCase() }}
            <img src="@/assets/H5/home/arrow-down.svg" alt="">
          </div>
        </div>
        <div class="language-selectbox-list">
          <div @click="chooseLanguage(item)" v-for="(item, index) in languageList" :key="index" class="language-selectbox-list-item">
            {{ `${ item.sourceLan } / ${String(item.language).toLocaleUpperCase()}` }}
          </div>
        </div>
      </div>
      <div class="h5-header-search-container" @click="showSearchFoucs">
        <div @click.stop="showLanguageSelect" class="search-container-language-selectbox-wrapper">
          <div class="search-container-language-selectbox">
            {{ String(language).toLocaleUpperCase() }} <img src="@/assets/H5/home/arrow-down.svg" alt="">
          </div>
        </div>
        <div class="search-container-text">🔍 Search anything you want</div>
        <div class="search-container-btn">
          <div @click.stop="photoClick" class="container-btn-photo">
            <img src="@/assets/H5/home/photo.svg" alt="" />
          </div>
          <div class="container-btn-search" @click="searchText">
            <img src="@/assets/H5/home/search.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <H5Dialog class="h5-dialog-content" v-model="showPopup" height="100%" style="padding: 12px;">
      <H5LoginIn :handleClickOutside="handleClickOutside" />
    </H5Dialog>

    <H5Dialog v-model="showH5Currency" :height="`calc(${innerHeight}px - 80px)`" :initStyles="{ background: 'white' }">
      <H5Currency :handleClickOutside="handleCurrencyClickOutside"  />
    </H5Dialog>
    <H5FullMask @maskClick="showLangunageSelectBox = false" />
  </div> 
</template>

<script setup>
import H5Dialog from '@/components/H5/H5Dialog/index.vue';
import H5LoginIn from '@/components/H5/H5LoginIn/index.vue';
import H5LoginOut from '@/components/H5/H5LoginOut/index.vue';
import H5Currency from '@/components/H5/H5Currency/index.vue';
import H5FullMask from '@/components/H5/H5FullMask/index.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { fetchData } from '../../../utils/fetch';
import { useStore } from 'vuex';
import { buriedPointUtil, gaAnalytics } from '@/utils/utils'
import { useRoute, useRouter } from 'vue-router';
import { checkHasQueryLanguage } from '../../../utils/utils';

const router = useRouter();

const showPopup = ref(false);
const showH5Currency = ref(false);

const store = useStore()
const innerHeight = ref(0)

const route = useRoute();
const { lg = "" } = route.query;

const userInfo = computed(() => store.state.common.userInfo);
const countryInfo = computed(() => store.state.countryInfo);
const isLoginVisible = computed(() => store.state.isLoginVisible);
const language = computed(() => store.state.h5Module.language);
const languageList = computed(() => store.state.h5Module.languageList);

const cartCount = computed(() => store.state.cart.cartCount);

watch(isLoginVisible, (newData) => {
  showPopup.value = newData;
});

const isHome = window.location.pathname === "/";

const searchText = (e) => {
  gaAnalytics("mp_search_click", "mp_search_click");
};

const getHashParam = (key) => {
  const hash = window.location.hash.substring(1);
  const params = new URLSearchParams(hash);
  return params.get(key);
};

const goHome = () => {
  window.location.href = "/";
};

onMounted(() => {
  innerHeight.value = window.innerHeight;
  getCountry()
  getCurrencyList()
  getUserInfo()

  if (/code|access_token/.test(window.location.hash)) {
    store.commit("setLoading", true);
    const registerChannel = !!localStorage.getItem("source")
      ? localStorage.getItem("source")
      : 2;
    const access_token = getHashParam("access_token");
    fetchData("/dsp-app/ul/login", {
      method: "post",
      body: JSON.stringify({
        loginType: "google",
        registerChannel,
        saasChannel: 12,
        registerTerminal: 2,
        accessToken: access_token,
      }),
    })
      .then(async ({ data }) => {
        store.commit("setLoading", false);
        localStorage.setItem("tk", data.tk);
        localStorage.setItem("dsp.uid", data.uid);
        localStorage.removeItem('searchUrl')
        if (data?.newUser) {
          gaAnalytics("mp_register_success", "continue_with_google", {
            userId: data.uid,
            newUser: data?.newUser,
            bdUserId: data.uid,
            loginType: "continue_with_google",
          });
        }
        const url = localStorage.getItem('loginRedirectUrl') || '/';
        setTimeout(() => {
          window.location.href = url
        }, 300)
      })
      .catch((error) => {
        store.commit("setLoading", false);
      });
  }
});

const loadCacheCountry = () => {
  const _cacheData = localStorage.getItem('cacheCountryList')
  if (!_cacheData) return null;

  const cacheData = JSON.parse(_cacheData);
  if (Date.now() - cacheData.timestamp > 24 * 60 * 60 * 1000) {
    localStorage.removeItem('cacheCountryList');
    return null;
  }
  return cacheData.data
}

const getCountry = async () => {
  const cacheValue = loadCacheCountry();
  if (cacheValue) {
    store.commit("setCountryInfo", cacheValue);

    const countryObj = !!localStorage.getItem("setCurrentCountry")
      ? JSON.parse(localStorage.getItem("setCurrentCountry"))
      : cacheValue[0];
    store.commit("setCurrentCountry", countryObj);
    return
  }

  const { code, data } = await fetchData("/dsp-app/alibaba-common/country");
  if (code === 200) {
    const cacheData = {
      data,
      timestamp: Date.now()
    }
    localStorage.setItem('cacheCountryList', JSON.stringify(cacheData))

    store.commit("setCountryInfo", data);

    const countryObj = !!localStorage.getItem("setCurrentCountry")
      ? JSON.parse(localStorage.getItem("setCurrentCountry"))
      : data[0];
    store.commit("setCurrentCountry", countryObj);
  }
};

const getCurrencyList = async () => {
  const { code, data } = await fetchData("/dsp-app/alibaba-common/currencyList");
  if (code === 200) {
    store.commit("setCurrencyList", data);
    if (!localStorage.getItem("currencyData")) {
      localStorage.setItem("currencyData", JSON.stringify(data[0]));
      store.dispatch("setProduct", data[0]);
      store.commit("setCurrencyData", data[0]);
    } else {
      store.dispatch(
        "setProduct",
        JSON.parse(localStorage.getItem("currencyData"))
      );
      store.commit(
        "setCurrencyData",
        JSON.parse(localStorage.getItem("currencyData"))
      );
    }
  }
};

const getUserInfo = async () => {
  const { code, data } = await fetchData("/dsp-app/alibaba-inter/queryMyInfo", {
    method: "post",
  });
  if (code === 200) {
    localStorage.setItem("dsp.uid", data.uid);
    store.commit("common/SET_USER_INFO", data);
  }
};

const showSearchFoucs = () => {
  store.commit("h5Module/setSearchFoucs", true);
  store.commit("setEmptyObj", {});
};

watch(countryInfo, (newData) => {
  if (newData) {
    countryInfo.value = newData;
  }
});

watch(userInfo, (newData) => {
  if (newData) {
    userInfo.value = newData;
  }
});

const signIn = () => {
  if (userInfo.value.uid) {
    router.push('/account')
  } else {
    gaAnalytics("mp_sign_in_click", "mp_sign_in_click");
    buriedPointUtil({ eventId: "MPWH201" });
    buriedPointUtil({ eventId: "MPLG501" });
    store.commit("setPassiveLogin", 0);
    showPopup.value = true;
  }
};

const productListCopy = computed(() => store.state.productListCopy);
const handleClickOutside = () => {
  showPopup.value = false;
  store.commit("setProductList", productListCopy.value);
  store.commit("setLoginVisible", false);
};

const showCurrency = () => {
  showH5Currency.value = true;
};

const handleCurrencyClickOutside = () => {
  showH5Currency.value = false;
};
const photoClick = () => {
  buriedPointUtil({ eventId: "MPSC202" });
  store.commit("h5Module/setImageSearchGuidePageVisible", true);
};

const renderStyles = () => {
  if (window.location.pathname.includes("/offer")) {
    return {
      background: "none",
    };
  }
};

const showLangunageSelectBox = ref(false)
const showLanguageSelect = () => {
  showLangunageSelectBox.value = true;
  store.commit('h5Module/setFullMask', true)
}

const showCategory = () => {
  store.commit('h5Module/setCategoryDialogVisible', true);
  buriedPointUtil({ eventId: "MPWH401" });
}

const selectedLanguage = computed(() => {
  const item = languageList.value.find(item => (item.language).toLowerCase() === (language.value).toLowerCase())
  return `${ item.sourceLan } / ${item.language}`
})

const chooseLanguage = (item) => {
  store.commit('h5Module/setLanguage', item.language)
  showLangunageSelectBox.value = false;
  store.commit('h5Module/setFullMask', false)
  localStorage.setItem('language', item.language)
}

const toCartPage = () => {
  buriedPointUtil({ eventId: 'MPC201' })
  router.push('/carts')
}

</script>

<style lang="less">
@import "./index.less";
</style>
