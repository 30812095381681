<template>
  <div class="custom-select">
    <input
      v-model="searchText"
      @focus="openDropdown"
      @blur="closeDropdown"
      @input="filterOptions"
      placeholder=""
    />
    <div class="custom-select-img">
      <img src="@/assets/country/down.svg" alt="" v-if="!isDropdownOpen">
      <img src="@/assets/country/search.svg" alt="" v-if="isDropdownOpen">
    </div>
    <!-- 使用 Vue 的 transition 组件 -->
    <transition name="dropdown-fade">
      <div v-if="isDropdownOpen" class="dropdown">
        <div
          v-for="option in filteredOptions"
          :key="option"
          @click="selectOption(option)"
          class="dropdown-item"
        >
          {{ option }}
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
const emit = defineEmits();

// Declare props
const props = defineProps({
  modelValue: {
    type: String,
    default: 'USD',
  },
  options: {
    type: Array,
    required: true,
  },
});

// Reactive state
const searchText = ref(props.modelValue);
const isDropdownOpen = ref(false);
const filteredOptions = ref(props.options);

// Methods
const filterOptions = () => {
  filteredOptions.value = props.options.filter((option) =>
    option.toLowerCase().includes(searchText.value.toLowerCase())
  );
};

const openDropdown = () => {
  isDropdownOpen.value = true;
};

const closeDropdown = () => {
  setTimeout(() => {
    isDropdownOpen.value = false;
  }, 200);
};

const selectOption = (option) => {
  searchText.value = option;
  emit('updateModelValue', option);
  closeDropdown();
};

// Watchers
watch(
  () => props.modelValue,
  (newVal) => {
    searchText.value = newVal;
  }
);
</script>

<style scoped>
.custom-select {
  position: relative;
  width: 156px;
  margin-top: 6px;
  input {
    height: 32px;
    font-family: mon-500;
  }
}

.custom-select-img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

input {
  width: 100%;
  padding: 4px 8px;
  box-sizing: border-box;
  border: 0.5px solid #B2B7C2;
  border-radius: 4px;
  height: 24px;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -2%;
  color: #6E717A;
  transition: outline 0.3s ease;
  outline: none
}

input:hover {
  outline: 0.5px solid #0069E0;
}

.dropdown {
  position: absolute;
  top: 28px;
  left: 0;
  right: 0;
  border: 0.5px solid #B2B7C2;
  background-color: white;
  max-height: 200px;
  overflow-y: auto;
  border-radius: 4px;
  z-index: 10;
  padding: 4px;
  min-height: 20px;
  padding-top: 10px;
  padding-bottom: 10px;

  div {
    height: 32px;
    display: flex;
    align-items: center;
  }
}

.dropdown-item {
  padding: 4px 8px;
  cursor: pointer;
  font-family: mon-600;
  font-size: 12px;
  letter-spacing: -2%;
  line-height: 16px;
  color: #6E717A;
  font-family: mon-500;
}

.dropdown-item:not(:first-child) {
  margin-top: 4px;
}

.dropdown-item:hover {
  background-color: #F5F5F5;
  border-radius: 4px;
  font-family: mon-600;
  color: #232323;
}

/* 定义动画 */
.dropdown-fade-enter-active, .dropdown-fade-leave-active {
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.dropdown-fade-enter, .dropdown-fade-leave-to {
  opacity: 0;
  transform: translateY(-10px); /* 设置一个轻微的上移 */
}
</style>
