<template>
  <div class="order-filling-h5">
    <div class="order-filling-h5-header-wrapper">
      <div class="order-filling-h5-header">
        <div class="order-filling-h5-header-left" @click="handleBack">
          <img
            src="@/assets/H5/order/icon-arrow-left.svg"
            alt=""
            :style="{ visibility: bankPaymentDialog ? 'hidden' : 'visible' }"
          />
          <div
            class="order-filling-h5-header-left-title"
            :style="{
              visibility:
                showShippingDesc || bankPaymentDialog || isOrder
                  ? 'hidden'
                  : 'visible',
            }"
          >
            Carts
          </div>
        </div>
        <div class="order-filling-h5-header-title">
          <!-- {{ showShippingDesc ? "Shipping" : "Order Filling" }} -->
          {{ title }}
        </div>
        <div class="close-btn-wrapper">
          <img
            src="@/assets/H5/offer/sourcing-close.svg"
            alt=""
            class="close-btn"
            @click="closeBtn"
            :style="{ visibility: showShippingDesc ? 'hidden' : 'hidden' }"
          />
        </div>
      </div>
    </div>
    <template v-if="!isLoading && !isPaySuccess">
      <div
        :class="[
          { 'order-filling-h5-content-wrapper-paypal': isPaypalMethod },
          'order-filling-h5-content-wrapper',
        ]"
        ref="contentWrapper"
      >
        <div class="order-filling-h5-content">
          <div class="order-filling-h5-content-title">
            Shipping Methods
            <span
              v-if="isDisabledshippingList"
              class="order-filling-h5-content-title-count"
              >({{
                shippingList.filter((item) => !item.disabled).length
              }})</span
            >
          </div>
          <div
            class="order-filling-h5-content-address"
            :style="{
              height: expanded ? renderShippingListHeight() : '64px',
            }"
            v-if="isDisabledshippingList"
          >
            <div
              :class="[
                'order-filling-h5-content-address-item',
                {
                  'order-filling-h5-content-address-item-disabled':
                    item?.disabled,
                },
              ]"
              v-for="(item, index) in shippingList"
              :key="item.channelName"
              @click="selectShippingMethod(item)"
            >
              <img
                :src="item?.checked ? item.checkedIcon : item.uncheckedIcon"
                alt=""
              />
              <div class="order-filling-h5-content-address-item-right">
                <div class="order-filling-h5-content-address-item-right-name">
                  {{ item.channelName }}
                </div>
                <div
                  class="order-filling-h5-content-address-item-right-delivery"
                >
                  <span v-if="item.disabled">Not supported yet</span>
                  <span
                    v-if="!item.disabled"
                    class="order-filling-h5-content-address-item-right-delivery-text"
                    >Delivery:</span
                  >
                  <span
                    v-if="!item.disabled"
                    class="order-filling-h5-content-address-item-right-delivery-time"
                    >{{
                      `${item?.shipPackage?.minDeliveryTime}-${item?.shipPackage?.maxDeliveryTime} days`
                    }}</span
                  >
                </div>
                <div
                  class="address-line"
                  v-if="index !== shippingList.length - 1"
                  :style="{
                    opacity: expanded ? '1' : '0',
                  }"
                ></div>
                <div
                  class="address-select"
                  @click="(e) => expandAddress(e, item)"
                  v-if="index === 0 && shippingList.length > 1"
                >
                  <img
                    src="@/assets/H5/orderFilling/address-select.svg"
                    alt=""
                    :style="{
                      transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                      transition: 'transform 0.3s ease-in-out',
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="!isDisabledshippingList"
            class="order-filling-h5-content-disabled"
          >
            <div class="order-filling-h5-content-address-item-disabled-left">
              <div
                class="order-filling-h5-content-address-item-disabled-left-title"
              >
                Oops!
              </div>
              <div
                class="order-filling-h5-content-address-item-disabled-left-desc"
              >
                No delivery logistics available.
              </div>
            </div>
            <img src="@/assets/H5/orderFilling/no-supplier.svg" alt="" />
          </div>
        </div>
        <div class="product-list">
          <div
            class="product-list-item"
            v-for="(item, index) in productList"
            :key="index"
          >
            <div class="product-item">
              <div class="product-item-left">
                <img
                  src="@/assets/H5/orderFilling/disabled-checked.svg"
                  alt=""
                  v-if="!item.shipAmount && !item.inCheckedList"
                />
                <img
                  src="https://cdn-image.bestdealer.com/app-bestdealer/1688/carts/h5-checked-icon.svg"
                  alt=""
                  class="product-checked"
                  v-if="item.checked && (item.shipAmount || item.inCheckedList)"
                  @click="handleProductChecked(item)"
                />
                <img
                  src="https://cdn-image.bestdealer.com/app-bestdealer/1688/carts/h5-unchecked-icon.svg"
                  alt=""
                  class="product-checked"
                  v-if="
                    !item.checked && (item.shipAmount || item.inCheckedList)
                  "
                  @click="handleProductChecked(item)"
                />
                <img :src="item.skuImage" alt="" class="product-image" />
              </div>
              <div class="product-item-right">
                <div class="product-price-qty">
                  <div class="product-price">
                    {{ currencyData?.targetCurrency?.slice(0, 2) }}
                    {{ currencyData?.targetUnit
                    }}{{
                      bankersRound(currencyData?.rate * item?.skuPriceRmb, 2)
                    }}
                  </div>
                  <div class="product-qty">×{{ item?.skuQuantity }}</div>
                </div>
                <div class="product-title">{{ item.spuName }}</div>
                <div class="product-specs">
                  <img :src="item.skuImage" alt="" />
                  <span class="product-specs-text-1">
                    {{
                      item.skuSpec
                        ?.split(";")
                        .map((x) => x.split(":")[1])
                        .join("; ")
                    }}
                  </span>
                </div>
              </div>
            </div>
            <div class="product-total">
              <div class="product-shipping-item">
                <span>Shipping fee: </span>
                <span v-if="item.shipAmount || item.inCheckedList">
                  {{ currencyData?.targetCurrency?.slice(0, 2) }}
                  {{ currencyData?.targetUnit }}{{ item.shipAmount }}</span
                >
                <span v-else class="product-shipping-item-not-supported"
                  >Not supported</span
                >
              </div>
              <div
                class="product-subtotal-item"
                v-if="item.shipAmount || item.inCheckedList"
              >
                <span>Subtotal: </span>
                <IntlCurrency
                  :count="item.totalAmount || 0.0"
                  :size="16"
                  :currency="currencyData?.targetCurrency.slice(0, 2)"
                  color="#052785"
                  v-if="item.shipAmount || item.inCheckedList"
                  style="position: relative; top: 5px"
                />
                <span v-else class="product-shipping-item-not-supported"
                  >Not supported</span
                >
              </div>
            </div>
            <div
              class="product-total-line"
              v-if="index !== productList.length - 1"
            ></div>
          </div>
        </div>
        <div class="payment-method">
          <div class="payment-method-title">Payment Method</div>
          <div class="payment-method-subtitle">
            All transactions are secure and encrypted.
          </div>
          <div
            :class="[
              'payment-method-item',
              { 'payment-method-item-first': index === 0 },
              {
                'payment-method-item-disabled': item.id === 1 && disabledPaypal,
              },
            ]"
            v-for="(item, index) in paymentList"
            :key="index"
            :style="{
              border: item.checked ? '1px solid #232323' : '1px solid #EBF1F5',
            }"
            @click="
              item.id === 1 && disabledPaypal ? null : selectPaymentMethod(item)
            "
          >
            <img
              :src="item.checked ? item.checkedIcon : item.uncheckedIcon"
              alt=""
            />
            <img :src="item.icon" alt="" class="payment-method-icon" />
            <span class="payment-method-name">{{ item.name }}</span>
          </div>
        </div>
        <div class="shipping-address">
          <div class="shipping-address-title">Shipping Address</div>
          <div class="shipping-address-subtitle">
            Your address information is encrypted.
          </div>
          <div class="shipping-address-item">
            <img src="@/assets/H5/orderFilling/shipping-address.svg" alt="" />
            <div class="shipping-address-item-right">
              <div class="shipping-address-item-right-name">
                {{ shippingAddress?.name }}, {{ shippingAddress?.phoneValue }}
              </div>
              <div class="shipping-address-item-right-address">
                <div class="shipping-address-item-right-address-line-1">
                  {{ shippingAddress?.streetAddress }}
                </div>
                <div class="shipping-address-item-right-address-line-2">
                  {{ shippingAddress?.aptEtc }}
                </div>
                <div class="shipping-address-item-right-province">
                  {{ shippingAddress?.city }} {{ shippingAddress?.province }} ,
                  {{ shippingAddress?.postCode }} {{ shippingAddress?.country }}
                </div>
                <div
                  class="shipping-address-item-right-edit"
                  @click="showShipAddress"
                >
                  Edit
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="shipping-cards">
          <BottomSafeCards />
        </div>
      </div>
      <div
        class="shipping-cards-bottom-wrapper"
        :style="{
          height: showDetailModel ? '198px' : '192px',
        }"
        v-show="!showShippingDesc"
      >
        <div
          :class="[
            { 'shipping-cards-bottom-paypal': isPaypalMethod },
            'shipping-cards-bottom',
          ]"
        >
          <div class="shipping-cards-bottom-main" v-if="!showDetailModel">
            <div class="total-price" v-if="!isPaypalMethod">
              <div class="total-price-title">Total items</div>
              <div class="total-price-price">
                <IntlCurrency
                  :count="renderItemsTotalPrice()"
                  :size="14"
                  :currency="currencyData?.targetCurrency.slice(0, 2)"
                  color="#ffffff"
                />
              </div>
            </div>
            <div
              class="total-price-shipping"
              :style="{ marginTop: !isPaypalMethod ? '10px' : '0px' }"
            >
              <div class="total-price-shipping-title">
                Shipping fee by
                <img src="@/assets/H5/orderFilling/shipping-logo.svg" alt="" />
                <img
                  src="@/assets/H5/orderFilling/question.svg"
                  alt=""
                  @click="handleShippingDesc"
                  class="total-price-shipping-question"
                />
              </div>
              <div class="total-price-shipping-price">
                <div
                  class="total-price-shipping-old-price"
                  v-if="renderPrice()"
                >
                  <span>{{ currencyData?.targetUnit }}</span>
                  <span>{{ renderPrice() }}</span>
                </div>
                <div class="total-price-shipping-new-price">
                  <IntlCurrency
                    :count="renderShippingPrice()"
                    :size="14"
                    :currency="currencyData?.targetCurrency.slice(0, 2)"
                    color="#ffffff"
                  />
                </div>
              </div>
            </div>
            <div class="total-amount">
              <div class="total-amount-title">Total Amount</div>
              <div class="total-amount-price" @click="showDetailTotal">
                <IntlCurrency
                  :count="renderTotalUSPrice()"
                  :size="16"
                  currency="us"
                  color="#ffffff"
                />
                <img src="@/assets/H5/orderFilling/total-down.svg" alt="" />
              </div>
            </div>
          </div>
          <div
            class="shipping-cards-bottom-detail"
            v-if="showDetailModel"
            ref="detailModel"
          >
            <div class="shipping-header">
              <div class="shipping-header-left">
                <div class="shipping-header-left-title">Total Amount</div>
                <div
                  class="shipping-header-left-price"
                  @click="hideDetailTotal"
                >
                  <IntlCurrency
                    :count="renderTotalUSPrice()"
                    :size="16"
                    currency="us"
                    color="#ffffff"
                  />
                  <img src="@/assets/H5/orderFilling/total-up.svg" alt="" />
                </div>
              </div>
            </div>
            <div
              class="shipping-header-img"
              v-if="productList?.some((item) => item.checked)"
            >
              <div
                class="shipping-header-img-wrapper"
                v-for="item in productList?.filter((item) => item.checked)"
                :key="item"
              >
                <img
                  :src="item.skuImage"
                  alt=""
                  class="shipping-header-img-item"
                />
                <div class="shipping-header-img-price">
                  {{ currencyData?.targetUnit
                  }}{{
                    bankersRound(currencyData?.rate * item?.skuPriceRmb, 2)
                  }}
                  ×{{ item.skuQuantity }}
                </div>
              </div>
            </div>
            <div class="shipping-header-img-line"></div>
            <div class="shipping-summary">
              <div class="shipping-summary-header">
                <div class="summary-header-left"></div>
                <div class="summary-header-title">Summary</div>
              </div>
              <div class="summary-main">
                <div class="summary-main-item">
                  <div class="summary-main-item-title">Total items</div>
                  <div class="summary-main-item-price">
                    {{ currencyData?.targetCurrency?.slice(0, 2) }}
                    {{ currencyData?.targetUnit }}{{ renderItemsTotalPrice() }}
                  </div>
                </div>
                <div class="summary-main-shipping">
                  <div class="summary-main-shipping-title">
                    Shipping fee
                    <img
                      src="@/assets/H5/orderFilling/question.svg"
                      alt=""
                      @click="handleShippingDesc"
                    />
                  </div>
                  <div class="summary-main-shipping-price-wrapper">
                    <div
                      class="summary-main-shipping-old-price"
                      v-if="renderPrice()"
                    >
                      <span>{{ currencyData?.targetUnit }}</span>
                      <span>{{ renderPrice() }}</span>
                    </div>
                    <div class="summary-main-shipping-price">
                      <!-- <span>{{ currencyData?.targetUnit }}</span>
                    <span>{{ renderShippingPrice() }}</span>
                    <span>{{ currencyData?.targetCurrency }}</span> -->
                      {{ currencyData?.targetCurrency?.slice(0, 2) }}
                      {{ currencyData?.targetUnit }}{{ renderShippingPrice() }}
                    </div>
                  </div>
                </div>
                <div class="shpping-main-desc">
                  <span>Your order shipping & delivery with</span>
                  <img
                    src="@/assets/H5/orderFilling/shipping-logo.png"
                    alt=""
                  />
                </div>
                <div class="shipping-amount">
                  <div class="shipping-amount-title">Total</div>
                  <div class="shipping-amount-price">
                    {{ currencyData?.targetCurrency?.slice(0, 2) }}
                    {{ currencyData?.targetUnit }}{{ renderTotalPrice() }}
                  </div>
                </div>
                <div class="shipping-amount-line"></div>
                <div class="shipping-current">
                  <div class="shipping-current-title">
                    Current exchange rate
                  </div>
                  <div class="shipping-current-price">
                    <span
                      >{{ currencyData?.targetCurrency?.slice(0, 2) }}
                      {{ currencyData?.targetUnit }}1</span
                    >
                    <span>=</span>
                    <span
                      >{{ currencyUsdData?.targetCurrency.slice(0, 2) }}
                      {{ currencyUsdData?.targetUnit
                      }}{{ renderToUScurrencyData() }}
                    </span>
                  </div>
                </div>
                <div class="shiiping-total-amount">
                  <div class="shiiping-total-amount-title">Total Amount</div>
                  <div class="shiiping-total-amount-price">
                    <IntlCurrency
                      :count="renderTotalUSPrice()"
                      :size="18"
                      currency="us"
                      color="#ffffff"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            :class="[
              { 'total-amount-line-paypal': isPaypalMethod },
              'total-amount-line',
            ]"
          ></div>
          <div
            class="total-place-order"
            v-show="!isPaypalMethod"
            @click="handleBankPayment"
            :style="{
              opacity: productList.some((item) => item.checked) ? 1 : 0.5,
            }"
          >
            Place Order
          </div>
          <div
            ref="paypalButtonContainer"
            v-show="isPaypalMethod"
            class="paypal-button-container"
            :style="{
              opacity: productList.some((item) => item.checked) ? 1 : 0.5,
            }"
          >
            <div
              class="disabled-paypal-button"
              v-if="!productList.some((item) => item.checked)"
            ></div>
          </div>
        </div>
      </div>
      <div
        ref="shippingDescRef"
        v-if="showShippingDesc"
        class="shipping-desc-wrapper"
      >
        <ShippingDesc />
        <div class="shipping-desc-wrapper-empty-line"></div>
        <div class="shipping-desc-wrapper-line"></div>
      </div>
    </template>
    <div class="order-empty" v-if="isLoading || isPaySuccess">
      <img
        src="@/assets/H5/empty.gif"
        alt=""
        v-if="isLoading"
        class="order-empty-img"
      />
      <div v-if="isPaySuccess" class="order-success-wrapper">
        <img
          src="@/assets/H5/orderFilling/paysuccess.png"
          alt=""
          class="order-success-img"
        />
        <div class="order-success-desc">order has been Paid successfully.</div>
        <div class="order-success-desc-2">
          Your order is on the way, please be patient.
        </div>
        <div class="order-success-btn" @click="handleViewOrder">View Order</div>
      </div>
    </div>
    <div class="pop-container" :class="{ visible: popVisible }" @click.stop>
      <H5AddressInfo
        v-if="showAddressInfo"
        :handleClose="hiddenPop"
        @updateAddress="updateAddress"
        :currentCountry="currentCountry"
        :addressType="4"
      />
    </div>
    <div
      class="pop-container"
      :class="{ visible: bankPopPaymentDialog }"
      @click.stop
    >
      <H5BankPayment
        v-if="bankPaymentDialog"
        :price="renderTotalUSPrice()"
        :orderNo="orderNo"
        @cancel="handleCancel"
      />
    </div>
  </div>
</template>

<script setup>
import { loadScript } from "@paypal/paypal-js";
import H5AddressInfo from "@/components/H5/H5AddressInfo/index.vue";
import { useRouter, useRoute } from "vue-router";
import BottomSafeCards from "@/views/Carts/components/CartsH5/components/BottomSafeCards/index.vue";
import ShippingDesc from "./component/ShippingDesc/index.vue";
import economyChecked from "@/assets/H5/orderFilling/economy-checked.png";
import economyUnchecked from "@/assets/H5/orderFilling/economy-unchecked.png";
import standardChecked from "@/assets/H5/orderFilling/standard-checked.png";
import standardUnchecked from "@/assets/H5/orderFilling/standard-unchecked.png";
import expressChecked from "@/assets/H5/orderFilling/express-checked.png";
import expressUnchecked from "@/assets/H5/orderFilling/express-unchecked.png";
import freightChecked from "@/assets/H5/orderFilling/freight-checked.png";
import freightUnChecked from "@/assets/H5/orderFilling/freight-unchecked.png";
import paypal from "@/assets/H5/orderFilling/paypal.png";
import bank from "@/assets/H5/orderFilling/bank.png";
import paymentChecked from "@/assets/H5/orderFilling/payment-checked.svg";
import paymentUnchecked from "@/assets/H5/orderFilling/payment-unchecked.svg";
import { useStore } from "vuex";
import { useCommonToast } from "@/hooks/useCommonToast";
import H5BankPayment from "@/components/H5/H5BankPayment/index.vue";

const store = useStore();
const { toast } = useCommonToast();

const router = useRouter();
const showDetailModel = ref(false);
const detailModel = ref(null);
const showShippingDesc = ref(false);
const shippingDescRef = ref(null);
const isLoading = ref(true);
const isPaySuccess = ref(false);
const paypalButtonContainer = ref(null);

const popVisible = ref(false);
const showAddressInfo = ref(false);
const currencyUsdData = ref(null);
const title = ref("Order Filling");

const bankPaymentDialog = ref(false);
const bankPopPaymentDialog = ref(false);
const orderNo = ref(null);

const route = useRoute();
const isOrder = route.query.isOrder;

const shippingAddress = ref(
  localStorage.getItem("addressForm")
    ? JSON.parse(localStorage.getItem("addressForm"))
    : null
);

import { computed, nextTick, onMounted, onUnmounted, ref, watch } from "vue";
import { fetchData } from "../../../../utils/fetch";
import {
  bankersRound,
  buriedPointUtil,
  gaAnalytics,
} from "../../../../utils/utils";
const channelListEnum = {
  1: "Ocean Freight",
  2: "Economy",
  3: "Standard",
  4: "Express",
};

// SEA_FREIGHT(1, "海运"),
// AIR_FREIGHT_ECONOMY(2, "空运经济"),
// AIR_FREIGHT_STANDARD(3, "空运标准"),
// AIR_FREIGHT_EXPRESS(4, "空运极速");

const channelListEnumValue = {
  SEA_FREIGHT: 1,
  AIR_FREIGHT_ECONOMY: 2,
  AIR_FREIGHT_STANDARD: 3,
  AIR_FREIGHT_EXPRESS: 4,
};

const expanded = ref(false);
const contentWrapper = ref(null);
const channelList = ref([]);

const currencyData = ref(null);

const productList = ref([]);

const shippingList = ref([]);
const currentInnerHeight = ref(0);
const initInnerHeight = ref(0);
const currentInnerHeightCalc = ref(0);
const disabledPaypal = ref(false);
const currentCountry = ref(null);

const paymentList = ref([
  {
    id: 1,
    name: "Pay with PayPal",
    checked: true,
    icon: paypal,
    checkedIcon: paymentChecked,
    uncheckedIcon: paymentUnchecked,
  },
  {
    id: 2,
    name: "Pay with Bank",
    checked: false,
    icon: bank,
    checkedIcon: paymentChecked,
    uncheckedIcon: paymentUnchecked,
  },
]);

const getCountry = async () => {
  const { code, data } = await fetchData("/dsp-app/alibaba-common/country");
  if (code === 200) {
    store.commit("setCountryInfo", data);

    const countryObj = !!localStorage.getItem("setCurrentCountry")
      ? JSON.parse(localStorage.getItem("setCurrentCountry"))
      : data[0];
    store.commit("setCurrentCountry", countryObj);
    currentCountry.value = countryObj;
  }
};

onMounted(async () => {
  document.querySelector(".content").style.backgroundColor = "#000000";
  document.body.style.backgroundColor = "#000000";

  initInnerHeight.value = window.innerHeight;
  window.addEventListener("resize", () => {
    initInnerHeight.value = window.innerHeight;
  });

  getCountry();
  await getCurrency();
  await getCartsList();
});

const getCartsList = async () => {
  try {
    const carts = sessionStorage.getItem("carts");
    if (carts) {
      const { code, data = [] } = await fetchData("/appb/b2b/cart/cartList", {
        method: "POST",
        body: JSON.stringify({
          cartIds: JSON.parse(carts),
        }),
      });
      if (code === 200) {
        if (!shippingAddress.value) {
          isLoading.value = false;
          showAddressInfo.value = true;
          popVisible.value = true;
          return;
        }
        await calcFulfillmentFee(
          data.map((item) => ({ ...item, checked: true }))
        );
      }
      isLoading.value = false;
    }
  } catch (error) {
    isLoading.value = false;
  }
};

onUnmounted(() => {
  window.removeEventListener("resize", () => {});
  document.querySelector(".content").style.backgroundColor = "#FFFFFF";
  document.body.style.backgroundColor = "#FFFFFF";
});

const getCurrency = async () => {
  const { code, data } = await fetchData(
    "/dsp-app/alibaba-common/currencyList"
  );
  if (code === 200) {
    const storageCurrencyData = localStorage.getItem("currencyData");
    currencyUsdData.value = data.find((item) => item.targetCurrency === "USD");
    if (storageCurrencyData) {
      const storageCurrencyDataObj = JSON.parse(storageCurrencyData);
      currencyData.value = data.find(
        (item) => item.targetCurrency === storageCurrencyDataObj.targetCurrency
      );
    } else {
      currencyData.value = data[0];
    }
  }
};

const calcFulfillmentFee = async (items) => {
  const list = items ? items : productList.value;
  const checkedList = list.filter((item) => item.checked);
  const obj = {
    address: {
      ...shippingAddress.value,
      zip: shippingAddress.value?.postCode,
      phone: shippingAddress.value?.phoneValue,
      country: shippingAddress.value?.searchCountryText,
      streetAddr: shippingAddress.value?.streetAddress,
      extAddr: shippingAddress.value?.aptEtc,
      extension: shippingAddress.value?.countryCode === 'BR' ? shippingAddress.value?.cpfNumber || '' : shippingAddress.value?.countryCode === 'CL' ? shippingAddress.value?.rutNumber || '' : '',
    },
    currency: currencyData.value?.targetCurrency,
    details: checkedList.map((item) => ({
      skuId: item.skuId,
      skuQuantity: item.skuQuantity,
      spuId: item.spuId,
      skuPriceCny: item.skuPriceRmb,
      skuSpec: item.skuSpec,
      spuName: item.spuName,
    })),
  };
  const { code, data, msg ='' } = await fetchData("/appb/b2b/fee/calcFulfillmentFee", {
    method: "POST",
    body: JSON.stringify(obj),
  });
  if (data?.details) {
    data.details.sort((a, b) => {
      const order = { 3: 0, 1: 1, 2: 2, 4: 3 };
      return order[a.channelCode] - order[b.channelCode];
    });
  }

  if (code === 200) {
    // 处理返回的数据
    const mappedShippingList = data.usableChannelCodes.map((code) => {
      const iconMap = {
        1: {
          checkedIcon: freightChecked,
          uncheckedIcon: freightUnChecked,
        },
        2: {
          checkedIcon: economyChecked,
          uncheckedIcon: economyUnchecked,
        },
        3: {
          checkedIcon: standardChecked,
          uncheckedIcon: standardUnchecked,
        },
        4: {
          checkedIcon: expressChecked,
          uncheckedIcon: expressUnchecked,
        },
      };
      const matchedDetail = data.details.find((d) => d.channelCode === code);
      const existingShipping = shippingList.value?.find(
        (s) => s.channelCode === code
      );

      return {
        ...(matchedDetail || {}),
        channelName: channelListEnum[code],
        checked: existingShipping
          ? existingShipping.checked
          : code === data.details?.[0]?.channelCode,
        checkedIcon: iconMap[code]?.checkedIcon,
        uncheckedIcon: iconMap[code]?.uncheckedIcon,
        channelCode: code,
        disabled: !matchedDetail, // 如果details中没有对应的channelCode则禁用
      };
    });

    // 将checked为true的项放到数组第一位
    shippingList.value = [
      ...mappedShippingList.filter((item) => item.checked && !item.disabled),
      ...mappedShippingList.filter((item) => !item.checked && !item.disabled),
      ...mappedShippingList.filter((item) => item.disabled),
    ];

    const checkedList = list.filter((item) => !item.checked);
    productList.value = list.map((item) => ({
      ...item,
      checked:
        data.details
          .find(
            (detail) =>
              detail.channelCode ===
                shippingList.value.find((v) => v.checked)?.channelCode &&
              detail.singleShipPackages?.some((pkg) =>
                pkg.skuIds?.includes(item.skuId)
              )
          )
          ?.singleShipPackages?.some((pkg) =>
            pkg.skuIds?.includes(item.skuId)
          ) ?? false,
      inCheckedList: Boolean(
        checkedList.some((checkedItem) => checkedItem.skuId === item.skuId)
      ),
      ...data.details
        .find(
          (detail) =>
            detail.channelCode ===
              shippingList.value.find((v) => v.checked)?.channelCode &&
            detail.singleShipPackages?.some((pkg) =>
              pkg.skuIds?.includes(item.skuId)
            )
        )
        ?.singleShipPackages?.find((pkg) => pkg.skuIds?.includes(item.skuId)),
    }));
  }else {
    toast(msg, { type: "warning" });
  }
};

const handleProductChecked = async (item) => {
  if (item.shipAmount || item.inCheckedList) {
    // 如果当前是最后一个选中的商品且要取消选中,则不允许取消
    if (
      item.checked &&
      productList.value.filter((i) => i.checked).length === 1
    ) {
      toast("Please select at least one item", { type: "warning" });
      return;
    }
    item.checked = !item.checked;
    await calcFulfillmentFee();
  }
};

const updateAddress = async (items) => {
  try {
    const carts = sessionStorage.getItem("carts");
    if (items) {
      shippingAddress.value = items;
    }
    if (carts) {
      window.location.reload();
    }
  } catch (error) {
    isLoading.value = false;
  }
};

watch(isLoading, (newVal) => {
  if (!newVal) {
    nextTick(() => {
      initPaypal();
    });
  }
});

const initPaypal = async () => {
  const clientId = await getClientId();
  try {
    // 提前加载PayPal脚本
    const paypal = await loadScript({
      "client-id": clientId,
      intent: "capture",
      components: "buttons",
      "disable-funding": "paylater",
      // 添加缓存策略
      "data-csp-nonce": "xyz",
      "data-page-type": "checkout",
      // 设置超时时间
    });

    // 按钮配置
    const buttonConfig = {
      style: {
        layout: "vertical",
        shape: "pill",
        size: "small",
        tagline: false,
      },
      // 创建订单
      createOrder: async () => {
        try {
          const data = await createOrder("PAYPAL_STANDARD_B2B");
          localStorage.setItem("tradeCode", data.tradeCode);
          return data.thirdTradeCode;
        } catch (err) {
          throw err;
        }
      },
      // 支付成功
      onApprove: async (data) => {
        try {
          console.log(data, "success");
          const tradeCode = localStorage.getItem("tradeCode");
          const orderNo = localStorage.getItem("orderNo");

          const { code } = await fetchData(
            "/appb/b2b/order/completePayAction",
            {
              method: "POST",
              body: JSON.stringify({
                orderNo,
                tradeCode,
              }),
            }
          );

          localStorage.removeItem("tradeCode");
          localStorage.removeItem("orderNo");
          isPaySuccess.value = true;

          buriedPointUtil({ eventId: "MPPP503" });
          gaAnalytics("mp_pay_success", "mp_pay_success");

          if (localStorage.getItem("buy-again")) {
            gaAnalytics("mp_buy_again_success", "mp_buy_again_success");
            localStorage.removeItem("buy-again");
          }
        } catch (err) {
          console.error("支付完成失败:", err);
          throw err;
        }
      },
      // 取消支付
      onCancel: async () => {
        localStorage.removeItem("tradeCode");
        localStorage.removeItem("orderNo");
        router.replace("/orders?status=1");
      },
      // 支付错误
      onError: async (err) => {
        console.error("支付错误:", err);
        localStorage.removeItem("tradeCode");
        localStorage.removeItem("orderNo");

        if (localStorage.getItem("orderErrorCode")) {
          localStorage.removeItem("orderErrorCode");
          return;
        }
        router.replace("/orders?status=1");
      },
    };

    // 渲染按钮
    await paypal.Buttons(buttonConfig).render(paypalButtonContainer.value);
  } catch (err) {
    console.error("PayPal初始化失败:", err);
    // 错误处理
    setTimeout(() => {
      initPaypal(); // 如果加载失败,3秒后重试
    }, 3000);
  }
};

const getClientId = async () => {
  const { code, data } = await fetchData("/appb/b2b/pay/getPaypalClientId", {
    method: "get",
  });
  if (code === 200) {
    return data;
  }
  return "Ad-U4AlBoG22sqpaaAcLGpCdrJqSK7jZXHebOxhfyrr-ngI9JCvZ6GmIuUKs1ZWR99t1xmx6d2y6S-RP";
}

const createOrder = async (paymentMethodCode) => {
  try {
    const { code, data, msg } = await fetchData(
      "/appb/b2b/order/placeCartOrder",
      {
        method: "POST",
        body: JSON.stringify({
          fulfillmentAmount: shippingList.value.find((v) => v.checked)
            ?.shipPackage?.totalAmount,
          shipChannel: shippingList.value.find((v) => v.checked)?.channelCode,
          currency: currencyData.value?.targetCurrency,
          details: productList.value
            .filter((item) => item.checked)
            .map((item) => ({
              cartId: item.cartId,
              quantity: item.skuQuantity,
              skuId: item.skuId,
            })),
          address: {
            ...shippingAddress.value,
            zip: shippingAddress.value?.postCode,
            phone: shippingAddress.value?.phoneValue,
            country: shippingAddress.value?.searchCountryText,
            streetAddr: shippingAddress.value?.streetAddress,
            extAddr: shippingAddress.value?.aptEtc,
            extension: shippingAddress.value?.countryCode === 'BR' ? shippingAddress.value?.cpfNumber || '' : shippingAddress.value?.countryCode === 'CL' ? shippingAddress.value?.rutNumber || '' : '',
          },
        }),
      }
    );
    if (code === 200) {
      localStorage.setItem("orderNo", data);
      if (paymentMethodCode === "BANK_TRANSFER_B2B") {
        buriedPointUtil({ eventId: "MPPP201", others: { type: 'Bank' } });
        orderNo.value = data;
        return data;
      }
      const { code: payCode, data: payData } = await fetchData(
        "/appb/b2b/order/createOrderPay",
        {
          method: "POST",
          body: JSON.stringify({
            orderNo: data,
            paymentMethodCode,
            receiptInfos: productList.value.map((item) => item.skuImage),
          }),
        }
      );
      if (payCode === 200) {
        buriedPointUtil({ eventId: "MPPP201", others: { type: 'PayPal' } });
        gaAnalytics("mp_place_order_success", "mp_place_order_success");
        getCartCount();
        return payData;
      }
    } else {
      if (code === 500) {
        localStorage.setItem("orderErrorCode", 500);
      }
      toast(msg, { type: "error" });
    }
    return null;
  } catch (error) {
    toast(error.msg || error.message, { type: "error" });
    return null;
  }
};

onMounted(() => {
  buriedPointUtil({ eventId: "MPPP501" });
  document.querySelector("body").classList.remove("no-scroll");
  const timer = setInterval(() => {
    let intercom = document.querySelector(".intercom-lightweight-app");
    if (intercom) {
      intercom.style.display = "none"; // 隐藏元素
      clearInterval(timer);
    }
  }, 500);

  setTimeout(() => {
    clearInterval(timer);
  }, 3000);
});

const expandAddress = (e, item) => {
  expanded.value = !expanded.value;
  e.stopPropagation();
};

const selectShippingMethod = (item) => {
  if (item.disabled) {
    return;
  }
  shippingList.value.forEach((item) => {
    item.checked = false;
  });
  item.checked = true;
  // 把选中的项目移到第一位
  const index = shippingList.value.findIndex(
    (i) => i.channelCode === item.channelCode
  );
  if (index > 0) {
    const selected = shippingList.value.splice(index, 1)[0];
    shippingList.value.unshift(selected);
  }
  productList.value = productList.value.map((item) => {
    const selectedShipping = shippingList.value.find((v) => v.checked);
    const matchedPackage = selectedShipping?.singleShipPackages?.find((pkg) =>
      pkg.skuIds?.includes(item.skuId)
    );
    return {
      ...item,
      shipAmount: matchedPackage?.shipAmount || 0,
      totalAmount: matchedPackage?.totalAmount || 0,
    };
  });
  expanded.value = false;
};

const selectPaymentMethod = (item) => {
  paymentList.value.forEach((item) => {
    item.checked = false;
  });
  item.checked = true;
};

const showDetailTotal = () => {
  showDetailModel.value = !showDetailModel.value;
  if (showDetailModel.value) {
    document.querySelector("body").classList.add("no-scroll");
  } else {
    document.querySelector("body").classList.remove("no-scroll");
  }

  nextTick(() => {
    setTimeout(() => {
      detailModel.value.style.height = isPaypalMethod.value
        ? `calc(${initInnerHeight.value}px - 184px)`
        : `calc(${initInnerHeight.value}px - 132px)`;
      contentWrapper.value.style.overflowY = "hidden";
    }, 100);
    setTimeout(() => {
      detailModel.value.style.opacity = "1";
    }, 300);
  });
};

const hideDetailTotal = () => {
  detailModel.value.style.opacity = "0";
  contentWrapper.value.style.overflowY = "auto";
  document.querySelector("body").classList.remove("no-scroll");
  setTimeout(() => {
    detailModel.value.style.height = "0px";
  }, 100);
  setTimeout(() => {
    showDetailModel.value = false;
  }, 300);
};

const handleShippingDesc = () => {
  showShippingDesc.value = true;
  title.value = "Shipping Fee";
  document.querySelector("body").classList.add("no-scroll");
  nextTick(() => {
    setTimeout(() => {
      shippingDescRef.value.style.opacity = "1";
      shippingDescRef.value.style.height = `${window.innerHeight - 72}px`;
    }, 100);
  });
};

const handleBack = () => {
  document.querySelector("body").classList.remove("no-scroll");
  if (showShippingDesc.value) {
    showShippingDesc.value = false;
    title.value = "Order Filling";
  } else if (bankPaymentDialog.value) {
    bankPaymentDialog.value = false;
    bankPopPaymentDialog.value = false;
    title.value = "Order Filling";
  } else {
    router.back();
  }
};

const handleViewOrder = () => {
  router.replace("/orders");
};

// const isPaypalMethod = () => {
//   return paymentList.value.find((item) => item.checked).id === 1
// }

const isPaypalMethod = computed(() => {
  return paymentList.value.find((item) => item.checked).id === 1;
});

const hiddenPop = () => {
  popVisible.value = false;
  showAddressInfo.value = false;
};

const showShipAddress = () => {
  showAddressInfo.value = true;
  popVisible.value = true;
};

const calcSubtotal = (item) => {
  const productTotal =
    bankersRound(item.skuPriceRmb * currencyData.value?.rate, 2) *
    item.skuQuantity;
  const shippingTotal = bankersRound(
    item.shipCostCny * currencyData.value?.rate,
    2
  );
  return (Number(productTotal) + Number(shippingTotal)).toFixed(2);
};

const renderPrice = () => {
  if (!productList.value.some((item) => item.checked)) {
    return 0;
  }
  const shipCostTotal = shippingList.value.find((v) => v.checked)?.shipPackage
    ?.shipAmount;

  const totalShipCost = productList.value
    .filter((item) => item.checked)
    .reduce((sum, item) => sum + Number(item.shipAmount), 0);

  return totalShipCost > shipCostTotal ? totalShipCost.toFixed(2) : false;
};

const renderShippingPrice = () => {
  if (!productList.value.some((item) => item.checked)) {
    return "0.00";
  }
  const shipCostTotal = shippingList.value.find((v) => v.checked)?.shipPackage
    ?.shipAmount;
  return isNaN(shipCostTotal) ? 0 : shipCostTotal;
};

const renderTotalPrice = () => {
  if (!productList.value.some((item) => item.checked)) {
    return "0.00";
  }
  return shippingList.value.find((v) => v.checked)?.shipPackage?.totalAmount;
};

const renderTotalUSPrice = () => {
  if (!productList.value.some((item) => item.checked)) {
    return "0.00";
  }
  const totalAmount =
    shippingList.value.find((v) => v.checked)?.shipPackage?.totalAmountUsd ||
    "0.00";
  payPalDisabled(totalAmount);
  return totalAmount;
};

const renderToUScurrencyData = () => {
  if (!currencyData.value?.rate || !currencyUsdData.value?.rate) {
    return 0;
  }
  // 计算1单位当前货币对应的美元汇率
  // 先将1单位当前货币转换为人民币,再用美元汇率转换为美元
  const cnyAmount = 1 / currencyData.value.rate;
  return bankersRound(cnyAmount * currencyUsdData.value.rate, 6) || 0;
};

const payPalDisabled = (totalShipCost) => {
  if (Number(totalShipCost) >= 200) {
    paymentList.value.forEach((item) => {
      item.id === 2 && (item.checked = true);
      item.id === 1 && (item.checked = false);
    });
    disabledPaypal.value = true;
  } else {
    disabledPaypal.value = false;
  }
};

const renderCurrencyTotalPrice = () => {
  const totalShipCost = productList.value
    .filter((item) => item.checked)
    .reduce((sum, item) => sum + Number(item.subItemTotalPrice), 0)
    .toFixed(2);

  return (
    (Number(totalShipCost) + Number(renderShippingPrice()))?.toFixed(2) || "-"
  );
};
const handleBankPayment = async () => {
  if (!productList.value.some((item) => item.checked)) {
    return;
  }
  try {
    const data = await createOrder("BANK_TRANSFER_B2B");
    getCartCount();
    if (!!data) {
      toast("Order created successfully", { type: "success" });
      gaAnalytics("mp_place_order_success", "mp_place_order_success");
      title.value = "Transfer Information";
      bankPaymentDialog.value = true;
      bankPopPaymentDialog.value = true;
      buriedPointUtil({ eventId: "MPPP601" });
    } else {
      toast(msg, { type: "error" });
    }
  } catch (error) {}
};

const renderTotalItemsPrice = () => {
  return productList.value
    .filter((item) => item.checked)
    .reduce((sum, item) => sum + Number(item.subItemTotalPrice), 0)
    .toFixed(2);
};

const renderItemsTotalPrice = () => {
  if (!productList.value.some((item) => item.checked)) {
    return "0.00";
  }
  return (
    shippingList.value.find((v) => v.checked)?.shipPackage?.itemAmount || 0
  );
};

const closeBtn = () => {
  if (bankPaymentDialog.value) {
    router.replace("/orders?status=1");
  } else {
    router.back();
  }
};

const handleCancel = () => {
  router.replace("/orders?status=1");
};

const renderShippingListHeight = () => {
  return `${shippingList.value.length * 57}px`;
};

const isDisabledshippingList = computed(() => {
  return productList.value.some((item) => item.itemAmount);
});

const getCartCount = async () => {
  const { code, data } = await fetchData("/appb/b2b/cart/cartCount");
  if (code === 200) {
    store.commit("cart/SET_CART_COUNT", data);
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
