<template>
  <!-- 类目搜索 -->
  <div v-if="categorySearchVisible" class="category-search-box" :style="{ top: fixed ? '70px' : '170px' }">
    <div class="left">{{ categoryName }}</div>
    <div class="right">
      <img src="@/assets/H5/home/search-black.svg" alt="">
      <input 
        ref="categorySearchInputRef"
        @input="handleCategorySearchInput" 
        @keyup.enter="handleCategorySearchEnter" 
        v-model.trim="categorySearchInputValue" 
        enterkeyhint="search"
        placeholder="Search within category">
      <img 
        v-if="categorySearchInputValue" 
        @click="categorySearchInputValue = ''" 
        src="@/assets/H5/offer/sourcing-close.svg" alt="">
    </div>
  </div>
  <div class="h5-tab-filters-wrapper" :class="{ fixed: fixed && !categorySearchVisible }">
    <div class="h5-tab-filters" :style="{ paddingTop: fixed ? '16px' : '24px' }">
      <div v-if="filtersSeletonVisible" class="filters-skeleton-wrapper">
        <div v-if="fullFilters.length" class="seleton-item first"></div>
        <div class="seleton-item"></div>
        <div class="seleton-item"></div>
        <div class="seleton-item" v-for="(item, index) in 10"  :key="index"></div>
      </div>
      <div v-else class="filters-wrapper">
        <div v-if="categoryName" class="filter-item search" @click="showCategroySearch">
          <img src="@/assets/H5/home/search-black.svg" alt="">
          <div v-if="showCategorySearchAlert" class="category-search-alert">Search within category</div>
        </div>
        <div v-if="fullFilters.length" @click="openFullFiltersDialog" class="filter-item first" :class="{ active: fullFiltersSelectedNums}">
          <img v-if="fullFiltersSelectedNums" src="@/assets/H5/filters/filters-icon-white.svg" alt="">
          <img v-else src="@/assets/H5/filters/filters-icon.svg" alt="">
          <div v-if="fullFiltersSelectedNums" class="full-filters-nums">{{ fullFiltersSelectedNums }}</div>
        </div>
        <div class="scroll-wrapper">
          <div @click="openSortByDialog" class="filter-item" :class="{ active: isSelectedSortBy }">
            Sort By
            <img v-if="isSelectedSortBy" class="arrow-down" src="@/assets/H5/filters/arrow-down-white.svg" alt="">
            <img v-else class="arrow-down" src="@/assets/H5/filters/arrow-down.svg" alt="">
          </div>
          <div @click="openPriceRangeDialog" class="filter-item" :class="{ active: isSelectedPriceRange }">
            Price Range
            <img v-if="isSelectedPriceRange" class="arrow-down" src="@/assets/H5/filters/arrow-down-white.svg" alt="">
            <img v-else class="arrow-down" src="@/assets/H5/filters/arrow-down.svg" alt="">
          </div>
          <div 
            v-for="(item, index) in fullFilters" 
            :key="index" 
            @click="openCommonDialog(item)" 
            class="filter-item"
            :class="{ active: cacheCommonFilter.find(cache => cache.id === item.id) }">
            {{ item.ptrans }}
            <img v-if="cacheCommonFilter.find(cache => cache.id === item.id)" class="arrow-down" src="@/assets/H5/filters/arrow-down-white.svg" alt="">
            <img v-else class="arrow-down" src="@/assets/H5/filters/arrow-down.svg" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
  <H5Dialog class="custom-filters-dialog common" v-model="sortByDialog" height="auto">
    <div class="filter-dialog">
      <div class="header">
        <div class="header-title">Sort By</div>
        <div class="close-button" @click="closeSortByDialog">
          <img src="@/assets/close-circle-icon.svg" width="20px" alt="">
        </div>
      </div>
      <div class="content">
        <div @click="handleSortByChange(sortByFilters, item)" class="content-item" v-for="(item, index) in sortByFilters" :key="index">
          <div class="content-item-title">{{ item.name }}</div>
          <div class="content-item-radio">
            <input type="radio" :checked="item.checked">
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="reset-button" @click="resetSortBy">Reset</div>
        <div class="done-button" @click="handleSortByDone">Done</div>
      </div>
    </div>
  </H5Dialog>

  <H5Dialog class="custom-filters-dialog common" v-model="priceRangeDialog" height="auto">
    <div class="filter-dialog">
      <div class="header">
        <div class="header-title">Price Range</div>
        <div class="close-button" @click="closePriceRangeDialog">
          <img src="@/assets/close-circle-icon.svg" width="20px" alt="">
        </div>
      </div>
      <div class="content">
        <div class="price-range">
          <input v-model="priceMap.priceStart" placeholder="Low (US $)" type="number"> to
          <input v-model="priceMap.priceEnd" placeholder="High (US $)" type="number">
        </div>
      </div>
      <div class="footer">
        <div class="reset-button" @click="resetPriceRange">Reset</div>
        <div class="done-button" @click="handlePriceRangeDone">Done</div>
      </div>
    </div>
  </H5Dialog>

  <H5Dialog class="custom-filters-dialog common" v-model="commonDialog" height="auto">
    <div class="filter-dialog">
      <div class="header">
        <div class="header-title">{{ currentFilter.ptrans }}</div>
        <div class="close-button" @click="commonDialog = false">
          <img src="@/assets/close-circle-icon.svg" width="20px" alt="">
        </div>
      </div>
      <div class="content">
        <div @click="item.checked = !item.checked" class="content-item" v-for="(item, index) in currentFilter.values" :key="index">
          <div class="content-item-title">{{ item.vtrans }}</div>
          <div class="content-item-radio">
            <input v-model="item.checked" type="checkbox">
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="reset-button" @click="resetCommonFilter">Reset</div>
        <div class="done-button" @click="handleCommonFilterDone">Done</div>
      </div>
    </div>
  </H5Dialog>

  <H5Dialog class="custom-filters-dialog" v-model="fullFiltersDialog" height="100%">
    <div class="filter-dialog full-filters">
      <div class="full-filters-header">
        <div class="header-title">Filters</div>
        <div class="close-button" @click="closeFullFiltersDialog">
          <img src="@/assets/close-circle-icon.svg" width="20px" alt="">
        </div>
      </div>
      <div class="full-filters-content">
        <div class="header">
          <div class="header-title">Price Range</div>
          <div v-if="priceMap.priceStart || priceMap.priceEnd" @click="resetPriceRange" class="clear-button">Clear</div>
          <div class="close-button" @click="priceRangeExpand = !priceRangeExpand">
            <img v-if="priceRangeExpand" src="@/assets/H5/home/category-minus-icon.svg" width="16px" alt="">
            <img v-else src="@/assets/H5/home/category-plus-icon.svg" width="16px" alt="">
          </div>
        </div>
        <div class="content" :class="{ expand: priceRangeExpand }" :style="{ height: priceRangeExpand ? 'auto' : '0.5px' }">
          <div class="price-range">
            <input v-model="priceMap.priceStart" placeholder="Low (US $)" type="number"> to
            <input v-model="priceMap.priceEnd" placeholder="High (US $)" type="number">
          </div>
        </div>
        <div class="header">
          <div class="header-title">Sort By</div>
          <div class="close-button" @click="sortByExpand = !sortByExpand">
            <img v-if="sortByExpand" src="@/assets/H5/home/category-minus-icon.svg" width="16px" alt="">
            <img v-else src="@/assets/H5/home/category-plus-icon.svg" width="16px" alt="">
          </div>
        </div>
        <div class="content" :class="{ expand: sortByExpand }" :style="{ height: sortByExpand ? 'auto' : '0.5px' }">
          <div @click="handleSortByChange(sortByFilters, item)" class="content-item" v-for="(item, index) in sortByFilters" :key="index">
            <div class="content-item-title">{{ item.name }}</div>
            <div class="content-item-radio">
              <input type="radio" :checked="item.checked">
            </div>
          </div>
        </div>
        <div v-for="(item, index) in fullFilters" :key="index">
          <div class="header">
            <div class="header-title">{{ item.ptrans }}</div>
            <div 
              v-if="item.values.some(filter => filter.checked)"
              @click="item.values.forEach(filter => filter.checked = false)" 
              class="clear-button">
              Clear
            </div>
            <div class="close-button" @click.stop="item.expand = !item.expand">
              <img v-if="item.expand" src="@/assets/H5/home/category-minus-icon.svg" width="16px" alt="">
              <img v-else src="@/assets/H5/home/category-plus-icon.svg" width="16px" alt="">
            </div>
          </div>
          <div class="content" :class="{ expand: item.expand }" :style="{ height: calcHeight(item) }">
            <div @click="filter.checked = !filter.checked" class="content-item" v-for="(filter, index) in item.values" :key="index">
              <div class="content-item-title">{{ filter.vtrans }}</div>
              <div class="content-item-radio">
                <input v-model="filter.checked" type="checkbox">
              </div>
            </div>
            <div v-if="!item.showMore" @click="item.showMore = true" class="content-item show-more">Shore More</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="reset-button" @click="resetAllFilters">Reset</div>
        <div class="done-button" @click="handleFullFiltersDone">Done</div>
      </div>
    </div>
  </H5Dialog>

  <H5FullMask @maskClick="closeCategorySearch" style="background-color: rgba(0, 0, 0, 0.8); backdrop-filter: blur(8px);" />
</template>

<script setup>
import { onMounted, ref, computed, nextTick, watch } from 'vue';
import H5Dialog from '@/components/H5/H5Dialog/index.vue'; 
import H5FullMask from '@/components/H5/H5FullMask/index.vue'
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { fetchData } from '@/utils/fetch';
import { buriedPointUtil } from '@/utils/utils'
import { throttle, checkHasQueryLanguage } from '../../../utils/utils';

const emits = defineEmits(['search', 'categorySearch'])

const props = defineProps({
  fixed: {
    default: false,
  },
  categoryId: {
    default: '',
  }
})

const store = useStore()
const route = useRoute();
const fullFilters = ref([]);
const cacheCommonFilter = ref([]);
const categoryId = ref('');

const searchInfo = computed(() => store.state.searchInfo);
const filtersSeletonVisible = computed(() => store.state.h5Module.filtersSeletonVisible)
const categoryName = computed(() => route.query.categoryName)

// sort by
const sortByDialog = ref(false);
const sortByFilters = ref([
  {name: 'Relevance', checked: true},
  {name: 'Sales', checked: false},
  {name: 'Lowest to Highest Price', checked: false},
  {name: 'Highest to Lowest Price', checked: false},
])
const sortByFiltersCopy = ref([...sortByFilters.value]);
const sortMap = ref(null)
const sortByExpand = ref(true);
const isSelectedSortBy = computed(() => sortByFiltersCopy.value.filter(item => item.name !== 'Relevance' && item.checked).length > 0)

// price range
const priceRangeDialog = ref(false);
const priceMap = ref({
  priceStart: null,
  priceEnd: null,
})
const priceMapCopy = ref({ ...priceMap.value})
const isSelectedPriceRange = computed(() => priceMapCopy.value.priceStart || priceMapCopy.value.priceEnd);
const priceRangeExpand = ref(true)

// 通用弹窗
const commonDialog = ref(false);
const currentFilter = ref({});

// 完整分类弹窗
const fullFiltersDialog = ref(false);
const fullFiltersCopy = ref([]);

const innerHeight = ref(0)

const onlyOnce = ref(false);

// 文搜取传递的categoryId
watch(() => props.categoryId, (value) => {
  if (value && !route.query.categoryId && !onlyOnce.value) {
    onlyOnce.value = true;
    categoryId.value = value;
    getFilters(value);
  }
})

onMounted(() => {
  innerHeight.value = window.innerHeight;

  // 类目搜索取路径上的categoryId
  if (route.query.categoryId) {
    categoryId.value = route.query.categoryId;
    getFilters(categoryId.value);
  }

  setTimeout(() => {
    showCategorySearchAlert.value = false;
  }, 5000)
})

const categorySearchVisible = ref(false)
const categorySearchInputValue = ref('')
const showCategorySearchAlert = ref(localStorage.getItem('categorySearchAlert') !== 'hidden');
const categorySearchInputRef = ref(null)
const showCategroySearch = () => {
  showCategorySearchAlert.value = false
  localStorage.setItem('categorySearchAlert', 'hidden');
  categorySearchVisible.value = true;
  store.commit('h5Module/setFullMask', true);
  nextTick(() => {
    const input = categorySearchInputRef.value;
    if (input) {
      input.click();
      input.focus();
    }
  })
}

const closeCategorySearch = () => {
  categorySearchVisible.value = false;
  store.commit('h5Module/setFullMask', false);
}

const { lg = "" } = route.query;
const language = computed(() => checkHasQueryLanguage(lg) || store.state.h5Module.language)
const lastQuery = ref('')
const handleCategorySearchInput = throttle((e) => {
  if(e.target.value.length >= lastQuery.value.length) {
    lastQuery.value = e.target.value
  }
  if(e.target.value.length < lastQuery.value.length) {
    lastQuery.value = e.target.value
    return
  }
  e.target.scrollLeft = e.target.scrollWidth;
  const serachData = {
    ...searchInfo.value,
    pageSize: 20,
    pageNow: searchInfo.value.pageNow,
    imageId: 0,
    region: '',
    async: true,
    language: language.value,
    keyword: categorySearchInputValue.value,
    categoryId: categoryId.value
  };

  fetchData('/dsp-app/alibaba-common/searchKeyWord?itemNumber=50', {
    method: 'post',
    body: JSON.stringify(serachData),
  });
}, 400)

const handleCategorySearchEnter = () => {
  closeCategorySearch();
  emits('categorySearch', categorySearchInputValue.value)
}

const getFilters = async (id) => {
  if(!id) {
    return
  }
  const { code, data } = await fetchData(
    `/dsp-app/alibaba-common/categoryHotWords?categoryId=${id}`,
    {
      method: "get",
      headers: { "Content-Type": "application/json" },
    }
  );
  if (code === 200) {
    const filters = data.map(item => {
      item.expand = true;
      item.showMore = false;
      item.values.forEach(filter => filter.checked = false);
      return item;
    })
    fullFilters.value = filters;
    fullFiltersCopy.value = filters;
  }
};


// sort by
const openSortByDialog = () => {
  sortByFilters.value = JSON.parse(JSON.stringify(sortByFiltersCopy.value));
  sortByDialog.value = true;
}

const closeSortByDialog = () => {
  sortByDialog.value = false;
}

const handleSortByChange = (list, item) => {
  list.forEach(item => {
    item.checked = false;
  })
  item.checked = true;
  
}

const resetSortBy = () => {
  sortByFilters.value.forEach(item => {
    item.checked = false;
    if (item.name === 'Relevance') {
      item.checked = true;
    }
  })
}

const handleSortByDone = (canSearch = true) => {
  const isChanged = sortByFiltersCopy.value.some((item, index) => {
    return item.checked !== sortByFilters.value[index].checked
  })

  sortByFiltersCopy.value = [...sortByFilters.value];
  const item = sortByFilters.value.find(item => item.checked);

  let isDesc = false;
  if (item.name === 'Relevance') {
    sortMap.value = null;
    isDesc = true
  } else if (item.name === 'Sales') {
    sortMap.value = { monthSold: "desc" };
    isDesc = true
  } else if (item.name === 'Lowest to Highest Price') {
    sortMap.value = { price: "asc" };
    isDesc = false;
  } else if (item.name === 'Highest to Lowest Price') {
    sortMap.value = { price: "desc" };
    isDesc = true
  }

  // 有改动才埋点
  const others = {
    sortBy: item.name,
    isDesc,
  }
  if (others.sortBy === 'Lowest to Highest Price' || others.sortBy === 'Highest to Lowest Price') {
    others.sortBy = 'price'
  }
  isChanged && buriedPointUtil({ eventId: "MPSF202", others});


  const data = {
    ...searchInfo.value,
    sortMap: sortMap.value
  }

  if (!data.sortMap) delete data.sortMap;

  store.commit('setSearchInfo', data)
  sortByDialog.value = false;
  canSearch && emits('search')
}

// price range
const openPriceRangeDialog = () => {
  priceMap.value = { ...priceMapCopy.value };
  priceRangeDialog.value = true;
}

const closePriceRangeDialog = () => {
  resetPriceRange();
  priceRangeDialog.value = false;
}

const resetPriceRange = () => {
  priceMap.value = {
    priceStart: null,
    priceEnd: null,
  }
}

const handlePriceRangeDone = (canSearch = true) => {
  const isChanged = priceMapCopy.value.priceStart !== priceMap.value.priceStart || priceMapCopy.value.priceEnd !== priceMap.value.priceEnd;
  isChanged && buriedPointUtil({ eventId: "MPSF203", others: { lowPrice: priceMap.value.priceStart, highPrice: priceMap.value.priceEnd } });

  priceMapCopy.value = { ...priceMap.value };
  const currencyData = JSON.parse(localStorage.getItem("currencyData"));

  const data = {...searchInfo.value}
  if (priceMap.value.priceStart) {
    data.priceStart = priceMap.value.priceStart / currencyData.rate
  } else {
    delete data.priceStart
  }
  if (priceMap.value.priceEnd) {
    data.priceEnd = priceMap.value.priceEnd / currencyData.rate
  } else {
    delete data.priceEnd
  }
  store.commit('setSearchInfo', { ...data })
  priceRangeDialog.value = false;
  canSearch && emits('search')
}

// 通用弹窗
const openCommonDialog = (filter) => {
  filter = JSON.parse(JSON.stringify(filter));
  filter.values.map(item => {
    item.checked = false;
    return item;
  })

  const cacheItem = cacheCommonFilter.value.find(item => item.id === filter.id);
  if (cacheItem) {
    currentFilter.value = JSON.parse(JSON.stringify(cacheItem));
  } else {
    currentFilter.value = filter;
  }
  commonDialog.value = true;
}

const resetCommonFilter = () => {
  currentFilter.value.values.forEach(item => item.checked = false);
}

const handleCommonFilterDone = () => {
  buriedPointUtil({ eventId: "MPSF201", others: { categoryId: categoryId.value, filterKeyword: categoryName.value }});

  const checkdList = currentFilter.value.values.filter(item => item.checked);
  const noCheckedList = currentFilter.value.values.filter(item => !item.checked);

  // 当前筛选框有选中项，则加入缓存，否则从缓存中移除
  if (checkdList.length) {
    const data = JSON.parse(JSON.stringify(currentFilter.value));

    // 如果缓存中存在当前筛选框,更新，否则新增
    const cacheIndex = cacheCommonFilter.value.findIndex(item => item.id === data.id)
    if (cacheIndex > -1) {
      cacheCommonFilter.value.splice(cacheIndex, 1, data);
    } else {
      cacheCommonFilter.value.push(data);
    }
  } else {
    const cacheIndex = cacheCommonFilter.value.findIndex(item => item.id === currentFilter.value.id);
    if (cacheIndex > -1) {
      cacheCommonFilter.value.splice(cacheIndex, 1);
    }
  }

  // 处理联动
  const cacheIdList = []
  cacheCommonFilter.value.forEach(filter => {
    cacheIdList.push(filter.id);
    const index = fullFiltersCopy.value.findIndex(item => item.id === filter.id)
    if (index > -1) { // 有缓存就将数据更新到完整筛选框
      fullFiltersCopy.value[index].values = JSON.parse(JSON.stringify(filter.values));
    }

    // 没有缓存的数据初始化
    fullFiltersCopy.value.forEach(item => {
      if (cacheIdList.indexOf(item.id) === -1) {
        item.values.forEach(item => item.checked = false);
      }
    })
  })

  if (cacheCommonFilter.value.length === 0) {
    fullFiltersCopy.value.forEach(item => {
      if (cacheIdList.indexOf(item.id) === -1) {
        item.values.forEach(item => item.checked = false);
      }
    })
  }
  
  const _attrValues = searchInfo.value.attrValues || [];
  const attrValues = [..._attrValues];
  checkdList.forEach(item => {
    if (item.checked && !attrValues.includes(item.vname)) {
      attrValues.push(item.vname)
    }
  })

  noCheckedList.forEach(item => {
    if (attrValues.includes(item.vname)) {
      const index = attrValues.findIndex(attr => attr === item.vname);
      attrValues.splice(index, 1);
    }
  })

  const _searchInfo = {
    ...searchInfo.value,
    attrValues: attrValues
  }
  if (!_searchInfo.attrValues.length) delete _searchInfo.attrValues;
  store.commit('setSearchInfo', _searchInfo);
  commonDialog.value = false;
  emits('search')
}

const calcHeight = (item) => {
  const len = item.values.length;
  if (len < 6) item.showMore = true; // 长度小于6 默认显示全部

  if (item.expand) {
    let height = 0;
    if (item.showMore) {
      height = len * 42 + 16
    } else {
      let _len = len > 6 ? 6 : len;
      height = _len * 42 + 16 + 32
    }
    return height + "px"
  } else {
    return '0.5px';
  }
}

// 全部分类相关
const openFullFiltersDialog = () => {
  priceMap.value = { ...priceMapCopy.value };
  sortByFilters.value = JSON.parse(JSON.stringify(sortByFiltersCopy.value));
  fullFilters.value = JSON.parse(JSON.stringify(fullFiltersCopy.value));
  
  fullFiltersDialog.value = true;
}

const closeFullFiltersDialog = () => {
  fullFiltersDialog.value = false;
}

const resetAllFilters = () => {
  resetSortBy();
  resetPriceRange();

  fullFilters.value.forEach(item => {
    item.values.forEach(item => item.checked = false);
  })
}

const handleFullFiltersDone = () => {
  buriedPointUtil({ eventId: "MPSF201", others: { categoryId: categoryId.value, filterKeyword: categoryName.value }});

  fullFiltersCopy.value = JSON.parse(JSON.stringify(fullFilters.value));
  handleSortByDone(false);
  handlePriceRangeDone(false);
  
  const checkedFilters = []; // 勾选的筛选项列表
  const attrValues = []; // 勾选的筛选项的名称列表
  fullFilters.value.forEach(filter => {
    filter.values.forEach(value => {
      if (value.checked) {
        checkedFilters.push(JSON.parse(JSON.stringify(filter)));
        attrValues.push(value.vname);
      }
    })
  })

  // 处理联动关系
  cacheCommonFilter.value = [...checkedFilters];
  const _searchInfo = {
    ...searchInfo.value,
    attrValues: attrValues
  }
  if (_searchInfo.attrValues.length === 0) delete _searchInfo.attrValues;

  store.commit('setSearchInfo', _searchInfo)
  emits('search');
  fullFiltersDialog.value = false;
}

const fullFiltersSelectedNums = computed(() => {
  let num = 0;
  fullFiltersCopy.value.forEach(item => {
    const len = item.values.some(item => item.checked);
    if (len > 0) {
      num++;
    }
  })
  if (isSelectedSortBy.value) {
    num++
  }
  if (isSelectedPriceRange.value) {
    num++
  }
  return num;
})
</script>

<style lang="less" scoped>
.h5-tab-filters-wrapper {
  padding-left: 16px;
  background-color: white;
  width: 100%;
  border-radius: 8px 8px 0 0;

  &.fixed {
    position: fixed;
    top: 55px;
    z-index: 1000;
    margin-top: 0;
  }
}

.filters-skeleton-wrapper {
  display: flex;
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  .seleton-item {
    flex-shrink: 0;
    width: 94px;
    height: 36px;
    background-color: #f5f5f5;
    background-image: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
    background-size: 200% 100%;
    background-position: 0 0;
    animation: gradient 2s linear infinite;
    border-radius: 20px;
    margin-right: 8px;
    &.first {
      width: 36px;
    }
  }
  @keyframes gradient {
    0% {
      background-position: 0 center;
    }
    100% {
      background-position: 200% center;
    }
  }
}

.category-search-box {
  position: absolute;
  top: 170px;
  left: 16px;
  right: 16px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  z-index: 1000;
  .left {
    width: 92px;
    height: 100%;
    line-height: 40px;
    text-align: center;
    border-radius: 20px 0 0 20px;
    border: 0.5px solid #b2b7c2;
    background-color: #ebebeb;
    color: #b2b7c2;
    font-size: 12px;
    font-family: mon-500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 12px;
  }
  .right {
    flex: 1;
    background-color: white;
    border-radius: 0 20px 20px 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px 0 12px;

    input {
      border: none;
      outline: none;
      margin: 0 8px;
      width: 100%;
      height: 100%;
      background-color: transparent;
      font-size: 13px;
      &::placeholder {
        font-family: mon-500;
        color: #b2b7c2;
      }
    }
  }
}

.h5-tab-filters {
  position: relative;
  padding: 16px 0;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 16px;
    content: '';
    height: 0.5px;
    background-color: #e4e6eb;
  }
  
  .filters-wrapper {
    display: flex;

    .scroll-wrapper {
      display: flex;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    .filter-item {
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f5f5f5;
      border-radius: 20px;
      margin-right: 8px;
      padding: 10px 16px;
      flex-shrink: 0;
      font-size: 12px;
      font-family: mon-600;
      color: #232323;
      transition: width 0.3s ease;

      &.search {
        position: relative;
        padding: 0;
        width: 36px;
      }

      .category-search-alert {
        position: absolute;
        top: 40px;
        left: -4px;
        width: 150px;
        height: 48px;
        line-height: 55px;
        color: white;
        text-align: center;
        background: url('@/assets/H5/filters/search-with-category-alert.png') no-repeat center/contain;
        border-radius: 4px;
        font-size: 11px;
        font-family: mon-500;
        z-index: 1;
        transition: all .8 ease;
        animation: shaking .8s ease infinite;

        @keyframes shaking {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-1px);
          }
          100% {
            transform: translateY(0);
          }
        }
      }

      .arrow-down {
        margin-left: 8px;
      }

      &.first {
        position: relative;
        padding: 0;
        width: 36px;

        .full-filters-nums {
          position: absolute;
          top: -8px;
          right: -5px;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #009de0;
          font-size: 10px;
          font-family: mon-600;
          color: white;
          border: 1px solid white;
          border-radius: 50%;
        }
      }
    }

    .active {
      background-color: #052784;
      color: white;
    }
  }
}

.custom-filters-dialog {
  ::v-deep(.popup-content) {
    border-radius: 8px;
    background: #00000080;
    backdrop-filter: blur(8px);
  }

  &.common {
    ::v-deep(.popup-content) {
      padding: 0 16px 12px;
      background-color: transparent;
      backdrop-filter: none;
    }
  }

  .filter-dialog {
    height: 100%;
    width: 100%;
    background-color: white;
    border-radius: 8px;

    &.full-filters {
      position: relative;
      background-color: transparent;
      padding: 12px 16px;

      .full-filters-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
        background-color: white;
        border-radius: 8px;
        height: 48px;
        margin-bottom: 12px;
        .header-title {
          font-family: mon-600;
          font-size: 14px;
          color: #232323;
        }
        .close-button {
          height: 20px;
        }
      }

      .full-filters-content {
        padding-top: 8px;
        background-color: white;
        border-radius: 8px;
        height: calc(100% - 60px);
        padding-bottom: 64px;
        overflow: scroll;

        .content {
          position: relative;
          margin-bottom: 0;
          padding-bottom: 0.5px;
          overflow: hidden;
          transition: height .3s ease;

          &.expand {
            padding-bottom: 16px;
          }

          &::after {
            content: '';
            position: absolute;
            display: inline-block;
            bottom: 0;
            left: 16px;
            right: 16px;
            height: 0.5px;
            background-color: #e4e6eb;
          }
        }
      }

      .footer {
        position: absolute;
        bottom: 12px;
        width: calc(100% - 32px);
        border-radius: 0 0 8px 8px;
        background-color: white;
      }
    }

    .header {
      position: relative;
      height: 52px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 16px;
      .header-title {
        font-size: 12px;
        font-family: mon-600;
        color: #5c5c5c;
      }

      .clear-button {
        position: absolute;
        right: 52px;
        top: 18px;
        color: #009de0;
        font-family: mon-500;
        font-size: 12px;
        line-height: 16px;
      }
    }
    .content {
      position: relative;
      max-height: calc(100vh - 250px);
      margin-bottom: 24px;
      overflow-y: scroll;
      .content-item {
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;

        &.show-more {
          position: absolute;
          bottom: 16px;
          height: 32px;
          width: 100%;
          color: #009de0;
          font-size: 12px;
          font-family: mon-500;
          background-color: white;
        }
        .content-item-title {
          font-size: 14px;
          font-family: mon-500;
          color: #232323;
        }
        .content-item-radio {
          width: 20px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          
          input[type="radio"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none !important;
            border: 1px solid #b2b7c2;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            position: relative;
          }

          input[type="radio"]:checked {
            border: 1px solid #000;
          }

          input[type="radio"]:checked::before {
            background-color: #000;
            content: '';
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          input[type="checkbox"] {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none !important;
            border: 1px solid #b2b7c2;
            border-radius: 2px;
            width: 16px;
            height: 16px;
            position: relative;
          }

          input[type="checkbox"]:checked::before {
            content: '';
            position: absolute;
            top: -1px;
            left: -1px;
            width: 16px;
            height: 16px;
            text-align: center;
            line-height: 16px;
            color: #fff;
            background-color: #232323;
            background-image: url('@/assets/H5/filters/check.svg');
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 2px;
          }
        }
      }

      .price-range {
        padding: 0 16px;
        display: flex;
        gap: 8px;
        align-items: center;
        color: #232323;
        font-size: 12px;
        font-family: mon-500;
        input {
          text-align: center;
          height: 36px;
          line-height: 36px;
          width: 50%;
          border: 0.5px solid #b2b7c2;
          outline: none;
          border-radius: 4px;

          &::placeholder {
            font-family: mon-500;
            font-size: 12px;
          }
        }

        input:focus {
          border: 0.5px solid #009de0;
        }

        input::placeholder {
          color: #b2b7c2;
        }
      }
    }

    .footer {
      width: 100%;
      border-top: 0.5px solid #e4e6eb;
      padding: 8px 16px 0;
      height: 64px;
      display: flex;
      justify-content: center;
      gap: 9px;

      .reset-button, .done-button {
        width: 50%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        border-radius: 20px;
        font-size: 14px;
        font-family: mon-600;
        color: #232323;
      }
      .reset-button {
        border: 0.5px solid #b2b7c2;
      }

      .done-button {
        background-color: #052785;
        color: white;
      }
    } 
  }
}
</style>