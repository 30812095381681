<template>
  <div class="homeview" @scroll="handleScroll">
    <div class="homeview-title">Treasures Just For You!</div>
    <!-- <div class="homeview-main">
      <div v-for="(item, index) in new Array(6).fill(0)">
        <Product />
      </div>
    </div> -->
    <Product />
    <div class="homeview-bootom-line">
      <div class="bootom-line-1"></div>
      <div class="bootom-line-2">
        <span v-if="!adsWord">I'm a bottom line.</span>
        <span v-else style="color: #009DE0" @click="goTopSearch">Come on! Try to search for the treasure you want.</span>
      </div>
      <div class="bootom-line-3"></div>
    </div>
    <H5Footer />
  </div>
</template>

<script setup>
import Product from '@/components/H5/Product/index.vue'
import H5Footer from '@/components/H5/H5Footer/index.vue'
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
const store = useStore();
const route = useRoute();
const { data: adsWord } = route.query;

const goTopSearch = () => {
  store.commit('h5Module/setSearchFoucs', true)
  store.commit('setEmptyObj', {})
}
</script>

<style>
@import './index.less';
</style>