<template>
  <Transition name="popup">
    <div 
      v-if="dialogVisible" 
      class="popup-overlay" 
      :class="{ showMask: showMask }"
      @click.self="close">
      <div 
        class="popup-content" 
        :style="{ 
          height: `${height}`, 
          overflow: scrollable ? 'hidden' : 'scroll',
          ...initStyles
        }">
        <template v-if="scrollable">
          <div style="height: 16px;"></div>
          <div @scroll="handleScroll" class="scroll-content">
            <slot></slot>
          </div>
        </template>
        <template v-else>
          <slot></slot>
        </template>
      </div>
    </div>
  </Transition>
</template>

<script setup>
import { defineProps, defineEmits, watch, computed, ref, nextTick } from "vue";

const props = defineProps({
  height: {
    type: String
  },
  scrollable: {
    type: Boolean,
    default: false
  },
  scrollEvent: {
    type: Function
  },
  initStyles: {
    type: Object
  },
  closeOnClickModal: {
    type: Boolean,
    default: true
  }
});

const dialogVisible = ref(false);
const showMask = ref(false);
const visible = defineModel({ required: true, default: false })

watch(visible, (value) => {
  if (value) {
    dialogVisible.value = true;
    setTimeout(() => {
      showMask.value = true;
    }, 300);
    document.querySelector('body').classList.add('no-scroll');
  } else {
    setTimeout(() => {
      document.querySelector('body').classList.remove('no-scroll');
    }, 300);
    showMask.value = false;
    nextTick(() => {
      dialogVisible.value = false;
    })
  }
})

const emit = defineEmits(["closeByModal"]);

const close = () => {
  if (props.closeOnClickModal) visible.value = false;
  emit('closeByModal')
};

const handleScroll = (e) => {
  if (!props.scrollable) return;
  props.scrollEvent(e)
}

</script>

<style lang="less" scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1100;

  &.showMask {
    background: rgba(0, 0, 0, 0.8);
    backdrop-filter: blur(8px);
  }
}

.popup-content {
  width: 100%;
  max-width: 600px;
  border-radius: 12px 12px 0 0;
  height: calc(100vh - 80px);
}

.scroll-content {
  height: 100%;
  overflow: scroll;
}

.popup-enter-active,
.popup-leave-active {
  transition: transform 0.3s ease-out, opacity 0.3s ease;
}

/* 进入动画 */
.popup-enter-from {
  transform: translateY(100%);
  opacity: 0;
}
.popup-enter-to {
  transform: translateY(0);
  opacity: 1;
}

/* 离开动画 */
.popup-leave-from {
  transform: translateY(0);
  opacity: 1;
}
.popup-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>