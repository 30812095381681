<template>
  <transition name="fade">
    <div class="toast" v-if="toastVisible">
      <svg
        v-if="type === 'success'"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <circle cx="9" cy="9" r="7.5" fill="#529426" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.99475 11.9737C8.42035 12.6146 7.42561 12.6418 6.81704 12.0333L4.65602 9.87223C4.36313 9.57934 4.36313 9.10447 4.65602 8.81157C4.94891 8.51868 5.42379 8.51868 5.71668 8.81157L7.8777 10.9726L11.9858 6.38867C12.2623 6.08021 12.7364 6.05425 13.0449 6.3307C13.3534 6.60715 13.3793 7.08131 13.1029 7.38978L8.99475 11.9737Z"
          fill="white"
        />
      </svg>
      {{ msg }}
    </div>
  </transition>
</template>
<script setup>
import { ref, defineProps, defineEmits, watch } from "vue";
const props = defineProps({
  msg: {
    type: String,
    required: true,
  },
  visible: {
    type: Boolean,
    required: true,
  },
  type: {
    type: String,
    default: "success",
  },
});
const emit = defineEmits(["update:visible"]);

const toastVisible = ref(false);

watch(
  () => props.visible,
  (newVal) => {
    if (newVal) {
      toastVisible.value = true;
      setTimeout(() => {
        toastVisible.value = false;
      }, 1500);
    }
  },
  { immediate: true }
);
</script>
<style lang="less" scoped>
@import "./index.less";
</style>
