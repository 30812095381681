
<template>
  <div class="h5-country">
    <div class="h5-country-title">Language</div>
    <img src="@/assets/H5/home/left.svg" alt="" class="country-left"  @click="handleClose">
    <div class="h5-country-contanier">
      <div class="contanier-title">English</div>
      <div class="selected">
        <img src="@/assets/H5/filters/check.svg" alt="">
      </div>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
  handleClose: Function,
});
const handleClose = props.handleClose;

</script>

<style lang="less">
@import './index.less';
</style>