<template>
  <div class="h5-orders">
    <H5Dialog 
      class="custom-dialog"
      height="100%"
      v-model="dialogVisible">
      <div class="h5-order-dialog">
        <div class="dialog-header">
          <div class="dialog-header-left" @click="handleBack">
            <img src="@/assets/H5/order/icon-arrow-left.svg" alt="">
            <span v-if="title === FULL_ORDERS_TITLE">Account</span>
          </div>
          <div class="dialog-header-middle">{{ title }}</div>
          <!-- <div class="dialog-header-right" @click="handleBack">
            <img src="@/assets/close-circle-icon.svg" alt="">
          </div> -->
        </div>
        <div v-if="!isEmptyList" class="dialog-content">
          <div ref="scrollBarRef" class="scroll-bar">
            <div 
              class="scroll-bar-item" 
              :class="{ active: index === activeIndex }"
              @click="handleScrollBarClick(index)"
              v-for="(item, index) in scrollBarItems" 
              :key="item">
              {{ item }}
            </div>
          </div>
          <div 
            ref="scrollContentRef" 
            class="scroll-content"
            @touchstart="handleTouchStart"
            @touchend="handleTouchEnd"
            @scroll="handleContentScroll">
            <div style="flex-shrink: 0; width: 100%;"></div>
            <div 
              ref="scrollItemRef"
              @scroll="handleItemScroll"
              class="scroll-content-item">
              <div v-if="isLoading" class="loading-wrapper">
                <div class="loading">
                  <img :src="gifSrc" width="160px" alt="">
                </div>
              </div>
              <OrderItem 
                v-for="(item, index) in orderList"
                :key="item.b2bOrderVo.orderNo"
                :data="item"
                @cancel="showCancelDialog(item)"
                @payNow="handleOrderItemPayNow(item)"
                @confirm="showConfirmDialog(item)"
                @showDetail="showDetailDialog(item)"
                @repay="showPayAgainDialog(item)"
              />
              <BottomLine v-if="!isLoading" />
            </div>
            <div style="flex-shrink: 0; width: 100%;"></div>
          </div>
        </div>

        <!-- 空列表 -->
        <div v-if="isEmptyList" class="dialog-content">
          <Empty/>
        </div>
        
        <Pop :visible="popVisible">
          <CancelOrder 
            v-if="dialogs.cancelDialog"
            @cancel="handleCancelOrder"
            @close="handleBack"
          />

          <PayNow 
            v-if="dialogs.payNowDialog" 
            :data="currentOrder" 
            :paypalInstance="paypal"
            @bankPayment="handleBankPayment"
          />

          <ConfirmOrder 
            v-if="dialogs.confirmDialog" 
            @got="handleBack"
            :data="currentOrder" 
          />
          
          <OrderDetail 
            v-if="dialogs.detailDialog" 
            :data="currentOrder" 
            @showShippingDesc="showShippingDesc"
            @payNow="handleOrderDetailPayNow"
            @repay="handleOrderDetailRepay"
            @confirming="showConfirmingDialog"
          />

          <RePayment 
            v-if="dialogs.rePaymentDialog"
            :data="currentOrder"
            @cancel="handleBack"
          />

          <H5BankPayment 
            v-if="dialogs.bankPaymentDialog"
            @confirm="getOrders"
            @cancel="handleBankPaymentCancel"
            :price="currentOrder.b2bOrderVo.actualTotalAmountUsd"
            :orderNo="currentOrder.b2bOrderVo.orderNo"
          />

          <ShippingDesc 
            v-if="dialogs.shippingDescDialog" 
          />
        </Pop>
      </div>
    </H5Dialog>
  </div>
</template>

<script setup>
  import { computed, onMounted, reactive, ref, watch, nextTick } from 'vue';
  import { useRouter, useRoute } from 'vue-router'
  import { useCommonToast } from '@/hooks/useCommonToast.js'
  import { fetchData } from '@/utils/fetch'
  import { gaAnalytics, buriedPointUtil } from '@/utils/utils'
  import { loadScript } from "@paypal/paypal-js";
  import H5Dialog from '@/components/H5/H5Dialog/index.vue';
  import OrderItem from './components/OrderItem.vue';
  import BottomLine from './components/BottomLine.vue';
  import Empty from './components/Empty.vue';
  import Pop from './components/Pop.vue';
  import CancelOrder from './components/CancelOrder.vue';
  import PayNow from './components/PayNow.vue';
  import ConfirmOrder from './components/ConfirmOrder.vue';
  import OrderDetail from './components/OrderDetail.vue';
  import RePayment from './components/RePayment.vue'
  import H5BankPayment from '@/components/H5/H5BankPayment/index.vue'
  import ShippingDesc from './components/ShippingDesc.vue';
  import gifUrl from '@/assets/H5/empty.gif'

  const ORDER_STATUS = Object.freeze({
    PLACE_ORDER: 0, // 下单成功待支付
    PAY_CONFIRMING: 10, // 支付确认中
    PAY_FAIL: 20, // 支付失败
    PAY_COMPLETE: 30, // 支付完成
    IN_PROCESS: 40, // 采购中
    SHIPPED: 50, // 已发货
    DELIVERED: 90, // 已送达
    CANCELLED: 99, // 已取消
  })

  const router = useRouter();
  const route = useRoute();

  const { toast } = useCommonToast();
  
  const FULL_ORDERS_TITLE = 'Fulfillment Orders'
  const title = ref(FULL_ORDERS_TITLE)
  const preTitle = ref('')

  const dialogVisible = ref(false)
  const scrollBarItems = ref([ "All Orders", "Awaiting Payment", "Fulfillment", "Completed"])
  const activeIndex = ref(0);
  const scrollBarRef = ref(null)
  const scrollContentRef = ref(null)
  const scrollItemRef = ref(null)

  const orderList = ref([])

  const isEmptyList = ref(false)

  const gifSrc = computed(() => {
    return gifUrl + '?' + new Date().getTime();
  })

  watch(activeIndex, (index) => {
    // 0, 1, 2 待支付， 4, 5 履约中， 9,99 已完成 
    if (index === 0) {
      searchInfo.value.listStatusEnum = null
    } else if (index === 1) {
      searchInfo.value.listStatusEnum = 'PENDING_PAYMENT'
    } else if (index === 2) {
      searchInfo.value.listStatusEnum = 'FULFILLING'
    } else if (index === 3) {
      searchInfo.value.listStatusEnum = 'COMPLETED'
    }
    if (scrollItemRef.value) {
      scrollItemRef.value.scrollTop = 0
    }
    getOrders();
  })

  const handleBack = () => {
    if (title.value === FULL_ORDERS_TITLE) {
      dialogVisible.value = false;
      router.push('/account')
    } else {
      Object.keys(dialogs).forEach(key => {
        dialogs[key] = false;
      })
      preTitle.value = title.value;
      title.value = FULL_ORDERS_TITLE;
      hiddenPop();

      if (preTitle.value === 'Shipping Fee') {
        title.value = 'Order Information';
        dialogs.detailDialog = true;
        showPop();
      } 
    }
  }

  const handleScrollBarClick = (index) => {
    activeIndex.value = index
    const scrollBar = scrollBarRef.value;
    const activeItem = scrollBar.children[index];
    scrollBar.scrollLeft = activeItem.offsetLeft - scrollBar.clientWidth / 2 + activeItem.clientWidth / 2;
  }

  const startX = ref(0)
  const startY = ref(0)
  const handleTouchStart = (e) => {
    startX.value = e.touches[0].clientX
    startY.value = e.touches[0].clientY
  }

  const handleTouchEnd = (e) => {
    const endX = e.changedTouches[0].clientX
    const endY = e.changedTouches[0].clientY
    const moveX = startX.value - endX
    const moveY = startY.value - endY

    if (Math.abs(moveX) > Math.abs(moveY) && Math.abs(moveX) > 100) {
      if (moveX > 0) { // 右滑
        activeIndex.value = activeIndex.value === 3 ? 0 : activeIndex.value + 1
      } else { // 左滑
        activeIndex.value = activeIndex.value === 0 ? 3 : activeIndex.value - 1
      }
    } else {
      scrollContentRef.value.scrollLeft = scrollContentRef.value.clientWidth
    }
  }

  const handleContentScroll = async (e) => {
    e.preventDefault();
    const index = activeIndex.value
    const scrollBar = scrollBarRef.value;
    const activeItem = scrollBar.children[index];
    scrollBar.scrollLeft = activeItem.offsetLeft - scrollBar.clientWidth / 2 + activeItem.clientWidth / 2;
  }

  const handleItemScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;

    if (scrollTop + clientHeight >= scrollHeight - 10) {
      searchInfo.value.pageNow++;
      handleLoadMore();
    }
  }

  const popVisible = ref(false);
  const showPop = () => {
    popVisible.value = true;
  }

  const hiddenPop = () => {
    popVisible.value = false
  }

  const dialogs = reactive({
    cancelDialog: false,
    buyAgainDialog: false,
    payNowDialog: false,
    confirmDialog: false,
    detailDialog: false,
    rePaymentDialog: false,
    shippingDescDialog: false,
    bankPaymentDialog: false,
  })

  const currentOrder = ref(null);

  const showCancelDialog = (data) => {
    currentOrder.value = data;
    title.value = 'Cancel Orders'
    dialogs.cancelDialog = true;
    showPop();
  }

  // 取消订单
  const handleCancelOrder = async () => {
    const { code, data } = await fetchData('/appb/b2b/order/cancelOrder/' + currentOrder.value.b2bOrderVo.orderNo, {
      method: 'PUT',
    })
    if (code === 200) {
      toast('Cancel successfully')
      dialogs.cancelDialog = false;
      handleBack();
      getOrders();

      gaAnalytics('mp_cancel_order_success', 'mp_cancel_order_success')
      buriedPointUtil({ eventId: 'MPOP601', others: { orderNo: currentOrder.value.b2bOrderVo.orderNo } })
    }
  }

  const handleOrderDetailPayNow = () => {
    hiddenPop();
    dialogs.detailDialog = false;
    setTimeout(() => {
      if (currentOrder.value.b2bOrderVo.totalAmountUsd > 200) {
        showBankPaymentDialog()
      } else {
        showPayNowDialog(currentOrder.value);
      }
    }, 300);
  }

  const handleOrderDetailRepay = () => {
    hiddenPop();
    dialogs.detailDialog = false;
    setTimeout(() => {
      showPayAgainDialog(currentOrder.value);
    }, 300);
  }

  const handleOrderItemPayNow = (data) => {
    currentOrder.value = data
    hiddenPop();
    dialogs.detailDialog = false;
    setTimeout(() => {
      if (currentOrder.value.b2bOrderVo.totalAmountUsd > 200) {
        showBankPaymentDialog()
      } else {
        showPayNowDialog(data);
      }
    }, 300);
  }

  const showPayNowDialog = (data) => {
    currentOrder.value = data;
    title.value = 'Payment';
    dialogs.payNowDialog = true;
    showPop();
  }

  const showConfirmDialog = (data) => {
    currentOrder.value = data;
    title.value = 'Order Information';
    dialogs.confirmDialog = true;
    showPop();
  }

  const showDetailDialog = (data) => {
    currentOrder.value = data;
    title.value = 'Order Information';
    dialogs.detailDialog = true;
    showPop();
  }

  const handleBankPayment = () => {
    if (currentOrder.value.b2bOrderVo.status === 20) { // 支付失败
      showPayAgainDialog(currentOrder.value);
    } else {
      showBankPaymentDialog();
    }
  }

  const showBankPaymentDialog = () => {
    hiddenPop();
    dialogs.payNowDialog = false;
    setTimeout(() => {
      title.value = 'Transfer Information';
      dialogs.bankPaymentDialog = true;
      showPop();
    }, 300)
  }

  const showShippingDesc = () => {
    dialogs.detailDialog = false;
    title.value = 'Shipping Fee';
    dialogs.shippingDescDialog = true;
  }

  const showPayAgainDialog = (data) => {
    currentOrder.value = data;
    hiddenPop();
    dialogs.payNowDialog = false;
    setTimeout(() => {
      title.value = 'Transfer Information';
      dialogs.rePaymentDialog = true;
      showPop();
    }, 300)
  }

  const handleBankPaymentCancel = () => {
    dialogs.bankPaymentDialog = false;
    if (currentOrder.value.b2bOrderVo.totalAmountUsd > 200) {
      hiddenPop();
    } else {
      dialogs.payNowDialog = true
    }
  }

  const searchInfo = ref({
    pageSize: 20,
    pageNow: 1 ,
    listStatusEnum: null
  })
  const isLoading = ref(false);
  const noMore = ref(false);
  
  const getOrders = async () => {
    isLoading.value = true
    searchInfo.value.pageNow = 1;
    noMore.value = false;

    const { code, data } = await fetchData('/appb/b2b/order/page', {
      method: 'POST',
      body: JSON.stringify({
        ...searchInfo.value
      })
    })

    if (code === 200) {
      orderList.value = data.rows || [];

      if (orderList.value.length < 20) {
        noMore.value = true;
      }
      if (activeIndex.value === 0 && orderList.value.length === 0) {
        isEmptyList.value = true;
      }

      gaAnalytics('mp_order_page_view', 'mp_order_page_view')

      nextTick(() => {
        if (scrollContentRef.value) {
          scrollContentRef.value.scrollLeft = scrollContentRef.value.clientWidth
        }
      })
    }

    isLoading.value = false;
  }

  const handleLoadMore = async () => {
    if (noMore.value) return;

    if (isLoading.value === true) return;
    isLoading.value = true;

    const { code, data } = await fetchData('/appb/b2b/order/page', {
      method: 'POST',
      body: JSON.stringify({
        ...searchInfo.value
      })
    })

    if (code === 200) {
      const list = data.rows || []
      orderList.value = [...orderList.value, ...list]

      if (list.length < 20) {
        noMore.value = true;
      }
    }

    isLoading.value = false;
  }

  const showConfirmingDialog = () => {
    hiddenPop();
    dialogs.detailDialog = false;
    setTimeout(() => {
      dialogs.confirmDialog = true;
      title.value = 'Order Information';
      showPop();
    }, 300)
  }

  const paypal = ref(null);
  const initPaypal = async () => {
    const { data } = await fetchData('/appb/b2b/pay/getPaypalClientId');
    paypal.value = await loadScript({ 
      "client-id": data,
      intent: "capture",
      components: "buttons",
      disableFunding: "paylater,venmo",
      locale: "en_US",
    });
  }

  const handleFromEmail = async () => {
    if (route.fullPath.includes('fromEmail')) {
      const orderNo = route.query.orderNo
      const { code, data } = await fetchData('/appb/b2b/order/page', {
        method: 'POST',
        body: JSON.stringify({
          orderNo,
        })
      })
      const order = data.rows[0];
      currentOrder.value = order;

      const totalAmountUsd = order.b2bOrderVo.totalAmountUsd
      if (totalAmountUsd >= 200) {
        if (currentOrder.value.b2bPaymentOrderVo.method === 'BANK_TRANSFER_B2B') {
          dialogs.detailDialog = false
          showPayAgainDialog(currentOrder.value);
        } else {
          showPayNowDialog(currentOrder.value);
        }
      } else {
        showPayNowDialog(currentOrder.value)
      }
    }
  }

  onMounted(() => {
    dialogVisible.value = true;
    if (route.query.status) {
      activeIndex.value = +route.query.status;
      nextTick(() => {
        const el = scrollContentRef.value
        el.scrollLeft = el.clientWidth * activeIndex.value
      })
    } else {
      getOrders();
    }
    initPaypal();
    handleFromEmail();

    buriedPointUtil({ eventId: 'MPOP501' })
  })
</script>

<style lang="less" scoped>
  .custom-dialog {
    padding: 12px;
    background-color: #141414 !important;
    backdrop-filter: blur(0) !important;

    ::v-deep(.popup-content) {
      border-radius: 0;
    }
  }
  .h5-orders {
    .h5-order-dialog {
      display: flex;
      flex-direction: column;
      height: 100%;
      .dialog-header {
        position: relative;
        display: flex;
        flex-shrink: 0;
        justify-content: space-between;
        height: 48px;
        padding: 0 12px;
        background-color: white;
        border-radius: 8px;
        color: #232323;

        .dialog-header-left {
          display: flex;
          align-items: center;
          font-size: 13px;
          font-family: mon-600;

          img {
            margin-right: 4px;
          }
        }

        .dialog-header-middle {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          font-size: 14px;
          font-family: mon-600;
        }

        .dialog-header-right {
          display: flex;
          align-items: center;
          img {
            width: 20px;
          }
        }
      }
      .dialog-content {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 12px;
        background-color: #f5f5f5;
        border-radius: 8px;
        height: calc(100% - 60px);

        .scroll-bar {
          display: flex;
          flex-wrap: nowrap;
          overflow-x: scroll;
          scroll-behavior: smooth;
          padding: 0 16px;
          &::-webkit-scrollbar {
            display: none;
          }

          .scroll-bar-item {
            position: relative;
            color: #5c5c5c;
            font-size: 13px;
            font-family: mon-500;
            line-height: 17px;
            white-space: nowrap;
            padding: 16px 0;
            margin-left: 16px;
            -webkit-tap-highlight-color: transparent;

            &:first-child {
              margin-left: 0;
            }
            
            &.active {
              font-family: mon-600;
              color: #232323;

              &::after {
                display: block;
              }
            }

            &::after {
              content: "";
              position: absolute;
              left: 50%;
              top: 37px;
              transform: translateX(-50%);
              width: 24px;
              height: 3px;
              background-color: #232323;
              border-radius: 2px;
              display: none;
            }
          }
        }

        .scroll-content {
          flex: 1;
          display: flex;
          overflow-x: scroll;
          height: 100%;
          scroll-snap-type: x mandatory;

          &::-webkit-scrollbar {
            display: none;
          }

          .scroll-content-item {
            position: relative;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
            padding: 3px 12px 12px 12px;
            scroll-snap-align: start;
            overflow-y: scroll;

            .loading-wrapper {
              position: absolute;
              inset: 0;
              padding: 3px 12px 12px 12px;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 99;
              background-color: #f5f5f5;
            }
            .loading {
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
              width: 100%;
              background-color: white;
              border-radius: 8px;
            }
          }
        }
      }
    }
  }
</style>