<template>
  <div v-show="loadMoreSleleton" v-if="nums" v-for="(item, index) in new Array(nums).fill(0)" class="init-skeleton-item">
    <div class="skeleton-item-main"></div>
    <div class="skeleton-item-line-1"></div>
    <div class="skeleton-item-line-2"></div>
    <div class="skeleton-item-line-3"></div>
    <div class="skeleton-item-line-4"></div>
    <div class="skeleton-item-line-5"></div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const nums = ref(60)

const productList = computed(() => store.state.productList);
const loadMoreSleleton = computed(() => store.state.loadMoreSleleton)

watch(
  () => store.state.productList.length,
  (newData) => {
    nums.value = newData % 6 === 0 ? 6 : (6 - (newData % 6)) + 6
  }
);

watch(loadMoreSleleton, (newData) => {
  if (newData) {
    loadMoreSleleton.value = newData;
  }
});


// watch(productList, (newData) => {
//   if (newData) {
//     nums.value = ( 6 - (newData.length % 6)) + 6
//     console.log(newData.length, 'ddd')
//   }
// }, {deep: true});

// watch(
//   () => props.productList,
//   (newVal) => {
//     // searchText.value = newVal;
//     console.log(newVal, 'dddddd')
//   }
// );

</script>

<style lang="less">
@import './index.less';
</style>