<template>
  <div class="h5-keywords-header" :class="{ 'hidden': hidden }">
    <div class="h5-header-banner">
      <div class="h5-header-main">
        <div class="h5-header-main-left">
          <div class="h5-header-menu" @click="showCategory">
            <img src="@/assets/H5/home/h5-menu.svg" alt="">
          </div>
          <div class="h5-header-main-logo" @click="goHome">
            <img src="@/assets/H5/home/logo.svg" alt="">
          </div>
        </div>
        
        <div class="h5-header-main-right">
          <div class="h5-header-sign" @click="signIn">
            <img src="@/assets/H5/home/person.svg" alt="">
          </div>
          <div class="h5-header-cart" @click="toCartPage">
            <template v-if="userInfo.uid">
              <div v-if="cartCount" class="cart-count">{{ cartCount > 99 ? '99+' : cartCount }}</div>
              <img v-if="cartCount === 0" src="@/assets/H5/carts/empty-cart.svg" alt="">
              <img v-else src="@/assets/H5/carts/cart-icon.svg" alt="">
            </template>
            <img v-else src="@/assets/H5/carts/empty-cart.svg" alt="">
          </div>
          <div class="h5-header-country" @click="showCurrency">
            <img :src="countryInfo[0]?.countryUrl" alt="" class="h5-country-logo">
            <div>{{ countryInfo[0]?.countryName.length > 7 ? countryInfo[0]?.countryCode : countryInfo[0]?.countryName }}</div>
            <img src="@/assets/H5/home/down.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="h5-header-search">
      <div class="language-selectbox" v-if="showLangunageSelectBox">
        <div class="language-selectbox-selected">
          <div class="selected">
             {{ String(selectedLanguage).toLocaleUpperCase() }}
            <img src="@/assets/H5/home/arrow-down.svg" alt="">
          </div>
        </div>
        <div class="language-selectbox-list">
          <div @click="chooseLanguage(item)" v-for="(item, index) in languageList" :key="index" class="language-selectbox-list-item">
            {{ `${ item.sourceLan } / ${String(item.language).toLocaleUpperCase()}` }}
          </div>
        </div>
      </div>
      <img v-if="canBack" @click="handleBack" src="@/assets/H5/home/h5-back-arrow.svg" alt="">
      <div class="h5-header-search-container" :style="{ marginLeft: canBack ? '12px' : '0'}">
        <div @click.stop="showLanguageSelect" class="search-container-language-selectbox-wrapper">
          <div class="search-container-language-selectbox">
            {{ String(language).toLocaleUpperCase() }} <img src="@/assets/H5/home/arrow-down.svg" alt="">
          </div>
        </div>
        <div class="search-container-text">
          <input 
            ref="inputRef"
            @focus="handleFocus"
            @keyup.enter="searchText" 
            v-model="searchTextValue" 
            placeholder="🔍 Search anything you want" 
            type="text">
        </div>
        <div class="search-container-btn">
          <div @click.stop="photoClick" class="container-btn-photo">
            <img src="@/assets/H5/home/photo.svg" alt="">
          </div>
          <div class="container-btn-search" @click="searchText">
            <img src="@/assets/H5/home/search.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <H5Dialog class="h5-dialog-login" v-model="showPopup" height="100%">
      <H5LoginIn :handleClickOutside="handleClickOutside" />
    </H5Dialog>

    <H5Dialog v-model="showH5Currency" :height="`calc(${innerHeight}px - 80px)`" :initStyles="{ background: 'white' }">
      <H5Currency :handleClickOutside="handleCurrencyClickOutside"  />
    </H5Dialog>
    <div v-if="showLangunageSelectBox" @click="showLangunageSelectBox = false" class="fullmask"></div>
  </div> 
</template>

<script setup>
import H5Dialog from '@/components/H5/H5Dialog/index.vue';
import H5LoginIn from '@/components/H5/H5LoginIn/index.vue';
import H5LoginOut from '@/components/H5/H5LoginOut/index.vue';
import H5Currency from '@/components/H5/H5Currency/index.vue';
import { computed, onMounted, ref, watch, getCurrentInstance } from 'vue';
import { fetchData } from '@/utils/fetch';
import { useStore } from 'vuex';
import { buriedPointUtil, gaAnalytics } from '@/utils/utils'
import { useRouter, useRoute } from 'vue-router';
import { checkHasQueryLanguage } from '../../../utils/utils';
const props = defineProps({
  hidden: {
    type: Boolean,
    default: false,
  },
  canInput: {
    type: Boolean,
    default: true,
  },
  canBack: {
    type: Boolean,
    default: false,
  }
})

const emits = defineEmits(['search', 'focus', 'back'])


const router = useRouter();
const route = useRoute()

const searchTextValue = defineModel({ default: '' })

const showPopup = ref(false);

const showH5Currency = ref(false)

const store = useStore()
const innerHeight = ref(0)

const { lg = "" } = route.query;

const userInfo = computed(() => store.state.common.userInfo);
const countryInfo = computed(() => store.state.countryInfo);
const isLoginVisible = computed(() => store.state.isLoginVisible);

const language = computed(() => store.state.h5Module.language);
const languageList = computed(() => store.state.h5Module.languageList);

const cartCount = computed(() => store.state.cart.cartCount);

watch(isLoginVisible, (newData) => {
  showPopup.value = newData;
});

const handleFocus = () => {
  emits('focus')
}

const searchText = (e) => {
  if (!props.canInput) {
    emits('focus')
    return;
  }
  if (!searchTextValue.value) return;
  gaAnalytics('mp_search_click', "mp_search_click");
  emits('search', searchTextValue.value)
}

const goHome = () => {
  window.location.href = '/'
}

const inputRef = ref(null);
onMounted(() => {
  innerHeight.value = window.innerHeight;
  props.canInput && inputRef.value.focus();
})

const signIn = () => {
  if(userInfo.value.uid) {
    router.push('/account')
  }else {
    gaAnalytics('mp_sign_in_click', "mp_sign_in_click");
    buriedPointUtil({ eventId: "MPWH201" });
    buriedPointUtil({ eventId: "MPLG501" });
    store.commit('setPassiveLogin', 0)
    showPopup.value = true
  }
}

const productListCopy = computed(() => store.state.productListCopy)
const handleClickOutside = () => {
  showPopup.value = false
  store.commit('setProductList', productListCopy.value)
  store.commit('setLoginVisible', false)
}

const showCurrency = () => {
  showH5Currency.value = true
}

const handleCurrencyClickOutside = () => {
  showH5Currency.value = false
}
const photoClick = () => {
  buriedPointUtil({ eventId: "MPSC202" });
  store.commit('h5Module/setImageSearchGuidePageVisible', true)
}

const showLangunageSelectBox = ref(false)
const showLanguageSelect = () => {
  showLangunageSelectBox.value = true;
}

const showCategory = () => {
  store.commit('h5Module/setCategoryDialogVisible', true)
  buriedPointUtil({ eventId: "MPWH401" });
}

const selectedLanguage = computed(() => {
  const item = languageList.value.find(item => (item.language).toLowerCase() === (language.value).toLowerCase())
  return `${ item.sourceLan } / ${item.language}`
})

const chooseLanguage = (item) => {
  store.commit('h5Module/setLanguage', item.language)
  showLangunageSelectBox.value = false;
  store.commit('h5Module/setFullMask', false)
  localStorage.setItem('language', item.language)
}

const handleBack = () => {
  emits('back')
}

const toCartPage = () => {
  buriedPointUtil({ eventId: 'MPC201' })
  router.push('/carts')
}
</script>

<style lang="less" scoped>
@import './index.less';

.h5-dialog-login {
  padding: 12px 16px;
  ::v-deep(.popup-content) {
    background-color: transparent;
  }
}

.fullmask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0);
  z-index: 999;
}
</style>