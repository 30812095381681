<template>
  <div v-show="loadMoreSleleton" v-if="nums" v-for="(item, index) in new Array(nums).fill(0)" class="init-h5skeleton-item">
    <div class="skeleton-item-main"></div>
    <div class="skeleton-item-line-1"></div>
    <div class="skeleton-item-line-2"></div>
    <div class="skeleton-item-line-3"></div>
    <div class="skeleton-item-line-4"></div>
    <div class="skeleton-item-line-5"></div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
const nums = ref(4)

const loadMoreSleleton = computed(() => store.state.loadMoreSleleton)

watch(
  () => store.state.productList.length,
  (newData) => {
    nums.value = newData % 2 === 0 ? 4 : 5
  }
);

watch(loadMoreSleleton, (newData) => {
  if (newData) {
    loadMoreSleleton.value = newData;
  }
});

</script>

<style lang="less">
@import './index.less';
</style>