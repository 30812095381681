<template>
  <div class="order-list-item-wrapper" @click="showOrderDetail">
    <Transition name="alert">
      <div v-if="alertVisible" class="alert-box-wrapper" @click.stop>
        <img src="@/assets/H5/order/alert-bg.png" height="56px" width="100%" alt="">
        <div class="text">
          If not paid within 24 hours, the order will be automatically canceled.
        </div>
      </div>
    </Transition>
    <div v-if="orderStatus === 0 || orderStatus === 20 && data.b2bPaymentOrderVo.method === 'BANK_TRANSFER_B2B'" class="count-down-time">
      <div class="count-down-time-content">
        <div class="end-icon" @click.stop="showAlert">
          End In
          <img src="@/assets/H5/order/end-in.svg" alt="">
        </div>
        <CountDownTimer v-if="orderStatus === 0" :time="data?.b2bOrderVo?.createTime" /> 
        <CountDownTimer v-if="orderStatus === 20 && data.b2bPaymentOrderVo.method === 'BANK_TRANSFER_B2B'" :time="data?.b2bPaymentOrderVo.bankTransferVO?.auditTime" /> 
      </div>
    </div>
    <div v-if="orderStatus === 0 || orderStatus === 20 && data.b2bPaymentOrderVo.method === 'BANK_TRANSFER_B2B'" style="height: 36px;"></div>
    <div class="order-list-item">
      <div class="header">
        <div class="header-time">
          <img src="@/assets/H5/order/timer.svg" alt="">
          <span>{{ data?.b2bOrderVo?.createTimeStr }}</span>
        </div>
        <div class="header-status">{{ orderStatusText }}</div>
      </div>
      <div class="content">
        <div class="sku-list" @touchend.stop>
          <div @click.stop="toDetailPage(item)" v-for="item in data.b2bOrderDetails" :key="item.skuId" class="sku">
            <img :src="item.skuImage" width="100%" alt="">
          </div>
        </div>
        <div class="total-price">
          <div>Total </div>
          <div>US ${{ data?.b2bOrderVo?.totalAmountUsd }}</div>
        </div>
      </div>
      <div class="order-info">
        <span>Total {{ data?.b2bOrderVo?.productQuantity }} items</span>
        <span></span>
        <span>Awaiting Payment:</span>
        <IntlCurrency :count="data.b2bOrderVo.totalAmountUsd" :size="14" currency="us" color="#052785"/>
      </div>
      <!-- 支付失败 -->
      <div v-if="orderStatus === ORDER_STATUS.PAY_FAIL" class="warning-ifon">
        <img src="@/assets/H5/order/warning.svg" width="14px" alt="">
        Payment confirmation failed, Please pay again.
      </div>
      <div v-if="orderStatus === ORDER_STATUS.CANCELLED" class="warning-ifon">
        <img src="@/assets/H5/order/warning.svg" width="14px" alt="">
        The transaction is closed.
      </div>
      <div class="button-list">
        <div 
          v-if="orderStatus === ORDER_STATUS.PLACE_ORDER" 
          class="button cancel" 
          @click.stop="showCancelOrderDialog">
          Cancel Order
        </div>
        <div 
          @click.stop="handleBuyAgain"
          :class="{ 'pay-now': ![0, 10, 20].includes(orderStatus) }"
          class="button buy-again">
          Rebuy
        </div>
        <div 
          v-if="orderStatus === ORDER_STATUS.PLACE_ORDER" 
          class="button pay-now" 
          @click.stop="showPayNowDialog">
          Pay Now
        </div>
        <div 
          v-if="orderStatus === ORDER_STATUS.PAY_CONFIRMING" 
          @click.stop="showConfirmOrderDialog"
          :class="{ disabled: data.b2bPaymentOrderVo?.method === 'PAYPAL_STANDARD_B2B' }"
          class="button confirming">
          Confirming
        </div>
        <div 
          v-if="orderStatus === ORDER_STATUS.PAY_FAIL" 
          class="button pay-now" 
          @click.stop="showPayAgainDialog">
          Pay Again
        </div>
      </div>
    </div>

    <!-- 遮罩层 -->
    <div 
      v-if="maskVisible" 
      @click.stop="closeMask" 
      @touchmove.stop="closeMask" 
      class="mask">
    </div>
  </div>
</template>
<script setup>
  import { computed, onBeforeMount, onMounted, ref } from 'vue';
  import CountDownTimer from './CountDownTimer.vue';
  import { fetchData } from '@/utils/fetch';
  import { useRouter } from 'vue-router';
  import { gaAnalytics,buriedPointUtil } from '@/utils/utils';
  import { useStore } from 'vuex';

  const ORDER_STATUS = Object.freeze({
    PLACE_ORDER: 0, // 下单成功待支付
    PAY_CONFIRMING: 10, // 支付确认中
    PAY_FAIL: 20, // 支付失败
    PAY_COMPLETE: 30, // 支付完成
    IN_PROCESS: 40, // 采购中
    SHIPPED: 50, // 已发货
    DELIVERED: 90, // 已送达
    CANCELLED: 99, // 已取消
  })

  const emits = defineEmits(['cancel', 'payNow', 'confirm', 'showDetail', 'repay'])

  const props = defineProps({
    data: {
      type: Object,
      default: () => {}
    }
  })

  const router = useRouter();

  const store = useStore()

  const orderStatus = computed(() => {
    return props.data.b2bOrderVo.status
  })

  const orderStatusText = computed(() => {
    if ([0, 10, 20].includes(orderStatus.value)) { 
      return 'Awaiting Payment'
    } else if ([30, 40, 50].includes(orderStatus.value)) {
      return 'Fulfillment'
    } else {
      return 'Completed'
    }
  })

  const formatTime = (value) => {
    const date = new Date(value);
    const YY = date.getFullYear();
    const MM = date.getMonth() + 1;
    const DD = date.getDate();
    const res = `${DD}/${MM}/${YY} ${date.toLocaleTimeString()}`
    return res;
  }

  const alertVisible = ref(false)
  const showAlert = () => {
    alertVisible.value = true;
    showMask();
  }

  // 遮罩层相关逻辑
  const maskVisible = ref(false)
  const showMask = () => {
    maskVisible.value = true;
  }
  const closeMask = () => {
    maskVisible.value = false;
    alertVisible.value = false;
  }

  const showOrderDetail = () => {
    emits('showDetail')
  }

  // 确认取消订单弹窗
  const showCancelOrderDialog = () => {
    gaAnalytics('mp_cancel_order_click', 'mp_cancel_order_click')
    buriedPointUtil({ eventId: 'MPOP205' })
    emits('cancel');
  }
  // 选择支付
  const showPayNowDialog = () => {
    buriedPointUtil({ eventId: 'MPOP201' })
    emits('payNow');
  }

  const showPayAgainDialog = () => {
    gaAnalytics('mp_pay_again_click', 'mp_pay_again_click')
    buriedPointUtil({ eventId: 'MPOP203' })
    if (props.data.b2bOrderVo.totalAmountUsd > 200) {
      emits('repay')
    } else {
      emits('payNow');
    }
  }

  // 订单确认
  const showConfirmOrderDialog = () => {
    if (props.data.b2bPaymentOrderVo?.method === 'PAYPAL_STANDARD_B2B') return;
    gaAnalytics('mp_confirming_click', 'mp_confirming_click')
    buriedPointUtil({ eventId: 'MPOP202' })
    emits('confirm')
  }

  const handleBuyAgain = async () => {
    gaAnalytics('mp_buy_again_click', 'mp_buy_again_click')
    buriedPointUtil({ eventId: 'MPOP204' })
    const { code, data } = await fetchData('/appb/b2b/cart/reBuyOrder', {
      method: 'POST',
      body: JSON.stringify({
        orderNo: props.data.b2bOrderVo.orderNo
      })
    })
    if (code === 200) {
      fetchData("/appb/b2b/cart/cartCount").then(res => {
        store.commit("cart/SET_CART_COUNT", res.data);
      })

      sessionStorage.setItem('carts', JSON.stringify(data))
      localStorage.setItem('buy-again', true)
      router.push('/carts');
    }
  }

  const toDetailPage = (item) => {
    window.open('/offer/' + item.spuId)
  }

  onMounted(() => {
  })

  onBeforeMount(() => {
  })
</script>
<style lang="less" scoped>
.order-list-item-wrapper {
  position: relative;
  .alert-box-wrapper {
    position: absolute;
    top: 30px;
    left: 12px;
    width: 266px;
    height: 56px;
    z-index: 10;
    overflow: hidden;
    .text {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 48px;
      color: white;
      font-size: 11px;
      font-family: mon-500;
      padding: 0 16px;
      line-height: 16px;
      display: flex;
      align-items: center;
    }
  }
  .count-down-time {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 48px;
    background-color: #fff9e0;
    border-radius: 10px 10px 0 0;
    z-index: 1;
    .count-down-time-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      height: 36px;
      padding: 0 12px;

    }
    .end-icon {
      display: flex;
      align-items: center;
      color: #232323;
      font-size: 12px;
      font-family: mon-600;
      img {
        margin-left: 4px;
      }
    }
  }
}
.order-list-item {
  position: relative;
  padding: 14px 12px;
  background-color: white;
  border-radius: 8px;
  margin-bottom: 8px;
  z-index: 2;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-family: mon-500;

    .header-time {
      display: flex;
      align-items: center;
      gap: 8px;
      color: #232323;
    }

    .header-status {
      color: #009de0;
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-top: 12px;
    background-color: #f5f5f5;
    border-radius: 6px;

    .sku-list {
      display: flex;
      gap: 6px;
      width: 100%;
      overflow: scroll;

      &::-webkit-scrollbar {
        display: none;
      }

      .sku {
        flex-shrink: 0;
        width: 52px;
        height: 52px;
        background-color: white;
        border-radius: 4px;
        overflow: hidden;
      }
    }

    .total-price {
      text-align: right;
      font-size: 12px;
      line-height: 16px;
      margin-left: 24px;

      div:first-child {
        color: #6e717a;
        font-family: mon-500;
      }

      div:last-child {
        margin-top: 2px;
        color: #232323;
        font-family: mon-600;
        white-space: nowrap;
      }
    }
  }

  .warning-ifon {
    display: flex;
    align-items: center;
    justify-content: start;
    height: 32px;
    padding: 0 8px;
    margin-top: 12px;
    border: 1px solid #f5c4c4;
    border-radius: 4px;
    background-color: #fff5f5;
    color: #f54550;
    font-size: 11px;
    font-family: mon-500;
    white-space: nowrap;
    img {
      margin-right: 6px;
    }
  }

  .order-info {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 12px;

    span:nth-child(1) {
      color: #6e717a;
      font-family: mon-500;
      font-size: 12px;
    }

    span:nth-child(2) {
      height: 8px;
      width: 0.5px;
      background-color: #b2b7c2;
      margin: 0 8px;
    }

    span:nth-child(3) {
      color: #232323;
      font-family: mon-500;
      font-size: 12px;
      margin-right: 4px;
    }
  }

  .button-list {
    display: flex;
    align-items: center;
    justify-content: end;
    margin-top: 12px;

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 12px;
      border-radius: 16px;
      font-size: 12px;
      font-family: mon-600;
      margin-left: 8px;
      height: 32px;
      min-width: 80px;
      white-space: nowrap;

      &.cancel {
        border: 1px solid #b2b7c2;
        color: #232323;
      }

      &.buy-again {
        border: 1px solid #4681F9;
        color: #4681F9;
      }

      &.pay-now {
        background-color: #4681F9;
        color: white;
      }

      &.confirming {
        background-color: #4681F9;
        color: white;
      }
      
      &.disabled {
        border: none;
        background-color: #ebebeb;
        color: #b2b7c2;
      }
    }
  }
}
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 9;
}

.alert-enter-active {
  transform-origin: 50px 0;
  animation: alert 0.3s;
}

.alert-leave-active {
  transform-origin: 50px 0;
  animation: alert 0.3s reverse;
}

@keyframes alert {
  0% {
    transform: scale(0, 0);
    opacity: 0;
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

</style>