<template>
  <div class="h5-Home">
    <div v-if="!isKewordsSearch">
      <div v-if="!searchFoucs">
        <KeywordsMiniHeader :isShow="showMiniHeader" />
        <div class="main-header" :class="{ hidden: showMiniHeader }">
          <!-- <HeaderTopSwiper /> -->
          <Header />
          <Partner />
          <ShopCategory />
        </div>
        <HomeProduct />
      </div>
      <H5SearchFoucs v-if="searchFoucs" @back="() => store.commit('h5Module/setSearchFoucs', false)" />
    </div>
    <div v-if="isKewordsSearch">
      <KeywordsProduct />
    </div>
  </div>
</template>

<script setup>
import Header from '@/components/H5/Header/index.vue';
import HomeProduct from './components/HomeProduct/index.vue'
import H5SearchFoucs from '@/components/H5/H5SearchFoucs/index.vue'
import KeywordsProduct from './components/KeywordsProduct/index.vue'
import Partner from './components/Partner/index.vue'
import ShopCategory from './components/ShopCategory/index.vue'
import HeaderTopSwiper from './components/HeaderTopSwiper/index.vue'
import KeywordsMiniHeader from '@/components/H5/KeywordsMiniHeader/index.vue'
import { computed, nextTick, onBeforeUnmount, onMounted, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { buriedPointUtil, checkHasQueryLanguage, gaAnalytics, throttle } from '../../../../utils/utils';
import { fetchData } from '@/utils/fetch';

const route = useRoute();
const router = useRouter();

const store = useStore()

const isKewordsSearch = ref(false);

const searchFoucs = computed(() => store.state.h5Module.searchFoucs)

onMounted(() => {
  handleRouteChange()
  if(location.pathname.includes('/search')) {
    // gaAnalytics('mp_search_page_view', "mp_search_page_view");
    // buriedPointUtil({ eventId: "MPSC101"});
  }else {
    gaAnalytics('mp_home_page_view', "mp_home_page_view", { bdUserId: localStorage.getItem('dsp.uid') });
  }
  if(location.pathname === '/') {
    window.addEventListener('scroll', handleHomeScroll);
  }
  buriedEmail()
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleHomeScroll);
});

const buriedEmail = () => {
  const { emailType = '', email_id = '' } = route.query;
  if(!!emailType && window.location.pathname === '/') {
    buriedPointUtil({ eventId: 'MPHM501', emailId: atob(email_id)})
  }
}


const handleRouteChange = () => {
  const isSearchPath = route.fullPath.includes('/search') && !route.fullPath.includes('imageId') && !route.fullPath.includes('offerId')
  if (isSearchPath) {
    isKewordsSearch.value = true;
  }
  if(!isSearchPath) {
    isKewordsSearch.value = false;
    window.scrollTo(0, 0)
  }
};

watch(
  () => route.fullPath,
  () => {
    handleRouteChange();
  }
);

const showMiniHeader = ref(false)
let lastScrollTop = 0;
const handleHomeScroll = (e) => {
  const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  const scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
  const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;

  if (scrollTop > 314 && scrollTop > lastScrollTop) {
    // 向下滚动，显示小头部
    showMiniHeader.value = true;
  } else if (scrollTop < lastScrollTop) {
    // 向上滚动，显示大头部
    showMiniHeader.value = false;
  }
  lastScrollTop = scrollTop;

  if (scrollTop + 75 > scrollHeight - clientHeight) {
    if (route.path === '/') {
      const { data: adsWord } = route.query;
      if(!!adsWord) return;
      store.dispatch('loadMoreHomeData');
    }
  }
}

const preSearch = computed(() => store.state.search.preSearch);
watch(preSearch, (value) => {
  if (value && route.fullPath === '/') {
    const { data: adsWord } = route.query;
    if(!!adsWord) return;
    store.dispatch('loadMoreHomeData');
  }
})
</script>

<style lang="less" scoped>
@import './index.less';

.custom-curreny-dialog {
  padding: 12px 16px 24px;
  box-sizing: border-box;
  ::v-deep(.popup-content) {
    border-radius: 8px;
  }
}
</style>