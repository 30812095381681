<template>
  <div class="loading-container" v-if="loading">
    <div class="container">
      <div class="spinner"></div>
      <div class="container-text">Loading</div>
    </div>
  </div>
</template>

<script setup>
import { computed, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const loading = computed(() => store.state.loading);

watch(loading, (newData) => {
  if (newData) {
    loading.value = newData;
  }
});
</script>

<style lang="less">
.loading-container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  top: 0px;
  z-index: 99999;
}

.container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 118px;
  height: 108px;
  box-sizing: border-box;
  background-color: rgba(28, 32, 38, 0.8);
  backdrop-filter: blur(20px);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .container-text {
    color: #fff;
    font-size: 14px;
    font-family: mon-400;
    margin-top: 8px;
  }

  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid white; 
    border-top: 4px solid transparent;
    border-radius: 50%;
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}
</style>
