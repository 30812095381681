
<template>
  <div class="shipping-free-desc">
    <img
      src="https://cdn-image.bestdealer.com/app-bestdealer/1688/carts/shipping-free.png"
      alt="shipping-free-desc"
      class="shipping-free-desc-img"
    />
    <div class="shipping-free-desc-title">Shipping fee description</div>
    <div class="shipping-free-desc-content">
      We want to assure you that this fee is designed to protect your business
      throughout the purchase process.
    </div>
    <div class="shipping-free-desc-content-list">
      <div class="shipping-free-desc-content-list-item">
        <div class="item-radius"></div>
        <div class="item-content">
          It includes essential elements such as the preliminary infringement
          verification fee for product designs and patents, procurement costs,
          domestic logistics expenses, quality inspection fees at the warehouse,
          operational charges, and customs duties.
        </div>
      </div>
      <div class="shipping-free-desc-content-list-item">
        <div class="item-radius"></div>
        <div class="item-content">
          By encompassing all these aspects, we provide you with a comprehensive
          service that ensures compliance and delivers excellent value for your
          investment.
        </div>
      </div>
      <div class="shipping-free-desc-content-list-item">
        <div class="item-radius"></div>
        <div class="item-content">
          If you have a large order and want a better price please contact
          customer service.
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less">
.shipping-free-desc {
  padding: 15px 16px;
  font-size: 0px;
  .shipping-free-desc-img {
    width: 100%;
  }
  .shipping-free-desc-title {
    font-size: 20px;
    font-family: mon-700;
    color: #232323;
    margin-top: 24px;
    line-height: 28px;
  }
  .shipping-free-desc-content {
    font-size: 14px;
    font-family: mon-500;
    color: #232323;
    margin-top: 16px;
    line-height: 18px;
    letter-spacing: 0.02px;
  }
  .shipping-free-desc-content-list {
    margin-top: 16px;
    .shipping-free-desc-content-list-item {
      font-size: 12px;
      font-family: mon-500;
      color: #5C5C5C;
      line-height: 16px;
      letter-spacing: 0.02px;
      display: flex;
      align-items: baseline;
      .item-radius {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #4681F9;
      }
      .item-content {
        width: calc(100% - 16px);
        margin-left: 16px;
      }
    }
    .shipping-free-desc-content-list-item:not(:first-child) {
      margin-top: 8px;
    }
  }
}
</style>