<template>
  <OrderFillingH5 v-if="isMobile === 'MOBILE'" />
</template>

<script setup>
import { onMounted, ref } from 'vue';
import OrderFillingH5 from './components/OrderFillingH5/index.vue'

const isMobile = ref(false);
onMounted( async () => {
  const userAgent = navigator.userAgent.toLowerCase();
  isMobile.value = /iphone|ipod|ipad|android|blackberry|windows phone/i.test(userAgent) ? "MOBILE" : "PC";
});


</script>