<template>
  <div class="h5-header-searchFoucs-wrapper">
    <KeywordsHeader 
      v-model="inputValue" 
      :canBack="true"
      @back="handleBack"
      @search="serachProductList" />

    <div class="keyword-search-bg-wrapper">
      <img src="@/assets/H5/home/keyword-search.png" alt="">
      <div class="keyword-search-text">
        <div class="row1">Wath do you want do ...?</div>
        <div class="row2">🔍 Enter the description, and let’s find your treasure!</div>
      </div>
    </div>
  </div>
  <H5Empty />
</template>

<script setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import { buriedPointUtil, gaAnalytics, redirectWithSearch, debounce, throttle } from '../../../utils/utils';
import { fetchData } from '../../../utils/fetch';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import H5Empty from '@/components/H5/H5Empty/index.vue';
import KeywordsHeader from '@/components/H5/KeywordsHeader/index.vue'

const emits = defineEmits(['back'])

const inputSearchRef = ref(null)

const route = useRoute();
const router = useRouter();
const store = useStore()
const inputValue = ref('')
const lastQuery = ref('')

const searchInfo = computed(() => store.getters.searchInfo);
const productList = computed(() => store.state.productList)
const language = computed(() => store.state.h5Module.language)

watch(searchInfo, (newData) => {
  if (newData) {
    searchInfo.value = newData;
  }
});

watch(inputValue, (value) => {
  preSearch(value);
})

// 预调用文搜接口
const preSearch = throttle((value) => {
  if(value.length >= lastQuery.value.length) {
    lastQuery.value = value
  }
  if(value.length < lastQuery.value.length) {
    lastQuery.value = value
    return
  }

  const params = {
    async: true,
    imageId: 0,
    keyword: value,
    language: language.value,
    page: 1,
    pageSize: 20,
    region: ''
  }

  fetchData('/dsp-app/alibaba-common/searchKeyWord?itemNumber=50', {
    method: 'POST',
    body: JSON.stringify(params),
  })
}, 400)


onMounted( async () => {
  await nextTick();
  if(inputSearchRef.value) {
    inputSearchRef.value.focus()
  }

  document.querySelector('body').classList.add('no-scroll');
})

onUnmounted(() => {
  document.querySelector('body').classList.remove('no-scroll');
})

const serachProductList = async (value) => {
  inputValue.value = value;
  gaAnalytics('mp_search_click', "mp_search_click");
  if (!inputValue.value) {
    return;
  }
  if (location.pathname === '/') {
    localStorage.setItem('homeProductList', JSON.stringify(productList.value));
  }
  store.commit('h5Module/setSearchFoucs', false)
  // const { lg = "" } = route.query;
  const language = localStorage.getItem('language')
  const { href } = router.resolve({
    path: '/search',
    query: {
      keyword: inputValue.value,
      lg: language
    }
  });
  window.open(href, '_blank');
}

const handleBack = () => {
  emits('back')
}
</script>

<style lang="less">
@import './index.less';
</style>