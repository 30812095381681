<template>

  <div v-if="emptyObj?.code !== 5209">
    <!-- styles 图搜 -->
    <div v-if="type === 'styles'" class="find-styles-wrapper">
      <!-- canvas list -->
      <div v-if="!!canvasImgSelectList?.length" class="canvas-list">
        <div class="canvas-main">
          <img :src="currentMainImage" alt="" />
        </div>
        <div class="canvas-line"></div>
        <div class="canvas-line-item-list">
          <div class="canvas-line-item-list-title">
            <span>
              Relate style searches
            </span>
          </div>
          <div class="canvas-line-item-list-content">
            <div v-for="(item, index) in canvasImgSelectList" :class="{
              'canvas-list-item': true,
              'select-item': initSelected === index,
            }" @click="selectImg(item, index)">
              <img class="canvas-body-border-img" src="@/assets/item_body_border.svg" alt="" />
              <div class="canvas-list-item-img">
                <img :src="item.imgUrl" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2>Results for <span>"Similar items"</span></h2>
      <FindStylesCarousel ref="findStylesCarouselRef" :productList="productList" :total="total" />
      <h2 v-if="stylesList.length">Results for <span>"Style Recommendation"</span></h2>
      <Product :productList="stylesList" />
    </div>
    <!-- similar 图搜 -->
    <main v-if="type === 'similar'" class="main-container">
      <!-- canvas list -->
      <div v-if="!!canvasImgSelectList?.length" class="canvas-list" ref="findStylesCarouselRef">
        <div class="canvas-main">
          <img :src="currentMainImage" alt="" />
        </div>
        <div class="canvas-line"></div>
        <div class="canvas-line-item-list">
          <div class="canvas-line-item-list-title">
            Relate similar searches
          </div>
          <div class="canvas-line-item-list-content">
            <div v-for="(item, index) in canvasImgSelectList" :class="{
              'canvas-list-item': true,
              'select-item': initSelected === index,
            }" @click="selectImg(item, index)">
              <img class="canvas-body-border-img" src="@/assets/item_body_border.svg" alt="" />
              <div class="canvas-list-item-img">
                <img :src="item.imgUrl" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 v-if="productList.length">Results for <span>"Similar items"</span></h2>
      <Product :productList="productList" />
    </main>
    <!-- 文搜 -->
    <main v-if="(categoryId || keyword)" class="main-container">
      <LongTimeLoading v-if="translateLoading && keyword" :keyword="keyword"></LongTimeLoading>
      <div v-else>
        <Breadcrumb :categoryName="categoryName"></Breadcrumb>
        <TagFilter v-if="categoryId || keyword" :activeCategory="activeCategory" @search="searchByKeyword" />
        <div v-else class="product-result">
          <!-- <span v-if="total === -1">Searching...</span>
        <span v-else>We found {{ total }} items fou you</span> -->
          <div class="left">Results for "<span :title="searchKeyword">{{ searchKeyword }}</span>"</div>
          <div class="right">We found <span>{{ new Intl.NumberFormat('en-US').format(total) }}+</span> items fou you
          </div>
        </div>
        <Product :productList="productList" />
      </div>
    </main>
  </div>

  <Empty v-if="emptyObj?.code === 5209" :msg="emptyObj?.msg || 'Sorry, no results found'" />
</template>

<script setup>
import { computed, nextTick, onMounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import LongTimeLoading from "@/components/LongTimeLoading/index.vue";
import TagFilter from "@/components/TagFilter/index.vue";
import Product from "@/components/Product/index.vue";
import FindStylesCarousel from "@/components/FindStylesCarousel/index.vue";
import Breadcrumb from "./components/Breadcrumb/index.vue";
import { fetchData } from "@/utils/fetch";
import { buriedPointUtil, gaAnalytics } from "@/utils/utils";
import Empty from "@/components/Empty/index.vue";
import { checkHasQueryLanguage, findMostFrequentCategory } from "../../utils/utils";

const store = useStore();
const route = useRoute();
const router = useRouter();
const { lg = "" } = route.query;

const currentMainImage =
  sessionStorage.getItem(`currentMainImage_${route.query.imageId}`) ||
  sessionStorage.getItem(`currentMainImage_${route.query.offerId}`) ||
  "";
const language = computed(() => checkHasQueryLanguage(lg) || localStorage.getItem("language") || "en");
const initSelected = ref(0);

const keyword = computed(() => route.query.keyword);
const mostCategoryId = ref('')
const categoryId = ref(route.query.categoryId);
const categoryName = computed(() => route.query.categoryName);
const activeCategory = computed(() => ({
  categoryId: categoryId.value || mostCategoryId.value,
  categoryName: categoryName.value,
}));
const type = computed(() => route.query.type);
const imageId = computed(() => route.query.imageId);
const offerId = computed(() => route.query.offerId);

const searchInfo = computed(() => store.state.searchInfo);
const productList = computed(() => store.state.productList);
const total = computed(() => store.state.total);
const canvasImgSelectList = computed(() => store.state.canvasImgSelectList);
const emptyObj = computed(() => store.state.emptyObj);

const translateLoading = computed(() => store.state.search.translateLoading);
const activeSimilarItem = computed(() => store.state.search.activeSimilarItem);
const similarOfferIdList = computed(
  () => store.state.search.similarOfferIdList
);
const stylesSearchInfo = computed(() => store.state.search.stylesSearchInfo);
const stylesList = computed(() => store.state.search.stylesList);

const searchKeyword = computed(() => route.query.keyword);

const keyWordCn = computed(() => store.state.common.keyWordCn);

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const calculateCropDimensions = (picRegionInfo) => {
  const regions = picRegionInfo?.yoloCropRegion?.split(";");

  // 将每个区域的字符串分割并转换为对象
  const result = regions.map((region) => {
    const [x0, y0, x1, y1] = region.split(",").map(Number);
    return {
      x0,
      y0,
      x1,
      y1,
    };
  });

  return result;
};

const searchByKeyword = async (categoryInputValue = '') => {
  const searchData = {
    ...searchInfo.value,
    keyword: keyword.value,
    language: language.value,
    categoryId: categoryId.value,
    pageSize: 50,
    pageNow: 1,
    imageId: 0,
    region: "",
    async: language.value !== "zh",
  };

  if (categoryInputValue) searchData.keyword = categoryInputValue;

  if (language.value === "zh") {
    store.commit("setInitSkeleton", true);
  } else {
    // 只有第一次翻译需要翻译动画
    const hasTpage = location.search.includes('tpage');
    if (!keyWordCn.value && !hasTpage) {
      store.commit("search/SET_TRANSLATE_LOADING", true);
    } else {
      store.commit("setInitSkeleton", true);
    }
  }
  const res = await fetchData("/dsp-app/alibaba-common/searchKeyWord?itemNumber=50", {
    method: "post",
    body: JSON.stringify(searchData),
  });
  if (language.value === "zh") {
    store.commit("setInitSkeleton", false);
  } else {
    setTimeout(() => {
      store.commit("search/SET_TRANSLATE_LOADING", false);
    }, 1000)
    store.commit("setInitSkeleton", false);
  }

  if (res.code === 200) {
    if (language.value === "zh") {
      const findMostId = findMostFrequentCategory(res.data?.rows || [])
      if(findMostId && !mostCategoryId.value) {
        mostCategoryId.value = findMostId
      }

      store.dispatch("setSearchProduct", {
        ...res.data,
        searchInfo: searchData,
      });
      const { keyWordCn } = res.data;
      store.commit("common/SET_KEY_WORD_CN", keyWordCn);
    } else {
      // 非中文请求第二遍
      store.commit("setInitSkeleton", true);
      const { keyWordCn } = res.data;
      store.commit("common/SET_KEY_WORD_CN", keyWordCn);
      searchData.async = false;
      keyWordCn && (searchData.keyword = keyWordCn);
      searchData.language = "zh";
      const { code, data = {} } = await fetchData(`/dsp-app/alibaba-common/searchKeyWord?itemNumber=50`, {
        method: "post",
        body: JSON.stringify(searchData),
      });
      if (code === 200) {
        if (!data) {
          data.rows = [];
          data.total = 0;
        }
        const findMostId = findMostFrequentCategory(data.rows || [])
        if(!!findMostId && !mostCategoryId.value) {
          mostCategoryId.value = findMostId
        }
        store.dispatch("setSearchProduct", {
          ...data,
          searchInfo: searchData,
        });
      }
      store.commit("setInitSkeleton", false);
    }
  }

};

const searchByImgId = async (pageNow = 1) => {
  store.commit("setProductList", []);
  store.commit("setTotal", -1);
  if (type.value === "styles") {
    store.commit("search/SET_SIMILAR_SKELETON", true);
  } else {
    store.commit("setInitSkeleton", true);
  }
  const searchData = {
    ...searchInfo.value,
    keyword: "",
    pageNow: type.value === "styles" ? pageNow : 1,
    pageSize: 50,
  };
  if (offerId.value) {
    searchData.imageAddress = currentMainImage;
  }
  if (imageId.value) {
    searchData.imageId = imageId.value;
  }
  try {
    const { code, data } = await fetchData("/dsp-app/alibaba-common/searchImage", {
      method: "post",
      body: JSON.stringify(searchData),
    });
    if (code === 200) {
      const { picRegionInfo } = data;

      const coordinates = calculateCropDimensions(picRegionInfo);

      cutImageByCoordinates(currentMainImage, coordinates, picRegionInfo).then(list => {
        store.dispatch("setImgSearch", {
        ...data,
        canvasImgList: list,
        searchInfo: {
          ...searchInfo.value,
          keyword: "",
          imageId,
          pageNow: 1,
          pageSize: 50,
          region: picRegionInfo?.currentRegion,
        },
        currentMainImage: currentMainImage,
      });
      if (type.value === "styles") {
        store.commit("search/SET_ACTIVE_SIMILAR_ITEM", productList.value[0]);
        store.commit(
          "search/SET_SIMILAR_OFFER_ID_LIST",
          productList.value.map((item) => {
            return item.offerId;
          })
        );
      }
      if (window.location.pathname.includes("/offer")) {
        router.replace("/");
      }
      store.commit("setLoading", false);
      if (type.value === "styles") {
        store.commit("search/SET_SIMILAR_SKELETON", false);
      } else {
        store.commit("setInitSkeleton", false);
      }
      })
      // const list = [];

      // const image = new Image();
      // image.src = currentMainImage;

      // const canvas = document.getElementById("canvas");
      // const ctx = canvas.getContext("2d");

      // coordinates.forEach((coord, index) => {
      //   const width = coord.y0 - coord.x0;
      //   const height = coord.y1 - coord.x1;

      //   canvas.width = width;
      //   canvas.height = height;

      //   ctx.clearRect(0, 0, canvas.width, canvas.height); // 清空画布
      //   ctx.drawImage(
      //     image,
      //     coord.x0,
      //     coord.x1,
      //     width,
      //     height,
      //     0,
      //     0,
      //     width,
      //     height
      //   );

      //   list.push({
      //     imgUrl: canvas.toDataURL(),
      //     region: picRegionInfo?.yoloCropRegion?.split(";")[index],
      //   });
      // });




    }
  } catch (error) {
    console.log(error, "error");
  }
};

const cutImageByCoordinates = async (imgUrl, coordinates, picRegionInfo) => {
  const response = await fetch(imgUrl);
  const blob = await response.blob();
  const base64Image = await blobToBase64(blob);

  const image = new Image();
  image.src = base64Image;
  const list = [];

  return new Promise((resolve, reject) => {
    image.onload = () => {
      const canvas = document.getElementById('canvas');
      const ctx = canvas.getContext('2d');

      coordinates.forEach((coord, index) => {
        const width = coord.y0 - coord.x0;
        const height = coord.y1 - coord.x1;

        canvas.width = width;
        canvas.height = height;

        ctx.clearRect(0, 0, canvas.width, canvas.height); // 清空画布
        ctx.drawImage(
          image,
          coord.x0,
          coord.x1,
          width,
          height,
          0,
          0,
          width,
          height
        );
        list.push({
          imgUrl: canvas.toDataURL(),
          region: picRegionInfo?.yoloCropRegion?.split(';')[index],
        });
      });

      resolve(list);
    }
  })
}


const searchByOfferId = async (pageNow = 1) => {
  store.commit("setProductList", []);
  store.commit("setTotal", -1);
  if (type.value === "styles") {
    store.commit("search/SET_SIMILAR_SKELETON", true);
  } else {
    store.commit("setInitSkeleton", true);
  }

  await fetchData("/dsp-app/alibaba-common/searchImage", {
    method: "post",
    body: JSON.stringify({
      ...searchInfo.value,
      imageAddress: currentMainImage,
      imageId: 0,
      pageNow: type.value === "styles" ? pageNow : 1,
      pageSize: 50,
    }),
  })
    .then(async (v) => {
      const { picRegionInfo } = v.data;

      const response = await fetch(currentMainImage);
      const blob = await response.blob();
      const base64Image = await blobToBase64(blob);

      const coordinates = calculateCropDimensions(picRegionInfo);
      const list = [];
      const image = new Image();
      image.src = base64Image;

      image.onload = () => {
        const canvas = document.getElementById("canvas");
        const ctx = canvas.getContext("2d");

        coordinates.forEach((coord, index) => {
          const width = coord.y0 - coord.x0;
          const height = coord.y1 - coord.x1;

          canvas.width = width;
          canvas.height = height;

          ctx.clearRect(0, 0, canvas.width, canvas.height); // 清空画布
          ctx.drawImage(
            image,
            coord.x0,
            coord.x1,
            width,
            height,
            0,
            0,
            width,
            height
          );
          list.push({
            imgUrl: canvas.toDataURL(),
            region: picRegionInfo?.yoloCropRegion?.split(";")[index],
          });
        });
        store.dispatch("setImgSearch", {
          ...v.data,
          canvasImgList: list,
          searchInfo: {
            ...searchInfo.value,
            keyword: "",
            imageId: 0,
            pageNow: 1,
            pageSize: 50,
            region: picRegionInfo?.currentRegion,
            imageAddress: currentMainImage,
          },
          currentMainImage: currentMainImage,
        });
        if (type.value === "styles") {
          store.commit("search/SET_ACTIVE_SIMILAR_ITEM", productList.value[0]);
          store.commit(
            "search/SET_SIMILAR_OFFER_ID_LIST",
            productList.value.map((item) => {
              return item.offerId;
            })
          );
        }
        store.commit("setLoading", false);
        if (type.value === "styles") {
          store.commit("search/SET_SIMILAR_SKELETON", false);
        } else {
          store.commit("setInitSkeleton", false);
        }
      };
    })
    .catch((error) => {
      store.commit("setLoading", false);
      if (type.value === "styles") {
        store.commit("search/SET_SIMILAR_SKELETON", false);
      } else {
        store.commit("setInitSkeleton", false);
      }
    });
};

// 搜索推荐
const searchStylesList = async () => {
  store.commit("setInitSkeleton", true);
  store.commit("search/SET_STYLES_LIST", []);
  store.commit("search/SET_STYLES_LIST_TOTAL", 0);
  const searchData = {
    ...stylesSearchInfo.value,
    keyword: keyword.value,
    pageSize: 50,
    pageNow: 1,
    offerId: activeSimilarItem.value?.offerId,
    subject: activeSimilarItem.value?.subject,
    similarOfferIds: similarOfferIdList.value,
    categoryId: activeSimilarItem.value.secondCategoryId
  };
  const { code, data } = await fetchData("/dsp-app/alibaba-common/relatedRecommend", {
    method: "POST",
    body: JSON.stringify(searchData),
  });
  if (code === 200) {
    store.commit("setInitSkeleton", false);
    store.dispatch("search/setStylesList", {
      rows: data.rows,
      total: data.total,
      searchInfo: {
        ...searchData,
        keyword: data.keyword
      },
    });
  }
};

const findStylesCarouselRef = ref(null);
// setInterval(() => {
//   console.log(findStylesCarouselRef.value, 'findStylesCarouselRef')
// }, 1000)

// 切换图片
const selectImg = async (item, index) => {
  await nextTick()
  findStylesCarouselRef.value.currentIndex = 0;
  const searchInfoObj = {
    ...searchInfo.value,
    region: item.region,
    pageSize: 50,
    pageNow: 1,
  };
  initSelected.value = index;
  gaAnalytics("p_search_click", "p_search_click");
  buriedPointUtil({ eventId: "PSI201" });
  store.commit("setProductList", []);
  store.commit("setTotal", -1);
  if (type.value === "styles") {
    store.commit("search/SET_SIMILAR_SKELETON", true);
  } else {
    store.commit("setInitSkeleton", true);
  }

  try {
    const { code, data } = await fetchData("/dsp-app/alibaba-common/searchImage", {
      method: "post",
      body: JSON.stringify(searchInfoObj),
    });
    if (code === 200) {
      store.dispatch("setImgSearch", { ...data, searchInfo: searchInfoObj });
      if (type.value === "styles") {
        store.commit("search/SET_ACTIVE_SIMILAR_ITEM", productList.value[0]);
        store.commit(
          "search/SET_SIMILAR_OFFER_ID_LIST",
          productList.value.map((item) => {
            return item.offerId;
          })
        );
      }
    }
    store.commit("setLoading", false);
    if (type.value === "styles") {
      store.commit("search/SET_SIMILAR_SKELETON", false);
    } else {
      store.commit("setInitSkeleton", false);
    }
  } catch (error) {
    store.commit("setLoading", false);
    if (type.value === "styles") {
      store.commit("search/SET_SIMILAR_SKELETON", false);
    } else {
      store.commit("setInitSkeleton", false);
    }
  }
};

// 查询选中的activeSimilarItem
watch(
  () => activeSimilarItem.value,
  (val) => {
    if (val && !!Object.keys(activeSimilarItem.value).length && type.value === "styles") {
      searchStylesList();
    }
  },
  { immediate: true }
);

// 纯文本搜索
watch(
  () => [keyword.value, categoryId.value],
  ([keyVal, categoryIdVal]) => {
    if (keyVal || categoryIdVal) {
      searchByKeyword();
    }
  },
  { immediate: true }
);

// 标品搜索
watch(
  () => offerId.value,
  (val) => {
    if (val) {
      searchByOfferId();
    }
  },
  { immediate: true }
);
// 图片搜索
watch(
  () => imageId.value,
  (val) => {
    if (val) {
      searchByImgId();
    }
  },
  { immediate: true }
);
</script>

<style scoped lang="less">
@import "./index.less";
</style>
