<template>
  <div class="layout" ref="scrollLayoutContainer">
    <header ref="headerRef" class="header-pc">
      <HeaderTopSource v-if="!showCeiling" />
      <HeaderTop v-if="route.path === '/' && !showCeiling" />
      <div
        :class="{ ceiling: showCeiling, 'ceiling-up': scrollDirection === 'up', 'ceiling-down': scrollDirection === 'down' }">
        <Search :guidancePopVisible="guidancePopVisible" />
        <CategorySlider />
      </div>
      <Header v-if="!showCeiling"></Header>
    </header>
    <div :style="{ height: headerHeight + 'px' }" v-if="showCeiling"></div>
    <section class="content" :style="{ 'padding-bottom': route.path.includes('guide') ? '0' : '64px' }">
      <router-view></router-view>
    </section>
    <Footer></Footer>
    <NewbieGuidance :popVisible="guidancePopVisible" :title="currentGuidance.title" :videoSrc="currentGuidance.videoSrc"
      @close="closeGuidancePop"></NewbieGuidance>
    <Toast :msg="toastInfo.msg" :type="toastInfo.type" :visible="toastVisible"></Toast>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, onBeforeUnmount, nextTick, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";

import Header from "@/components/Header/index.vue";
import HeaderTop from "@/components/HeaderTop/index.vue";
import HeaderTopSource from '@/components/HeaderTopSource/index.vue'
import Search from "@/components/Search/index.vue";
import CategorySlider from "@/components/CategorySlider/index.vue";
import Footer from "@/components/Footer/index.vue";
import NewbieGuidance from "@/components/NewbieGuidance/index.vue";
import Toast from "@/components/Toast/index.vue";

import { fetchData } from "@/utils/fetch";
import { buriedPointUtil, gaAnalytics, debounce } from "@/utils/utils";

const store = useStore();
const route = useRoute();

const router = useRouter();

const canvasImgSelectList = computed(() => store.state.canvasImgSelectList);
const currentMainImage = computed(() => store.state.currentMainImage);
const userInfo = computed(() => store.state.common.userInfo);

const drawImage = ref([]);

const scrollLayoutContainer = ref(null);
const isLoading = ref(false);
const hasMore = ref(true);
const showCeiling = ref(false)
const headerRef = ref(null);  // 创建 ref 引用
const headerHeight = ref(0);

const lastScrollY = ref(0);
const scrollDirection = ref('')

const keyWordCn = computed(() => store.state.common.keyWordCn);
const guidancePopVisible = computed(
  () => store.state.common.guidancePopVisible
);
const currentGuidance = computed(() => store.state.common.currentGuidance);
const toastVisible = computed(() => store.state.common.toastVisible);
const toastInfo = computed(() => store.state.common.toastInfo);

const searchInfo = computed(() => store.state.searchInfo);
const productList = computed(() => store.state.productList);

const translateLoading = computed(() => store.state.search.translateLoading);
const stylesSearchInfo = computed(() => store.state.search.stylesSearchInfo);
const stylesList = computed(() => store.state.search.stylesList);

const emptyObj = computed(() => store.state.emptyObj);

const closeGuidancePop = () => {
  store.commit("common/SET_GUIDANCE_POP_VISIBLE", false);
  // store.commit("common/SET_ANIMATE_HELPCENTER", true);
};

const getHashParam = (key) => {
  const hash = window.location.hash.substring(1);
  const params = new URLSearchParams(hash);
  return params.get(key);
};

// 滚动加载
const layoutOnScroll = () => {
  const pageScrollY = window.scrollY;
  let scrollHeight = document.documentElement.scrollHeight;
  const clientHeight = window.innerHeight;

  if (pageScrollY > headerHeight.value) {
    if (pageScrollY > lastScrollY.value) {
      scrollDirection.value = "down"; // 向下滚动
    } else {
      // 临界值判断 (距离顶部 20px 时触发隐藏动画)
      if (pageScrollY < headerHeight.value + 20) {
        scrollDirection.value = ""; // 清除方向
        showCeiling.value = false; // 隐藏 ceiling
      } else {
        scrollDirection.value = "up"; // 向上滚动
        showCeiling.value = true; // 显示 ceiling
      }
    }
  } else {
    showCeiling.value = false; // 隐藏 ceiling
    scrollDirection.value = "";
  }
  lastScrollY.value = pageScrollY

  // 判断当前位置加 75px 是否大于等于最大滚动位置
  if (route.path === "/search") {
    if (pageScrollY + 75 >= scrollHeight - clientHeight && scrollHeight > clientHeight) {
      loadMore();
    }
  }
}

// 文搜
const searchByKeyword = async () => {
  if (!productList.value.length) {
    hasMore.value = false;
  }
  const searchData = {
    ...searchInfo.value,
    pageNow: searchInfo.value.pageNow + 1,
    language: "zh",
    async: false,
  };
  if (keyWordCn.value) {
    searchData.keyword = keyWordCn.value;
  }
  buriedPointUtil({ eventId: "PSC401", others: { ...searchData } });
  const { code, data  } = await fetchData("/dsp-app/alibaba-common/searchKeyWord?itemNumber=50", {
    method: "post",
    body: JSON.stringify(searchData),
  });
  if (code === 200) {
    if (!data.rows || !data.rows.length) {
      hasMore.value = false;
    }
    
    const rows = [...productList.value, ...data.rows];
    store.dispatch("setSearchProduct", {
      rows,
      total: data.total,
      searchInfo: searchData,
    });
  }
};

// 图搜
const searchByImage = async () => {
  // if (productList.value.length < searchInfo.value.pageSize) {
  //   hasMore = false;
  // }
  const searchData = {
    ...searchInfo.value,
    pageNow: searchInfo.value.pageNow + 1,
  };
  buriedPointUtil({ eventId: "PSC401", others: { ...searchData } });

  const { code, data } = await fetchData("/dsp-app/alibaba-common/searchImage", {
    method: "post",
    body: JSON.stringify(searchData),
  });
  if (code === 200) {
    if (!data.rows || data.rows?.length === 0) {
      hasMore.value = false;
    }
    const rows = [...productList.value, ...data.rows];
    store.dispatch("setImgSearch", {
      rows,
      total: data.total,
      searchInfo: searchData,
    });
  }
};

//  推荐
const searchByRecommend = async () => {
  if(!Object.keys(stylesSearchInfo.value).length) {
    return
  }
  if (!stylesList.value.length) {
    hasMore.value = false;
  }
  const searchData = {
    ...stylesSearchInfo.value,
    pageNow: stylesSearchInfo.value.pageNow + 1,
  };
  buriedPointUtil({ eventId: "PSC401", others: { ...searchData } });

  const { code, data } = await fetchData("/dsp-app/alibaba-common/relatedRecommend", {
    method: "post",
    body: JSON.stringify(searchData),
  });

  if (code === 200) {
    if (!data.rows || data.rows?.length === 0) {
      hasMore.value = false;
    }
    const rows = [...stylesList.value, ...data.rows];
    store.dispatch("search/setStylesList", {
      rows,
      total: data.total,
      searchInfo: {
        ...searchData,
        pageNow: data.pageNow,
      },
    });
  }
};

// 加载更多
const loadMore = debounce(async () => {
  if (translateLoading.value) return;
  console.log(hasMore.value, 'hasMore.value')
  if (isLoading.value || !hasMore.value) return;

  if (emptyObj?.code === 5209) {
    return
  }
  gaAnalytics("p_search_page_load_more", "p_search_page_load_more");

  try {
    isLoading.value = true;
    store.commit("setLoading", true);
    store.commit("setLoadMoreSleleton", true);

    // 文搜(关键字或者类目搜索)
    if (route.query.keyword || route.query.categoryId) {
      await searchByKeyword();
    }
    // similar 图搜
    if (route.query.type === "similar") {
      await searchByImage();
    }
    // styles 推荐搜索
    if (route.query.type === "styles") {
      await searchByRecommend();
    }

    isLoading.value = false;
    store.commit("setLoading", false);
    store.commit("setLoadMoreSleleton", false);
    store.commit('search/SET_PRE_SEARCH', false);
  } catch (error) {
    isLoading.value = false;
    store.commit("setLoading", false);
    store.commit("setLoadMoreSleleton", false);
    store.commit('search/SET_PRE_SEARCH', false);
  }
}, 300);

// 等待元素出现
const waitForElement = () => {
  return new Promise((resolve, reject) => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === "childList") {
          const element = document.querySelector(".intercom-launcher");
          if (element) {
            observer.disconnect(); // 找到元素后停止观察
            resolve(element); // 返回找到的元素
          }
        }
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
};

onMounted(() => {
  // 监听 windows 滚动
  window.addEventListener("scroll", layoutOnScroll);
  gaAnalytics("p_page_view", "p_page_view", {
    bdUserId: localStorage.getItem("dsp.uid"),
  });
  buriedPointUtil({ eventId: "PWH501" });

  waitForElement().then((element) => {
    element.addEventListener("click", function () {
      gaAnalytics("p_consultation_click", "p_consultation_click");
      buriedPointUtil({ eventId: "PWH202" });
    });
  });

  // initImageSearch()

  // findSimilarSearch()
  // 判断是否是从 google 登录回调过来的
  if (/code|access_token/.test(window.location.hash)) {
    store.commit("setLoading", true);
    const registerChannel = !!localStorage.getItem('source') ? localStorage.getItem('source') : 2;
    const access_token = getHashParam("access_token");
    // google 登录
    fetchData("/dsp-app/ul/login", {
      method: "post",
      body: JSON.stringify({
        loginType: "google",
        registerChannel,
        registerTerminal: 1,
        saasChannel: 12,
        accessToken: access_token,
      }),
    })
      .then(async ({ data }) => {
        store.commit("setLoading", false);
        localStorage.setItem("tk", data.tk);
        localStorage.setItem("dsp.uid", data.uid);
        getUserInfo(data.tk);
        if (data?.newUser) {
          gaAnalytics("p_register_success", "continue_with_google", {
            userId: data.uid,
            newUser: data?.newUser,
            bdUserId: data.uid,
            loginType: "continue_with_google",
          });
        }
        const url = localStorage.getItem('loginRedirectUrl') || '/';
        window.location.href = url
      })
      .catch((error) => {
        store.commit("setLoading", false);
      });
  }


  nextTick(() => {
    if (headerRef.value) {
      headerHeight.value = headerRef.value.offsetHeight;  // 获取高度
    }
  });

});

const getUserInfo = async (tk) => {
  const { code, data } = await fetchData("/dsp-app/alibaba-inter/queryMyInfo", {
    method: "post",
    tk,
  });
  if (code === 200) {
    store.commit("common/SET_USER_INFO", data);
  }
};

// 根据坐标裁剪图片
const cutImageByCoordinates = async (imgUrl, coordinates, picRegionInfo) => {
  const response = await fetch(imgUrl);
  const blob = await response.blob();
  const base64Image = await blobToBase64(blob);

  const image = new Image();
  image.src = base64Image;
  const list = [];

  return new Promise((resolve, reject) => {
    image.onload = () => {
      const canvas = document.getElementById('canvas');
      const ctx = canvas.getContext('2d');

      coordinates.forEach((coord, index) => {
        const width = coord.y0 - coord.x0;
        const height = coord.y1 - coord.x1;

        canvas.width = width;
        canvas.height = height;

        ctx.clearRect(0, 0, canvas.width, canvas.height); // 清空画布
        ctx.drawImage(
          image,
          coord.x0,
          coord.x1,
          width,
          height,
          0,
          0,
          width,
          height
        );
        list.push({
          imgUrl: canvas.toDataURL(),
          region: picRegionInfo?.yoloCropRegion?.split(';')[index],
        });
      });

      resolve(list);
    }
  })
}

const initImageSearch = async () => {
  const { imageId } = route.query;
  const currentMainImage = sessionStorage.getItem(`currentMainImage_${imageId}`) || ''
  if (!imageId || !currentMainImage) {
    return
  }
  store.commit('setProductList', []);
  store.commit('setTotal', -1)
  store.commit('setInitSkeleton', true);
  try {
    const { code, data } = await fetchData('/dsp-app/alibaba-common/searchImage', {
      method: 'post',
      body: JSON.stringify({
        ...searchInfo.value,
        keyword: '',
        imageId,
        pageNow: 1,
        pageSize: 50,
      }),
    })
    if (code === 200) {
      const { picRegionInfo, currentRegion } = data;

      const coordinates = calculateCropDimensions(picRegionInfo);
      cutImageByCoordinates(currentMainImage, coordinates, picRegionInfo).then(list => {
        store.dispatch('setImgSearch', {
          ...data,
          canvasImgList: list,
          searchInfo: {
            ...searchInfo.value,
            keyword: '',
            imageId,
            pageNow: 1,
            pageSize: 50,
            region: picRegionInfo?.currentRegion,
          },
          currentMainImage: currentMainImage
        });
        if (window.location.pathname.includes('/offer')) {
          router.replace(`/${redirectWithSearch()}`);
        }
        store.commit('setLoading', false);
        store.commit('setInitSkeleton', false);
      })
    }
  } catch (error) {
    console.log('error: ', error);
  }
}

const findSimilarSearch = async () => {
  const { offerId = '' } = route.query;
  const currentMainImage = sessionStorage.getItem(`currentMainImage_${offerId}`) || ''

  if (!currentMainImage || !offerId) {
    return
  }
  store.commit('setProductList', []);
  store.commit('setTotal', -1)
  store.commit('setInitSkeleton', true);

  await fetchData('/dsp-app/alibaba-common/searchImage', {
    method: 'post',
    body: JSON.stringify({
      ...searchInfo.value,
      imageAddress: currentMainImage,
      imageId: 0,
      pageNow: 1,
      pageSize: 50,
    })
  }).then(async (v) => {
    const { picRegionInfo, currentRegion } = v.data;
    const coordinates = calculateCropDimensions(picRegionInfo);
    cutImageByCoordinates(currentMainImage, coordinates, picRegionInfo).then(list => {
      store.dispatch('setImgSearch', {
        ...v.data,
        canvasImgList: list,
        searchInfo: {
          ...searchInfo.value,
          keyword: '',
          imageId: 0,
          pageNow: 1,
          pageSize: 50,
          region: picRegionInfo?.currentRegion,
          imageAddress: currentMainImage,
        },
        currentMainImage: currentMainImage
      });
      store.commit('setLoading', false);
      store.commit('setInitSkeleton', false);
    })
  })
  .catch((error) => {
    store.commit('setLoading', false);
    store.commit('setInitSkeleton', false);
  });
}

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const calculateCropDimensions = (picRegionInfo) => {
  const regions = picRegionInfo?.yoloCropRegion?.split(';');

  // 将每个区域的字符串分割并转换为对象
  const result = regions.map((region) => {
    const [x0, y0, x1, y1] = region.split(',').map(Number);
    return {
      x0,
      y0,
      x1,
      y1,
    };
  });

  return result;
};

const preSearch = computed(() => store.state.search.preSearch);
watch(preSearch, (value) => {
  if (value) {
    loadMore();
  }
})

onBeforeUnmount(() => {
  window.removeEventListener("scroll", layoutOnScroll);
});
</script>

<style scoped>
.layout {
  position: relative;
  overflow: hidden;

  .content {
    padding-bottom: 64px;
  }

  .ceiling {
    position: fixed;
    z-index: 999;
    visibility: hidden;
    opacity: 0;
    /* transform: translateY(-100%); */
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  .ceiling-up {
    visibility: initial;
    opacity: 1;
    transform: translateY(0px);
  }

  .ceiling-down {
    visibility: initial;
    opacity: 0;
    transform: translateY(-100%);
  }
}
</style>
