import store from "../store";
import router from "../router";
import { gaAnalytics, buriedPointUtil } from "./utils";

const apiUrl = import.meta.env.VITE_API_URL;
let isRedirect = false
/**
 * 封装的 fetch 请求函数
 * @param {string} url 请求地址
 * @param {object} options 请求配置
 * @returns {Promise} 返回一个 Promise 对象，处理响应或错误
 */
export const fetchData = async (url, options = {}, headers = {}) => {
  if (localStorage.getItem("deviceId") === null) {
    localStorage.setItem("deviceId", "web" + Math.random().toString(36).substr(2));
  }
  
  const defaultOptions = {
    method: "GET", // 默认 GET 请求
    headers: {
      "Content-Type": "application/json",
      tk: localStorage.getItem("tk") || "",
      deviceId: localStorage.getItem("deviceId"),
      userId: localStorage.getItem("dsp.uid"),
      ...headers,
    },
    ...options,
  };

  try {
    const response = await fetch(`${apiUrl}${url}`, defaultOptions);

    // 判断是否请求成功
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // 假设返回的结果是 JSON 格式
    const data = await response.json();

    // 禁用国家ip
    if (data.code === 302) {
      if (isRedirect === true) return data
      isRedirect = true
      router.replace(data.redirectUrl)
    }
    
    // 免登录url列表
    const noLoginUrlList = ['/queryMyInfo', '/cart/cartCount']
    const needLogin = noLoginUrlList.findIndex(item => url.includes(item)) === -1

    if (data.code === 401 && needLogin) {
      store.commit('h5Module/closeImageSearchDialog');

      localStorage.setItem('loginRedirectUrl', window.location.href)
      const urlParmas = window.location.search
      router.push(`/guide${urlParmas}`)
      
      localStorage.removeItem('tk')
      localStorage.removeItem('dsp.uid')
      const element = document.querySelector(".layout");
      if (element) {
        element.scrollTop = 0;
        document.body.scrollTop = 0;
      }
    }
    if(data.code === 500 && !url.includes('/cart/cartCount')) {
      if(url.includes('/dsp-app/alibaba-common/recommend/productListV2') && window.location.pathname === '/') {
        store.commit("setLoading", false)
        return data
      }
      store.commit("setEmptyObj", {
        msg: data.msg,
        showEmpty: true,
      });
      store.commit("setLoading", false);
    }
    if(data.code === 5209) {
      store.commit("setEmptyObj", {
        msg: data.msg,
        showEmpty: true,
        code: 5209
      });
      store.commit("setLoading", false);
    }

    return data;
  } catch (error) {
    // 错误处理
    store.commit("setLoading", false);
    console.error("Fetch Error:", error);
    throw error;
  }
};