<template>
  <H5Account v-if="isMobile === 'MOBILE'" />
</template>

<script setup>
import { onMounted, ref } from 'vue';
import H5Account from './H5Account/index.vue'

const isMobile = ref('');
onMounted(() => {
  const userAgent = navigator.userAgent.toLowerCase();
  isMobile.value = /iphone|ipod|ipad|android|blackberry|windows phone/i.test(userAgent) ? "MOBILE" : "PC";
});
</script>