<template>
  <div class="homeview-main">
    <div v-for="(item, index) in productList" key="index">
      <div 
        class="h5product-item" 
        :class="{ activePress: index === currentPressIndex || index === 3 && pressMask === 'show' && route.path === '/' }"
        @click="goDetail(item.offerId, item.monthSold, item.ext)" 
        :data-id="item.offerId"
        ref="boxes">
        <div 
          v-if="index === 3 && pressMask === 'show' && route.path === '/'" 
          @touchstart="(e) => startPress(e, item, index)" 
          @touchmove="movePress"
          @touchend="endPress"
          class="h5product-item-mask">
          <div class="h5product-item-mask-content">
            <div class="image-wrapper">
              <img @touchstart.prevent src="@/assets/H5/home/long-touch.png" alt="">
            </div>
            <div class="text">Long press here to “find similar” or “find styles”</div>
          </div>
        </div>
        <div class="item-img" :style="{ height: dynamicHeight + 'px' }">
          <img :src="item.imageUrl" alt="">
        </div>
        <div 
          class="item-main" 
          :style="{ pointerEvents: index === currentPressIndex ? 'none' : 'auto'}"
          @touchstart="(e) => startPress(e, item, index)" 
          @touchmove="movePress"
          @touchend="endPress">
          <div class="item-money">
            <IntlCurrency :count="productPrice(item)" :size="16" :currency="item?.priceInfo?.targetCurrency?.slice(0, 2)" />
          </div>
          <div class="item-price">
            <span>{{ item?.priceInfo?.fromCurrency?.slice(0, 2) }} {{ item?.priceInfo?.fromUnit }}{{
              item?.priceInfo?.price
              }}</span><span> (Factory Price)</span>
          </div>
          <div class="item-title">
            {{ item?.subjectTrans }}
          </div>
          <div class="item-order">
            Min.order: {{ item?.minOrderQuantity }} pieces
          </div>
          <div class="item-sold">
            <img src="@/assets/H5/home/fire.svg" alt="">
            <span class="item-sold-num">
                {{ new Intl.NumberFormat().format(item?.monthSold || 0) }}
            </span>
            <span class="item-sold-title">sold</span>
          </div>
          <div v-if="repeatPurchasePercent(item)" class="item-repeat">
            <img src="@/assets/H5/home/repeat.svg" alt="">
            <span class="item-repeat-num">{{ repeatPurchasePercent(item) }}%</span>
            <span class="item-repeat-title">repeat customer</span>
          </div>
        </div>
      </div>
    </div>
    <H5LoadMore />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, nextTick, watch, computed } from 'vue';
import { useStore } from 'vuex';
import H5LoadMore from './components/H5LoadMore/index.vue'
import { buriedPointUtil, gaAnalytics, throttle } from '@/utils/utils';
import { useRoute, useRouter } from 'vue-router';
import { redirectWithSearch } from '@/utils/utils';
import { bankersRound } from '../../../utils/utils';

const store = useStore()
const router = useRouter();
const route = useRoute();

const dynamicHeight = ref(new Array(6).fill(167));
let resizeObserver = null;
const boxes = ref(null);

const productList = computed(() => store.state.productList)

const pressMask = ref(localStorage.getItem('pressMask') || 'show')

const searchInfo = computed(() => store.state.searchInfo)

watch(productList, (newData) => {
  if (newData) {
    dynamicHeight.value = new Array(newData.length).fill(167);
  }
});

const productPrice = computed(() => (item) => {
  if (item) {
    const price = item?.priceInfo?.price || 0;
    const rate = item?.priceInfo?.rate || 1;
    return bankersRound(price * rate, 2)
  } else {
    return ''
  }
})

const repeatPurchasePercent = computed(() => (item) => {
  if (item) {
    const repeatPurchasePercent = item?.sellerDataInfo?.repeatPurchasePercent || 0
    return Number((repeatPurchasePercent * 100).toFixed(0))
  }
  return ''
})

const updateHeight = () => {
  boxes.value.forEach((box, index) => {
    if (box) {
      const width = box.offsetWidth; // 获取当前宽度
      dynamicHeight.value[index] = width; // 更新对应的高度
    }
  });
};

onMounted(async () => {
  await nextTick(); // 确保 DOM 已完全渲染
  if (boxes?.value && boxes.value.length > 0) {
    resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    boxes.value.forEach((box) => {
      if (box) {
        resizeObserver.observe(box); // 为每个元素添加观察器
      }
    });

    updateHeight(); // 初始化高度
  } else {
  }

  observer.value = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        const offerId = entry.target.dataset.id;
        observerIdList.value.add(offerId);
        reportExposure();
      }
    })
  }, { threshold: 0.5 })
});

onUnmounted(() => {
  if (resizeObserver) {
    resizeObserver.disconnect();
  }
});

const showImageSearch = computed(() => store.state.h5Module.imageSearchDialogVisible)

const goDetail = (id, monthSold, recExt) => {
  if((location.pathname.includes('/search') || location.pathname.includes('/offer')) || showImageSearch.value) {
    buriedPointUtil({ eventId: "MPSC205", spuId: id });
    gaAnalytics('mp_search_page_click', "mp_search_page_click",{ spuId: id });
  }else{
    gaAnalytics('mp_home_product_click', "mp_home_product_click", { spuId: id });
    if (!showImageSearch.value) buriedPointUtil({ eventId: "MPHM203", spuId: id, recExt: recExt });
  }

  const url = `/offer/${id}${redirectWithSearch(`key=${btoa(monthSold)}`)}`
  window.open(url, '_blank')
};

const pressTimer = ref(null);
const currentPressIndex = ref(null);
const startPress = (e, item, index) => {
  if (navigator.maxTouchPoints < 2) {
    e.preventDefault();
  }

  currentPressIndex.value = index;
  pressTimer.value = setTimeout(() => {
    store.commit('h5Module/setPressProductItem', item);
    store.commit('h5Module/setPressProductSearchVisible', true);

    // 默认显示一次
    localStorage.setItem('pressMask', 'hidden');
    pressMask.value = 'hidden'

    setTimeout(() => {
      currentPressIndex.value = null;
    }, 300);
  }, 500);
};

const movePress = (e) => {
  endPress(e);
}

const endPress = (e) => {
  if (pressTimer.value) {
    clearTimeout(pressTimer.value);
    pressTimer.value = null;
    currentPressIndex.value = null;
  }
};

const lastPageSize = ref(0)
watch(productList, (value, oldValue) => {
  if (value && value.length > 0) {
    productExposure();
    lastPageSize.value = value.length - oldValue.length;
  }
})

const observer = ref(null);
const observerIdList = ref(new Set()); // 正在观察的 offerId 列表
const reportedOfferIdList = ref([]); // 已报告的 offerId 列表
const productExposure = () => {
  setTimeout(() => {
    document.querySelectorAll('.h5product-item').forEach(product => {
      observer.value.observe(product);
    })
  }, 500);
}

const reportExposure = throttle(() => {
  let offerIdList = [];
  observerIdList.value.forEach(id => {
    if (reportedOfferIdList.value.indexOf(id) === -1) {
      offerIdList.push(id);
      reportedOfferIdList.value.push(id); // 添加到已报告的列表中
    }
  })

  if (offerIdList.length > 0) {
    buriedPointUtil({ eventId: "MPEX101", others: { offerIdList } })
    preSearch();
  }
}, 500)

const preSearch = () => {
  const reportedCount = observerIdList.value.size; // 已曝光商品总数

  const half = Math.floor(lastPageSize.value / 2)
  const point = productList.value.length - half
  if (reportedCount >=  point) {
    store.commit('search/SET_PRE_SEARCH', true);
  }
}

defineExpose({
  reportedOfferIdList
})
</script>


<style>
@import './index.less';
</style>