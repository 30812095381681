// 备注: 1-Search，2-Category，3-Sourcing，4-Product，5-Language

export const COMPLETED_LIST = [
  {
    id: 1,
    label: "Search",
    videoSrc: "https://www.youtube.com/embed/ymZmuIMHJHY?si=WhxX9lT8MWqmm-iI",
    title: "how to use search",
  },
  {
    id: 2,
    label: "Category",
    videoSrc: "https://www.youtube.com/embed/5qap5aO4i9A&enablejsapi=1",
    title: "how to use category",
  },
  {
    id: 3,
    label: "Sourcing",
    videoSrc: "https://www.youtube.com/embed/PdTC4gF0cpw?si=oLxhSEfoFTXmfT6P",
    title: "how to use sourcing",
  },
  {
    id: 4,
    label: "Product",
    videoSrc: "https://www.youtube.com/embed/4XlzbzKpjdM?si=21mTp1sS15XFaX-F",
    title: "how to use product",
  },
  {
    id: 5,
    label: "Language",
    videoSrc: "https://www.youtube.com/embed/ymZmuIMHJHY?si=WhxX9lT8MWqmm-iI",
    title: "how to use language",
  },
];

export const COMPLETED_OBJ = () => {
  let obj = {};
  COMPLETED_LIST.forEach((item) => {
    obj[item.id] = item;
  });
  return obj;
};
