<template>
  <div class="addressInfoDialog" @click.self="close">
    <div class="addressInfoDialog-content" v-if="!showSuccessDialog">
      <img src="@/assets/offer/address-header.svg" alt="" class="addressInfoDialog-header-img">
      <!-- <div class="addressInfoDialog-close" @mouseenter="mouseenter" @mouseleave="mouseleave" @click="handleClose">
        <img src="@/assets/offer/address-close.svg" alt="" v-if="!showHoveredClose">
        <img src="@/assets/offer/address-hover-close.svg" alt="" v-if="showHoveredClose">
      </div> -->

      <div class="addressInfoDialog-body" v-if="!showEditModel">
        <div class="addressInfoDialog-main">
          <div class="header-text">Add shipping address to help us build your delivery posting</div>
        </div>
        <div class="form form-first">
          <div class="form-item">
            <label for="name" class="form-item-title">Name*</label>
            <input type="text" class="form-name-input" v-model="formParams.name"
              @input="(e) => handleInput(e, 'name')" />
            <div class="address-error" v-if="verifyNameError">
              <img src="@/assets/error.svg" alt="" /> {{ verifyNameError }}
            </div>
          </div>
          <div class="form-item">
            <label for="name" class="form-item-title">Phone Number</label>
            <div class="form-item-phone">
              <div class="country-item">
                <img :src="formParams.areaCodeImg" alt="" style="width: 20px; height: 15px;" class="country-img">
                <input v-model="formParams.phoneCode" @focus="openAreaDropdown" @blur="closeAreaDropdown"
                  @input="filterAreaCodeOptions" :placeholder="formParams.placeholderSearchText"
                  class="form-country-input areaCode-select" />
                <img src="@/assets/offer/address-down.svg" alt="" class="phone-down">
                <transition name="dropdown-fade">
                  <div v-if="isAreaCodeDropdownOpen" class="dropdown areaCode-dropdown">
                    <div v-for="option in filteredCountryList" :key="option.countryName"
                      @click="selectAreaCodeOption(option)" class="dropdown-item">
                      <img :src="option?.countryUrl" alt="" class="dropdown-item-img">
                      {{ option.countryName }}
                      {{ (option.phoneCode) }}
                    </div>
                  </div>
                </transition>
              </div>
              <input type="text" class="form-name-input areaCode-input" v-model="formParams.phoneValue" />
            </div>
          </div>
        </div>
        <div class="form form-country">
          <div class="form-item">
            <label for="name" class="form-item-title">Country*</label>
            <div class="country-item">
              <img :src="formParams.searchImg" alt="" style="width: 20px; height: 15px;" class="country-img">
              <input v-model="formParams.searchCountryText" @focus="openDropdown" @blur="closeDropdown"
                @input="filterOptions" :placeholder="formParams.placeholderSearchText" class="form-country-input" />
            </div>
            <transition name="dropdown-fade">
              <div v-if="isDropdownOpen" class="dropdown">
                <div v-for="option in filteredCountryList" :key="option.countryName" @click="selectOption(option)"
                  class="dropdown-item">
                  <img :src="option?.countryUrl" alt="" class="dropdown-item-img">
                  {{ option.countryName }}
                </div>
              </div>
            </transition>
          </div>

        </div>
        <div class="form">
          <div class="form-item">
            <label for="name" class="form-item-title">Street Address*</label>
            <input type="text" class="form-name-input" v-model="formParams.streetAddress"
              @input="(e) => handleInput(e, 'streetAddress')" />
            <div class="address-error" v-if="verifyStreetAddressError">
              <img src="@/assets/error.svg" alt="" /> {{ verifyStreetAddressError }}
            </div>
          </div>
          <div class="form-item">
            <label for="name" class="form-item-title">Apt, suite, etc. (optional)*</label>
            <input type="text" class="form-name-input" v-model="formParams.aptEtc"
              @input="(e) => handleInput(e, 'aptEtc')" />
            <div class="address-error" v-if="verifyAptEtcError">
              <img src="@/assets/error.svg" alt="" /> {{ verifyAptEtcError }}
            </div>
          </div>
          <div class="form-item">
            <label for="name" class="form-item-title">State / Province*</label>
            <input type="text" class="form-name-input" v-model="formParams.province"
              @input="(e) => handleInput(e, 'province')" />
            <div class="address-error" v-if="verifyAptProvinceError">
              <img src="@/assets/error.svg" alt="" /> {{ verifyAptProvinceError }}
            </div>
          </div>
          <div class="form-item">
            <label for="name" class="form-item-title">City / Suburb*</label>
            <input type="text" class="form-name-input" v-model="formParams.city"
              @input="(e) => handleInput(e, 'city')" />
            <div class="address-error" v-if="verifyAptCityError">
              <img src="@/assets/error.svg" alt="" /> {{ verifyAptCityError }}
            </div>
          </div>
          <div class="form-item form-item-bottom">
            <label for="name" class="form-item-title">Post Code*</label>
            <input type="text" class="form-name-input" v-model="formParams.postCode"
              @input="(e) => handleInput(e, 'postCode')" />
            <div class="address-error" v-if="verifyAptPostCodeError">
              <img src="@/assets/error.svg" alt="" /> {{ verifyAptPostCodeError }}
            </div>
          </div>
          <div class="form-item form-item-bottom">
            <label for="name" class="form-item-title">Email</label>
            <input type="text" class="form-name-input" v-model="formParams.email"
              @input="(e) => handleInput(e, 'email')" />
            />
            <div class="address-error" v-if="verifyEmailError">
              <img src="@/assets/error.svg" alt="" /> {{ verifyEmailError }}
            </div>
          </div>
        </div>
      </div>

      <div class="addressInfoDialog-content addressInfoDialog-detail" v-if="showEditModel">
        <div class="addressInfoDialog-main">
          <div class="header-text">Shipping Address</div>
        </div>
        <div class="addressInfoDialog-info">
          <div class="address-title">Address information</div>
          <div class="address-detail">
            <div class="address-detail-left">
              <img src="@/assets/offer/address-info.svg" alt="" class="addressInfoDialog-info-img">
              <div class="address-detail-item">
                <div class="address-detail-name">{{ formParams.name }}, {{ formParams.phoneValue }}</div>
                <div class="address-detail-code">{{ formParams.streetAddress }}, {{ formParams.aptEtc }}, {{
                  formParams.province }} {{ formParams.city }}, {{ formParams.postCode }}</div>
              </div>
            </div>
            <div class="address-detail-right" @click="showEdit">Edit</div>
          </div>
        </div>
      </div>

      <div class="addressInfoDialog-bottom">
        <div class="addressInfoDialog-cancel" @click="handleCancel">Cancel</div>
        <div class="addressInfoDialog-save" @click="saveAddress"> {{ !showEditModel ? 'Save' : 'Confirm' }}</div>
      </div>
    </div>
    <div class="addressInfoDialog-success" v-if="showSuccessDialog">
      <div class="success-content">
        <img src="@/assets/offer/address-success.png" alt="" class="success-header-img">
        <div class="success-title">
          Sourcing Request Successful
        </div>
        <div class="success-desc">
          We will notify you of the procurement results via email, so please pay attention to check it at that time.
        </div>
        <div class="success-btn" @click="handleClose">
          Confirm
        </div>
      </div>
      <div class="addressInfoDialog-close" @mouseenter="mouseenter" @mouseleave="mouseleave" @click="handleClose">
        <img src="@/assets/offer/address-close.svg" alt="" v-if="!showHoveredClose">
        <img src="@/assets/offer/address-hover-close.svg" alt="" v-if="showHoveredClose">
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { fetchData } from '../../../../../utils/fetch';
import { buriedPointUtil, gaAnalytics } from '../../../../../utils/utils';

const store = useStore()

const props = defineProps({
  // handleClickOutside: Function,
  currentCountry: Object,
  handleClose: Function,
  showAddressInfoDialogData: Object
});

const currentCountry = props.currentCountry;
const handleClose = props.handleClose
const showAddressInfoDialogData = props.showAddressInfoDialogData
const countryList = computed(() => store.state.countryInfo)
const userInfo = computed(() => store.state.common.userInfo);
const showHoveredClose = ref(false)

const crrencyDataUpdated = computed(() => store.state.currencyData);

const filteredCountryList = ref(countryList.value)

const isDropdownOpen = ref(false)
const isAreaCodeDropdownOpen = ref(false)
const showSuccessDialog = ref(false)

const address = localStorage.getItem('addressForm') ? JSON.parse(localStorage.getItem('addressForm')) : {
  name: '',
  searchImg: currentCountry?.countryUrl,
  phoneCode: currentCountry?.phoneCode,
  searchCountryText: '',
  placeholderSearchText: currentCountry?.countryName,
  streetAddress: '',
  aptEtc: '',
  province: '',
  city: '',
  postCode: '',
  email: userInfo.value?.email,
  areaCodeImg: currentCountry?.countryUrl,
  placeholderAreaCodeText: `${currentCountry?.countryName} (${currentCountry.phoneCode})`,
  phoneValue: '',
  countryCode: currentCountry?.countryCode
}

const showEditModel = ref(!!localStorage.getItem('addressForm'))
const formParams = reactive(address)

const showEdit = () => {
  showEditModel.value = false
  buriedPointUtil({eventId: "PAC201"});
}

const handleCancel = () => {

  if(showEditModel.value) {
    buriedPointUtil({eventId: "PAC203"});
  }else {
    buriedPointUtil({eventId: "PAE202"});
  }

  if (showEditModel.value && localStorage.getItem('addressForm')) {
    handleClose()
    return
  }
  if (!showEditModel.value && localStorage.getItem('addressForm')) {
    showEditModel.value = true
    return
  }
  if (!showEditModel.value && !localStorage.getItem('addressForm')) {
    handleClose()
    return
  }
}

const touched = reactive({
  name: false,
  streetAddress: false,
  aptEtc: false,
  province: false,
  city: false,
  postCode: false,
  email: false
});

const verifyNameError = computed(() => {
  if (!formParams.name && touched.name) return 'The Name is required.';
  if (formParams.name.length > 100) return 'Cannot exceed 100 characters.';
  return '';
});

const verifyStreetAddressError = computed(() => {
  if (!formParams.streetAddress && touched.streetAddress) return 'The Street Address is required.';
  if (formParams.streetAddress.length > 200) return 'Cannot exceed 200 characters.';
  return '';
});

const verifyAptEtcError = computed(() => {
  if (!formParams.aptEtc && touched.aptEtc) return 'The Apt, suite, etc. (optional) is required.';
  if (formParams.aptEtc.length > 200) return 'Cannot exceed 200 characters.';
  return '';
});

const verifyAptProvinceError = computed(() => {
  if (!formParams.province && touched.province) return 'The State / Province is required.';
  return '';
});

const verifyAptCityError = computed(() => {
  if (!formParams.city && touched.city) return 'The City / Suburb is required.';
  return '';
});

const verifyAptPostCodeError = computed(() => {
  if (!formParams.postCode && touched.postCode) return 'The Post Code is required.';
  if (formParams.postCode.length > 20) return 'Cannot exceed 20 characters.';
  return '';
});

const verifyEmailError = computed(() => {
  if (formParams.email.length > 100) return 'Cannot exceed 100 characters.';
  return '';
});

const handleInput = (e, file) => {
  formParams[file] = e.target.value;
  touched[file] = true
}

const saveAddress = async () => {

  if (!!verifyNameError.value || !formParams.name) {
    touched.name = true;
    return;
  }
  if (!!verifyStreetAddressError.value || !formParams.streetAddress) {
    touched.streetAddress = true;
    return;
  }
  if (!!verifyAptEtcError.value || !formParams.aptEtc) {
    touched.aptEtc = true;
    return;
  }
  if (!!verifyAptProvinceError.value || !formParams.province) {
    touched.province = true;
    return;
  }
  if (!!verifyAptCityError.value || !formParams.city) {
    touched.city = true;
    return;
  }
  if (!!verifyAptPostCodeError.value || !formParams.postCode) {
    touched.postCode = true;
    return;
  }

  if (!!verifyEmailError.value) {
    touched.postCode = true;
    return;
  }

  if(showEditModel.value) {
    buriedPointUtil({eventId: "PAC202"});
  }else {
    buriedPointUtil({eventId: "PAE201"});
  }


  if (!showEditModel.value) {
    localStorage.setItem('addressForm', JSON.stringify(formParams))
    showEditModel.value = true
    return
  } else {
    const { mergedArray, price,  ...others } = showAddressInfoDialogData;
    const skus = mergedArray.flatMap(item =>
      item.skuShipInfos.map(sku => ({
        ...sku,
        price: (sku.price * crrencyDataUpdated.value.rate).toFixed(2),
        skuPrice: (sku.price * crrencyDataUpdated.value.rate).toFixed(2)
      }))
    );
    const currentObj = {
      ...others,
      supplierFee: showAddressInfoDialogData?.supplierFee === "unknown" ? 'Need a quote' : (Math.ceil(showAddressInfoDialogData?.supplierFee * crrencyDataUpdated.value.rate* 100) / 100).toFixed(2),
      skus,
      titleCn: mergedArray[0]?.titleCn,
      price: (price * crrencyDataUpdated.value.rate).toFixed(2),
      customerInfo: {
        email: formParams?.email,
        name: formParams?.name,
        phone: formParams?.phoneValue,
        phoneCode: formParams?.phoneCode,
        country: !formParams.searchCountryText ? currentCountry?.countryName : formParams.searchCountryText,
        countryCode: formParams?.countryCode,
        province: formParams?.province,
        city: formParams?.city,
        streetAddr: formParams?.streetAddress,
        extAddr: formParams?.aptEtc,
        zip: formParams?.postCode
      }
    }
    try {
      store.commit('setLoading', true);
      const { code, data } = await fetchData(
        '/dsp-app/alibaba-inter/source-purchase',
        {
          method: 'post',
          body: JSON.stringify(currentObj)
        }
      )
      if (code === 200) {
        showSuccessDialog.value = true
        gaAnalytics('p_address_success', "p_address_success", { bdUserId: localStorage.getItem('dsp.uid') });
      }
      store.commit('setLoading', false);
    } catch (error) {
      store.commit('setLoading', false);
    }
  }

}

const close = () => {
  handleClose()
}

const openDropdown = () => {
  isDropdownOpen.value = true;
};

const closeDropdown = () => {
  isDropdownOpen.value = false;
};

const openAreaDropdown = () => {
  isAreaCodeDropdownOpen.value = true
}

const closeAreaDropdown = () => {
  isAreaCodeDropdownOpen.value = false
}

const selectOption = (option) => {
  formParams.searchCountryText = option.countryName;
  formParams.placeholderSearchText = option.countryName;
  formParams.searchImg = option.countryUrl,
  formParams.countryCode = option.countryCode
  closeDropdown();
};

const filterOptions = (e) => {
  filteredCountryList.value = countryList.value.filter(v =>
    v.countryName.toLowerCase().includes(e.target.value.toLowerCase())
  )
};

const selectAreaCodeOption = (option) => {
  formParams.placeholderAreaCodeText = `${option.countryName} (${option.phoneCode})`;
  formParams.areaCodeImg = option.countryUrl
  formParams.phoneCode = option.phoneCode
  closeDropdown();
}

const filterAreaCodeOptions = (e) => {
  filteredCountryList.value = countryList.value.filter(v =>
    v.countryName.toLowerCase().includes(e.target.value.toLowerCase())
  )
}

const mouseenter = () => {
  showHoveredClose.value = true
}

const mouseleave = () => {
  showHoveredClose.value = false
}

</script>

<style lang="less">
@import './index.less';
</style>