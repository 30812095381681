<template>
  <div class="clock">
    <div class="digit">{{ timeParts[0] }}</div>
    <div class="colon">:</div>
    <div class="digit">{{ timeParts[1] }}</div>
    <div class="colon">:</div>
    <div class="digit">{{ timeParts[2] }}</div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeMount } from 'vue'

const props = defineProps({
  // 时间戳
  time: {
    type: Number,
    required: true
  }
})

const timeParts = ref(["24", "59", "59"])
const interval = ref(null);

const startCountdown = (startTime) => {
  // 计算结束时间 24小时后
  const endTime = new Date(startTime.getTime() + 24 * 60 * 60 * 1000);

  const updateClock = () => {
    const currentTime = new Date();
    const timeDiff = endTime.getTime() - currentTime.getTime();

    if (timeDiff <= 0) {
      clearInterval(interval.value);
      return;
    }

    const hours = Math.floor(timeDiff / (1000 * 60 * 60));
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
    
    timeParts.value = [
      hours.toString().padStart(2, '0'),
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0')
    ];
  };

  updateClock();
  interval.value = setInterval(updateClock, 1000);
};

onMounted(() => {
  startCountdown(new Date(props.time));
})

onBeforeMount(() => {
  clearInterval(interval.value);
})
</script>

<style lang="less" scoped>
.clock {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: mon-600;
  color: white;

  .digit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 19px;
    height: 19px;
    background-color: black;
    border-radius: 4px;
  }

  .colon {
    margin: 0 4px;
    color: #232323;
  }
}
</style>