<template>
  <div class="h5-sourcing-failed-content">
    <img src="@/assets/H5/offer/sourcing-failed.png" alt="" class="h5-sourcing-failed-content-image">
    <div class="h5-sourcing-failed-content-title">
      Invalid products
    </div>
    <div class="h5-sourcing-failed-content-reason">
      This product has been removed.
      <br>
      {{ props.reason || '' }}
    </div>
    <div class="h5-sourcing-failed-content-button" @click="handleBackHome">
      Back Home
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();

const props = defineProps({
  reason: {
    type: String,
    required: true,
  },
});

const handleBackHome = () => {
  router.replace('/');
}

</script>
<style lang="less" scoped>
.h5-sourcing-failed-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
}
.h5-sourcing-failed-content-image {
  width: 160px;
  margin-top: 80px;
}
.h5-sourcing-failed-content-title {
  font-size: 14px;
  color: #232323;
  line-height: 18px;
  font-family: mon-600;
}
.h5-sourcing-failed-content-reason {
  margin-top: 8px;
  font-family: mon-500;
  color: #B2B7C2;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  padding: 0px 64px;
}
.h5-sourcing-failed-content-button {
  margin-top: 16px;
  width: 110px;
  height: 36px;
  border: 1px solid #B2B7C2;
  border-radius: 20px;
  font-family: mon-600;
  font-size: 12px;
  line-height: 36px;
  text-align: center;
  color: #232323;
}
</style>