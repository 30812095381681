<template>
  <div class="source-popup-overlay" v-show="visible" @click.self="close">
    <div class="source-popup-content" :style="{ height: `${height}`, ...customStyle }">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, onMounted } from "vue";

const props = defineProps({
  visible: {
    type: Boolean,
    required: true,
  },
  height: {
    type: String,
  },
  customStyle: {
    type: Object,
    default: () => {}
  }
});

const emit = defineEmits(["update:visible"]);

const close = () => {
  emit("update:visible", false);
};

</script>

<style lang="less">
@import './index.less';
</style>