<template>
  <div class="help-center">
    <div class="breadcrumb">
      <div class="breadcrumb-inner">
        <a href="/">Home</a>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.09056 6.00171L3.96484 2.87428L4.57142 2.26857L8.30284 6.002L4.57113 9.73143L3.96542 9.12514L7.09056 6.00171Z" fill="#232323" stroke="#232323" stroke-width="0.5"/>
        </svg>
        <a>Account</a>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.09056 6.00171L3.96484 2.87428L4.57142 2.26857L8.30284 6.002L4.57113 9.73143L3.96542 9.12514L7.09056 6.00171Z" fill="#232323" stroke="#232323" stroke-width="0.5"/>
        </svg>
        <a>Help Center</a>
      </div>
    </div>
    <div class="header">
      <div class="help-header-icon">
        <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_2033_20244)">
            <rect y="36" width="72" height="72" rx="36" transform="rotate(-30 0 36)" fill="#009DE0"/>
            <g clip-path="url(#clip1_2033_20244)">
              <path d="M26.0154 63.2394C26.1543 63.1592 26.2616 63.0542 26.3309 62.9281C26.4034 62.8001 26.4371 62.6644 26.4337 62.5242C26.4335 62.3822 26.3954 62.2491 26.3227 62.1232C26.2481 61.994 26.1519 61.8945 26.0322 61.8214C25.9125 61.7484 25.7801 61.7129 25.6348 61.715C25.4896 61.717 25.3482 61.7556 25.2093 61.8357C25.0737 61.9141 24.9709 62.0121 24.8998 62.135C24.8286 62.258 24.7931 62.3904 24.7933 62.5324C24.7935 62.6745 24.8283 62.8094 24.9029 62.9386C24.9737 63.0613 25.0699 63.1608 25.1947 63.2352C25.3195 63.3096 25.4538 63.3483 25.5977 63.3514C25.743 63.3493 25.883 63.3159 26.0154 63.2394Z" fill="white"/>
              <path d="M24.4936 50.586L19.8266 50.5286L24.5421 46.8285L24.55 46.0961L18.2841 46.0144L18.276 46.9105L22.9431 46.9679L18.2275 50.668L18.2165 51.4022L24.4824 51.4839L24.4936 50.586Z" fill="white"/>
              <path d="M26.1749 39.8484L25.4268 39.6344L24.4127 43.144L22.4691 42.5823L23.3406 39.5641L22.6111 39.3522L21.7395 42.3704L19.8895 41.8365L20.869 38.4459L20.121 38.2318L18.8922 42.4812L24.9134 44.2201L26.1749 39.8484Z" fill="white"/>
              <path d="M27.8675 36.5581L24.7084 32.3097L29.802 33.7401L30.3338 32.9679L26.3579 27.6925L25.8752 28.3932L29.1156 32.7152L23.9191 31.2409L23.4535 31.9188L26.6546 36.2549L21.4973 34.7665L20.9715 35.5266L27.3251 37.3492L27.8675 36.5581Z" fill="white"/>
              <path d="M38.3214 26.181C38.4934 25.539 38.357 24.8254 37.9071 24.0386L36.1423 20.9447L35.3879 21.376L37.1358 24.4408C37.4813 25.0467 37.6011 25.5675 37.4934 26.0001C37.3857 26.4326 37.088 26.7897 36.5952 27.0699C36.1088 27.3464 35.6489 27.4225 35.2237 27.2976C34.7966 27.1695 34.4119 26.8016 34.0632 26.1976L32.3152 23.1328L31.5377 23.5688L33.3024 26.6627C33.7523 27.4494 34.2976 27.9313 34.9414 28.1066C35.5852 28.2818 36.2633 28.1659 36.979 27.757C37.703 27.3476 38.1475 26.8197 38.3214 26.181Z" fill="white"/>
              <path d="M44.0564 24.7821C44.3638 24.5314 44.5643 24.252 44.6628 23.9453C44.7614 23.6386 44.7698 23.3323 44.6879 23.0264C44.6037 22.709 44.4689 22.4681 44.2817 22.3006C44.0946 22.133 43.8771 22.0174 43.6293 21.9537C43.3815 21.8901 43.1154 21.8543 42.8327 21.8495C42.55 21.8448 42.2711 21.8465 41.9959 21.8546C41.7207 21.8628 41.4648 21.8598 41.2297 21.8405C40.9914 21.823 40.7927 21.7698 40.6235 21.678C40.4542 21.5862 40.3416 21.4359 40.2837 21.2239C40.2362 21.0446 40.2424 20.8688 40.3073 20.6978C40.369 20.5286 40.4997 20.3671 40.6965 20.2232C40.8934 20.0794 41.1603 19.9598 41.5008 19.8709C41.7739 19.7993 42.0624 19.762 42.3701 19.7652C42.6809 19.7666 42.9865 19.8184 43.2918 19.9221L43.3943 19.1523C43.0955 19.045 42.7598 18.9932 42.3888 18.9921C42.0178 18.991 41.6581 19.0393 41.306 19.1306C40.7765 19.2727 40.3587 19.4665 40.0596 19.7168C39.7605 19.967 39.56 20.2464 39.4647 20.5512C39.3694 20.856 39.3629 21.1655 39.4485 21.4778C39.5346 21.7985 39.6698 22.0477 39.857 22.2153C40.0442 22.3828 40.2635 22.5017 40.5113 22.5653C40.7591 22.629 41.0252 22.6648 41.3098 22.6728C41.5943 22.6808 41.8732 22.6791 42.1466 22.6677C42.4199 22.6563 42.6776 22.6625 42.9127 22.6818C43.1478 22.7011 43.3535 22.759 43.5209 22.8475C43.6901 22.9393 43.8009 23.0864 43.8569 23.2952C43.9044 23.4745 43.8964 23.6471 43.8296 23.8148C43.7628 23.9826 43.6316 24.1358 43.4329 24.2764C43.2341 24.417 42.9603 24.532 42.6082 24.6234C42.2201 24.7269 41.8325 24.7568 41.4378 24.722C41.0463 24.6854 40.7068 24.5971 40.4226 24.4555L40.2812 25.2175C40.5849 25.3781 40.9633 25.474 41.4227 25.5016C41.879 25.531 42.3423 25.4831 42.8011 25.3604C43.3279 25.2285 43.749 25.0328 44.0564 24.7821Z" fill="white"/>
              <path d="M51.0235 23.6461L47.3725 23.7299L47.3279 21.7057L50.4682 21.6325L50.4509 20.8716L47.3106 20.9448L47.2669 19.0192L50.7911 18.9397L50.7729 18.1621L46.3526 18.2637L46.4929 24.5262L51.0417 24.4236L51.0235 23.6461Z" fill="white"/>
              <path d="M53.3975 24.8068L53.8343 22.9526L55.3394 23.3067C55.4732 23.3371 55.6052 23.3643 55.7315 23.3818L56.6026 25.5619L57.5533 25.7838L56.5846 23.3974C56.6461 23.392 56.7076 23.3867 56.7686 23.373C57.1865 23.2911 57.5354 23.1198 57.8099 22.8493C58.0863 22.5821 58.2772 22.2264 58.3825 21.7823C58.4846 21.3401 58.4754 20.9363 58.3447 20.5681C58.2173 20.1982 57.9832 19.8897 57.6453 19.6326C57.3074 19.3755 56.8698 19.1846 56.3359 19.0579L53.9579 18.4972L52.5265 24.6035L53.3975 24.8068ZM57.3178 20.4677C57.55 20.7729 57.6141 21.145 57.5115 21.5789C57.4088 22.0129 57.1849 22.3187 56.841 22.4914C56.4972 22.6641 56.0458 22.6879 55.4864 22.5543L54.0068 22.207L54.6524 19.4614L56.132 19.8087C56.6932 19.9454 57.0856 20.1625 57.3178 20.4677Z" fill="white"/>
              <path d="M60.6459 22.672L61.5413 21.6943C61.6247 21.6074 61.6905 21.5349 61.7388 21.4769C61.7871 21.4188 61.8258 21.3664 61.8528 21.3163C61.8799 21.2662 61.907 21.2162 61.9257 21.1666C61.993 21.0071 62.0039 20.8544 61.9514 20.7039C61.8989 20.5533 61.7939 20.446 61.6326 20.3754C61.4783 20.3095 61.3274 20.3019 61.1755 20.3594C61.0235 20.417 60.9149 20.5271 60.8397 20.6954C60.7679 20.8618 60.7653 21.014 60.8275 21.159C60.8602 21.2305 60.9058 21.2946 60.9625 21.348L60.1259 22.4426L60.6459 22.672Z" fill="white"/>
              <path d="M66.9026 23.8879C66.6539 23.6138 66.3758 23.3782 66.0714 23.1792C65.6107 22.8811 65.1857 22.7044 64.7996 22.6474C64.4103 22.5922 64.0719 22.6326 63.7843 22.7685C63.4967 22.9043 63.2598 23.1057 63.0857 23.3785C62.9038 23.6601 62.811 23.9247 62.8112 24.1786C62.8115 24.4326 62.8708 24.6697 62.9898 24.8981C63.1088 25.1266 63.2605 25.3447 63.4456 25.5609C63.6307 25.777 63.8185 25.9829 64.0123 26.1768C64.2028 26.3726 64.3748 26.5661 64.5182 26.7547C64.6615 26.9433 64.7581 27.133 64.8042 27.3173C64.8504 27.5017 64.8147 27.686 64.7003 27.8683C64.6011 28.0246 64.4656 28.133 64.2956 28.1967C64.1256 28.2604 63.9221 28.2659 63.6883 28.2114C63.4512 28.1588 63.1814 28.0347 62.877 27.8357C62.5416 27.6202 62.2564 27.35 62.0186 27.0351C61.784 26.7183 61.6216 26.4072 61.5333 26.1051L60.8759 26.5105C60.9593 26.8413 61.1454 27.1861 61.431 27.5465C61.7167 27.907 62.0627 28.2154 62.4626 28.4755C62.9233 28.7737 63.3502 28.9536 63.7413 29.012C64.1325 29.0704 64.476 29.0314 64.7706 28.9001C65.0633 28.7655 65.2969 28.566 65.4664 28.3002C65.6438 28.0255 65.7333 27.7628 65.7317 27.514C65.7301 27.2651 65.6689 27.0248 65.5518 26.7996C65.4328 26.5711 65.2811 26.353 65.0946 26.1419C64.9082 25.9309 64.7204 25.725 64.528 25.526C64.3356 25.327 64.1686 25.1348 64.0221 24.9481C63.8755 24.7614 63.7808 24.5749 63.7328 24.3873C63.6847 24.1997 63.7237 24.0136 63.8413 23.8294C63.9405 23.6731 64.0793 23.5628 64.2455 23.4927C64.4136 23.4258 64.6185 23.4152 64.8569 23.4627C65.0954 23.5102 65.3633 23.6311 65.6588 23.8222C65.8936 23.9752 66.1156 24.1658 66.3215 24.3957C66.5275 24.6256 66.6971 24.8895 66.8251 25.1859L67.462 24.745C67.3372 24.4467 67.1513 24.1621 66.9026 23.8879Z" fill="white"/>
              <path d="M72.9629 35.3405C72.8204 35.3324 72.6804 35.3659 72.5429 35.4409C72.4021 35.5179 72.2929 35.6197 72.2186 35.7445C72.1442 35.8692 72.1054 36.0036 72.1056 36.1456C72.1058 36.2876 72.1406 36.4225 72.2082 36.5471C72.2796 36.6782 72.3744 36.7828 72.4941 36.8558C72.6138 36.9288 72.7449 36.9694 72.8888 36.9725C73.0327 36.9755 73.1759 36.9402 73.3167 36.8632C73.4542 36.7882 73.5569 36.6901 73.6299 36.5704C73.703 36.4507 73.7417 36.3164 73.7466 36.1758C73.7515 36.0351 73.7199 35.8983 73.6486 35.7672C73.5809 35.6426 73.4829 35.5399 73.3613 35.4637C73.2366 35.3893 73.1055 35.3487 72.9629 35.3405Z" fill="white"/>
              <path d="M78.223 47.0972C77.8396 46.918 77.4145 46.8232 76.9509 46.811C76.4874 46.7987 76.0556 46.8714 75.664 47.0286C75.2724 47.1857 74.9265 47.407 74.6299 47.6989C74.3332 47.9907 74.0984 48.3373 73.9285 48.7368C73.7606 49.1396 73.6668 49.5813 73.6555 50.0616C73.6436 50.5034 73.6978 50.9329 73.8231 51.3515C73.9453 51.7719 74.1343 52.1365 74.3873 52.4555L76.8293 52.5228L76.8546 51.6641L74.8193 51.6075C74.7159 51.4433 74.6356 51.2743 74.583 51.0937C74.4858 50.7837 74.4414 50.4605 74.4515 50.1274C74.46 49.7693 74.5289 49.441 74.6562 49.1393C74.7834 48.8376 74.9618 48.5796 75.1842 48.3607C75.4067 48.1418 75.6692 47.9773 75.968 47.8608C76.2668 47.7443 76.5862 47.6934 76.9244 47.7048C77.2709 47.7158 77.5909 47.7851 77.8811 47.9148C78.1712 48.0444 78.4228 48.2264 78.632 48.4545C78.8412 48.6826 79.001 48.952 79.1116 49.2629C79.2222 49.5737 79.2722 49.9066 79.2636 50.2646C79.2545 50.6144 79.1857 50.9427 79.0598 51.2393C78.9358 51.5392 78.7431 51.8098 78.4875 52.0607L79.0281 52.6315C79.359 52.3543 79.6091 52.0118 79.7817 51.602C79.9561 51.1956 80.0476 50.7423 80.0597 50.2486C80.0737 49.7582 80.0051 49.3112 79.8585 48.9005C79.7119 48.4899 79.4965 48.1319 79.2139 47.8214C78.9401 47.5187 78.6064 47.2764 78.223 47.0972Z" fill="white"/>
              <path d="M73.7702 54.9436C73.1752 55.2484 72.7553 55.7966 72.5048 56.5786C72.2548 57.3689 72.2778 58.0576 72.5839 58.6474C72.89 59.2373 73.4757 59.67 74.3411 59.9455L77.7363 61.03L77.9991 60.2022L74.6395 59.1273C73.9733 58.9133 73.5344 58.6154 73.3203 58.2223C73.1081 57.8323 73.0852 57.3675 73.26 56.8273C73.4302 56.2941 73.7139 55.9278 74.1183 55.7331C74.5176 55.5371 75.0529 55.5467 75.7191 55.7607L79.0787 56.8356L79.3515 55.9804L75.9562 54.896C75.0927 54.6236 74.3632 54.6357 73.7702 54.9436Z" fill="white"/>
              <path d="M70.7876 60.8064L70.3477 61.5859L75.8041 64.6658L76.2441 63.8863L70.7876 60.8064Z" fill="white"/>
              <path d="M67.6457 65.3189C67.2029 65.8201 66.9087 66.3473 66.7682 66.9022C66.6278 67.457 66.6385 67.9977 66.8037 68.5225C66.9689 69.0473 67.2827 69.5164 67.7486 69.9279C68.2144 70.3394 68.7161 70.5966 69.2568 70.6979C69.7976 70.7991 70.3359 70.7467 70.8697 70.5376C71.4035 70.3284 71.8937 69.9765 72.3365 69.4754L74.0893 67.4987L69.4017 63.3404L67.6457 65.3189ZM71.7835 68.9204C71.4392 69.3087 71.0662 69.58 70.6677 69.7326C70.2691 69.8852 69.8687 69.9226 69.4663 69.8448C69.064 69.767 68.6879 69.5707 68.3349 69.2577C67.9889 68.9493 67.7473 68.5979 67.6202 68.2062C67.493 67.8144 67.4806 67.4125 67.5861 66.9984C67.6916 66.5844 67.9165 66.1833 68.2608 65.795L69.383 64.5312L72.9057 67.6566L71.7835 68.9204Z" fill="white"/>
              <path d="M60.4144 71.1136L60.8378 71.765L63.9034 69.7797L65.0027 71.4749L62.3651 73.1871L62.7811 73.8255L65.4154 72.1151L66.463 73.7282L63.5072 75.6501L63.9274 76.3032L67.6378 73.8983L64.2277 68.6407L60.4144 71.1136Z" fill="white"/>
              <path d="M55.2358 73.8789L58.4292 72.7415L60.2716 77.9094L61.1141 77.6082L59.0121 71.7073L54.973 73.1478L55.2358 73.8789Z" fill="white"/>
              <path d="M52.9023 73.4208L53.9707 79.5947L54.8527 79.4421L53.7843 73.2681L52.9023 73.4208Z" fill="white"/>
              <path d="M50.0443 73.7544L49.8749 78.4214L46.2883 73.6189L45.5549 73.5944L45.3274 79.854L46.225 79.887L46.3912 75.2218L49.9811 80.0225L50.7112 80.0488L50.9419 73.7874L50.0443 73.7544Z" fill="white"/>
              <path d="M39.3567 71.824L39.1222 72.5666L42.61 73.6623L42.0006 75.5903L39.0033 74.6507L38.7762 75.3762L41.7734 76.3158L41.1987 78.1549L37.8318 77.0971L37.6006 77.8378L41.8176 79.1671L43.696 73.186L39.3567 71.824Z" fill="white"/>
              <path d="M36.2641 74.4353C36.2555 74.1819 36.1879 73.9453 36.0592 73.7225C35.9305 73.4996 35.7723 73.2852 35.5826 73.076C35.3929 72.8668 35.1954 72.6665 34.9971 72.4795C34.7969 72.2894 34.6235 72.1009 34.4705 71.918C34.3207 71.7331 34.2195 71.5504 34.1682 71.3647C34.117 71.1789 34.1444 70.9951 34.2537 70.8115C34.3478 70.6538 34.4796 70.5389 34.649 70.4669C34.8153 70.3968 35.0183 70.3829 35.2567 70.4304C35.4952 70.4779 35.7677 70.5919 36.0799 70.7821C36.4231 70.9887 36.7176 71.2451 36.9618 71.5562C37.2093 71.8655 37.3795 72.1678 37.478 72.4727L38.1224 72.0447C38.0274 71.7162 37.8316 71.377 37.5349 71.0272C37.2382 70.6774 36.8844 70.3779 36.4753 70.1317C36.0054 69.8475 35.5712 69.6847 35.1792 69.6397C34.7872 69.5948 34.4441 69.6421 34.157 69.7863C33.8699 69.9305 33.6432 70.1346 33.4793 70.4101C33.3108 70.6926 33.2315 70.958 33.2414 71.2064C33.2513 71.4548 33.3189 71.6913 33.4476 71.9142C33.5763 72.1371 33.7345 72.3515 33.9274 72.5588C34.1203 72.7661 34.316 72.9632 34.513 73.1553C34.71 73.3473 34.8866 73.5339 35.0364 73.7187C35.1875 73.8984 35.2906 74.0844 35.3418 74.2701C35.3963 74.454 35.3625 74.6415 35.2486 74.8322C35.1544 74.9899 35.0227 75.1047 34.8551 75.1799C34.6907 75.2533 34.4863 75.2723 34.2465 75.2298C34.0067 75.1874 33.7328 75.0785 33.4345 74.8976C33.1952 74.7516 32.9686 74.568 32.7548 74.3469C32.5392 74.1226 32.365 73.8657 32.2273 73.5748L31.6066 74.0365C31.7393 74.326 31.933 74.6017 32.1914 74.8703C32.4479 75.1356 32.7339 75.3624 33.0461 75.5525C33.516 75.8368 33.9451 75.9982 34.3352 76.0399C34.7254 76.0817 35.0615 76.0298 35.3486 75.8855C35.6357 75.7413 35.8586 75.5308 36.0258 75.2534C36.1901 74.9561 36.2726 74.6888 36.2641 74.4353Z" fill="white"/>
            </g>
            <path d="M37.3176 60.6433L33.1176 53.3687L34.5102 52.5647L41.8262 55.3244L41.1507 55.7144L38.1267 50.4767L39.7895 49.5167L43.9895 56.7913L42.6073 57.5893L35.2809 54.8356L35.9564 54.4456L38.9804 59.6833L37.3176 60.6433ZM44.5433 50.6796L48.0455 48.6576L48.8015 49.9671L45.2993 51.9891L44.5433 50.6796ZM46.396 53.6006L50.3554 51.3146L51.1354 52.6656L45.5028 55.9176L41.3028 48.643L46.8003 45.469L47.5803 46.82L43.756 49.028L46.396 53.6006ZM53.9116 51.0628L47.3317 45.1622L49.0776 44.1542L54.8657 49.4034L53.9927 49.9074L52.4343 42.2162L53.9932 41.3162L59.7916 46.5594L58.9499 47.0454L57.3499 39.3782L58.9607 38.4482L60.7809 47.0968L58.9726 48.1408L53.8692 43.5494L54.3472 43.2734L55.7198 50.0188L53.9116 51.0628ZM62.6884 42.6284L59.6243 38.0651L61.5572 36.9491L63.9771 41.8844L62.6884 42.6284ZM64.8388 44.8649C64.5478 45.0329 64.2547 45.0773 63.9597 44.9983C63.6646 44.9192 63.4431 44.7515 63.2951 44.4952C63.1431 44.2319 63.1106 43.9596 63.1977 43.6784C63.2807 43.3903 63.4678 43.1622 63.7588 42.9942C64.0567 42.8222 64.3477 42.7743 64.6318 42.8504C64.9189 42.9156 65.1384 43.0799 65.2904 43.3432C65.4384 43.5995 65.4729 43.8752 65.3938 44.1703C65.3217 44.4613 65.1367 44.6929 64.8388 44.8649Z" fill="white"/>
          </g>
          <defs>
            <clipPath id="clip0_2033_20244">
              <rect y="36" width="72" height="72" rx="36" transform="rotate(-30 0 36)" fill="white"/>
            </clipPath>
            <clipPath id="clip1_2033_20244">
              <rect width="56" height="61.8629" fill="white" transform="translate(9.49414 36.2959) rotate(-30)"/>
            </clipPath>
          </defs>
        </svg>

      </div>
      <div class="left">
        <h3>Customer Help Center</h3>
        <a href="#keywords">· How to use <span>the search function?</span> </a>
        <a href="#productCard">· How to use  <span>image translation?</span></a>
        <!-- <a href="#category">How to use <span>category?</span></a> -->
        <a href="#sourcing">· How to use <span>Sourcing Now?</span></a>
      </div>
      <div class="right">
        <img src="@/assets/help-exp.png" alt="" />
      </div>
    </div>

    <h2 class="split-bar">About New User’s Useing <span>Guidelines</span></h2>

    <div class="video-list-container">
      <h2>HOW TO FIND THE PRODUCTS FOR</h2>
      <div class="your-business">
        <img src="@/assets/helpCenter/your-business.svg" alt="">
      </div>
      <ul>
        <li v-for="(item, index) in videoList" :key="item.id" :id="item.id">
          <div>
            <VideoPlayer :src="item.src" :title="item.label"></VideoPlayer>
          </div>
          <p>{{ item.title }}</p>
        </li>
      </ul>
    </div>

    <div id="sourcing">
      <div class="left">
        <div class="logo">
          <img src="@/assets/logo-bestdealer.png" alt="" />
        </div>
        <h2>Protects You From <br/> Payment to Delivery</h2>
        <div class="sub-text">How to use sourcing now?</div>
        <div class="watch-now">
          Watch Now
          <img src="@/assets/double-right-icon.svg" alt="">
        </div>
      </div>
      <div class="right">
        <div>
          <VideoPlayer
            title="New User’s Guidelines 04"
            src="https://www.youtube.com/embed/PdTC4gF0cpw?si=oLxhSEfoFTXmfT6P"
          ></VideoPlayer>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed, onMounted, ref, watch } from "vue";
import VideoPlayer from "@/components/VideoPlayer/index.vue";
import { useStore } from "vuex";
import { fetchData } from "@/utils/fetch";
const videoList = ref([
  {
    id: "keywords",
    src: "https://www.youtube.com/embed/ymZmuIMHJHY?si=WhxX9lT8MWqmm-iI",
    title: "How to use the search function?",
    label: "New User’s Guidelines 01",
  },
  // {
  //   id: "category",
  //   src: "https://www.youtube.com/watch?v=2",
  //   title: "How to use category?",
  //   label: "New User’s Guidelines 02",
  // },
  {
    id: "productCard",
    src: "https://www.youtube.com/embed/4XlzbzKpjdM?si=21mTp1sS15XFaX-F",
    title: "How to use  image translation?",
    label: "New User’s Guidelines 03",
  },
]);

const store = useStore();
</script>
<style scoped lang="less">
@import "./index.less";
</style>
