<template>
  <div class="container-detail">
    <div v-show="!showEmpty && emptyObj?.code !== 5209">
      <div class="container-main">
        <Carousel />
        <OfferInfo />
        <Sourcing />
      </div>
      <div>
        <OfferDetailInfo />
      </div>
    </div>
    <EmptyOffer v-show="showEmpty && emptyObj?.code !== 5209" />
    <Empty v-if="emptyObj?.code === 5209" :msg="emptyObj?.msg || 'Sorry, no results found'" />
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import Carousel from '@/components/Offer/Carousel/index.vue';
import OfferInfo from '@/components/Offer/OfferInfo/index.vue';
import Sourcing from '@/components/Offer/Sourcing/index.vue';
import OfferDetailInfo from '@/components/Offer/OfferDetailInfo/index.vue';
import EmptyOffer from '@/components/Offer/EmptyOffer/index.vue';
import { useStore } from 'vuex';
import { computed, onMounted, watch, ref } from 'vue';
import { buriedPointUtil, gaAnalytics, waitForIntercom } from '../../../../utils/utils';
import { fetchData } from '../../../../utils/fetch';
import Empty from "@/components/Empty/index.vue";
import { useCommonToast } from "@/hooks/useCommonToast";

const { toast } = useCommonToast();

const route = useRoute();
const store = useStore()
const offerId = route.params.id;

const showEmpty = ref(false)

onMounted(() => {
  gaAnalytics('p_product_detail_page_view', "p_product_detail_page_view", { spuId: offerId });
  buriedPointUtil({ eventId: 'MPEX101', others: { offerIdList: [offerId] } })
  
  getDetail()
  buriedEmail()

  const { isMobile } = route.query
  if(isMobile == '1') {
    toast('In the development of web functions, please go to the mobile end and reopen this email for further operations.', { type: 'error' })
    setTimeout(() => {
      window.location.href = '/'
    }, 2000)
  }
})

const emptyObj = computed(() => store.state.emptyObj);

const buriedEmail = () => {
  const { emailType = '', email_id = '', orderNo = '' } = route.query;
  if(!!emailType) {
    buriedPointUtil({ eventId: 'PPD501', emailId: atob(email_id)})
    !!orderNo && waitForIntercom(orderNo)
  }
}

const getDetail = async () => {
  try {
    showEmpty.value = true
    store.commit('setLoading', true)
    const { code, data } = await fetchData('/dsp-app/alibaba-common/searchProductDetail', {
      method: 'post',
      body: JSON.stringify({
        offerId
      })
    })

    if (code === 200) {
      store.commit('setOfferDetail', data)
      store.commit('setLoading', false)
      showEmpty.value = false
    }
  } catch (error) {
    showEmpty.value = false
    store.commit('setLoading', false)
  }
}

</script>

<style lang="less">
.container-detail {
  width: 1200px;
  margin: 32px auto 38px;

  .container-main {
    display: flex;
    column-gap: 16px;
  }
}
</style>
