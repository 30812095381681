<template>
  <div class="h5-interval" v-if="!offerData?.productSkuInfos?.[0]?.price">
    <template v-if="offerData?.productSaleInfo?.priceRangeList?.length != 1">
      <div
        v-for="(item, index) in offerData?.productSaleInfo?.priceRangeList"
        class="h5-interval-item"
        :key="index"
        :style="{
          width: offerData?.productSkuInfos?.length === 3 ? '33.33%' : '50%',
        }"
      >
        <div class="interval-item-pcs">{{ item?.startQuantityStr }} PCS</div>
        <div class="interval-item-price">
          <IntlCurrency
            :count="bankersRound(currencyData?.rate * item?.price, 2)"
            :size="14"
            :currency="currencyData?.targetCurrency.slice(0, 2)"
            color="#232323"
          />
        </div>
      </div>
    </template>
    <div v-if="offerData?.productSaleInfo?.priceRangeList?.length === 1" class="interval-price-only">
      <IntlCurrency
        :count="
          bankersRound(
            currencyData?.rate *
              offerData?.productSaleInfo?.priceRangeList[0]?.price,
            2
          )
        "
        :size="18"
        :currency="currencyData?.targetCurrency.slice(0, 2)"
        color="#232323"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { bankersRound } from "@/utils/utils";
const skuLength = ref(0);

const props = defineProps({
  offerData: Object,
  currencyData: Object,
});

const offerData = computed(() => props.offerData || {});
const currencyData = computed(() => props.currencyData || {});
console.log()
watch(
  () => offerData.value,
  (newData) => {
    if (newData) {
      console.log(newData?.productSaleInfo?.priceRangeList.length);
      skuLength.value = newData?.productSaleInfo?.priceRangeList?.length || 0;
    }
  }
);
</script>

<style>
@import "./index.less";
</style>