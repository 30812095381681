<template>
  <div>
    <section class="content">
      <router-view></router-view>
    </section>
    <Loading />

    <H5ImageSearchGuide v-if="imageSearchGuidePageVisible" />
    <H5ImageSearch v-if="imageSearchDialogVisible" />
    <PressProductSearch v-if="pressProductSearchVisible" />

    <!-- 分类弹窗 -->
     <Teleport to="body">
      <H5Dialog v-model="categoryDialogVisible" height="100%" style="z-index: 9999;">
        <H5CategoryDialog />
      </H5Dialog>
    </Teleport>
  </div>
</template>

<script setup>
import H5Dialog from '@/components/H5/H5Dialog/index.vue';
import H5CategoryDialog from '@/components/H5/H5CategoryDialog/index.vue';
import H5ImageSearchGuide from '@/components/H5/H5ImageSearchGuide/index.vue'
import H5ImageSearch from '@/components/H5/H5ImageSearch/index.vue'
import PressProductSearch from '@/components/H5/PressProductSearch/index.vue';
import Loading from '@/components/Loading/index.vue'
import { onMounted, computed, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { buriedPointUtil, gaAnalytics } from '@/utils/utils';
import { fetchData } from '@/utils/fetch'
import { checkHasQueryLanguage } from '../../../../utils/utils';

const store = useStore();
const route = useRoute();
const router = useRouter();

const imageSearchGuidePageVisible = computed(() => store.state.h5Module.imageSearchGuidePageVisible);
const imageSearchDialogVisible = computed(() => store.state.h5Module.imageSearchDialogVisible);
const pressProductSearchVisible = computed(() => store.state.h5Module.pressProductSearchVisible);
const categoryDialogVisible = computed(() => store.state.h5Module.categoryDialogVisible)

watch(() => route.fullPath, (value) => {
  openImageSearch();
})

onMounted(() => {
  waitForElement().then(element => {
    element.addEventListener('click', function () {
      gaAnalytics('mp_consultation_click', "mp_consultation_click");
      buriedPointUtil({ eventId: "MPWH202" });
    });
  });
  openImageSearch();
  getLanguageList();
  getCartCount();
})

const waitForElement = () => {
  return new Promise((resolve, reject) => {
    const observer = new MutationObserver((mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList') {
          const element = document.querySelector('.intercom-launcher');
          if (element) {
            observer.disconnect();  // 找到元素后停止观察
            resolve(element);  // 返回找到的元素
          }
        }
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
}

const openImageSearch = () => {
  const fullPath = route.fullPath;
  if (fullPath.includes('search?offerId') || fullPath.includes('search?imageId')) {
    const imageSearchInfo = JSON.parse(localStorage.getItem('imageSearchInfo'));
    if (imageSearchInfo) {
      store.commit('h5Module/showImageSearchDialog', imageSearchInfo)
    } else {
      router.push('/');
    }
  }
}

const getLanguageList = () => {
  const { lg = '' } = route.query;
  fetchData('/dsp-app/alibaba-common/language').then(res => {
    if (res.code === 200) {
      store.commit('h5Module/setLanguageList', res.data);

      const language = localStorage.getItem('language');
      if (language) {
        store.commit('h5Module/setLanguage', checkHasQueryLanguage(lg) || language)
      } else {
        localStorage.setItem('language', res.data[0].language)
        store.commit('h5Module/setLanguage', checkHasQueryLanguage(lg) || res.data[0].language)
      }
    }
  })
}

const getCartCount = () => {
  fetchData('/appb/b2b/cart/cartCount').then(res => {
    if (res.code === 200) {
      store.commit('cart/SET_CART_COUNT', res.data)
    }
  })
}
</script>
<style lang="less" scoped>
@import './index.less';

:global(.no-scroll) {
  position: fixed;
  width: 100%;
  overflow: hidden;
  position: fixed;
  width: 100%;
}

:global(.intercom-lightweight-app-launcher) {
  right: 8px !important;
  bottom: 80px !important;
  width: 40px !important;
  height: 40px !important;
  
}

:global(.intercom-lightweight-app-launcher-icon) {
  width: 40px !important;
  height: 40px !important;
}

:global(.intercom-lightweight-app-launcher-icon-open svg) {
  width: 20px !important;
  height: 20px !important;
}

.custom-category-dialog {
  ::v-deep(.popup-content) {
    border-radius: 0;
    background-color: transparent;
  }
}
</style>