<template>
  <div class="h5-camera-container">
    <div v-if="allowDialog" class="image">
      <img src="@/assets/example-search-img.jpeg" width="293px" alt="" />
    </div>
    <div v-if="uploadImageUrl" class="preview-scan-img">
      <img :src="uploadImageUrl" height="100%" width="100%" alt="">
    </div>
    <H5Dialog 
      class="h5-allow-dialog"
      :closeOnClickModal="false" 
      v-model="allowDialog" 
      height="auto">
      <AllowBox @uploadSuccess="handleUploadSuccess" />
    </H5Dialog>
  </div>
</template>

<script setup>
  import AllowBox from './components/AllowBox/index.vue'
  import { computed, onMounted, ref, watch } from 'vue';
  import { useStore } from 'vuex'
  import { uploadImage, buriedPointUtil } from '@/utils/utils'
  import { useRouter, useRoute } from 'vue-router';
  import { fetchData } from '@/utils/fetch'
  import H5Dialog from '@/components/H5/H5Dialog/index.vue';

  const store = useStore();
  const router = useRouter();
  const route = useRoute();

  const uploadImageUrl = ref(null);
  const allowDialog = ref(false);

  onMounted(() => {
    allowDialog.value = true;
  })

  const handleUploadSuccess = (file) => {
    allowDialog.value = false;
    uploadImageUrl.value = URL.createObjectURL(file);
    
    uploadImage(file).then(async res => {
      const url = res.fileUrl
      const { code, data } = await fetchData('/dsp-app/alibaba-common/imageUpload', {
        method: 'post',
        body: JSON.stringify({
          imageBase64: url
        }),
      })

      if (code === 200) {
        store.commit('h5Module/setImageSearchGuidePageVisible', false)
        store.commit('h5Module/showImageSearchDialog', {
          imageUrl: url,
          imageId: data,
          type: 'similar'
        })
        router.push(`/search?imageId=${data}&type=similar`)
        uploadImageUrl.value = null
      }

      if (code === 401) {
        uploadImageUrl.value = null
        buriedPointUtil({ eventId: "MPLG501" });
        store.commit('h5Module/setImageSearchGuidePageVisible', false)
      }
    })
  }
</script>

<style lang="less" scoped>
.h5-camera-container {
  position: fixed;
  top: 0;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #000;
  z-index: 9999;
  .image {
    position: absolute;
    top: 14px;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
  }
 
  .preview-scan-img {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
    }
    
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 60px;
      background: url("@/assets/H5/home/h5-scan-mask.png") no-repeat;
      animation: scan 3s infinite;
    }
     
    @keyframes scan {
      0% {
        top: 0;
      }

      100% {
        top: 100%;
      }
    }
  }
}

.h5-allow-dialog {
  height: 247px;
  top: auto;
  bottom: 0;
  ::v-deep(.popup-content) {
    margin: 0 20px 24px 20px;
    border-radius: 16px;
  }
}
</style>