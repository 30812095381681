<template>
  <svg
    :width="width"
    :height="height"
    :viewBox="viewBox"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.25999 6.0017L4.13428 2.87455L4.73999 2.26855L8.47142 6.00198L4.73999 9.7317L4.13428 9.12541L7.25999 6.0017Z"
      :fill="color"
    />
  </svg>
</template>
<script>
export default {
  name: "IconRight",
  props: {
    width: {
      type: String,
      default: "12",
    },
    height: {
      type: String,
      default: "12",
    },
    viewBox: {
      type: String,
      default: "0 0 12 12",
    },
    color: {
      type: String,
      default: "#fff",
    },
  },
};
</script>
