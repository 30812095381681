<template>
  <div class="shop-category">
    <div 
      class="shop-category-list"
      ref="listRef"
      @touchstart="handleTouchStart"
      @touchmove="handleTouchMove"
      @touchend="handleTouchEnd"
      :style="{ 
        transform: `translateX(-${scrollLeft}px)`,
        transition: isManualScrolling ? 'none' : 'transform 0.3s linear'
      }">
      <div class="shop-category-list-item" 
        v-for="(item, index) in firstCategoryList" 
        :key="index"
        :data-id="item?.categoryId"
        @click="toSearchPage">
        <img class="icon" :src="item?.icon" alt="">
        {{ item?.categoryName }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, onMounted, watch, onBeforeUnmount, nextTick } from 'vue'
import { useStore } from 'vuex'
import { fetchData } from '@/utils/fetch'
import { buriedPointUtil } from '@/utils/utils'
import { checkHasQueryLanguage } from '../../../../../../utils/utils';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute();

const { lg = "" } = route.query;

const language = computed(() => checkHasQueryLanguage(lg) || store.state.h5Module.language);
const firstCategoryList = ref([]);
const scrollLeft = ref(0);
const listRef = ref(null);

// 滑动相关变量
const startX = ref(0);
const isManualScrolling = ref(false);
let animationFrameId = null;

watch(language, (value) => {
  if (value) {
    localStorage.removeItem('cacheShopCategory')
    getFirstCategory();
  }
});


const loadCache = () => {
  const _cacheData = localStorage.getItem('cacheShopCategory');
  if (!_cacheData) return null;

  const cacheData = JSON.parse(_cacheData);
  if (Date.now() - cacheData.timestamp > 24 * 60 * 60 * 1000) {
    localStorage.removeItem('cacheShopCategory');
    return null;
  }
  return cacheData.data
}

const formatData = (list) => {
  const H5ClickedCategories = JSON.parse(localStorage.getItem('H5ClickedCategories')) || [];
  const _temp = [];
  if (H5ClickedCategories.length) {
    H5ClickedCategories.forEach(id => {
      const index = list.findIndex(item => item.categoryId === id);
      _temp.push(list[index]);
      list.splice(index, 1);
    })
  }
  
  const fullList = [..._temp, ...list];
  firstCategoryList.value = [...fullList, ...fullList]; // 复制一份数据用于无缝滚动
}

const getFirstCategory = async () => {
  const cacheValue = loadCache();
  if (cacheValue) {
    formatData(cacheValue)
    return;
  }

  let { code, data } = await fetchData(`/dsp-app/alibaba-common/firstLevelCategory?language=${language.value}`);
  if (code === 200) {
    const cacheData = {
      data,
      timestamp: Date.now()
    }
    localStorage.setItem('cacheShopCategory', JSON.stringify(cacheData));
    formatData(data || [])
  }
};

// 自动滚动
const smoothMove = () => {
  const animate = () => {
    if (!isManualScrolling.value) {
      scrollLeft.value += 0.5; // 降低滚动速度
      
      // 处理无缝滚动
      const listWidth = listRef.value?.scrollWidth / 2 || 0;
      if (scrollLeft.value >= listWidth) {
        isManualScrolling.value = true;
        scrollLeft.value = 0;
        // 在下一帧恢复动画，避免瞬移带来的卡顿感
        requestAnimationFrame(() => {
          isManualScrolling.value = false;
        });
      }
    }
    animationFrameId = requestAnimationFrame(animate);
  };
  animationFrameId = requestAnimationFrame(animate);
};

// 触摸开始
const handleTouchStart = (e) => {
  isManualScrolling.value = true;
  startX.value = e.touches[0].clientX + scrollLeft.value;
};

// 触摸移动
const handleTouchMove = (e) => {
  if (!isManualScrolling.value) return;
  
  const currentX = e.touches[0].clientX;
  const newScrollLeft = startX.value - currentX;
  
  // 处理边界情况
  const listWidth = listRef.value?.scrollWidth / 2 || 0;
  if (newScrollLeft < 0) {
    scrollLeft.value = listWidth + newScrollLeft;
    startX.value = currentX + scrollLeft.value;
  } else if (newScrollLeft >= listWidth) {
    scrollLeft.value = newScrollLeft - listWidth;
    startX.value = currentX + scrollLeft.value;
  } else {
    scrollLeft.value = newScrollLeft;
  }
};

// 触摸结束
const handleTouchEnd = () => {
  isManualScrolling.value = false;
};

const toSearchPage = (e) => {
  const categoryId = e.currentTarget.dataset.id || null;
  const item = firstCategoryList.value.find(item => item?.categoryId === categoryId);

  localStorage.setItem('parentCategory', JSON.stringify(item));
  store.commit('h5Module/setCategoryDialogVisible', true);
  buriedPointUtil({ eventId: "MPWH402", others: { categoryName: item.categoryName, categoryId: item.categoryId } });
};

onMounted(() => {
  getFirstCategory();
  smoothMove();
});

onBeforeUnmount(() => {
  if (animationFrameId) {
    cancelAnimationFrame(animationFrameId);
  }
});
</script>

<style lang="less">
.shop-category {
  padding: 16px 0;
  background-color: #052784;
  overflow: hidden;
  min-height: 68px;

  .shop-category-list {
    display: flex;
    flex-wrap: nowrap;
    width: fit-content;
    padding-left: 16px;
    touch-action: pan-x;
    user-select: none;
  }
  
  .shop-category-list-item {
    position: relative;
    flex-shrink: 0;
    height: 36px;
    padding: 10px 12px 10px 38px;
    border-radius: 20px;
    background-color: #cce5ff;
    color: #232323;
    font-size: 12px;
    font-family: mon-600;
    margin-right: 12px;
    overflow: hidden;
    
    &:last-child {
      margin-right: 12px;
    }

    .icon {
      position: absolute;
      top: 0;
      left: 0;
      width: 36px;
    }
  }
}
</style>