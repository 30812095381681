
<template>
  <div class="header-top">
    <div class="header-top-item">
      <span v-if="debugCounter > 0">
        <span @click="enterDebugMode" class="header-easter-eggs" style="cursor: pointer">Your</span> Gateway to Unlimited Goods!
      </span>
      <span v-else>
        <span @click="exitDebugMode" style="color: #002b5c">Click To Exit Debug Mode</span>
      </span>
    </div>
    <div class="header-top-desc">Discover billions of products for your needs.</div>
    <img src="https://cdn-image.bestdealer.com/app-bestdealer/1688/header-main.png" alt="" class="bg-image">
  </div>
</template>

<style scoped lang="less">
  .header-top {
    height: 120px;
    background-color: #052784;
    position: relative;
    .header-top-item {
      padding-top: 44px;
      font-size: 36px;
      line-height: 40px;
      color: #FFFFFF;
      font-family: mon-800;
      text-align: center;
      position: relative;
      z-index: 9;
    }
    .header-top-desc {
      text-align: center;
      font-size: 12px;
      line-height: 16px;
      font-family: mon-500;
      color: #F57231;
      margin-top: 4px;
      position: relative;
      z-index: 9;
    }
    .bg-image {
      position: absolute;
      object-fit: cover;
      width: 1920px;
      top: 0px;
    }
  }
</style>

<script setup>
import {
  ref,
  computed,
  onMounted,
  onBeforeUnmount,
  watch,
  nextTick,
} from "vue";
import {useStore} from "vuex";

const store = useStore();

const debugCounter = ref(10);

const enterDebugMode = () => {
  debugCounter.value -= 1
  if (debugCounter.value <= 0) {
    store.commit("setDebugMode", true);
  }
}

const exitDebugMode = () => {
  debugCounter.value = 10
  store.commit("setDebugMode", false);
}

</script>