<template >
  <div class="h5-partner">
    <div class="h5-partner-container">
      <img class="split-line" src="@/assets/H5/home/h5-split-line.svg" alt="">
      <div class="h5-partner-container-left">
        <div class="left-line1">
          <img src="@/assets/H5/home/h5-home-shop-icon.svg" alt="">
          <div class="left-line1-text">Shop <span class="orange">with</span> Confidence</div>
        </div>
        <div class="left-line2">Safety guarantee for you</div>
      </div>
      <div class="h5-partner-container-right">
        <div class="content-wrapper" ref="scrollContainer">
          <div class="content">
            <div class="left-line1">
              <div class="left-line1-text"><span class="orange">1688</span> Official Partner</div>
            </div>
            <div class="left-line2">Billions of source goods</div>
          </div>
          <div class="content">
            <div class="left-line1">
              <div class="left-line1-text">Support Global <span class="orange">Payment</span></div>
            </div>
            <div class="left-line2">Billions of source goods</div>
          </div>
          <div class="content">
            <div class="left-line1">
              <div class="left-line1-text">Support Global <span class="orange">Delivery</span></div>
            </div>
            <div class="left-line2">Billions of source goods</div>
          </div>
          <div class="content">
            <div class="left-line1">
              <div class="left-line1-text"><span class="orange">1688</span> Official Partner</div>
            </div>
            <div class="left-line2">Billions of source goods</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

const scrollContainer = ref(null);

onMounted(() => {
  partnerScroll();
})

const partnerScroll = () => {
  const container = scrollContainer.value;
  const contentHeight = 58; // 单个内容的总高度
  let scrollPosition = 0; // 当前滚动位置

  // 滚动逻辑
  const scroll = () => {
    scrollPosition += contentHeight; 
    container.style.transition = "transform 1s ease-in-out";
    container.style.transform = `translateY(-${scrollPosition}px)`;

    // 无缝衔接
    setTimeout(() => {
      if (scrollPosition >= contentHeight * 3) {
        scrollPosition = 0; 
        container.style.transition = "none"; 
        container.style.transform = `translateY(0)`;
      }
    }, 1000);
  };

  setInterval(scroll, 5000);
}
</script>
<style lang="less" scoped>
  .h5-partner {
    height: 56px;
    background-color: #052784;
    padding: 0 16px;

    .h5-partner-container {
      position: relative;
      height: 100%;
      display: flex;
      background: linear-gradient(
        to right,
        #0DA3A3,
        #295FCC,
        #5252E5,
        #295FCC,
        #0DA3A3
      );
      border-radius: 8px;

      .split-line {
        position: absolute;
        left: 50%;
        top: 11px;
        transform: translateX(-50%);
      }

      .h5-partner-container-left, .h5-partner-container-right {
        width: 50%;
        height: 100%;
        
        .left-line1 {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: mon-700;
          color: white;
          line-height: 16px;
          img {
            margin-right: 2px;
          }
          .orange {
            color: #F5804E;
          }
        }
        .left-line2 {
          margin-top: 2px;
          font-family: mon-500;
          font-size: 11px;
          color: #EBF5FF;
          line-height: 16px;
        }
      }
      .h5-partner-container-left {
        box-sizing: border-box;
        padding-top: 10px;
        padding-left: 12px;
      }
      .h5-partner-container-right {
        overflow: hidden;

        .content-wrapper {
          transition: all 1s;
        }
        .content {
          height: 58px;
          padding-top: 12px;
          padding-left: 12px;
          box-sizing: border-box;
        }
      }
    }
  }
</style>