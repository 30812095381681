import { fetchData } from "@/utils/fetch";
import { COMPLETED_OBJ } from "@/common/enum";
const state = {
  language: "", // 切换语言
  keyWordCn: "", // 中文关键字
  userInfo: {}, // 用户信息
  toastVisible: false,
  toastInfo: {
    type: "success",
    msg: "",
  },
  guidancePopVisible: false,
  currentGuidance: {
    title: "",
    videoSrc: "",
  },
  categoryVisible: false,
  helpCenterImage: '',
  showHelpCenterAnimate: false
};

const mutations = {
  SET_LANGUAGE(state, payload) {
    state.language = payload;
  },
  SET_KEY_WORD_CN(state, payload) {
    state.keyWordCn = payload;
  },
  SET_TOAST_VISIBLE(state, payload) {
    state.toastVisible = payload;
  },
  SET_TOAST_INFO(state, payload) {
    state.toastInfo = payload;
  },
  SET_GUIDANCE_POP_VISIBLE(state, payload) {
    state.guidancePopVisible = payload;
  },
  SET_CURRENT_GUIDANCE(state, payload) {
    state.currentGuidance = payload;
  },
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
    localStorage.setItem("userInfo", JSON.stringify(payload));
  },
  SET_CATEGORY_VISIBLE(state, payload) {
    state.categoryVisible = payload;
  },
  SET_HELPCENTER_IMAGE(state, payload) {
    state.helpCenterImage = payload;
  },
  SET_ANIMATE_HELPCENTER(state, payload) {
    state.showHelpCenterAnimate = payload;
  },
};

const actions = {
  // 打开引导弹窗
  async openGuidancePop({ commit, state }, data) {
    const { userInfo } = state;
    const { id, helpCenterImage = '' } = data;

    const complete = localStorage.getItem("complete") || "";
    const tk = localStorage.getItem("tk") || "";
    if (!tk) {
      commit("SET_CURRENT_GUIDANCE", COMPLETED_OBJ()[id]);
      commit("SET_GUIDANCE_POP_VISIBLE", true);
      commit("SET_HELPCENTER_IMAGE", helpCenterImage)
      localStorage.setItem("complete", complete + "," + id);
    }
    if (!!tk) {
      commit("SET_CURRENT_GUIDANCE", COMPLETED_OBJ()[id]);
      commit("SET_GUIDANCE_POP_VISIBLE", true);
      commit("SET_HELPCENTER_IMAGE", helpCenterImage)
      //TODO 要走请求 把当前的id传过去
      await fetchData(`/dsp-app/alibaba-inter/userComplete`, {
        method: "PUT",
        body: JSON.stringify(id)
      });
      localStorage.setItem("complete", complete + "," + id);
    }

  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
