<template>
  <div class="header-swiper">
    <div class="left" @click="scrollLeft">
      <img src="@/assets/H5/home/arrow-left.svg" alt="">
    </div>
    <div class="content-container">
      <div class="content-wrapper" :style="{ transform: `translateX(-${currentIndex * 100}%)` }">
        <div class="content">
          <div class="content-text1">products.bestdealer.com</div>
          <div class="content-text2">Directly purchase from the source manufacturer.</div>
        </div>
        <div class="content">
          <div class="content-text1">products.bestdealer.com protects you</div>
          <div class="content-text2" style="color: #EDF2B5;">from payment to delivery with trade assurance.</div>
        </div>
      </div>
    </div>
    <div class="right" @click="scrollRight">
      <img src="@/assets/H5/home/arrow-right.svg" alt="">
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'

const currentIndex = ref(0)
const totalContents = ref(2)

const scrollLeft = () => {
  if (currentIndex.value > 0) {
    currentIndex.value--;
  } else {
    currentIndex.value = 1; // 循环到最后一张
  }
}
const scrollRight = () => {
  if (currentIndex.value < totalContents.value - 1) {
    currentIndex.value++;
  } else {
    currentIndex.value = 0; // 循环滚动
  }
}
</script>
<style lang="less">
  .header-swiper {
    position: relative;
    width: 100%;
    height: 48px;
    box-sizing: border-box;
    background-color: #000000;
    text-align: center;
    font-size: 12px;
    font-family: mon-500;
    display: flex;
    align-items: center;
    justify-content: center;

    .left, .right {
      position: absolute;
      top: 16px;
      width: 16px;
      height: 16px;
      z-index: 9;
    }

    .left {
      left: 16px;
    }

    .right {
      right: 16px;
    }

    .content-container {
      overflow: hidden;
      position: relative;
    }
    
    .content-wrapper {
      display: flex;
      transition: transform 0.5s ease;
    }
    
    .content {
      flex: 0 0 100%;
      text-align: center;
      box-sizing: border-box;

      .content-text1 {
        color: white;
      }
      .content-text2 {
        color: #9DCA92;
      }
    }
  }
</style>