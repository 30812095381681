import { setThemeColor } from '../../../utils/utils';
import store from '../../index'

const h5Module = {
  namespaced: true, // 使用命名空间
  state: () => ({
    searchFoucs: false,
    imageSearchDialogVisible: false, // 图搜弹窗
    imageSearchGuidePageVisible: false, // 图搜引导页
    fullMask: false,
    categoryDialogVisible: false, // 类目弹窗
    language: localStorage.getItem('language'),
    languageList: JSON.parse(localStorage.getItem('languageList')) || [],
    filtersSeletonVisible: false,
    pressProductItem: '', // 长按搜索项
    pressProductSearchVisible: false,
  }),
  mutations: {
    setSearchFoucs(state, payload) {
      state.searchFoucs = payload;
    },
    /**
     * @param payload 
     * payload: {imageUrl: string, imageId: string, type: string}
     * */ 
    showImageSearchDialog(state, payload) {
      if (location.pathname === '/') {
        const productList = store.state.productList;
        localStorage.setItem('homeProductList', JSON.stringify(productList));
      }
      localStorage.setItem('imageSearchInfo', JSON.stringify(payload))
      state.imageSearchDialogVisible = true;
      setThemeColor('#141414')
    },
    closeImageSearchDialog(state, payload) {
      // localStorage.removeItem('imageSearchInfo')
      state.imageSearchDialogVisible = false;
      setThemeColor('')
    },
    setImageSearchGuidePageVisible(state, payload) {
      state.imageSearchGuidePageVisible = payload;
    },
    setFullMask(state, payload) {
      state.fullMask = payload;
    },
    setLanguage(state, payload) {
      state.language = payload;
      localStorage.setItem('language', payload);
    },
    setLanguageList(state, payload) {
      state.languageList = payload;
      localStorage.setItem('languageList', JSON.stringify(payload));
    },
    setCategoryDialogVisible(state, payload) {
      state.categoryDialogVisible = payload;
      setThemeColor(payload ? '#141414' : '#052785')
    },
    setFiltersSeletonVisible(state, payload) {
      state.filtersSeletonVisible = payload;
    },
    setPressProductItem(state, paylaod) {
      state.pressProductItem = paylaod;
    },
    setPressProductSearchVisible(state, payload) {
      state.pressProductSearchVisible = payload;
    },
  },
  actions: {
    
  },
  getters: {
  },
};

export default h5Module;
