<script setup>
import { ref, reactive, computed, watch, defineEmits, nextTick } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import { fetchData } from "@/utils/fetch";
import { buriedPointUtil, debounce } from "@/utils/utils";
import Loading from "@/components/Loading/index.vue";
import IconLeft from "../icons/IconLeft.vue";
import IconRight from "../icons/IconRight.vue";

const emit = defineEmits();
const router = useRouter();
const route = useRoute();
const store = useStore();

// 数据参数
const loading = ref(false); // loading 状态
const categories = ref([]); // 类目树数据
const activeCategory = reactive({}); // 当前激活的一级类目
const activeSubcategory = ref(null); // 当前激活的二级类目
const activeThirdCategory = ref(null); // 当前激活的三级类目
const isAllBtnActive = ref(false); // 是否激活全部类目按钮

const subCategoryConRef = ref(null); // 二级类目容器
const itemRefs = ref({}); // 三级类目 ref

const sliderCategories = ref([]); // 滚动类目列表
const scrollContainer = ref(null); // 滚动容器
const isLeftDisabled = ref(true); // 左滚动按钮是否禁用
const isRightDisabled = ref(false); // 右滚动按钮是否禁用

const allCategory = ref([])
const categorySubIndex = ref(0)
const categoryThirdIndex = ref(0)

const language = computed(() => store.state.common.language);
const categoryVisible = computed(() => store.state.common.categoryVisible);

const openGuidancePop = () => {

};

const scrollLeft = debounce(() => {
  const scrollWidth = scrollContainer.value.scrollWidth;
  const clientWidth = scrollContainer.value.clientWidth;
  const scrollLeft = scrollContainer.value.scrollLeft;

  nextTick(() => {
    scrollContainer.value.style.scrollBehavior = "smooth";
    scrollContainer.value.style.overflow = "auto";
    if (scrollLeft >= 600) {
      scrollContainer.value.scrollLeft -= 600;
    }
    scrollContainer.value.style.overflow = "hidden";
    isLeftDisabled.value = scrollLeft <= 610;
    isRightDisabled.value = scrollLeft >= scrollWidth - clientWidth + 601;
  });
}, 300);

const scrollRight = debounce(() => {
  const scrollWidth = scrollContainer.value.scrollWidth;
  const clientWidth = scrollContainer.value.clientWidth;
  const scrollLeft = scrollContainer.value.scrollLeft;

  nextTick(() => {
    scrollContainer.value.style.scrollBehavior = "smooth";
    scrollContainer.value.style.overflow = "auto";
    if (scrollLeft <= scrollWidth - clientWidth) {
      scrollContainer.value.scrollLeft += 600;
    }
    scrollContainer.value.style.overflow = "hidden";
    isLeftDisabled.value = scrollLeft <= -610;
    isRightDisabled.value = scrollLeft >= scrollWidth - clientWidth - 601;
  });
}, 300);

const loadFirstCategoryCache = () => {
  const _cacheData = localStorage.getItem('cacheShopCategory');
  if (!_cacheData) return null;

  const cacheData = JSON.parse(_cacheData);
  if (Date.now() - cacheData.timestamp > 24 * 60 * 60 * 1000) {
    localStorage.removeItem('cacheShopCategory');
    return null;
  }
  return cacheData.data
}

// 请求一级类目
const fetchCategories = async () => {
  const format = (data) => {
    fetchAllCategory()
    categories.value = data;
    let clickedCategories = JSON.parse(localStorage.getItem("clickedCategories")) || [];
    clickedCategories = clickedCategories.filter(item => item);

    const length = clickedCategories.length;
    let newData = [];
    // 固定前三个一级类目
    for (let i = 0; i < length; i++) {
      const item = data.find(item => item.categoryId === clickedCategories[i]);
      newData.push(item)
      data = data.filter(item => item.categoryId !== clickedCategories[i])
    }
    // 完整类目
    newData = [ ...newData, ...data ]
    sliderCategories.value = newData;
    if (window.location.pathname.includes('/offer')) {
      setActiveCategory('', true);
    } else {
      setActiveCategory('', true);
    }
  }

  const cacheValue = loadFirstCategoryCache()
  if (cacheValue) {
    format(cacheValue)
    return;
  }

  loading.value = true;
  let { code, data } = await fetchData(
    `/dsp-app/alibaba-common/firstLevelCategory?language=${language.value}`,
    {
      method: "get",
    }
  );
  if (code === 200) {
    localStorage.setItem('cacheShopCategory', JSON.stringify({
      data,
      timestamp: Date.now()
    }))
    format(data)
  } else {
    console.error("获取类目失败");
  }
  loading.value = false;
};

const loadCache = () => {
  const _cacheData = localStorage.getItem('cacheAllCategory')
  if (!_cacheData) return null;

  const cacheData = JSON.parse(_cacheData);
  if (Date.now() - cacheData.timestamp > 24 * 60 * 60 * 1000) {
    localStorage.removeItem('cacheAllCategory');
    return null;
  }
  return cacheData.data
}

const fetchAllCategory = () => {
  const cacheValue = loadCache()
  if (cacheValue) {
    allCategory.value = cacheValue
    return
  }

  fetchData(
    `/dsp-app/alibaba-common/allCategory?language=${language.value}`,
    {
      method: "get",
    }
  ).then((res) => {
    if (res.code === 200) {
      localStorage.setItem('cacheAllCategory', JSON.stringify({
        data: res.data,
        timestamp: Date.now()
      }))
      allCategory.value = res.data
    }
  })
}

// 请求二、三级类目
const fetchSubCategories = async (parentId) => {
  loading.value = true;
  const { code, data } = await fetchData(
    `/dsp-app/alibaba-common/subordinateCategory?language=${language.value}&firstCategoryId=${parentId}`,
    {
      method: "get",
    }
  );

  if (code === 200) {
    activeCategory.children = { ...data };
    setActiveSubcategory(data[0]);
  } else {
    console.error("获取子类目失败");
  }
  loading.value = false;
};

// 选择全部类目
const selectAllCategories = () => {
  isAllBtnActive.value = true;
  const ppId = route.query.ppId;
  const pId = route.query.pId;
  const id = route.query.categoryId;
  buriedPointUtil({ eventId: "PWH401"});
  if (id && ppId) {
    selectCategory(ppId, pId, id);
  }else {
    setActiveCategory('', true);
  }
  // setActiveCategory(categories.value[0]);
  store.commit("common/SET_CATEGORY_VISIBLE", true);
};

// 设置 ref
const setRef = (el, child) => {
  if (el) {
    itemRefs.value[child.categoryId] = el;
  }
};

// 设置一级类目并请求完整子类目树
const setActiveCategory = async (category, needBuried) => {
  const newActiveCategory = reactive({ ...category });
  activeCategory.value = newActiveCategory;
  nextTick(() => {
    const activeElement = itemRefs.value[category?.categoryId];
    if (activeElement) {
      activeElement.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });

  let clickedCategories = JSON.parse(localStorage.getItem("clickedCategories")) || [];
  clickedCategories = clickedCategories.filter(item => item);
  if (clickedCategories.indexOf(newActiveCategory?.categoryId) === -1) {
    clickedCategories.unshift(newActiveCategory?.categoryId);
    // 只取最前面三个值
    if (clickedCategories.length > 3) {
      clickedCategories = clickedCategories.slice(0, 3);
    }
  } else {
    const index = clickedCategories.findIndex(item => item === activeCategory.value.categoryId);
    clickedCategories.splice(index, 1);
    clickedCategories.unshift(newActiveCategory?.categoryId);
  }
  localStorage.setItem("clickedCategories", JSON.stringify(clickedCategories));
  if(!needBuried) {
    buriedPointUtil({
    eventId: "PWH402",
    categoryId: category?.categoryId,
    categoryName: category?.categoryName,
  });
  }

  nextTick(() => {
    const subCategoryCon = subCategoryConRef.value;
    if (subCategoryCon) {
      subCategoryCon.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  });

  // 请求二级类目
  // console.log(category, 'category?.children')
  // if (!category?.children) {
  //   await fetchSubCategories(category.categoryId);
  // }
};

const mouseenterFirstCategory = async (category, index) => {
  categorySubIndex.value = index
  const newActiveCategory = reactive({ ...category });
  activeCategory.value = newActiveCategory;
}

const mouseenterSubCategory =  async (sub, index) => {
  // console.log(category, index, 'dd')
  // categoryThirdIndex.value = index
  activeSubcategory.value = sub;
  activeThirdCategory.value = {};
  // 滚动到对应的三级类目标题
  nextTick(() => {
    const activeElement = itemRefs.value[activeSubcategory.value?.categoryId];
    if (activeElement) {
      activeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  });
}

// 设置二级类目
const setActiveSubcategory = (sub) => {
  activeSubcategory.value = sub;
  activeThirdCategory.value = {};
  // 滚动到对应的三级类目标题
  nextTick(() => {
    const activeElement = itemRefs.value[activeSubcategory.value?.categoryId];
    if (activeElement) {
      activeElement.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  });
};

// 设置三级类目
const setActiveThirdCategory = (third, parentChild, ppObj) => {
  activeThirdCategory.value = third;
  const { href } = router.resolve({
    path: "/search",
    query: {
      categoryId: third.categoryId,
      categoryName: third.categoryName,
      pName: parentChild.categoryName,
      pId: parentChild.categoryId,
      ppName: ppObj.categoryName,
      ppId: ppObj.categoryId,
    },
  })
  store.commit("common/SET_CATEGORY_VISIBLE", false);
  window.open(href, '_blank')
};

// 点击sliderItem
const clickSliderItem = (item) => {
  setActiveCategory(item, false);
  isAllBtnActive.value = false;
  store.commit("common/SET_CATEGORY_VISIBLE", true);

  const _index = allCategory.value?.findIndex(v => v.categoryId === item.categoryId)
  if(_index !== -1) {
    categorySubIndex.value = _index
  }
};
// 点击最右侧的第二级类目
const clickSubCategory = (sub) => {
  setActiveSubcategory(sub);
  activeThirdCategory.value = sub;
  const { href } = router.resolve({
    path: "/search",
    query: {
      categoryId: sub.categoryId,
      categoryName: sub.categoryName,
      ppName: activeCategory.value.categoryName,
      ppId: activeCategory.value.categoryId,
    },
  });
  store.commit("common/SET_CATEGORY_VISIBLE", false);
  window.open(href, '_blank')
};
// 选中类目
const selectCategory = (ppId, pId, id) => {
  if (ppId) {
    let activeCategory = allCategory.value.find(
      (item) => item.categoryId === ppId
    );
    if (activeCategory) {
      setActiveCategory(activeCategory);
      const _index = allCategory.value?.findIndex(v => v.categoryId === ppId)
      if(_index !== -1) {
        categorySubIndex.value = _index
      }
    }
  }
  if (pId && activeCategory?.childCategoryList) {
    console.log( Object.values(activeCategory?.children), 'activeCategory?.children')
    let activeSubcategory = Object.values(activeCategory?.children).find(
      (item) => item.categoryId === pId
    );
    if (activeSubcategory) {
      setActiveSubcategory(activeSubcategory);
    }
  }
  if (id && activeSubcategory?.childCategoryList) {
    let activeThirdCategory = activeSubcategory?.childCategoryList.find(
      (item) => item.categoryId === id
    );
    if (activeThirdCategory) {
      setActiveThirdCategory(activeThirdCategory);
    }
  }
};

watch(
  () => language.value,
  (newVal, oldValue) => {
    if (newVal) {
      if (oldValue && newVal !== oldValue) {
        localStorage.removeItem('cacheAllCategory')
        localStorage.removeItem('cacheShopCategory')
      }
      fetchCategories();
    }
  },
  { immediate: true }
);

watch(
  () => categoryVisible.value,
  (newVal) => {
    if (newVal) {
      const ppId = route.query.ppId;
      const pId = route.query.pId;
      const id = route.query.categoryId;
      if (id && ppId) {
        // selectCategory(ppId, pId, id);
      }
    }
  }
);

const isActive = (item) => {
  const id = activeCategory.value.categoryId;
  return item.categoryId === id && route.fullPath !== '/' && !isAllBtnActive
}

const handleMouseLeave = () => {
  isAllBtnActive.value = false;
  store.commit('common/SET_CATEGORY_VISIBLE', false)
}
</script>

<template>
  <div class="category-wrapper" @click="openGuidancePop"
  @mouseleave="handleMouseLeave"
    >
    <!-- loading  -->
    <Loading v-if="loading" />

    <!-- 热搜词滚动条 -->
    <div class="category-slider">
      <div class="slider-container">
        <!-- 类目列表 -->
        <div class="category-list">
          <div :class="[
            {
              active: isAllBtnActive,
            },
            'category-item first-item',
          ]" @click="selectAllCategories">
            <img class="all-icon" src="../../assets/all.svg" alt="" />
            All Categories
            <img class="select-icon" src="../../assets/select-down.svg" alt="" />
          </div>
          <div ref="scrollContainer" class="scroll-container">
            <div :class="[
              {
                active: isActive(item),
              },
              'category-item',
            ]" v-for="(item, index) in sliderCategories" :key="item.categoryName + '-' + index"
              :ref="(el) => setRef(el, item)" @click="clickSliderItem(item)">
              {{ item.categoryName }}
            </div>
          </div>
        </div>
        <!-- 左右按钮 -->
        <IconLeft :color="isLeftDisabled ? 'rgba(255, 255, 255, 0.40)' : '#fff'" :class="[
          {
            disabled: isLeftDisabled,
          },
          'move-btn left-btn',
        ]" :disabled="isLeftDisabled" @click="scrollLeft"></IconLeft>
        <IconRight :color="isRightDisabled ? 'rgba(255, 255, 255, 0.40)' : '#fff'" :class="[
          {
            disabled: isRightDisabled,
          },
          'move-btn right-btn',
        ]" @click="scrollRight"></IconRight>
      </div>
    </div>

    <!-- 类目导航 -->
    <div v-if="categoryVisible" class="category-slider-wrapper">
      <div class="category-nav-wrapper" @mouseenter.stop>
        <div class="category-nav">
          <!-- 一级类目 -->
          <div class="categories">
            <h2 class="categories-header-title">Shop by Categories</h2>
            <ul>
              <li v-for="(category, index) in allCategory" :key="category.categoryName" 
              @mouseenter="mouseenterFirstCategory(category, index)"
              :class="{
                active:
                  activeCategory?.value?.categoryId === category?.categoryId,
              }"
              >
                <span class="first-categoryName">{{ category.categoryName }}</span>
                <IconRight color="#B2B7C2"></IconRight>
              </li>
            </ul>
          </div>
          <!-- 二级类目 -->
          <div class="subcategories" v-if="allCategory[categorySubIndex]?.childCategoryList" ref="subCategoryConRef">
            <h3 class="sub-categoryName">{{ allCategory[categorySubIndex]?.categoryName }}</h3>
            <div class="subcategory-list">
              <div v-for="(sub, index) in allCategory[categorySubIndex].childCategoryList" :key="sub.categoryId" 
              @mouseenter="mouseenterSubCategory(sub, index)"
              >
                <span class="sub-categoryName-label">{{ sub.categoryName }}</span>
                <IconRight color="#B2B7C2"></IconRight>
              </div>
            </div>
          </div>
          <!-- 三级类目 -->
          <div class="third-level" v-if="allCategory[categorySubIndex]?.childCategoryList">
            <ul>
              <li v-for="(child, index) in allCategory[categorySubIndex]?.childCategoryList" :key="child.id + '-' + index"
                :ref="(el) => setRef(el, child)"
                >
                <h3 :class="{
                  active: child.categoryId === activeThirdCategory?.categoryId, 'sub-categoryName-label' : true
                }" @click="clickSubCategory(child)">
                  {{ child.categoryName }}
                </h3>
                <ul>
                  <li v-for="(grandson, index) in child?.childCategoryList" :key="grandson.categoryId + '-' + index" :class="[
                    {
                      active:
                        grandson?.categoryId === activeThirdCategory?.categoryId,
                    },
                    'grandson-item',
                  ]" @click="setActiveThirdCategory(grandson, child, allCategory[categorySubIndex])">
                    {{ grandson.categoryName }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" scoped>
@import "./index.less";
</style>
