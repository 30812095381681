
<template>
  <div class="h5-currency-content">
    <div class="currency-content-title">Currency</div>
    <img src="@/assets/H5/home/left.svg" alt="" class="currency-left"  @click="handleClose">
    <div class="h5-currency-basic">
      <div v-for="item in currency" :key="item.targetCurrency" class="currency-item" @click="selectCurrency(item)">
        <div class="currency-item-left">
          <div class="currency-item-left-title">{{ item.targetCurrency.slice(0, 2) }} ({{ item.targetUnit }})</div>
        </div>
        <!-- <img src="@/assets/H5/home/check-right.svg" alt="" v-if="currencyData.targetCurrency === item.targetCurrency"> -->
        <div v-if="currencyData.targetCurrency === item.targetCurrency" class="selected">
          <img src="@/assets/H5/filters/check.svg" alt="">
        </div>
        <div v-else class="unselected"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore()

const props = defineProps({
  handleClose: Function,
  currencyData: Object
});
const handleClose = props.handleClose;

const currencyData = ref(props.currencyData)

const currency = computed(() => store.getters.getCurrencyList).value;

const selectCurrency = (item) => {
  currencyData.value = item
  localStorage.setItem('currencyData', JSON.stringify(item))
  store.commit('setCurrencyData', item)
  store.dispatch("setProduct", item)
}

</script>

<style lang="less" scoped>
@import './index.less';
</style>